import { colors } from '@styles/colors'
import { screenSize } from '@styles/index'
import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	background-color: #ffffff;
`

export const ChildrenWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	height: auto;
	flex-grow: 1;

	@media (${screenSize.antXs}) {
		margin: 24px 16px;
	}

	@media (${screenSize.antMd}) {
		margin: 24px 48px;
	}
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

export const TempWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;

	width: 100%;
	height: 100%;
	padding: 50px;

	background-color: ${colors.white};
	font-size: 24px;
	color: ${colors.black};

	& > div {
		text-align: center;
	}
`
