import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { Col } from 'antd'

import { Block, Icon } from '@ui/index'
import { useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { styleboardsSelector } from '@store/styleboards'
import { clothesSelector } from '@store/clothes'
import { capsulesSelector } from '@store/capsules'
import { UserRole } from '@typings/types'
import { SpanStyled, StyledBlock } from './header-title-styled'

function getTitle({
	styleboardTitle,
	itemTitle,
	capsuleTitle,
	capsuleId,
	pathname,
	navigate,
	userRole,
	userName,
	stylistId,
	userId,
}: {
	styleboardTitle: string
	itemTitle: string
	capsuleTitle: string
	capsuleId: string
	pathname: string
	navigate: NavigateFunction
	userRole?: UserRole
	userName?: string
	stylistId?: string
	userId?: string
}) {
	const mainDomain = pathname.split('/')[1]
	const secondDomain = pathname.split('/')[2]
	const thirdDomain = pathname.split('/')[3]

	const isPreviewPage = !!Number(secondDomain)
	const isItemEditPage = thirdDomain === 'edit'
	const isAcceptedPage = thirdDomain === 'accepted'

	const onArrowBackClick = () => {
		navigate(`/${mainDomain}`)
	}

	switch (mainDomain) {
		case 'clients':
			return <Block>All clients</Block>

		case 'styleboards':
			if (userRole === 'client') {
				if (isAcceptedPage) {
					return (
						<Block isFlexBlock onClick={() => navigate('/styling-studio')}>
							<Icon name="arrowIcon" size={24} />
							<SpanStyled>{`Hello ${userName}`}</SpanStyled>
						</Block>
					)
				}

				return (
					<Block isFlexBlock onClick={() => navigate('/styling-studio')}>
						<Icon name="arrowIcon" size={24} />
						<SpanStyled>{`${styleboardTitle}`}</SpanStyled>
					</Block>
				)
			}

			if (isPreviewPage || isItemEditPage) {
				return (
					<Block isFlexBlock onClick={onArrowBackClick}>
						<Icon name="arrowIcon" size={24} />
						<SpanStyled>{`Styleboard: ${styleboardTitle}`}</SpanStyled>
					</Block>
				)
			}
			return <Block>My Styleboards</Block>

		case 'clothes':
			if (isItemEditPage) {
				return (
					<Block isFlexBlock onClick={onArrowBackClick}>
						<Icon name="arrowIcon" size={24} />
						<SpanStyled>Add items</SpanStyled>
					</Block>
				)
			}

			if (isPreviewPage) {
				return (
					<Block
						isFlexBlock
						onClick={
							capsuleId
								? () => {
									navigate(`capsules/${capsuleId}`, {
										state: {
											capsuleId,
										},
									})
								}
								: onArrowBackClick
						}
					>
						<Icon name="arrowIcon" size={24} />
						<SpanStyled>{itemTitle}</SpanStyled>
					</Block>
				)
			}
			return <Block>Clothes</Block>

		case 'capsules':
			if (userRole === 'client') {
				return (
					<Block
						isFlexBlock
						onClick={() => {
							navigate(`/stylists/${userId}`, {
								state: {
									userId,
									stylistId,
								},
							})
						}}
					>
						<Icon name="arrowIcon" size={24} />
						<SpanStyled>{`Back to Stylist`}</SpanStyled>
					</Block>
				)
			}

			if (isPreviewPage || isItemEditPage) {
				return (
					<Block isFlexBlock onClick={onArrowBackClick}>
						<Icon name="arrowIcon" size={24} />
						<SpanStyled>{`Capsule: ${capsuleTitle}`}</SpanStyled>
					</Block>
				)
			}

			return <Block>Capsules</Block>

		case 'stylists':
			if (isPreviewPage) {
				return (
					<Block isFlexBlock onClick={onArrowBackClick}>
						<Icon name="arrowIcon" size={24} />
						<SpanStyled>Back to all stylists</SpanStyled>
					</Block>
				)
			}

			return <Block>All stylists</Block>

		case 'styling-studio':
			return <Block>Styling studio</Block>
	}

	return undefined
}

export function HeaderTitle() {
	const { userInfo } = useAppSelector(userSelector)
	const { styleboard } = useAppSelector(styleboardsSelector)
	const { item } = useAppSelector(clothesSelector)
	const { capsule } = useAppSelector(capsulesSelector)
	const navigate = useNavigate()
	const location = useLocation()
	const { state } = useLocation()
	const capsuleId: string = state?.capsuleId ?? null
	const title = getTitle({
		pathname: location.pathname,
		navigate,
		styleboardTitle: styleboard.title,
		itemTitle: item.title,
		capsuleTitle: capsule.title,
		capsuleId: capsuleId,
		userRole: userInfo?.role,
		userName:
			userInfo?.stylist?.first_name || userInfo?.client?.first_name || 'Client',
		stylistId: state?.stylistId || capsule?.stylist_id,
		userId: state?.userId || capsule?.user_id,
	})

	const userName =
		userInfo?.stylist?.first_name || userInfo?.client?.first_name || 'Guest'

	return <StyledBlock>{title ? title : `Hello, ${userName}!`}</StyledBlock>
}
