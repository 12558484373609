import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { setUserCode, setUserEmail } from '@store/auth/auth'
import { setLoginFormStep } from '@store/login-flow'
import { useAppDispatch } from '@store/store'
import { handleToast } from '@utils/handle-toast'
import { checkExistingUser } from '../../api/check-existing-user'
import { generateAuthCode } from '../../api/generate-auth-code'

type FormValues = {
	email: string
}

export function useEmailForm() {
	const dispatch = useAppDispatch()
	const [isValidated, setIsValidated] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const {
		setError,
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm<FormValues>()

	const onSubmitEmail = async (data: FormValues) => {
		if (!data.email) {
			handleToast({ content: 'Please enter your email' })
			setError('email', {
				type: 'manual',
				message: 'Please enter your email',
			})
			return
		}

		if (!isValidated) {
			handleToast({ content: 'Please enter a valid email' })
			setError('email', {
				type: 'manual',
				message: 'Please enter a valid email',
			})
			return
		}

		dispatch(setUserEmail(data.email))

		setIsLoading(true)
		const isUserExist = await checkExistingUser({
			email: data.email,
		})

		if (!isUserExist) {
			setIsLoading(false)
			dispatch(setLoginFormStep('user-create'))
			return
		}

		dispatch(setUserCode(''))
		const userCode = await generateAuthCode({
			email: data.email,
		})

		if (!userCode?.success) {
			handleToast({ content: 'Something went wrong. Please try again' })
			setIsLoading(false)
			return
		}

		if (userCode?.code) {
			dispatch(setUserCode(userCode.code))
		}

		setIsLoading(false)
		dispatch(setLoginFormStep('code'))
	}

	const emailRegexp =
		// eslint-disable-next-line no-useless-escape
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	const formRegister = register('email', {
		onChange: (e) => {
			setIsValidated(emailRegexp.test(e.target.value))
		},
	})

	return {
		errors,
		formRegister,
		handleSubmit,
		isLoading,
		isValidated,
		onSubmitEmail,
		watch,
	}
}
