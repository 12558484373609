import { colors } from '@styles/colors'
import {
	fontRoboto,
	fontTenorSans,
	regular13,
	regular16,
} from '@styles/typography'
import styled from 'styled-components'

export const InfoCont = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: no-wrap;
	gap: 16px;
	align-items: flex-start;
	flex: 1 1;
`
export const TextCont = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	overflow: hidden;
`

export const AdditionalInfo = styled.div`
	${fontRoboto}
	${regular16}
  color: ${colors.black};

	line-height: 24px;
	min-height: 24px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	& span {
		${regular13}
		color: ${colors.blackTr64};
	}
`

export const TimeWrapper = styled.div<{ isExpired?: boolean }>`
	${fontRoboto}
	${regular16}
	line-height: 24px;
	min-height: 24px;

	color: ${(props) => (props.isExpired ? 'red' : colors.black)};

	& span {
		${regular13}
		color: ${colors.blackTr64};
	}
`
export const TitleFlex = styled.div`
	display: flex;
	flex-flow: row;
	gap: 20px;
`

export const LinkCont = styled.div`
	${fontRoboto}
	${regular16}
  color: #FF0C63;
	letter-spacing: 0.16px;
	text-decoration-line: underline;
`
