import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	CapsuleInfo,
	ReduxClientCapsules,
	ShowCapsulesListType,
} from '@typings/types'

const initialState: ReduxClientCapsules = {
	isListLoading: false,
	isCapsuleLoading: false,
	showListType: 'all',
	capsulesList: [],
	filteredCapsulesList: [],
	capsule: {
		id: 3,
		user_id: 17,
		title: 'Test-capsule new 003',
		description: 'description here...',
		status: 'draft',
		sortnum: 0,
		created_at: '2022-07-04 06:45:06',
		updated_at: '2022-07-04 07:12:29',
		images: [],
		items: [],
		summary: {
			price: 120,
			priceValute: '',
			category: {},
			color: {},
			size: {},
		},
	},
}

const slice = createSlice({
	name: 'clientCapsules',
	initialState,
	reducers: {
		setIsListLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isListLoading = payload
		},
		setIsCapsuleLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isCapsuleLoading = payload
		},
		setShowListType: (
			state,
			{ payload }: PayloadAction<ShowCapsulesListType>
		) => {
			state.showListType = payload
		},
		setCapsulesList: (state, { payload }: PayloadAction<CapsuleInfo[]>) => {
			state.capsulesList = payload
		},
		setFilteredCapsulesList: (
			state,
			{ payload }: PayloadAction<CapsuleInfo[]>
		) => {
			state.filteredCapsulesList = payload
		},
		setCapsule: (state, { payload }: PayloadAction<CapsuleInfo>) => {
			state.capsule = payload
		},
	},
})

export const ClientCapsulesReducer = slice.reducer
export const {
	setIsListLoading,
	setIsCapsuleLoading,
	setShowListType,
	setCapsulesList,
	setFilteredCapsulesList,
	setCapsule,
} = slice.actions
