import { useEffect, useState } from 'react'

import { getQuizAnswers } from '@api/quiz/get-quiz-answers'
import { getQuizQuestions } from '@api/quiz/get-quiz-questions'
import { Quiz } from '@components/quiz'
import { authSelector } from '@store/auth'
import {
	QuizSelector,
	setLocalQuizAnswers,
	setQuizQuestions,
	setQuizStep,
} from '@store/quiz'
import { useAppDispatch, useAppSelector } from '@store/store'
import { Block, Spinner } from '@ui/index'
import { handleToast } from '@utils/handle-toast'
import { QuizFooter } from './quiz-footer/quiz-footer'
import { QuizHeader } from './quiz-header/quiz-header'
import { BlockStyled, QuizCol, RowStyled } from './quiz-page-styled'

export function QuizPage() {
	const dispatch = useAppDispatch()
	const { token } = useAppSelector(authSelector)
	const { questions, currentStep } = useAppSelector(QuizSelector)

	const [isLoading, setIsLoading] = useState(false)

	const matchesClass =
		currentStep === questions.length + 1 ? 'results-frame' : ''
	const loadQuestions = async () => {
		setIsLoading(true)

		const [questions, answers] = await Promise.all([
			await getQuizQuestions({ token }),
			await getQuizAnswers({ token }),
		])

		if (!questions) {
			handleToast({ content: 'Something went wrong while questions loading' })
			setIsLoading(false)
			return
		}

		dispatch(setQuizQuestions(questions))

		if (!answers) {
			handleToast({ content: 'Something went wrong while answers loading' })
			setIsLoading(false)
			return
		}

		let tempAnswers: Record<number, string[]> = {}

		// TODO: Refactor this in future
		answers.forEach((answer) => {
			const pastAnswers = tempAnswers[answer.step_num] || []
			tempAnswers = {
				...tempAnswers,
				[answer.step_num]: [...pastAnswers, answer.answer],
			}

			dispatch(
				setLocalQuizAnswers({
					answer: tempAnswers[answer.step_num],
					step: answer.step_num,
				})
			)
		})

		setIsLoading(false)
	}

	useEffect(() => {
		loadQuestions()

		return () => {
			dispatch(setQuizStep(0))
		}
	}, [])

	return (
		<>
			<BlockStyled isFullWidth style={{height: "100%"}}>
				<RowStyled align="middle" justify="center">
					<QuizHeader />
					<QuizCol className={matchesClass}>
						{isLoading ? <Spinner /> : <Quiz />}
					</QuizCol>
					<QuizFooter />
				</RowStyled>
			</BlockStyled>
		</>
	)
}
