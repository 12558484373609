import {
	FormWrapper,
	ButtonsBlock,
	TitleWrapper,
	StyledP,
} from '../form-styled'
import { Button, Input, Skeleton, Block } from '../../../../UI'
import { useNewStylistForm } from './use-new-stylist-form'
import PhoneInput from 'react-phone-number-input'
import { Row, Col } from 'antd'
import { CookieBlock } from './new-stylist-form-styled'
import { PROD_DOMAIN } from '@typings/constants'

export function NewStylistForm() {
	const {
		defaultValues,
		emailRegister,
		errors,
		handleSubmit,
		isLoading,
		onCancelClick,
		onSubmitNewUser,
		phoneRegister,
		register,
		watch,
	} = useNewStylistForm()

	if (isLoading) {
		return (
			<Skeleton width={326} height={354}>
				<rect x="0" y="0" rx="6" ry="6" width="326" height="32" />
				<rect x="0" y="56" rx="6" ry="6" width="326" height="88" />
				<rect x="0" y="200" rx="6" ry="6" width="326" height="222" />
				<rect x="0" y="478" rx="6" ry="6" width="326" height="56" />
			</Skeleton>
		)
	}

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmitNewUser)}>
			<Block margin="0 auto">
				<TitleWrapper>Register as a Stylist</TitleWrapper>
			</Block>
			<StyledP>
				Fill in the required fields and click "Next". <br />
				We'll email you a confirmation code to proceed with your application
			</StyledP>
			<Block margin="56px auto 0">
				<Row gutter={[8, 32]}>
					<Col span={12}>
						<Input
							hasValue={!!watch('firstName')}
							label="firstName"
							placeholder="Name *"
							inputFieldSlot={<input {...register('firstName')} type="text" />}
							isError={!!errors.firstName}
						/>
					</Col>
					<Col span={12}>
						<Input
							hasValue={!!watch('lastName')}
							label="lastName"
							placeholder="Surname"
							inputFieldSlot={<input {...register('lastName')} type="text" />}
							isError={!!errors.lastName}
						/>
					</Col>

					<Col span={24}>
						<Input
							hasValue={!!defaultValues?.email || !!watch('email')}
							label="email"
							placeholder="E-mail address *"
							inputFieldSlot={<input type="email" {...emailRegister} />}
							isError={!!errors.email}
							errorText={errors.email?.message}
						/>
					</Col>
					<Col span={24}>
						<Input
							hasValue={!!watch('phone')}
							label="phone"
							placeholder="Phone number *"
							inputFieldSlot={
								<PhoneInput
									{...phoneRegister}
									international
									defaultCountry="RU"
									maxLength="16"
									onChange={() => {}}
								/>
							}
							isError={!!errors.phone}
							errorText={errors.phone?.message}
						/>
					</Col>
					<Col>
						<CookieBlock>
							By registering, you agree to our{' '}
							<a
								href={
									PROD_DOMAIN +
									'docs/Privacy_and_Cookie_Policy_StyleDyle_Ltd.pdf'
								}
								target="_blank"
								rel="noreferrer"
							>
								Privacy and Cookie Policy
							</a>
						</CookieBlock>
					</Col>
				</Row>
			</Block>
			<ButtonsBlock>
				<Button onClick={onCancelClick} skin="grey-link" width={159}>
					Cancel
				</Button>
				<Button isSubmit skin="dark" width={159}>
					Next
				</Button>
			</ButtonsBlock>
		</FormWrapper>
	)
}
