import axios from 'axios'
import { axiosInstance } from '../../config'
import { CapsuleInfo } from '../../typings/types'

type Props = {
	token: string
}

export async function getCapsulesList({ token }: Props) {
	try {
		const response = await axiosInstance.get<CapsuleInfo[]>(`/api/capsule`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
