import { Col, Row, Space, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { ReactHTMLElement, ReactNode, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { PageNavigation } from '@components/page-navigation'
import { SdNotification } from '@components/sd-notification'
import {
	Block,
	Button,
	ButtonLinkTo,
	CustomSelect,
	CustomSelectPlusAdd,
	GalleryButtons,
	GrayLine,
	Icon,
	InfoTextBlock,
	Input,
	Modal,
	Spinner,
	Swap,
} from '@ui/index'
import { ColorButton } from './color-button'
import { GalleryBlock } from './gallery-block/gallery-block'
import { useClothesEditPage } from './use-clothes-edit-page'

import {
	ColFlex,
	ColUpload,
	InlineStyle,
	ItemDataWrapper,
	SelectStyled,
} from './clothes-edit-page-styled'
import { SdInformation } from '@components/sd-information'

export function ClothesEditPage() {
	const carouselRef = useRef<any>()
	const {
		brandOptions,
		categoriesOptions,
		currencyOptions,
		currentPage,
		defaultCurrencyLabel,
		errors,
		handleAddNewPhoto,
		handleBrandSelect,
		handleCategorySelect,
		handleChangeCurrency,
		handleChangeDescription,
		handleChangeLink,
		handleChangePrice,
		handleChangeSize,
		handleChangeTitle,
		handleColorSelect,
		handleDeleteClick,
		handlePublishItem,
		handleSaveItem,
		handleSetCoverClick,
		handlePriceInputClick,
		handleUploadBtnClick,
		imageItems,
		isLoading,
		isNewPhotoLoading,
		isNotReadyPublish,
		isParsingInProgress,
		isSettingsLoading,
		item,
		itemColors,
		navigationButtons,
		register,
		watch,
		newFields,
		uploadNotificationText,
		notSupportedDomain,
		isShowSupportedDomain,
		setIsShowSupportedDomain,
		supportedURLsArr,
	} = useClothesEditPage()
	const location = useLocation()
	const isNewItem = location.state?.itemId === 'new'

	const re = /(?:^https?:\/\/(?:www\.)?)|(?:\/$)/g
	const supportedDomainsBlock = (
		<Row justify="center">
			{supportedURLsArr.map((domain) => {
				return (
					<Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
						<a href={domain} target="_blank">
							{domain.replace(re, '')}
						</a>
					</Col>
				)
			})}
		</Row>
	)

	return (
		<Block isFullWidth margin="0 48px 48px">
			<PageNavigation showListType={currentPage} buttons={navigationButtons} />
			<Swap is={isLoading} isSlot={<Spinner />}>
				<Row style={{ marginTop: 24 }} gutter={[16, 12]} align="middle">
					<Col xxl={{ span: 6 }} xl={{ span: 4 }} className="row-block-label">
						Upload an item
					</Col>
					<Col xxl={{ span: 18 }} xl={{ span: 20 }}>
						<Block isFlexBlock gap="5px">
							<div className="col-block-label-h3">Link</div>
							<SdInformation
								text="Check out the list of supported stores"
								onClick={() => setIsShowSupportedDomain(true)}
							/>
						</Block>
					</Col>
					<Col xxl={{ span: 12, offset: 6 }} xl={{ span: 14, offset: 4 }}>
						<Input
							hasValue={!!watch('clothLink') || !!newFields.source_url}
							label="clothLink"
							placeholder="Paste link here"
							inputFieldSlot={
								<input
									{...register('clothLink')}
									onChange={handleChangeLink}
									type="text"
									disabled={isParsingInProgress}
								/>
							}
							isError={!!errors.clothLink}
							errorText={errors.clothLink?.message}
						/>
					</Col>
					<Col xxl={{ span: 2, offset: 6 }} xl={{ span: 3, offset: 4 }}>
						<Button
							isDisabled={notSupportedDomain || isParsingInProgress}
							skin="dark"
							onClick={handleUploadBtnClick}
							isLoading={isParsingInProgress}
						>
							Upload
						</Button>
					</Col>
					<Col xxl={{ span: 10 }} xl={{ span: 11 }}>
						{!!uploadNotificationText && (
							<SdNotification text={uploadNotificationText} />
						)}
					</Col>
					<Col span={24} style={{ marginTop: 64 }}>
						<GrayLine />
					</Col>
				</Row>
				<ItemDataWrapper className={isNewItem ? 'disabled' : ''}>
					<Row style={{ marginTop: 24 }} gutter={[16, 12]} align="middle">
						<Col xxl={{ span: 6 }} xl={{ span: 4 }} className="row-block-label">
							Cloth name
						</Col>

						<Col xxl={{ span: 18 }} xl={{ span: 20 }}>
							<div className="col-block-label-h3">Cloth name</div>
						</Col>
						<Col
							xxl={{ span: 12, offset: 6 }}
							xl={{ span: 14, offset: 4 }}
							style={{ marginTop: 20 }}
						>
							<Input
								hasValue={!!watch('title')}
								label="title"
								placeholder="Add name here"
								inputFieldSlot={
									<input
										{...register('title')}
										onBlur={handleChangeTitle}
										type="text"
									/>
								}
								isError={!!errors.title}
								errorText={errors.title?.message}
							/>
						</Col>
						<Col span={24} style={{ marginTop: 64 }}>
							<GrayLine />
						</Col>
					</Row>
					<Row gutter={[16, 24]} style={{ marginTop: 64 }}>
						<Col xxl={{ span: 6 }} xl={{ span: 4 }} className="row-block-label">
							Item photo
						</Col>
						<Col xxl={{ span: 15 }} xl={{ span: 17 }}>
							<Block isFlexBlock gap="24px">
								<div className="col-block-label-h3">Photos</div>
								<div>{imageItems.length} of 12</div>
							</Block>
						</Col>
						<Col span={3}>
							<GalleryButtons
								onNextArrowClick={() => carouselRef.current?.next()}
								onPrevArrowClick={() => carouselRef.current?.previous()}
							/>
						</Col>
						<ColUpload xxl={{ span: 4, offset: 6 }} xl={{ span: 5, offset: 4 }}>
							<ImgCrop rotationSlider aspect={268 / 402}>
								<Upload
									onChange={isNewPhotoLoading ? () => {} : handleAddNewPhoto}
									showUploadList={false}
								>
									<ButtonLinkTo
										onClick={() => {}}
										title="Add photo"
										icon={<Icon name="uploadAvatarIcon" size={36} />}
										maxWidth="268px"
										minHeight="402px"
									/>
								</Upload>
							</ImgCrop>
						</ColUpload>
						<Col
							xxl={{ span: 14 }}
							xl={{ span: 15 }}
							style={{ overflow: 'hidden' }}
						>
							<GalleryBlock
								carouselRef={carouselRef}
								imageList={imageItems}
								handleSetCoverClick={handleSetCoverClick}
								handleDeleteClick={handleDeleteClick}
							/>
						</Col>
						<Col span={24} style={{ marginTop: 45 }}>
							<GrayLine />
						</Col>
					</Row>
					<Row gutter={16} style={{ marginTop: 64 }}>
						<Col xxl={{ span: 6 }} xl={{ span: 4 }} className="row-block-label">
							Description
						</Col>
						<Col xxl={{ span: 18 }} xl={{ span: 20 }}>
							<div className="col-block-label-h3">Common description</div>
						</Col>
						<Col xxl={{ span: 6 }} xl={{ span: 4 }} style={{ marginTop: 25 }}>
							<SdNotification text="Add any notes for your client here" />
							<Block margin="18px 0 0 0">
								<Input
									type="textArea"
									label="descriptionNote"
									inputFieldSlot={
										<textarea
											{...register('descriptionNote')}
											rows={4}
											onBlur={handleChangeDescription}
											maxLength={250}
										/>
									}
									errorText={
										errors.descriptionNote && errors.descriptionNote.message
									}
									width="100%"
									placeholder="Description"
									hasValue={!!watch('descriptionNote')}
								/>
							</Block>
						</Col>
						<Col xxl={{ span: 18 }} xl={{ span: 20 }}>
							<Row gutter={48} style={{ marginTop: 25 }}>
								{categoriesOptions && (
									<Col xxl={{ span: 5 }} xl={{ span: 7 }}>
										<CustomSelect
											placeholder="Category"
											defaultValue={newFields.params?.category}
											onChange={handleCategorySelect}
											isDisabled={isSettingsLoading}
											options={categoriesOptions}
											searchEnabled={true}
										/>
									</Col>
								)}
								<Col xxl={{ span: 5 }} xl={{ span: 7 }}>
									<CustomSelectPlusAdd
										placeholder="Brand/Designer"
										defaultValue={newFields.brand}
										onChange={handleBrandSelect}
										isDisabled={false}
										options={brandOptions}
										searchEnabled={true}
										addItemFeature
									/>
								</Col>
								<Col xxl={{ span: 5 }} xl={{ span: 7 }}>
									<Space.Compact block>
										{currencyOptions && (
											<SelectStyled
												defaultValue={defaultCurrencyLabel}
												suffixIcon={null}
												style={{ width: 40 }}
												onChange={handleChangeCurrency}
												options={currencyOptions}
											/>
										)}
										<InlineStyle />
										<Input
											hasValue={!!watch('price')}
											label="Price"
											placeholder="Price"
											rootClassName="input-text-wrapper"
											inputFieldSlot={
												<input
													{...register('price')}
													onBlur={handleChangePrice}
													onClick={handlePriceInputClick}
													type="number"
												/>
											}
											isError={!!errors.price}
											errorText={errors.price?.message}
										/>
									</Space.Compact>
								</Col>
							</Row>
							<Row gutter={48} style={{ marginTop: 34 }}>
								<Col span={24}>
									<div className="col-block-label-h3">Size</div>
								</Col>
								<Col span={24} style={{ marginTop: 20 }}>
									<Input
										width="246px"
										hasValue={!!watch('size')}
										label="Recommended Size"
										placeholder="Recommended Size"
										inputFieldSlot={
											<input
												{...register('size')}
												onBlur={handleChangeSize}
												type="text"
											/>
										}
										isError={!!errors.size}
										errorText={errors.size?.message}
									/>
								</Col>
							</Row>
							<Row gutter={48} style={{ marginTop: 34 }}>
								<Col span={24}>
									<div className="col-block-label-h3">Color</div>
								</Col>
								<ColFlex xxl={{ span: 20 }} xl={{ span: 24 }}>
									{itemColors.map((color) => (
										<ColorButton
											key={color.title}
											colorProp={color}
											handleColorSelect={handleColorSelect}
										/>
									))}
								</ColFlex>
							</Row>
						</Col>
						<Col span={24} style={{ marginTop: 45 }}>
							<GrayLine />
						</Col>
					</Row>
					<Row gutter={[48, 45]} style={{ marginTop: 36 }}>
						<Col xxl={{ span: 6 }} xl={{ span: 4 }} className="row-block-label">
							Save settings
						</Col>
						<Col xxl={{ span: 18 }} xl={{ span: 20 }}>
							<SdNotification text="Save as final: 1+ picture and all fields filled. Add to capsule when ready. " />
						</Col>
						<Swap
							is={item.status === 'active'}
							isSlot={
								<Col xxl={{ span: 6, offset: 6 }} xl={{ span: 8, offset: 4 }}>
									<Button
										isSubmit
										isDisabled={isNotReadyPublish && isLoading}
										skin="dark"
										onClick={() => handleSaveItem(item.status)}
									>
										Save changes
									</Button>
								</Col>
							}
						>
							<Col xxl={{ span: 6, offset: 6 }} xl={{ span: 8, offset: 4 }}>
								<Button
									isSubmit
									isDisabled={isNotReadyPublish || isLoading}
									skin="dark"
									onClick={handlePublishItem}
								>
									Save as final
								</Button>
							</Col>
							<Col xxl={{ span: 6 }} xl={{ span: 8 }}>
								<Button
									isSubmit
									isDisabled={isLoading}
									skin="dark"
									onClick={() => handleSaveItem(item.status)}
								>
									Save as draft
								</Button>
							</Col>
						</Swap>
					</Row>
				</ItemDataWrapper>
			</Swap>
			<Modal
				modalTitle="We upload automatically from"
				isModalOpen={isShowSupportedDomain}
				onCancel={() => setIsShowSupportedDomain(false)}
				onOk={() => {}}
				customFooter={
					<span>You can also use manual upload from any store</span>
				}
				children={supportedDomainsBlock}
			/>
		</Block>
	)
}
