import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserCookieOptions, UserPreferences, UserStatus } from '@typings/types'
import { Photo, ReduxUser, UserInfo } from '@typings/types'

const initialState: ReduxUser = {
	isPreferencesLoading: false,
	userInfo: {
		id: 0,
		username: '',
		email: '',
		status: 'moderation-wait',
		role: undefined,
		phone: '',
		created_at: '',
		updated_at: '',
		lastlogin_at: '',
		client: {
			id: 0,
			user_id: 0,
			name: '',
			first_name: '',
			sex: 'male',
			byear: 0,
			clothing_size: '',
			height: 0,
			questions: null,
			params: {},
		},
		stylist: {
			id: 0,
			user_id: 0,
			first_name: '',
			last_name: '',
			middle_name: '',
			sex: 'male',
			notes: '',
			approve_interview_time: '',
			approve_profile_time: '',
			profile_last_update: '',
			params: {},
		},
	},
	userPreferences: [],
	userCookieOptions: {
		general: true,
		performance: true,
		functional: true,
	},
}

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserInfo: (state, { payload }: PayloadAction<UserInfo>) => {
			state.userInfo = payload
		},
		setUserInitialState: (state) => {
			state.userInfo = initialState.userInfo
		},
		setUserPhoto: (state, { payload }: PayloadAction<Photo>) => {
			state.userInfo.role === 'client'
				? (state.userInfo.client.params.photo = [payload])
				: (state.userInfo.stylist.params.photo = [payload])
		},
		setUserStatus: (state, { payload }: PayloadAction<UserStatus>) => {
			state.userInfo.status = payload
		},
		setUserPreferences: (
			state,
			{ payload }: PayloadAction<UserPreferences[]>
		) => {
			state.userPreferences = payload
		},
		setIsPreferencesLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isPreferencesLoading = payload
		},
		setUserCookieOptions: (
			state,
			{ payload }: PayloadAction<UserCookieOptions>
		) => {
			state.userCookieOptions = payload
		},
	},
})

export const userReducer = slice.reducer
export const {
	setUserInfo,
	setUserInitialState,
	setUserPhoto,
	setUserStatus,
	setUserPreferences,
	setIsPreferencesLoading,
	setUserCookieOptions,
} = slice.actions
