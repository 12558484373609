import {
	bold12,
	bold16,
	fontRoboto,
	fontTenorSans,
	regular18,
	regular32,
} from './../../styles/typography'
import styled, { createGlobalStyle } from 'styled-components'
import { Button } from '@ui/button'

export const BasketCont = styled.div`
	margin-bottom: 50px;
	overflow: hidden;
`

export const CapsuleName = styled.div`
	${fontTenorSans}
	${regular18}
`

export const LabelDiv = styled.div`
	${fontRoboto}
	${bold16}
  margin-top: 16px;
`

export const ValueDiv = styled.div`
	${fontTenorSans}
	${regular32}

  display: flex;
`
export const ButtonsBlock = styled.div`
	display: flex;
	gap: 32px;
	margin-top: 16px;
`
export const ButtonStyled = styled(Button)`
	${fontRoboto}
	${bold12}
  padding: 18px 8px;
`
export const BasketAnimateStyle = createGlobalStyle`

`
