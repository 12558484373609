import { CapsuleGuide } from '@components/capsule-guide/capsule-guide'
import { Block } from '@ui/index'

export function CapsuleGuidePage() {
	return (
		<Block
			isFullWidth
			margin="20px 50px 50px"
			isFlexBlock
			justifyContent="center"
			flexDirection="column"
		>
			<CapsuleGuide />
		</Block>
	)
}
