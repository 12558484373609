import { StylistRegistrationForm } from '@components/stylist-registration-form/stylist-registration-form'
import { usePersonalDataForm } from '@components/stylist-registration-form/personal-data/use-personal-data-form'
import { Container } from './stylist-registration-page-styled'

export function StylistRegistrationPage() {
	const { userInfo } = usePersonalDataForm()

	if (userInfo.status === 'active') {
		//return <Navigate to={'/account'} />
	}

	if (userInfo.status === 'interview-wait') {
		/*
		return (
			<Container>
				<WaitApproveScreen name={userInfo.stylist?.first_name} />
			</Container>
		)
		*/
	}

	return (
		<Container>
			<StylistRegistrationForm />
		</Container>
	)
}
