import axios from 'axios'
import { axiosInstance } from '../../config'
import { CapsuleInfo } from '@typings/types'

type Props = {
	capsuleId: string
}

export async function getShareCapsuleInfo({ capsuleId }: Props) {
	try {
		const response = await axiosInstance.get<CapsuleInfo>(
			`/api/capsule-shared/${capsuleId}`
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
