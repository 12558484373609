import { Outlet } from 'react-router-dom'

import { LeftSidebar } from '@components/app-layout/left-sidebar/left-sidebar'
import { Chat } from '@components/chat/chat'
import { CookieNotification } from '@components/cookie-notification/cookie-notification'
import { Footer } from '../../footer/footer'
import { Header } from '../../header/header'
import { ChildrenWrapper, Content, Wrapper } from './app-layout-desktop-styled'

export function AppLayoutDesktop() {
	return (
		<Wrapper>
			<LeftSidebar />
			<Content>
				<Header />
				<ChildrenWrapper>
					<Outlet />
					<CookieNotification />
				</ChildrenWrapper>
				<Footer />
				{/* <ChatWidget /> */}
				<Chat />
			</Content>
		</Wrapper>
	)
}
