import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { PROD_DOMAIN } from '@typings/constants'
import { ItemInfo, ItemStatus } from '@typings/types'
import { Icon, BlankPlaceholder } from '@ui/index'
import { colors } from '@styles/colors'
import { useGetCurrency } from '@hooks/use-get-currency'
import { useAppDispatch, useAppSelector } from '@store/store'
import { authSelector } from '@store/auth'
import {
	clothesSelector,
	setNewCapsuleClothesList,
	setClothesList,
	setFilteredClothesList,
} from '@store/clothes'
import { archiveItem } from '@api/item/archive-item'
import { restoreItem } from '@api/item/restore-item'
import { addCapsuleItem } from '@api/capsule/add-capsule-item'
import { removeCapsuleItem } from '@api/capsule/remove-capsule-item'
import {
	AddToCapsuleButton,
	Brand,
	Container,
	CoverImageCont,
	IconWrapper,
	ImageCont,
	Price,
	Title,
} from './clothes-item-styled'

type Props = {
	clothItem: ItemInfo
	onClick: (id: number) => void
	maxWidth?: string
}

export function ClothesItem({ clothItem, onClick, maxWidth }: Props) {
	const { state } = useLocation()

	const dispatch = useAppDispatch()

	const { token } = useAppSelector(authSelector)
	const { clothesList, filteredClothesList, newCapsuleClothesList } =
		useAppSelector(clothesSelector)

	const clothPhotoUrl = clothItem.coverPhoto
		? PROD_DOMAIN + clothItem.coverPhoto.urls?.M
		: ''

	const currency = useGetCurrency(clothItem.price_currency_id)

	const isInCapsule = useMemo(
		() =>
			newCapsuleClothesList.findIndex((item) => item.id === clothItem.id) !==
			-1,
		[newCapsuleClothesList, clothItem.id]
	)

	const toggleAddToCapsuleClick = async (e: any) => {
		e.stopPropagation()

		if (state?.capsule?.id) {
			const isItemOperationSuccess = isInCapsule
				? await removeCapsuleItem(
						{
							items: [clothItem.id],
						},
						state?.capsule?.id,
						token
				  )
				: await addCapsuleItem(
						{
							items: [clothItem.id],
						},
						state?.capsule?.id,
						token
				  )

			if (!isItemOperationSuccess) return
		}

		if (!isInCapsule) {
			dispatch(setNewCapsuleClothesList([...newCapsuleClothesList, clothItem]))
			return
		}

		const tempList = newCapsuleClothesList.filter(
			(item) => item.id !== clothItem.id
		)

		dispatch(setNewCapsuleClothesList(tempList))
	}

	const handleArchiveClick = async (e: any) => {
		e.stopPropagation()

		try {
			const isSuccess =
				clothItem.status !== 'archive'
					? await archiveItem({ token, id: clothItem.id.toString() })
					: await restoreItem({ token, id: clothItem.id.toString() })

			if (!isSuccess) {
				return
			}

			const tempList = clothesList.map((el) => {
				if (el.id === clothItem.id) {
					return {
						...el,
						status:
							clothItem.status !== 'archive'
								? ('archive' as ItemStatus)
								: ('active' as ItemStatus),
					}
				}

				return el
			})

			dispatch(setClothesList(tempList))
			dispatch(
				setFilteredClothesList(
					filteredClothesList.filter((item) => item.id !== clothItem.id)
				)
			)
		} catch (error) {
			console.log(error)
		}
	}

	const addToCapsuleBtnText = !isInCapsule ? 'Add to capsule' : 'Added'

	return (
		<Container
			onClick={() => onClick(clothItem.id)}
			data-created={clothItem.created_at}
			data-status={clothItem.status}
			style={{ maxWidth, margin: '0 auto' }}
		>
			<CoverImageCont>
				{clothItem.status !== 'archive' && (
					<IconWrapper
						onClick={handleArchiveClick}
						className="hidden-button archive-btn"
					>
						<Icon color={colors.white} name={'archiveIcon'} size={20} />
					</IconWrapper>
				)}
				{clothItem.status === 'active' && (
					<AddToCapsuleButton
						isFitContent
						skin="dark"
						onClick={toggleAddToCapsuleClick}
						className={`hidden-button ${isInCapsule ? 'item-added' : ''}`}
						leftIcon={
							isInCapsule ? (
								<Icon color={colors.white} name="addedItemIcon" />
							) : (
								<Icon color={colors.white} name="hangerIcon" />
							)
						}
					>
						<p>{addToCapsuleBtnText}</p>
					</AddToCapsuleButton>
				)}
				{clothItem.status === 'archive' && (
					<AddToCapsuleButton
						isFitContent
						skin="dark"
						onClick={handleArchiveClick}
						className={`hidden-button ${isInCapsule ? 'item-added' : ''}`}
						leftIcon={<Icon color={colors.white} name="tShirtIcon" />}
					>
						<p>Resume</p>
					</AddToCapsuleButton>
				)}
				<ImageCont imageUrl={clothPhotoUrl}>
					{!clothPhotoUrl && <BlankPlaceholder color={colors.blackTr24} />}
				</ImageCont>
			</CoverImageCont>
			<Title>{clothItem.title}</Title>
			<Brand>{clothItem.brand}</Brand>
			<Price>{clothItem.price && `${currency} ${clothItem.price}`}</Price>
		</Container>
	)
}
