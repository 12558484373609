import styled from 'styled-components'
import {
	fontTenorSans,
	fontRoboto,
	regular16,
	regular26,
} from '@styles/typography'
import { colors } from '@styles/colors'

export const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;
	padding: 0px 48px 48px;

	.styledyle-input-paddings {
		${fontRoboto}
		${regular16}
		color: ${colors.blackTr64};
		padding: 18px 16px;
		width: 100%;
		height: 60px;
	}

	.ant-picker .ant-picker-input > input {
		font-size: unset;
		line-height: unset;
		color: unset;
		color: ${colors.black};
	}
	.ant-picker .ant-picker-input > input::placeholder {
		color: unset !important;
	}
	/* 
	.ant-select-single {
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item {
		line-height: 2.2rem;
	}
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		height: 60px;
		padding: 18px 16px;
		${fontRoboto}
		${regular16}
		color: ${colors.black};
	}
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: unset;
	}

	.ant-select-arrow {
		color: ${colors.black};
	}
	*/
`

export const RowTitle = styled.div`
	${fontTenorSans}
	${regular26}
`
