import { ButtonBg, ButtonSwitch, ButtonWrapper } from './radio-button-styled'
import { RadioButtonProps } from './types'

export function RadioButton({
	isActive,
	isDisabled,
	onClick,
	style,
}: RadioButtonProps) {
	return (
		<ButtonWrapper onClick={isDisabled ? () => {} : onClick} style={style}>
			<ButtonBg isActive={isActive} isDisabled={isDisabled}>
				{!isDisabled && <ButtonSwitch isActive={isActive}></ButtonSwitch>}
			</ButtonBg>
		</ButtonWrapper>
	)
}
