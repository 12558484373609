import { Col, Row } from 'antd'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

import ClientItem from '@components/client-item/client-item'
import { ItemsList } from '@components/items-list/items-list'
import { PageNavigation } from '@components/page-navigation'
import { Block, Skeleton } from '@ui/index'
import { useClientsPage } from './use-clients-page'

const CLIENT_SIZE = {
	width: 540,
	height: 574,
}

export default function ClientsPage() {
	const {
		dealsToShow,
		isListLoading,
		navigationButtons,
		showListType,
		handleChangeSearch,
		handleClickClient,
	} = useClientsPage()

	const isXxl = useMediaQuery({
		query: '(min-width: 1600px)',
	})
	const itemsPerPage = isXxl ? 3 : 2
	const loadersArr = new Array(itemsPerPage * 2 - 1).fill(1)

	return (
		<Block isFullWidth margin="0 48px 48px">
			<PageNavigation
				showListType={showListType}
				buttons={navigationButtons}
				handleChangeSearch={handleChangeSearch}
			/>
			<Row gutter={[48, 44]}>
				<ItemsList
					isLoading={isListLoading}
					itemsList={dealsToShow}
					loaderEl={
						<>
							{loadersArr.map((_, index) => (
								<Col key={index} xxl={{ span: 8 }} xl={{ span: 12 }}>
									<Skeleton width="100%" height={CLIENT_SIZE.height}>
										<rect
											x="0"
											y="0"
											rx="20"
											ry="20"
											width="100%"
											height={CLIENT_SIZE.height}
										/>
									</Skeleton>
								</Col>
							))}
						</>
					}
					renderItem={(item) => (
						<Col key={item.id} xxl={{ span: 8 }} xl={{ span: 12 }}>
							<ClientItem
								dealItem={item}
								onClick={handleClickClient}
								maxWidth={CLIENT_SIZE.width + 'px'}
							/>
						</Col>
					)}
				/>
			</Row>
		</Block>
	)
}
