import { screenSize } from '@styles/screen-sizes'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	width: inherit;
	padding: 30px 60px;
	gap: 80px;

	@media (${screenSize.maxTablet}) {
		padding: 20px;
		flex-direction: column;

		gap: 40px;
	}
`
