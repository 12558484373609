import { ImageItemType } from '@typings/types'
import { GalleryButtons } from '@ui/gallery-buttons'
import { Icon } from '@ui/icon'
import { Col, Row, Image } from 'antd'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useNavigate, useParams } from 'react-router-dom'
import { FullScrIcon } from '../capsules-preview-page-styled'
import {
	Brand,
	CarouselWrapper,
	Container,
	HoverWrapper,
	InfoWrapper,
	Price,
	Title,
} from './gallery-block-styled'
import { useState } from 'react'

type Props = {
	carouselRef: any
	imageList: ImageItemType[]
}

export function GalleryBlock({ carouselRef, imageList }: Props) {
	const navigate = useNavigate()
	const [visible, setVisible] = useState(false)
	const { id: capsuleId } = useParams()

	const ButtonGroup = () => {
		return (
			<Col span={6}>
				<GalleryButtons
					onNextArrowClick={() => carouselRef.current?.next()}
					onPrevArrowClick={() => carouselRef.current?.previous()}
				/>
			</Col>
		)
	}

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 1600 },
			items: 3,
			partialVisibilityGutter: 40,
		},
		desktop: {
			breakpoint: { max: 1599, min: 1400 },
			items: 2,
			partialVisibilityGutter: 60,
		},
		tablet: {
			breakpoint: { max: 1399, min: 1200 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	}

	return (
		<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 48 }}>
			<Col span={18} className="row-block-label">
				Clothing items
			</Col>
			<ButtonGroup />
			<Col span={24} style={{ marginTop: 58 }}>
				<CarouselWrapper>
					<Carousel
						ref={(el) => (carouselRef.current = el)}
						responsive={responsive}
						arrows={false}
						containerClass="carousel-container"
						itemClass="carousel-item"
						renderButtonGroupOutside={true}
						centerMode={false}
						partialVisbile={false}
					>
						{imageList.map((imageItem, index) => (
							<Container
								key={index}
								onClick={() => {
									if (visible) return
									imageItem.itemId &&
										navigate('/clothes/' + imageItem.itemId, {
											state: {
												capsuleId,
											},
										})
								}}
							>
								<HoverWrapper>
									<Image
										width={134}
										src={imageItem.url}
										preview={{
											src: imageItem.previewUrl,
											mask: (
												<FullScrIcon>
													<Icon color="white" name="fullScreenIcon" />
												</FullScrIcon>
											),
											onVisibleChange: (value) => {
												setVisible(value)
											},
										}}
										onClick={(e) => {
											e.stopPropagation()
											setVisible(true)
										}}
										style={{ height: '100%' }}
									/>
								</HoverWrapper>
								<InfoWrapper>
									<Title>{imageItem.title}</Title>
									<Brand>{imageItem.brand}</Brand>
									<Price>
										{imageItem.price &&
											`${imageItem.currency} ${imageItem.price}`}
									</Price>
								</InfoWrapper>
							</Container>
						))}
					</Carousel>
				</CarouselWrapper>
			</Col>
		</Row>
	)
}
