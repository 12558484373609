import { colors } from '@styles/colors'
import { fontTenorSans } from '@styles/typography'
import { Block } from '@ui/block'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	background: ${colors.blackTr5};
	border-radius: 20px;
	height: 100%;
	padding: 25px;
	max-width: 270px;
	min-height: 276px;
	margin: 0 auto;

	& .ant-upload-wrapper,
	.ant-upload {
		width: 100%;
	}

	#upload-photo {
		opacity: 0;
		position: absolute;
		z-index: -1;
	}

	&:hover {
		box-shadow: 0px 6px 32px ${colors.blackTr8};
	}
`

export const ButtonWrapper = styled.label`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 32px;

	border: 2px dashed ${colors.blackTr5};
	border-radius: 20px;

	cursor: pointer;
	height: 226px;
	width: 100%;
`

export const UploadText = styled(Block)`
	${fontTenorSans}
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
`
