import { Link } from 'react-router-dom'

import { authSelector } from '@store/auth'
import { useAppSelector } from '@store/store'
import { colors } from '@styles/colors'
import { SiteLogo } from '@ui/site-logo/site-logo'
import { LeftMenu } from '../../left-menu/left-menu'
import { LogoWrapper, Wrapper } from './left-sidebar-desktop-styled'

export function LeftSidebarDesktop() {
	const { token } = useAppSelector(authSelector)

	return (
		<Wrapper>
			<Link to="/">
				<LogoWrapper>
					<SiteLogo color={colors.white} size={58} />
				</LogoWrapper>
			</Link>
			{token && <LeftMenu />}
		</Wrapper>
	)
}
