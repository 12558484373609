import { useState } from 'react'
import {
	ClientInfo,
	ClientParams,
	StylistInfo,
	StylistParams,
} from '@typings/types'
import { updateProfile } from '../../api/update-profile'
import { useAppDispatch, useAppSelector } from '../../store'
import { authSelector } from '../../store/auth'
import { setUserInfo, userSelector } from '../../store/user'

type UserKeysToSave = keyof StylistInfo | keyof ClientInfo
type ParamsKeysToSave = keyof StylistParams | keyof ClientParams
type ValueToSave = string | string[] | number | number[]

export function useUpdateUserProfileInfo() {
	const dispatch = useAppDispatch()
	const { userInfo } = useAppSelector(userSelector)
	const { token } = useAppSelector(authSelector)
	const [isLoading, setIsLoading] = useState(false)

	const handleUpdateProfile = async (newFields: string) => {
		setIsLoading(true)
		const result = await updateProfile(newFields, userInfo.id, token)

		if (result?.user) {
			dispatch(setUserInfo(result.user))
		}
		setIsLoading(false)
	}

	const handleSetUserPhone = (phone: string) => {
		return handleUpdateProfile(
			JSON.stringify({
				phone,
			})
		)
	}

	const handleSetUserInfo = async (key: UserKeysToSave, value: ValueToSave) => {
		if (!userInfo.role) return
		return await handleUpdateProfile(
			JSON.stringify({
				[userInfo.role]: {
					[key]: value,
				},
			})
		)
	}

	const handleSetUserParams = async (
		key: ParamsKeysToSave,
		value: ValueToSave
	) => {
		if (!userInfo.role) return
		return await handleUpdateProfile(
			JSON.stringify({
				[userInfo.role]: {
					params: {
						[key]: value,
					},
				},
			})
		)
	}

	return {
		isLoading,
		handleSetUserInfo,
		handleSetUserParams,
		handleSetUserPhone,
	}
}
