import { fontRoboto } from './../../styles/typography'
import { colors } from '@styles/colors'
import styled, { css } from 'styled-components'

interface avatarProps {
	imageUrl: string
	size: number | string
}

export const AvatarCont = styled.div`
	position: relative;
`

export const AvatarWrapper = styled.div<avatarProps>`
	display: flex;
	flex: 0 0 auto;
	justify-content: center;
	align-items: center;
	margin: auto;

	border-radius: 50%;
	background-color: ${colors.white};
	overflow: hidden;

	${({ size }) => {
		if (typeof size === 'number') {
			return css`
				width: ${size}px;
				height: ${size}px;
			`
		} else if (typeof size === 'string') {
			return css`
				width: ${size};
				aspect-ratio: 1/1;
			`
		}
	}}

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
		`}

	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
`

export const IconWrapper = styled.div<{ color: string }>`
	width: 35%;
	height: 35%;
	border-radius: 50%;

	position: absolute;
	bottom: 0;
	right: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	background-origin: content-box;
	background-color: ${(props) => props.color};
`

export const CountWrapper = styled.div`
	width: 24px;
	height: 24px;

	border-radius: 28px;
	background: linear-gradient(180deg, #ff0c63 0%, #f56565 100%);

	position: absolute;
	bottom: 0;
	right: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	color: #fdf8f8;

	${fontRoboto}
	text-align: center;
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px; /* 160% */
`
