import styled from 'styled-components'
import { colors } from '@styles/colors'

export const Wrapper = styled.aside`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 108px;
	background: ${colors.black};
	box-shadow: 0px 0px 42px rgba(78, 115, 156, 0.1);
	color: ${colors.white};

	z-index: 900;
`

export const LogoWrapper = styled.div`
	height: 123px;
	display: flex;
	align-items: center;
`
