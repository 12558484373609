import { fontPlayfair, regular42 } from './../../styles/typography'
import LoginBG from '@assets/loginBG.png'
import styled from 'styled-components'
import { regular16 } from '../../styles/typography'
import { colors } from '@styles/colors'
import { Row } from 'antd'

export const Container = styled.div`
	width: 100%;
	height: 100%;
	${regular16}
	background: url(${LoginBG}),
	${colors.white};
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`

export const RowStyled = styled(Row)``

export const NotFoundTextDiv = styled.div`
	${fontPlayfair}
	${regular42}
	color: ${colors.black}
	letter-spacing: 0.84px;
	display: flex;
	align-items: center;
	height: 640px;
`
export const NotFoundImgWrapper = styled.div``
