import { colors } from '@styles/colors'
import {
	fontTenorSans,
	bold16,
	fontRoboto,
	regular18,
	regular32,
	regular13,
	regular42,
	regular16,
} from '@styles/typography'
import { Block } from '@ui/block'
import { Col } from 'antd'
import styled from 'styled-components'

export const PageWrapper = styled(Block)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`

export const HeaderText = styled.div`
	${fontTenorSans}
	${regular32}

	letter-spacing: 0.64px;
	text-align: center;
	margin: 48px auto;
`

export const SubHeaderText = styled.div`
	${fontTenorSans}
	${regular42}

	letter-spacing: 0.84px;
`

export const SmallText = styled.div`
	${fontTenorSans}
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px; /* 123.077% */
`

export const StylistImageFrame = styled.div`
	margin: 36px;
	height: 392px;
`
export const StylistInfoStyled = styled.div`
	margin: -68px auto 0;
	position: relative;
	z-index: 100;
	background: ${colors.white};
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 12px;
	display: flex;
	flex-wrap: wrap;

	background: ${colors.white};
	padding-top: 20px;
`

export const TipText = styled.div`
	${fontTenorSans}
	${regular16}

	color: ${colors.blackTr64};
	margin: 63px auto;
`

export const StylistAvatar = styled.div`
	margin-left: 24px;
	width: 56px;
	height: 56px;
	border-radius: 50%;
	background: gray;
	overflow: hidden;
`

export const StylistName = styled.div`
	${fontTenorSans}
	${regular18}
`

export const StylistAlignment = styled.div`
	${fontRoboto}
	${bold16}
  color: #FF0C63;
	align-self: start;
`
export const ColFlex = styled(Col)`
	display: flex;
	align-items: center;
`

export const CountCircle = styled.div`
	${fontRoboto}
	${bold16}
	width: 48px;
	height: 48px;
	line-height: 48px;
	border-radius: 50%;
	background: ${colors.blackTr5};
	text-align: center;
`

export const CountText = styled.div`
	margin: 0 16px;
	${fontRoboto}
	${regular13}
	color: ${colors.blackTr48};
`

export const StylistLink = styled.a`
	margin: 0 24px;
	${fontRoboto}
	${bold16}
	color: ${colors.blackTr64};
	display: flex;
	align-items: center;
	position: absolute;
	right: 0;
`

export const ColContainer = styled(Col)`
	cursor: pointer;

	&:hover {
		box-shadow: 0px 6px 32px ${colors.blackTr8};
	}
`
