import { colors } from '@styles/colors'
import { Icon } from '@ui/icon'
import { Container, DisableButtons, IconWrapper } from './like-buttons-styled'
import { StyleboardElement } from '@typings/types'
import { useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { styleboardsSelector } from '@store/styleboards'

type Props = {
	photo: StyleboardElement
}

export function LikeButtons({ photo }: Props) {
	const { userInfo } = useAppSelector(userSelector)
	const { styleboard } = useAppSelector(styleboardsSelector)

	if (
		(userInfo?.role === 'stylist' || styleboard.status === 'published') &&
		photo.isLiked
	)
		return (
			<Container>
				<IconWrapper>
					<Icon name="heartIcon" size={28} color={colors.mainThemeColor} />
				</IconWrapper>
			</Container>
		)

	if (
		(userInfo?.role === 'stylist' || styleboard.status === 'published') &&
		photo.isDisliked
	)
		return (
			<Container>
				<IconWrapper>
					<Icon name="dislikeIcon" size={28} color={colors.mainThemeColor} />
				</IconWrapper>
			</Container>
		)

	if (
		(userInfo?.role === 'stylist' || styleboard.status === 'published') &&
		!photo.isLiked &&
		!photo.isDisliked
	) {
		return null
	}

	return (
		<Container>
			{styleboard.status === 'accepted' && <DisableButtons />}
			<IconWrapper
				onClick={() => photo.handleDislikeClick(photo.id)}
				className={photo.isDisliked ? 'active' : ''}
			>
				<Icon
					name={photo.isDisliked ? 'dislikColoredIcon' : 'dislikeIcon'}
					size={28}
					color={photo.isDisliked ? colors.mainThemeColor : colors.blackTr64}
				/>
			</IconWrapper>
			<IconWrapper
				onClick={() => photo.handleLikeClick(photo.id)}
				className={photo.isLiked ? 'active' : ''}
			>
				<Icon
					name={photo.isLiked ? 'heartColoredIcon' : 'heartIcon'}
					size={28}
					color={photo.isLiked ? colors.mainThemeColor : colors.blackTr64}
				/>
			</IconWrapper>
		</Container>
	)
}
