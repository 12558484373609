import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { deleteImage } from '@api/delete-image'
import { createItem } from '@api/item/create-item'
import { getItemInfo } from '@api/item/get-item'
import { getItemParams } from '@api/item/get-item-params'
import { createItemByLInk } from '@api/item/parse/create-by-link'
import { getParseTargetList } from '@api/item/parse/get-parse-target-list'
import { updateItemByLInk } from '@api/item/parse/update-by-link'
import { publishItem } from '@api/item/publish-item'
import { updateItem } from '@api/item/update-item'
import { uploadImage } from '@api/upload-image'
import { authSelector } from '@store/auth'
import {
	clothesSelector,
	setIsItemArchived,
	setIsItemLoading,
	setItem,
	setNewItem,
} from '@store/clothes'
import { useAppDispatch, useAppSelector } from '@store/index'
import { setIsLoading, setSettings, settingsSelector } from '@store/settings'
import { PROD_DOMAIN } from '@typings/constants'
import {
	ImageItemType,
	ItemParamsList,
	NavigationButtonInfo,
	ShowClothesListType,
	ShowPageType,
	UploadImageRequest,
} from '@typings/types'
import { handleToast } from '@utils/handle-toast'
import { ColorObj } from './color-button/color-button'

type FormValues = {
	clothLink: string
	title: string
	photoSectionNote: string
	descriptionNote: string
	size: string
	price: number
	currency: number
}

export function useClothesEditPage() {
	const dispatch = useAppDispatch()
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const { id } = useParams()

	const { token } = useAppSelector(authSelector)
	const { item, isItemLoading } = useAppSelector(clothesSelector)
	const { isLoading: isSettingsLoading } = useAppSelector(settingsSelector)

	const [itemColors, setItemColors] = useState<ColorObj[]>([])

	const [isNewPhotoLoading, setNewPhotoLoading] = useState(false)
	const [itemId, setItemId] = useState<number | string>(id || '')
	const [uploadNotificationText, setUploadNotificationText] =
		useState<string>('')
	const [supportedURLsArr, setSupportedURLsArr] = useState<string[]>([])
	const [notSupportedDomain, setNotSupportedDomain] = useState<boolean>(true)
	const [isShowSupportedDomain, setIsShowSupportedDomain] =
		useState<boolean>(false)
	const [imageItems, setImageItems] = useState<ImageItemType[]>([])
	const [newFields, setNewFields] = useState<{
		title?: string
		source_url?: string
		brand?: string
		price?: string
		price_currency_id?: number
		params?: {
			category?: string
			clothLink?: string
			color?: string[]
			size?: string
			description?: string
		}
	}>({})
	const [itemParams, setItemParams] = useState<ItemParamsList>({})
	const [isParsingInProgress, setIsParsingInProgress] = useState(false)

	const {
		register,
		watch,
		formState: { errors },
		setValue,
		getValues,
		clearErrors,
		setError,
	} = useForm<FormValues>({
		defaultValues: {
			price: 0,
		},
	})

	const loadItemInfo = useCallback(
		async (itemId: string) => {
			if (isSettingsLoading) return
			dispatch(setIsItemLoading(true))

			const itemParams = await getItemParams({ token })

			if (!itemParams) {
				dispatch(setIsItemLoading(false))
				handleToast({ content: 'Error loading item params' })
				navigate('/clothes')
				return
			}

			setItemParams(itemParams)

			const URLArr = await getParseTargetList({ token })
			if (URLArr) setSupportedURLsArr(URLArr)

			if (itemId === 'new') {
				const itemColorsArr: ColorObj[] = itemParams?.ItemColors
					? Object.keys(itemParams?.ItemColors).map((color) => ({
							title: color,
							color: itemParams.ItemColors
								? itemParams.ItemColors[color]
								: '#ffffff',
							isActive: false,
					  }))
					: []

				dispatch(setNewItem())

				setItemColors(itemColorsArr)

				dispatch(setIsItemLoading(false))
				return
			}

			const data = await getItemInfo({ token, itemId: +itemId })

			if (data) {
				const images = data.images || []
				const imageItems = images.map((photo) => ({
					id: photo.id,
					url: PROD_DOMAIN + photo.urls.M,
					previewUrl: PROD_DOMAIN + photo.urls.L,
					isCover: data.coverPhoto?.id === photo.id,
				}))
				setImageItems(imageItems)

				const itemColorsArr: ColorObj[] = itemParams?.ItemColors
					? Object.keys(itemParams?.ItemColors).map((color) => ({
							title: color,
							color: itemParams.ItemColors
								? itemParams.ItemColors[color]
								: '#ffffff',
							isActive: data.params.color
								? Array.isArray(data?.params?.color)
									? data.params.color.findIndex((name) => name === color) !== -1
									: Array.from(data.params.color).findIndex(
											(name) => name === color
									  ) !== -1
								: false,
					  }))
					: []

				setItemColors(itemColorsArr)

				setValue('title', data.title)
				setValue('price', data.price)
				setValue('currency', data.price_currency_id)
				setValue('size', data?.params?.size || '')
				setValue('clothLink', data.source_url || '')
				setValue('descriptionNote', data?.params?.description || '')

				setNewFields({
					title: data.title,
					source_url: data.source_url,
					brand: data.brand,
					price: data.price?.toString(),
					price_currency_id: data.price_currency_id,
					params: {
						category: data.params?.category,
						color: data.params?.color,
						size: data.params?.size,
						description: data.params?.description,
					},
				})

				dispatch(setItem(data))
				dispatch(setIsItemArchived(data.status === 'archive'))
				dispatch(setIsItemLoading(false))
				return
			}
			dispatch(setIsItemLoading(false))
			navigate('/clothes')
		},
		[isSettingsLoading, dispatch, token, navigate, setValue]
	)

	const currentPage: ShowPageType = useMemo(
		() => (pathname === `/clothes/${itemId}/edit` ? 'edit' : 'preview'),
		[itemId, pathname]
	)

	const handlePreviewClick = useCallback(() => {
		if (itemId === 'new') {
			handleToast({ content: 'Please save the item first', type: 'warn' })
			return
		}

		navigate(`/clothes/${itemId}`, {
			state: {
				itemId: itemId,
			},
		})
	}, [itemId, navigate])

	const isUrlDomainSupported = (url: string) => {
		try {
			const newUrl = new URL(url).origin
			return url && supportedURLsArr.includes(newUrl)
		} catch (error) {
			console.log(error)
		}
	}

	const handleChangeLink = (e: any) => {
		clearErrors('clothLink')

		const newUrl = e.target.value
		setNewFields({
			...newFields,
			source_url: newUrl,
		})

		if (!isUrlDomainSupported(newUrl)) {
			setNotSupportedDomain(true)
			setError('clothLink', {
				type: 'custom',
				message: '',
			})
			setUploadNotificationText(
				newUrl.length
					? 'Automatic upload is unavailable for this store. Please upload manually'
					: 'Link missing. Please add a link to upload.'
			)
			return
		}

		setUploadNotificationText('')
		setNotSupportedDomain(false)
	}

	const handleChangeTitle = (e: any) => {
		clearErrors('title')
		setNewFields({
			...newFields,
			title: e.target.value,
		})
	}

	const handleCategorySelect = (value: string) => {
		setNewFields({
			...newFields,
			params: {
				...newFields.params,
				category: value,
			},
		})
	}

	const handleUploadButton = () => {
		const value = getValues('clothLink')

		if (value)
			setNewFields({
				...newFields,
				params: {
					...newFields.params,
					clothLink: value,
				},
			})
	}

	const handleBrandSelect = (value: string) => {
		setNewFields({
			...newFields,
			brand: value,
		})
	}

	const isNotReadyPublish = useMemo(() => {
		return (
			!newFields.source_url ||
			!newFields.title ||
			newFields.title === 'New empty item' ||
			!item.images?.length ||
			!newFields.brand ||
			!newFields.price ||
			!newFields.params?.size ||
			itemColors.findIndex((color) => color.isActive) === -1
		)
	}, [item.images?.length, itemColors, newFields])

	const handleSaveItem = async (itemStatus: ShowClothesListType) => {
		dispatch(setIsItemLoading(true))

		let newItemId: number | undefined
		if (itemId === 'new') {
			newItemId = await createItem(token)

			if (newItemId) {
				setItemId(newItemId.toString())
				navigate(`/clothes/${newItemId}/edit`)
			}
		}

		const updateResult = await updateItem(
			JSON.stringify(newFields),
			newItemId ?? +itemId,
			token
		)
		if (!updateResult?.success) {
			updateResult?.errors &&
				Object.entries(updateResult?.errors).forEach(([key, value]) => {
					handleToast({ content: `${value}` })
				})
			dispatch(setIsItemLoading(false))
			return
		}
		if (updateResult?.success) {
			dispatch(setIsLoading(true))
			const response = await getItemParams({ token })

			if (!response) {
				dispatch(setIsLoading(false))
				return
			}

			dispatch(setSettings(response))
			dispatch(setIsLoading(false))
			id && (await loadItemInfo(id))
		}
		dispatch(setIsItemLoading(false))
		navigate('/clothes', {
			state: {
				showListType: itemStatus,
			},
		})
	}

	const handlePublishItem = async () => {
		dispatch(setIsItemLoading(true))

		const updateResult = await updateItem(
			JSON.stringify(newFields),
			+itemId,
			token
		)
		if (!updateResult?.success) {
			updateResult?.errors &&
				Object.entries(updateResult?.errors).forEach(([key, value]) => {
					handleToast({ content: `${value}` })
				})
			dispatch(setIsItemLoading(false))
			return
		}

		if (updateResult?.success) {
			if (item.status !== 'active') {
				await publishItem({ token, itemId: +itemId })
			}

			const response = await getItemParams({ token })

			if (!response) {
				dispatch(setIsItemLoading(false))
				return
			}

			dispatch(setSettings(response))
			dispatch(setNewItem())
		}

		dispatch(setIsItemLoading(false))
		navigate(`/clothes`)
	}

	const handleAddNewPhoto = async (e: any) => {
		const files = e.fileList
		if (!e.event || !files) {
			return
		}

		setNewPhotoLoading(true)

		let newItemId: number | undefined
		if (itemId === 'new') {
			newItemId = await createItem(token)

			if (!newItemId) {
				handleToast({ content: 'Error creating new item' })
				setNewPhotoLoading(false)
				return
			}

			await updateItem(JSON.stringify(newFields), +newItemId, token)
			setItemId(newItemId.toString())
			navigate(`/clothes/${newItemId}/edit`)
		}

		const requestData: UploadImageRequest = {
			filename: files[files.length - 1].originFileObj,
			relation_id: newItemId?.toString() ?? itemId.toString(),
			relation_type: 'item',
		}

		const newImage = await uploadImage({
			requestData,
			token,
		})

		if (!newImage) {
			handleToast({ content: 'Error uploading image' })
			setNewPhotoLoading(false)
			return
		}

		if (newItemId) {
			loadItemInfo(newItemId.toString())
			setNewPhotoLoading(false)
			return
		}

		const itemData = await getItemInfo({ itemId: +itemId, token })
		const imageItems = itemData?.images
			? itemData.images.map((image) => ({
					id: image.id,
					url: PROD_DOMAIN + image.urls.M,
					previewUrl: PROD_DOMAIN + image.urls.L,
					isCover: itemData.coverPhoto?.id === image.id,
			  }))
			: []
		!!imageItems.length && setImageItems(imageItems)

		setNewPhotoLoading(false)
	}

	const handleSetCoverClick = async (imageId: number) => {
		const coverSet = await updateItem(
			JSON.stringify({
				params: {
					coverPhotoId: imageId,
				},
			}),
			+itemId,
			token
		)

		if (coverSet?.success) {
			const clearImageItems = imageItems.map((item) => {
				item.isCover = item.id === imageId

				return item
			})

			setImageItems(clearImageItems)
			dispatch(
				setItem({
					...item,
					coverPhoto: item.images?.find((item) => item.id === imageId),
				})
			)
		}
	}

	const handleDeleteClick = async (imageId: number) => {
		const isDeleted = await deleteImage({ imageId, token })

		if (isDeleted) {
			const clearImageItems = imageItems.filter((item) => item.id !== imageId)

			setImageItems(clearImageItems)
		}
	}

	const categoriesOptions = useMemo(
		() => itemParams.CATEGORY_DATA,
		[itemParams]
	)
	const brandOptions = useMemo(
		() => (itemParams.BRAND_DATA?.length ? itemParams.BRAND_DATA : []),
		[itemParams]
	)
	const defaultCurrencyLabel = useMemo(
		() =>
			itemParams.Currencies?.find(
				(currency) => currency.id === item.price_currency_id
			)?.title || '£',
		[itemParams, item.price_currency_id]
	)

	const currencyOptions = itemParams.Currencies?.map((item) => ({
		value: item.id,
		label: item.title,
	}))

	const handleChangeCurrency = (value: any) => {
		setNewFields({
			...newFields,
			price_currency_id: value,
		})
	}

	const handleChangePrice = (e: any) => {
		clearErrors('price')
		setNewFields({
			...newFields,
			price: e.target.value,
		})
	}

	const handleColorSelect = (value: string) => {
		const tmpColorsArr = itemColors.map((item) => {
			if (item.title === value) {
				return { ...item, isActive: !item.isActive }
			}
			return item
		})
		setItemColors(tmpColorsArr)
		setNewFields({
			...newFields,
			params: {
				...newFields.params,
				color: tmpColorsArr
					.map((color) => (color.isActive ? color.title : ''))
					.filter(Boolean),
			},
		})
	}

	const handleChangeSize = (e: any) => {
		clearErrors('size')
		setNewFields({
			...newFields,
			params: {
				...newFields.params,
				size: e.target.value,
			},
		})
	}

	const handleChangeDescription = (e: any) => {
		setNewFields({
			...newFields,
			params: {
				...newFields.params,
				description: e.target.value,
			},
		})
	}

	const handleUploadBtnClick = async () => {
		if (!itemId) return

		setIsParsingInProgress(true)
		const clothLink = watch('clothLink')
		if (!clothLink.length) {
			setError('clothLink', {
				type: 'custom',
				message: 'Link missing. Please add a link to upload.',
			})
			//TODO: Need to change text after response...
			setUploadNotificationText('Link missing. Please add a link to upload.')
			return
		}

		if (itemId === 'new') {
			const newItem = await createItemByLInk({ token, link: clothLink })
			if (!newItem) {
				setIsParsingInProgress(false)
				handleToast({ content: 'Error while creating item by link' })
				setUploadNotificationText(
					"This store isn't fully supported yet. We'll retrieve as much information as we can. Thank you for your patience!"
				)
				return
			}

			setIsParsingInProgress(false)
			setItemId(newItem.id.toString())
			navigate(`/clothes/${newItem.id}/edit`)
			return
		}

		const newItem = await updateItemByLInk({ token, link: clothLink, itemId })

		if (!newItem) {
			setIsParsingInProgress(false)
			handleToast({ content: 'Error while updating item by link' })
			return
		}

		setIsParsingInProgress(false)
		await loadItemInfo(itemId.toString())
	}

	const handlePriceInputClick = (e: any) => {
		e.target.select()
	}

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'Preview',
				iconName: 'previewIcon',
				showListType: 'preview',
				handleClick: handlePreviewClick,
			},
			{
				title: 'Edit',
				iconName: 'editIcon',
				showListType: 'edit',
				handleClick: () => {},
			},
		],
		[handlePreviewClick]
	)

	useEffect(() => {
		id && loadItemInfo(id)
	}, [id, loadItemInfo])

	return {
		brandOptions,
		categoriesOptions,
		currencyOptions,
		currentPage,
		defaultCurrencyLabel,
		errors,
		handleAddNewPhoto,
		handleBrandSelect,
		handleCategorySelect,
		handleChangeCurrency,
		handleChangeDescription,
		handleChangeLink,
		handleChangePrice,
		handleChangeSize,
		handleChangeTitle,
		handleColorSelect,
		handleDeleteClick,
		handlePublishItem,
		handlePreviewClick,
		handleSaveItem,
		handleSetCoverClick,
		handleUploadButton,
		imageItems,
		isLoading: isItemLoading,
		isNewPhotoLoading,
		isNotReadyPublish,
		isParsingInProgress,
		isSettingsLoading,
		item,
		itemColors,
		navigationButtons,
		register,
		watch,
		newFields,
		handlePriceInputClick,
		handleUploadBtnClick,
		uploadNotificationText,
		notSupportedDomain,
		isShowSupportedDomain,
		setIsShowSupportedDomain,
		supportedURLsArr,
	}
}
