import { PreferencesBlock } from '@components/preferences-block/preferences-block'
import { Input } from '@ui/index'
import { CustomSelect } from '@ui/select/select'
import { Col, Row } from 'antd'
import { useForm } from 'react-hook-form'
import { TitleBlock } from '../personal-data-form/personal-data-form-styled'
import { SelectWrapper } from './preferences-data-form-styled'
import { usePreferencesDataForm } from './use-preferences-data-form'

export function PreferencesDataForm() {
	const {
		isSettingsLoading,
		isPreferencesLoading,
		client,
		exspencesOptions,
		sizesOptions,
		sizesShoesOptions,
		handleSetUserParams,
		currentCategories,
		categoriesOptions,
		handleCategorySelect,
	} = usePreferencesDataForm()
	const { register, watch } = useForm()

	return (
		<Col flex="0 1 66%">
			<Row gutter={48}>
				<Col span={12}>
					<TitleBlock>Clothing size</TitleBlock>
				</Col>
				<Col span={12}>
					<TitleBlock>Budget for clothes</TitleBlock>
				</Col>
			</Row>
			<Row gutter={48}>
				<Col span={12}>
					<SelectWrapper>
						<CustomSelect
							isDisabled={false} //isSettingsLoading
							placeholder="Top"
							defaultValue={client.params?.topSize}
							width={'48%'}
							onChange={(value: any) => {
								handleSetUserParams('topSize', value)
							}}
							options={sizesOptions}
						/>
						<CustomSelect
							isDisabled={false}
							placeholder="Bottom"
							defaultValue={client.params?.bottomSize}
							width={'48%'}
							onChange={(value: any) => {
								handleSetUserParams('bottomSize', value)
							}}
							options={sizesOptions}
						/>
						<CustomSelect
							isDisabled={false}
							placeholder="Dress"
							defaultValue={client.params?.dressSize}
							width={'48%'}
							onChange={(value: any) => {
								handleSetUserParams('dressSize', value)
							}}
							options={sizesOptions}
						/>
						<CustomSelect
							isDisabled={false}
							placeholder="Shoes"
							defaultValue={client.params?.shoesSize}
							width={'48%'}
							onChange={(value: any) => {
								handleSetUserParams('shoesSize', value)
							}}
							options={sizesShoesOptions}
						/>
					</SelectWrapper>
				</Col>
				<Col span={12}>
					<SelectWrapper>
						<CustomSelect
							isDisabled={false} //isSettingsLoading
							placeholder="Top"
							defaultValue={client.params?.topExpense}
							width={'48%'}
							onChange={(value: any) => {
								handleSetUserParams('topExpense', value)
							}}
							options={exspencesOptions}
						/>
						<CustomSelect
							isDisabled={false}
							placeholder="Bottom"
							defaultValue={client.params?.bottomExpense}
							width={'48%'}
							onChange={(value: any) => {
								handleSetUserParams('bottomExpense', value)
							}}
							options={exspencesOptions}
						/>
						<CustomSelect
							isDisabled={false}
							placeholder="Dress"
							defaultValue={client.params?.dressExpense}
							width={'48%'}
							onChange={(value: any) => {
								handleSetUserParams('dressExpense', value)
							}}
							options={exspencesOptions}
						/>
						<CustomSelect
							isDisabled={false}
							placeholder="Shoes"
							defaultValue={client.params?.shoesExpense}
							width={'48%'}
							onChange={(value: any) => {
								handleSetUserParams('shoesExpense', value)
							}}
							options={exspencesOptions}
						/>
					</SelectWrapper>
				</Col>
			</Row>
			<Row gutter={[0, 36]} style={{ marginTop: 57 }}>
				<Col flex="0 1 100%">
					<TitleBlock>Stylist wants to know...</TitleBlock>
				</Col>
				<Col flex="0 1 100%">
					<CustomSelect
						defaultValue={currentCategories}
						width={'100%'}
						mode="multiple"
						placeholder="What wardrobe items to include in capsule"
						onChange={handleCategorySelect}
						options={categoriesOptions}
						isDisabled={isSettingsLoading || isPreferencesLoading}
						multiItemsType="text"
					/>
				</Col>
				<Col flex="0 1 100%">
					<Input
						type="textArea"
						label="label Text"
						inputFieldSlot={
							<textarea
								{...register('questionArea')}
								rows={4}
								defaultValue={''}
								onBlur={() => {}}
							/>
						}
						errorText={''}
						width="100%"
						placeholder="How open are you to stepping out of your styling comfort zone?"
						hasValue={!!watch('questionArea')}
						style={{ marginTop: 36 }}
					/>
				</Col>

				{/* TODO: Remove isPreview flag when setClientPreferences API will be ready */}
				<PreferencesBlock
					isPreview
					user={{
						id: client.id,
						role: 'client',
					}}
				/>
			</Row>
		</Col>
	)
}
