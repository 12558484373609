import { Icon } from '../../../../../UI/icon'
import { MenuItem, MenuTitle, IconWrapper } from './left-menu-item-styled'
import { useLeftMenuItem } from './use-left-menu-item'
import { MenuItemType } from '../use-left-menu'

type Props = {
	item: MenuItemType
}

export function LeftMenuItem({ item }: Props) {
	const { menuItemClickHandler, isActiveMenuItem } = useLeftMenuItem(item)

	return (
		<MenuItem
			onClick={() => menuItemClickHandler(item.route)}
			className={isActiveMenuItem ? 'active' : ''}
		>
			<IconWrapper>
				<Icon name={item.icon} size={item.size} />
			</IconWrapper>
			<MenuTitle>{item.title}</MenuTitle>
		</MenuItem>
	)
}
