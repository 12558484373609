import { Col, Row } from 'antd'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { bookStylist } from '@api/client/deal/book-stylist'
import { cancelBooking } from '@api/client/deal/cancel-booking'
import { getStylistsInfo } from '@api/client/get-stylist-info'
import { getStylistPublicCapsules } from '@api/client/get-stylist-public-capsules'
import { getStylistSharedCapsules } from '@api/client/get-stylist-shared-capsules'
import Avatar from '@components/avatar/avatar'
import { CapsulesItem } from '@components/capsules-item'
import { SdNotification } from '@components/sd-notification'
import { DeadlineBlock } from '@components/stylist-info-block/stylist-info-styled'
import { useInitChat } from '@hooks/use-init-chat'
import { authSelector, setUserCode } from '@store/auth'
import { chatSelector, setChatUser, setIsChatOpen } from '@store/chat'
import { useAppDispatch, useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { CapsuleInfo, DealAction, StylistInfo } from '@typings/types'
import { Block, GalleryButtons, GrayLine, Modal, Spinner } from '@ui/index'
import { handleToast } from '@utils/handle-toast'

import { generateAuthCode } from '@pages/login/api/generate-auth-code'
import { CodeForm } from '@pages/login/forms'
import {
	AvatarCont,
	BlockTitle,
	ButtonStyled,
	SdNoteContainer,
	StylistDescription,
	StylistInfoBlock,
	StylistName,
} from './stylist-details-page-styled'

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		partialVisibilityGutter: 40,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
}

export const StylistDetailsPage = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { state } = useLocation()
	const { id } = useParams()

	const { token, email } = useAppSelector(authSelector)
	const { userInfo } = useAppSelector(userSelector)
	const { chatUser, isChatLoading } = useAppSelector(chatSelector)
	const { initChat } = useInitChat()

	const userId = state?.userId
	const stylistId = state?.stylistId ?? id

	const [isDataLoading, setIsDataLoading] = useState(false)
	const [isNeedAuth, setIsNeedAuth] = useState(false)
	const [stylistData, setStylistData] = useState<StylistInfo>()
	const [stylistCapsules, setStylistCapsules] = useState<CapsuleInfo[]>([])

	const loadStylistDetails = useCallback(async () => {
		if (!userId) return

		setIsDataLoading(true)

		let [
			resultStylistData,
			resultStylistCapsules,
			resultStylistPublicCapsules,
		] = await Promise.all([
			await getStylistsInfo({ token, stylistId }),
			await getStylistSharedCapsules({ token, stylistId: userId }),
			await getStylistPublicCapsules({ token, stylistId: userId }),
		])

		if (resultStylistData) {
			setStylistData(resultStylistData)
		}

		resultStylistPublicCapsules = resultStylistPublicCapsules || []
		resultStylistCapsules = resultStylistCapsules || []

		if (resultStylistPublicCapsules || resultStylistCapsules) {
			setStylistCapsules(
				[...resultStylistPublicCapsules, ...resultStylistCapsules] || []
			)
		}

		setIsDataLoading(false)
	}, [stylistId, token, userId])

	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleCancelBooking = useCallback(async () => {
		setIsDataLoading(true)

		if (!stylistId) {
			return
		}

		const stylistCanceledResult = await cancelBooking({
			token,
			stylistId,
		})

		if (!stylistCanceledResult?.success) {
			handleToast({
				content:
					stylistCanceledResult?.errors?.[0] ?? 'Error while canceling booking',
			})
			setIsDataLoading(false)
			return
		}

		loadStylistDetails()
	}, [loadStylistDetails, stylistId, token])

	const handleCancel = () => {
		setIsModalOpen(false)
	}

	const handleOk = () => {
		setIsModalOpen(false)
		handleCancelBooking()
	}

	const handleActionApply = useCallback(
		async (action: DealAction) => {
			setIsDataLoading(true)
			// TODO: Тут будут добавляться обработчики других статусов кнопки
			switch (action) {
				case 'book':
					if (!token) {
						console.info('USER not auth!!!')

						if (!email) {
							handleToast({ content: 'Need to finish quiz first' })
							navigate('/quiz')
							setIsDataLoading(false)
							return
						}

						const userCode = await generateAuthCode({
							email,
						})

						if (!userCode?.success) {
							handleToast({ content: 'Error while generating code' })
							setIsDataLoading(false)
							return
						}

						dispatch(setUserCode(userCode.code))
						setIsNeedAuth(true)
						setIsDataLoading(false)
						return
					}

					const isStylistBooked = await bookStylist({ token, stylistId })

					if (!isStylistBooked) {
						navigate(`/rate-plans`, {
							state: {
								stylistId,
							},
						})
						return
					}

					setIsDataLoading(false)
					navigate(`/success-book`)
					return

				case 'styling_studio':
					navigate(`/styling-studio`, {
						state: {
							stylistId,
						},
					})
					return

				case 'book_cancel':
					setIsModalOpen(true)
					setIsDataLoading(false)
					return

				case 'book_another_stylist':
					setIsDataLoading(false)
					navigate('/stylists')
					return

				case 'chat_open':
					if (!chatUser) {
						const result = await initChat(userInfo, token)
						result && dispatch(setChatUser(result))
					}

					setIsDataLoading(false)
					dispatch(setIsChatOpen(true))
					return

				default:
					setIsDataLoading(false)
					handleToast({ content: 'This actions are not ready yet' })
					return
			}
		},
		[chatUser, dispatch, initChat, navigate, stylistId, token, userInfo]
	)

	useEffect(() => {
		if (isDataLoading) return

		loadStylistDetails()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const carouselRef = useRef<any>()

	if (!userId) return <>'No such stylist'</>
	if (isDataLoading) return <Spinner color="#FF5656" />

	const { statusText, statusDeadline } = stylistData?.dealStatus ?? {
		statusText: null,
		statusDeadline: null,
	}

	return (
		<Block isFullWidth padding={token ? '0 48px 48px' : '48px'}>
			<Row gutter={48}>
				<Col lg={{span: 12, offset: 6}} xl={{span: 6, offset: 0}}>
					<AvatarCont>
							<Avatar
								imageUrl={stylistData?.params?.photo?.[0].urls.L || ''}
								size="100%"
							/>
					</AvatarCont>
					<StylistInfoBlock>
						<StylistName>
							{stylistData?.first_name} {stylistData?.last_name}
						</StylistName>
						<StylistDescription>{stylistData?.notes}</StylistDescription>
						{statusText && (
							<SdNoteContainer>
								<GrayLine style={{ margin: '20px 0' }} />
								<SdNotification text={statusText} />
							</SdNoteContainer>
						)}
						{statusDeadline && (
							<DeadlineBlock>
								Due date {moment(statusDeadline).format('DD MMM, hh:mm')}
							</DeadlineBlock>
						)}
						<GrayLine style={{ margin: '20px 0' }} />
						{stylistData?.dealStatus?.clientActions.map((action, index) => (
							<ButtonStyled
								key={index}
								isDisabled={
									!action.enabled ||
									(action.action === 'chat_open' && isChatLoading)
								}
								skin={index % 2 ? 'dark-link' : 'dark'}
								onClick={() => handleActionApply(action.action)}
								isLoading={action.action === 'chat_open' && isChatLoading}
							>
								{action.button_text}
							</ButtonStyled>
						))}
					</StylistInfoBlock>
				</Col>
				<Col lg={{span: 24}} xl={{span: 18}}>
					<BlockTitle>My work</BlockTitle>
					{stylistCapsules.length > 3 && (
						<Row
							gutter={48}
							justify="space-between"
							style={{ margin: '24px 0 48px' }}
						>
							<Col span={16}>{/* Filter Block*/}</Col>
							<Col xs={{span: 24, offset: 0}} md={{span: 12, offset: 0}} lg={{span: 6, offset: 2}}>
								<GalleryButtons
									onNextArrowClick={() => carouselRef.current?.next()}
									onPrevArrowClick={() => carouselRef.current?.previous()}
								/>
							</Col>
						</Row>
					)}
					<Row gutter={[48, 48]} style={{ marginTop: 48 }}>
						<Col span={24}>
							<Carousel
								ref={(el) => (carouselRef.current = el)}
								responsive={responsive}
								arrows={false}
								containerClass="carousel-container"
								renderButtonGroupOutside={true}
								centerMode={false}
							>
								{stylistCapsules.map((capsule) => (
									<div key={capsule.id} style={{ margin: '0 24px 20px' }}>
										<CapsulesItem
											item={capsule}
											stylist={stylistData}
											noDetails
											onClick={() => {
												navigate(`/capsules/${capsule.id}`, {
													state: {
														capsuleId: capsule.id,
														stylistId,
														userId,
													},
												})
											}}
										/>
									</div>
								))}
							</Carousel>
						</Col>
					</Row>
				</Col>
			</Row>
			<Modal
				modalTitle="Are you sure you want to cancel this booking?"
				isModalOpen={isModalOpen}
				onCancel={handleCancel}
				onOk={handleOk}
			/>
			<Modal
				modalTitle="Please authorize to proceed with the booking"
				isModalOpen={isNeedAuth}
				onCancel={() => setIsNeedAuth(false)}
				onOk={() => {}}
				customFooter={<></>}
				children={
					<CodeForm
						stylistId={stylistId}
						isSilentUser
						handleCancelClick={() => setIsNeedAuth(false)}
						isWithTitle={false}
					/>
				}
			/>
		</Block>
	)
}
