import { fontTenorSans } from '@styles/typography'
import { Block } from '@ui/block'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
	& .cover-uploader,
	& .ant-upload-select {
		width: 100%;
	}
`

export const UpdateImageWrapper = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 0 auto;
	width: 100%;
	aspect-ratio: 1 / 1;
	background: rgba(0, 0, 0, 0.05);
	border-radius: 20px;

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
		`}
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	#upload-photo {
		opacity: 0;
		position: absolute;
		z-index: -1;
	}
`

export const StyledLabel = styled.label<{ isCoverAdded?: boolean }>`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 32px;

	width: 220px;
	height: 226px;

	border: 2px dashed rgba(0, 0, 0, 0.05);
	border-radius: 20px;

	${({ isCoverAdded }) =>
		isCoverAdded &&
		css`
			border: none;
		`}
`

export const UploadText = styled(Block)`
	${fontTenorSans}
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
`

export const EditPhotoWrapper = styled.div`
	#upload-file {
		opacity: 0;
		position: absolute;
		z-index: -1;
	}
`
