import { useLoginPage } from '@pages/login/use-login-page'
import { Spinner } from '@ui/spinner'
import { Outlet } from 'react-router-dom'

export function AuthRequired() {
	const { isLoading, token } = useLoginPage()

	if (isLoading && !token.length) return <Spinner color="#FF0C63" />
	return <Outlet />
}
