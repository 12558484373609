import styled, { css } from 'styled-components'
import { Block } from '../../../UI'
import { fontTenorSans } from '../../../styles/typography'

export const CompWrapper = styled.div`
	margin: 0 auto;
	display: flex;
	align-items: center;
	flex-direction: column;
`

export const UpdateImageWrapper = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	width: 246px;
	height: 246px;
	margin: 0 auto;

	background: rgba(0, 0, 0, 0.05);
	border-radius: 200px;

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
		`}
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	#upload-photo {
		opacity: 0;
		position: absolute;
		z-index: -1;
	}
`

export const StyledLabel = styled.label<{ isAvatarAdded?: boolean }>`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 32px;

	width: 190px;
	height: 190px;

	border: 2px dashed rgba(0, 0, 0, 0.05);
	border-radius: 200px;

	${({ isAvatarAdded }) =>
		isAvatarAdded &&
		css`
			border: none;
		`}
`

export const UploadText = styled(Block)`
	${fontTenorSans}
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
`

export const EditPhotoWrapper = styled.div`
	margin-top: 24px;

	#upload-file {
		opacity: 0;
		position: absolute;
		z-index: -1;
	}
`

export const ErrorBlock = styled.div`
	${fontTenorSans}
	color: red;
	text-align: center;
`
