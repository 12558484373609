import { Dropdown, Row } from 'antd'
import { Link } from 'react-router-dom'

import { Block, Button, Icon, SiteLogo } from '@ui/index'
import { HeaderCount } from '../../header-count/header-count'
import { HeaderProps } from '../../types'
import { useHeader } from '../../use-header'
import {
	ProfileArrowBlock,
	ProfileImage,
	ProfileWrapper,
	RightBlock,
	Wrapper,
} from './header-mobile-styled'

export function HeaderMobile({ onMenuToggle }: HeaderProps) {
	const { token, counts, role, imageUrl, handleProfile, handleLogOut } =
		useHeader()

	return (
		<Row>
			<Wrapper>
				<Block onClick={token ? onMenuToggle : () => {}}>
					{token && <Icon name="mobileMenuIcon" />}
				</Block>

				<Block isFlexBlock isContentCentered>
					<SiteLogo color="white" size={48} />
				</Block>
				<RightBlock isFlexBlock>
					{token &&
						counts.map((item, index) => <HeaderCount key={index} {...item} />)}
					<Link to={role ? `/${role}-profile` : '/login'}>
						<ProfileWrapper>
							<ProfileImage imageUrl={imageUrl}>
								{!imageUrl && <Icon name="userIcon" color="white" size={32} />}
							</ProfileImage>
						</ProfileWrapper>
					</Link>
					{token && (
						<Dropdown
							menu={{
								items: [
									{
										key: '1',
										label: (
											<Button skin="grey-link" size="m" onClick={handleProfile}>
												Profile
											</Button>
										),
									},
									{
										key: '2',
										label: (
											<Button skin="grey-link" size="m" onClick={handleLogOut}>
												Log Out
											</Button>
										),
									},
								],
							}}
							placement="bottomRight"
							arrow={{ pointAtCenter: true }}
						>
							<ProfileArrowBlock isFlexBlock margin="0 0 0 12px">
								<Icon name="arrowDownIcon" />
							</ProfileArrowBlock>
						</Dropdown>
					)}
				</RightBlock>
			</Wrapper>
		</Row>
	)
}
