import { StyleboardInfo } from '@typings/types'
import { updateStyleboard } from '../../api/styleboard/update-styleboard'
import { useAppSelector } from '../../store'
import { authSelector } from '../../store/auth'

type InfoKeysToSave = keyof StyleboardInfo
type ValueToSave = string | string[] | number | number[] | null

export function useUpdateStyleboardInfo() {
	const { token } = useAppSelector(authSelector)

	const handleSetStyleboardInfo = async (
		id: number,
		key: InfoKeysToSave,
		value: ValueToSave
	) => {
		const newFields = JSON.stringify({
			[key]: value,
		})
		const result = await updateStyleboard(newFields, id, token)

		return result
	}

	return {
		handleSetStyleboardInfo,
	}
}
