import { CometChatUIKit } from '@cometchat/chat-uikit-react'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useInitChat } from '@hooks/use-init-chat'
import { logOut } from '@pages/login/api/log-out'
import {
	chatSelector,
	setChatId,
	setChatUser,
	setIsChatOpen,
} from '@store/chat'
import {
	authSelector,
	setAuthInitialState,
	useAppDispatch,
	useAppSelector,
} from '@store/index'
import { setLoginFormStep } from '@store/login-flow'
import { setInitialQuizState } from '@store/quiz'
import { setUserInitialState, userSelector } from '@store/user'
import { PROD_DOMAIN } from '@typings/constants'
import { deleteToken } from '@utils/delete-token'

const STYLE_DYLE_NOTIFICATION_UID = 'c00bfc6d-39db-4a26-8b6b-ab483a94cd61'

export const useHeader = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { userInfo } = useAppSelector(userSelector)
	const { token } = useAppSelector(authSelector)
	const { chatUser } = useAppSelector(chatSelector)
	const { initChat } = useInitChat()

	const { role } = userInfo
	const userName =
		userInfo?.stylist?.first_name || userInfo?.client?.first_name || 'Guest'
	const imageUrl =
		role && userInfo?.[role].params?.photo
			? PROD_DOMAIN + userInfo[role].params.photo?.[0].urls.S
			: ''

	const onBellClick = useCallback(async () => {
		if (chatUser) {
			dispatch(setIsChatOpen(true))
			dispatch(setChatId(STYLE_DYLE_NOTIFICATION_UID))
			return
		}

		if (userInfo?.role && userInfo[userInfo.role].cometchat_uid) {
			const result = await initChat(userInfo, token)
			if (result) {
				dispatch(setChatUser(result))
				dispatch(setIsChatOpen(true))
				dispatch(setChatId(STYLE_DYLE_NOTIFICATION_UID))
			}
		}
	}, [chatUser, dispatch, initChat, token, userInfo])

	const countsStylist = useMemo(() => {
		return [
			{
				count:
					userInfo.chatList?.find(
						(chat) => chat.cometchatUid === STYLE_DYLE_NOTIFICATION_UID
					)?.unreadMessagesCount ?? 0,
				onClick: onBellClick,
			},
		]
	}, [userInfo.chatList, onBellClick])

	const countsClient = useMemo(() => {
		return [
			{
				count:
					userInfo.chatList?.find(
						(chat) => chat.cometchatUid === STYLE_DYLE_NOTIFICATION_UID
					)?.unreadMessagesCount ?? 0,
				onClick: onBellClick,
			},
		]
	}, [userInfo.chatList, onBellClick])

	const counts = role === 'stylist' ? countsStylist : countsClient

	const handleProfile = () => {
		navigate(`/${role}-profile`)
	}

	const handleLogOut = async () => {
		try {
			chatUser && (await CometChatUIKit.logout())
		} catch (error) {
			console.error('CometChatUIKit.logout error: ', error)
		}
		const isLogout = await logOut()

		if (isLogout) {
			dispatch(setInitialQuizState())
			dispatch(setAuthInitialState())
			dispatch(setUserInitialState())
			dispatch(setLoginFormStep('email'))
			deleteToken()
			navigate('/login')
		}
	}

	return {
		token,
		counts,
		role,
		imageUrl,
		userName,
		handleProfile,
		handleLogOut,
	}
}
