import { colors } from '@styles/colors'
import { bold14, fontRoboto } from '@styles/typography'
import styled from 'styled-components'

export const Container = styled.div`
	${fontRoboto}
	${bold14}
	color: #5C5C5C;

	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;

	position: absolute;
	width: 166px;
	height: 40px;
	left: calc(50% - 166px / 2 + 0.5px);
	bottom: 25px;

	background: ${colors.whiteTr80};
	border-radius: 8px;

	cursor: pointer;
`
