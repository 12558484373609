import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import type { DatePickerProps } from 'antd'
import { setUserStatus, userSelector } from '../../../store/user'
import { useAppDispatch, useAppSelector } from '../../../store'
import { authSelector } from '../../../store/auth'
import { signToInterview } from '../../../api/sign-to-interview'
import { statusToModeration } from '../../../api/status-to-moderation'
import { useUpdateUserProfileInfo } from '../../../hooks/api/use-update-user-profile-info'
import { Countries, getCountries } from '../../../api/get-countries'
import { settingsSelector } from '@store/settings'
import { useNavigate } from 'react-router-dom'
import { NavigationButtonInfo } from '@typings/types'

type FormValues = {
	firstName: string
	lastName: string
	phone: string
	email: string
	cv?: string
	portfolio?: string
	about?: string
	avatar?: string
	questionArea?: string
	city?: string
	country?: string
}

type LocationOption = {
	label: string
	value: string
}

export function usePersonalDataForm() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { userInfo } = useAppSelector(userSelector)
	const { token } = useAppSelector(authSelector)
	const { isLoading: isSettingsLoading, settings } =
		useAppSelector(settingsSelector)

	const { handleSetUserInfo, handleSetUserParams } = useUpdateUserProfileInfo()

	const [isCountriesLoading, setIsCountriesLoading] = useState(false)
	const [countriesObj, setCountriesObj] = useState<Countries | undefined>(
		undefined
	)
	const [countries, setCountries] = useState<LocationOption[]>([])
	const [cities, setCities] = useState<LocationOption[]>([])
	const [isCityDisabled, setCityDisabled] = useState(
		!userInfo.stylist.params.city
	)

	const [isUserLoading, setIsUserLoading] = useState(true)

	const navigationButtons: NavigationButtonInfo[] = [
		{
			title: 'Preview',
			iconName: 'previewIcon',
			showListType: 'preview',
			handleClick: () => {
				navigate(`./preview`)
			},
		},
		{
			title: 'Edit',
			iconName: 'editIcon',
			showListType: 'edit',
			handleClick: () => {},
		},
	]

	const fetchCountries = useCallback(async () => {
		setIsCountriesLoading(true)
		const countriesData = await getCountries()

		if (!countriesData) {
			setIsCountriesLoading(false)
			return
		}

		setCountriesObj(countriesData)
		const countries = Object.keys(countriesData ?? []).map((item) => ({
			label: item,
			value: item,
		}))
		setCountries(countries)

		if (
			!userInfo.stylist?.params?.country ||
			userInfo.stylist?.params?.country === 'Country'
		) {
			setCityDisabled(true)
			setIsCountriesLoading(false)
			return
		}

		const setOfCities = new Set(
			countriesData[
				userInfo.stylist?.params?.country as keyof typeof countriesData
			]
		)

		const tempCities = Array.from(setOfCities).map((item) => ({
			label: item,
			value: item,
		}))

		setCities(tempCities)
		setIsCountriesLoading(false)
	}, [userInfo.stylist?.params?.country])

	useEffect(() => {
		if (userInfo.id) {
			setIsUserLoading(false)
		}
	}, [userInfo.id])

	useEffect(() => {
		async function fetchData() {
			await fetchCountries()
		}
		if (isCountriesLoading || countriesObj) return

		fetchData()
	}, [countriesObj, fetchCountries, isCountriesLoading])

	const [isLoading, setIsLoading] = useState(false)

	const {
		register,
		formState: { errors },
		watch,
		setValue,
		setError,
	} = useForm<FormValues>()

	const onDatePickerChange: DatePickerProps['onChange'] = (dateString) => {
		handleSetUserParams('birthday', dateString ? dateString.toString() : '')
	}

	const handleGenderSelect = (value: string) => {
		handleSetUserInfo('sex', value)
	}

	const handleCountrySelect = (value: string) => {
		if (!countriesObj) return

		const setOfCities = new Set(countriesObj[value])

		const tempCities = Array.from(setOfCities).map((item) => ({
			label: item,
			value: item,
		}))
		setValue('city', 'City')
		setCities(tempCities)
		setCityDisabled(false)
		handleSetUserParams('country', value)
	}

	const handleCitySelect = (value: string) => {
		handleSetUserParams('city', value)
	}

	const handleSignUp = async (e: any) => {
		e.preventDefault()
		e.stopPropagation()

		if (!userInfo.stylist?.params?.photo?.length) {
			setError('avatar', { message: 'Please, upload your photo' })
		}

		if (userInfo.status === 'interview-wait') return

		if (userInfo.status === 'moderation-need') {
			setIsLoading(true)
			const isOnModeration = await statusToModeration(
				userInfo.stylist.id,
				token
			)
			if (isOnModeration) {
				dispatch(setUserStatus('moderation-wait'))
			}
			setIsLoading(false)
			return
		}

		setIsLoading(true)
		const isOnInterview = await signToInterview(userInfo.stylist.id, token)

		if (isOnInterview) {
			dispatch(setUserStatus('interview-wait'))
			navigate('/')
		}

		setIsLoading(false)
	}

	const genderOptions = useMemo(() => {
		if (!settings) return

		const genderData = settings.SexAttributes

		if (!genderData) return

		return Object.keys(genderData).map((item) => ({
			label: genderData[item],
			value: item,
		}))
	}, [settings])

	const submitButtonText = useMemo(() => {
		const userStatus = userInfo.status

		switch (userStatus) {
			case 'interview-wait':
				return 'Waiting for interview results'
			case 'moderation-need':
				return 'Sent to moderation'
			case 'moderation-wait':
				return 'Waiting for moderation'

			default:
				return 'Sign up for an interview'
		}
	}, [userInfo.status])

	const handleTakeQuiz = () => {
		navigate('/quiz')
	}

	return {
		cities,
		countries,
		errors,
		genderOptions,
		handleCitySelect,
		handleCountrySelect,
		handleGenderSelect,
		handleSetUserInfo,
		handleSignUp,
		handleTakeQuiz,
		isCityDisabled,
		isCountriesLoading,
		isLoading,
		isSettingsLoading,
		isUserLoading,
		navigationButtons,
		onDatePickerChange,
		register,
		submitButtonText,
		userInfo,
		watch,
		setValue,
	}
}
