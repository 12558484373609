import { Countries, getCountries } from '@api/get-countries'
import { useUpdateUserProfileInfo } from '@hooks/api/use-update-user-profile-info'
import { useAppSelector } from '@store/index'
import { settingsSelector } from '@store/settings'
import { userSelector } from '@store/user'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

type LocationOption = {
	label: string
	value: string
}

export function usePersonalDataForm() {
	const { userInfo } = useAppSelector(userSelector)
	const client = userInfo.client
	const { isLoading: isSettingsLoading, settings } =
		useAppSelector(settingsSelector)

	const { handleSetUserInfo, handleSetUserParams, handleSetUserPhone } =
		useUpdateUserProfileInfo()

	const [countries, setCountries] = useState<LocationOption[]>([])
	const [cities, setCities] = useState<LocationOption[]>([])
	const [isCountriesLoading, setIsCountriesLoading] = useState(false)
	const [isCityDisabled, setCityDisabled] = useState(
		!userInfo.client?.params?.city
	)
	const [countriesObj, setCountriesObj] = useState<Countries | undefined>(
		undefined
	)

	const { register, watch, setValue } = useForm<any>()

	const fetchCountries = useCallback(async () => {
		setIsCountriesLoading(true)
		const countriesData = await getCountries()

		if (!countriesData) {
			setIsCountriesLoading(false)
			return
		}

		setCountriesObj(countriesData)

		const countries = Object.keys(countriesData ?? []).map((item) => ({
			label: item,
			value: item,
		}))
		setCountries(countries)

		if (
			!userInfo.client?.params?.country ||
			userInfo.client?.params?.country === 'Country'
		) {
			setCityDisabled(true)
			setIsCountriesLoading(false)
			return
		}

		const setOfCities = new Set(
			countriesData[
				userInfo.client?.params?.country as keyof typeof countriesData
			]
		)

		const tempCities = Array.from(setOfCities).map((item) => ({
			label: item,
			value: item,
		}))

		setCities(tempCities)
		setIsCountriesLoading(false)
	}, [userInfo.client?.params?.country])

	useEffect(() => {
		async function fetchData() {
			await fetchCountries()
		}
		if (isCountriesLoading || countriesObj) return

		fetchData()
	}, [countriesObj, fetchCountries, isCountriesLoading])

	const genderOptions = useMemo(() => {
		if (!settings) return

		const genderData = settings.SexAttributes

		if (!genderData) return

		return Object.keys(genderData).map((item) => ({
			label: genderData[item],
			value: item,
		}))
	}, [settings])

	const handleCountrySelect = (value: string) => {
		if (!countriesObj) return

		const setOfCities = new Set(countriesObj[value])

		const tempCities = Array.from(setOfCities).map((item) => ({
			label: item,
			value: item,
		}))
		setValue('city', 'City')
		setCities(tempCities)
		setCityDisabled(false)
		handleSetUserParams('country', value)
	}

	return {
		cities,
		client,
		countries,
		genderOptions,
		handleCountrySelect,
		handleSetUserInfo,
		handleSetUserParams,
		handleSetUserPhone,
		isCityDisabled,
		isCountriesLoading,
		isSettingsLoading,
		register,
		setValue,
		watch,
		userInfo,
	}
}
