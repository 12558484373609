import { QuizSelector } from '@store/quiz'
import { useAppSelector } from '@store/store'
import { colors } from '@styles/index'
import { IconNames } from '@ui/icon/types'
import { Icon } from '@ui/index'
import { useCallback, useMemo } from 'react'
import {
	Container,
	IconCont,
	IconsCont,
	IconWrapper,
	Line,
} from './quiz-header-styled'

type StepOption = {
	className: 'active' | 'done' | 'wait'
	iconName: IconNames
	color: string
}

export function QuizHeader() {
	const { questions, currentStep } = useAppSelector(QuizSelector)

	const quizSteps = useMemo(
		() => Array.from({ length: questions.length }, (_, i) => i + 1),
		[questions]
	)

	const getStepOption: (step: number) => StepOption = useCallback(
		(step: number) => {
			if (currentStep === step)
				return {
					className: 'active',
					iconName: 'hangerIcon',
					color: colors.black,
				}
			if (currentStep > step)
				return {
					className: 'done',
					iconName: 'hangerFullIcon',
					color: colors.blackTr48,
				}
			return {
				className: 'wait',
				iconName: 'hangerIcon',
				color: colors.blackTr48,
			}
		},
		[currentStep]
	)

	if (!currentStep || currentStep === 888 || currentStep === 999) return null

	return (
		<Container>
			<IconsCont>
				{quizSteps.map((step) => (
					<IconWrapper
						key={step}
						step={step}
						quizLength={questions.length}
						className={getStepOption(step).className}
					>
						<IconCont>
							<Icon
								name={getStepOption(step).iconName}
								color={getStepOption(step).color}
							/>
						</IconCont>
					</IconWrapper>
				))}
			</IconsCont>
			<Line />
		</Container>
	)
}
