import { colors } from '@styles/colors'
import { PROD_DOMAIN } from '@typings/constants'
import { BlankPlaceholder, Icon } from '@ui/index'
import {
	AvatarCont,
	AvatarWrapper,
	IconWrapper,
	CountWrapper,
} from './avatar-styled'

type props = {
	imageUrl: String
	size?: number | string
	workStatus?: String
	count?: number
}

export default function Avatar({
	imageUrl,
	size,
	workStatus = 'none',
	count,
}: props) {
	const iconName = workStatus === 'in_progress' ? 'pinIcon' : 'checkCircleIcon'
	const iconSize = size && typeof size === 'number' ? size * 0.35 : 35
	const fullImageUrl = imageUrl ? PROD_DOMAIN + imageUrl : ''

	return (
		<AvatarCont>
			<AvatarWrapper imageUrl={fullImageUrl} size={size || 100}>
				{!fullImageUrl && (
					<BlankPlaceholder color={colors.blackTr5} logoSize="50%" />
				)}
			</AvatarWrapper>
			{!count && workStatus === 'done' && (
				<IconWrapper color="white">
					<Icon name={iconName} size={iconSize} />
				</IconWrapper>
			)}
			{!!count && <CountWrapper>{count}</CountWrapper>}
		</AvatarCont>
	)
}
