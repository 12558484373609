import { colors } from '@styles/colors'
import { regular16 } from '@styles/typography'
import styled from 'styled-components'

export const ClientBlock = styled.div`
	${regular16}

	display: flex;
	justify-content: start;
	align-items: center;
	padding: 8px;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid ${colors.blackTr16};

	width: 100%;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: default;

	& > div {
		margin: 0;
	}
`
