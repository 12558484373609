import styled from 'styled-components'

import { colors } from '@styles/colors'
import {
	bold12,
	fontRoboto,
	fontTenorSans,
	regular14,
	regular16,
} from '@styles/typography'
import { Button } from '@ui/button'

export const NotificationWrapper = styled.div`
	${fontRoboto}
	${regular14}
	color: ${colors.black};
	margin: 5px 9px;
`

export const TitleWrapper = styled.div`
	${fontTenorSans}
	${regular16}
  margin-bottom: 16px;
`

export const ButtonNotification = styled(Button)`
	padding: 8px 20px;
	${bold12}
`
