import { Col, Row } from 'antd'
import { useMemo, useRef } from 'react'

import { AvatarClientBlock } from '@components/avatar-client-block'
import Avatar from '@components/avatar/avatar'
import { PageNavigation } from '@components/page-navigation/page-navigation'
import { SdNotification } from '@components/sd-notification'
import { BookStatus } from '@typings/types'
import {
	Block,
	Button,
	ButtonLinkTo,
	CustomSelect,
	GalleryButtons,
	GrayLine,
	Icon,
	Input,
	Modal,
	RadioButton,
	Spinner,
} from '@ui/index'
import {
	CategoryItem,
	CategoryList,
	ClothNumber,
	ColorCircle,
	ColorWrapper,
	Price,
	ShareModalText,
	ShowcaseBtnText,
} from './capsules-edit-page-styled'
import { GalleryBlock } from './gallery-block/gallery-block'
import { UploadCover } from './upload-cover/upload-cover'
import { useCapsuleEditPage } from './use-capsule-edit-page'

export function CapsulesEditPage() {
	const carouselRef = useRef<any>()
	const {
		clientAvatarUrl,
		capsule,
		capsuleClothesList,
		clientLabel,
		dealInfo,
		currentPage,
		errors,
		handleAddNewItem,
		handleSetCapsuleInfo,
		handleSaveAsDraft,
		handleShare,
		handleDuplicateCapsule,
		handleUnlinkClient,
		handleLinkClients,
		isCapsuleLoading,
		isClientLoading,
		navigationButtons,
		register,
		settings,
		capsuleGenderOptions,
		handleGenderOptionsChange,
		watch,
		clientListToShow,
		homepageRadioHandleClick,
		coverCapsuleHandleClick,
		isHomepageRadioActive,
		isCoverCapsule,
		isShareModalOpen,
		handleShareOk,
		handleShareCancel,
		isNotReadyPublish,
		capsuleGender,
	} = useCapsuleEditPage()

	const capsuleIsShared = useMemo(() => {
		const blockedCapsuleStatuses: BookStatus[] = [
			'done',
			'archive',
			'response_capsule',
		]

		return (
			dealInfo?.dealStatus &&
			blockedCapsuleStatuses.includes(dealInfo?.dealStatus?.status)
		)
	}, [dealInfo])

	if (isCapsuleLoading) {
		return (
			<Block isFullWidth margin="0 48px 48px">
				<PageNavigation
					showListType={currentPage}
					buttons={navigationButtons}
				/>
				<Spinner color="#FF5656" />
			</Block>
		)
	}

	const renderCategories = () => {
		const categories = capsule.summary?.category
		if (!categories) return null

		return (
			<CategoryList>
				{Object.entries(categories).map(([key, value], index) => {
					return <CategoryItem key={index}>{`${key} (${value})`}</CategoryItem>
				})}
			</CategoryList>
		)
	}

	const renderColors = () => {
		const colors = capsule.summary?.color
		const storeColors = settings.ItemColors

		if (!colors) return null

		return (
			<ColorWrapper>
				{Object.entries(colors).map(([key, value]) => {
					return (
						<ColorCircle key={key} color={storeColors?.[key]}></ColorCircle>
					)
				})}
			</ColorWrapper>
		)
	}

	return (
		<Block isFullWidth margin="0 48px 48px">
			{capsule.dealStatus?.status}
			<PageNavigation showListType={currentPage} buttons={navigationButtons} />
			{capsuleIsShared && (
				<Row gutter={48} style={{ marginBottom: 64 }}>
					<Col xxl={{ span: 6 }} xl={{ span: 4 }} className="row-block-label">
						Share settings
					</Col>
					<Col xxl={{ span: 18 }} xl={{ span: 20 }}>
						<SdNotification text="This capsule has been shared with the client and cannot be modified You can use it as a template to create a new one" />
					</Col>
					<Col
						xxl={{ span: 12, offset: 6 }}
						xl={{ span: 16, offset: 4 }}
						style={{ marginTop: 63 }}
					>
						<Block isFlexBlock gap="48px" isFullWidth>
							<Button
								isSubmit
								isDisabled={true}
								skin="dark"
								margin="0"
								onClick={() => {}}
							>
								Share capsule
							</Button>
							<Button
								isSubmit
								isDisabled={false}
								skin="dark"
								margin="0"
								onClick={handleUnlinkClient}
							>
								Unlink client
							</Button>
							<Button
								isSubmit
								isDisabled={false}
								skin="dark"
								margin="0"
								onClick={handleDuplicateCapsule}
							>
								Duplicate capsule
							</Button>
						</Block>
					</Col>
				</Row>
			)}
			<Block
				style={capsuleIsShared ? { opacity: 0.2, pointerEvents: 'none' } : {}}
			>
				<Row gutter={48}>
					<Col xxl={{ span: 6 }} xl={{ span: 4 }} className="row-block-label">
						Capsule details
					</Col>
					<Col xxl={{ span: 5 }} xl={{ span: 6 }}>
						<div className="col-block-label-h3">Cover photo</div>
						<Block margin="26px 0 0 0">
							<UploadCover />
						</Block>
					</Col>
					<Col xxl={{ span: 13 }} xl={{ span: 14 }}>
						<Row gutter={[0, 26]}>
							<Col span={24}>
								<div className="col-block-label-h3">Capsule name</div>
							</Col>
							<Col span={24}>
								<Input
									width="540px"
									hasValue={!!watch('title')}
									label="title"
									placeholder="Come up with a name"
									inputFieldSlot={
										<input
											{...register('title')}
											onBlur={(e: any) =>
												handleSetCapsuleInfo('title', e.target.value)
											}
											onClick={(e: any) => {
												if (e.target.value === 'New capsule')
													e.target.value = ''
											}}
											type="text"
										/>
									}
									isError={!!errors.title}
									errorText={errors.title?.message}
								/>
							</Col>
							<Col span={24}>
								<div className="col-block-label-h3">
									Link capsule with client
								</div>
							</Col>
							<Col span={24}>
								<CustomSelect
									mode="tags"
									defaultValue={clientLabel}
									width={540}
									searchEnabled
									placeholder="Enter client name"
									onChange={handleLinkClients}
									options={clientListToShow}
									isDisabled={
										isHomepageRadioActive ||
										isClientLoading ||
										!!clientLabel.length
									}
									multiItemsType="client"
									tagRender={(props) => (
										<Block isFlexBlock gap="8px">
											<Avatar imageUrl={clientAvatarUrl ?? ''} size={24} />{' '}
											{clientLabel}
										</Block>
									)}
								/>
							</Col>
							<Col span={24}>
								<div className="col-block-label-h3">
									Who is this capsule for?
								</div>
							</Col>
							<Col span={24}>
								<CustomSelect
									defaultValue={capsuleGender ?? ''}
									width={540}
									placeholder="Select target group"
									onChange={handleGenderOptionsChange}
									options={capsuleGenderOptions}
									isDisabled={false}
									tagRender={(props) => (
										<Block isFlexBlock gap="8px">
											GENDER VALUE
										</Block>
									)}
								/>
							</Col>
							<Col span={24} style={{ display: 'flex', gap: 12 }}>
								<RadioButton
									isDisabled={!!clientLabel.length}
									onClick={homepageRadioHandleClick}
									isActive={isHomepageRadioActive}
								/>
								<ShowcaseBtnText>Show on my profile</ShowcaseBtnText>
								<SdNotification text="Make it visible to everyone viewing your profile" />
							</Col>
							<Col span={24} style={{ display: 'flex', gap: 12 }}>
								<RadioButton
									isDisabled={!!clientLabel.length}
									onClick={coverCapsuleHandleClick}
									isActive={isCoverCapsule}
								/>
								<ShowcaseBtnText>Set as cover capsule</ShowcaseBtnText>
							</Col>
						</Row>
					</Col>
					<Col span={24} style={{ marginTop: 72, marginBottom: 64 }}>
						<GrayLine />
					</Col>
				</Row>
				<Row gutter={48}>
					<Col xxl={{ span: 6 }} xl={{ span: 4 }} className="row-block-label">
						Clothing items
					</Col>
					<Col xxl={{ span: 18 }} xl={{ span: 20 }}>
						<Row gutter={24}>
							<Col flex="1 1 auto">
								<div
									className="col-block-label-h3"
									style={{ display: 'inline' }}
								>
									Added items
								</div>
								<ClothNumber>({capsuleClothesList.length})</ClothNumber>
							</Col>
							<Col flex="0 1 200px">
								<GalleryButtons
									onNextArrowClick={() => carouselRef.current?.next()}
									onPrevArrowClick={() => carouselRef.current?.previous()}
								/>
							</Col>
						</Row>
						<Row gutter={16} style={{ marginTop: 18 }}>
							<Col xxl={{ span: 4 }} xl={{ span: 5 }}>
								<ButtonLinkTo
									onClick={handleAddNewItem}
									title="Add"
									icon={<Icon name="clothAddIcon" size={36} />}
									minHeight={`234px`}
								/>
							</Col>
							<Col
								xxl={{ span: 20 }}
								xl={{ span: 19 }}
								style={{ overflow: 'hidden' }}
							>
								<GalleryBlock
									carouselRef={carouselRef}
									imageList={capsuleClothesList}
								/>
							</Col>
						</Row>
					</Col>
					<Col span={24} style={{ marginTop: 72, marginBottom: 64 }}>
						<GrayLine />
					</Col>
				</Row>
				<Row gutter={48}>
					<Col xxl={{ span: 6 }} xl={{ span: 4 }} className="row-block-label">
						Capsule summary
					</Col>
					<Col xxl={{ span: 18 }} xl={{ span: 20 }}>
						<Block margin="0 0 24px 0" className="col-block-label-h3">
							Clothing types in this capsule
						</Block>
						{renderCategories()}
					</Col>
					<Col span={24} style={{ marginTop: 56 }}></Col>
					<Col xxl={{ span: 6, offset: 6 }} xl={{ span: 7, offset: 4 }}>
						<div className="col-block-label-h3">Colors</div>
						{renderColors()}
					</Col>
					<Col xxl={{ span: 12 }} xl={{ span: 13 }}>
						<div className="col-block-label-h3">Overall budget</div>
						<Price>
							<p>{capsule.summary?.priceValute}</p>
							<p>{capsule.summary?.price}</p>
						</Price>
					</Col>
					<Col span={24} style={{ marginTop: 72, marginBottom: 64 }}>
						<GrayLine />
					</Col>
				</Row>
			</Block>

			{!capsuleIsShared && (
				<Row gutter={48}>
					<Col xxl={{ span: 6 }} xl={{ span: 4 }} className="row-block-label">
						Share settings
					</Col>
					<Col xxl={{ span: 18 }} xl={{ span: 20 }}>
						<SdNotification text="To share a capsule, fill all fields, add 4+ items, and choose to make it visible either to a specific client or to everyone viewing your profile." />
					</Col>
					<Col
						xxl={{ span: 12, offset: 6 }}
						xl={{ span: 16, offset: 4 }}
						style={{ marginTop: 63 }}
					>
						<Block isFlexBlock gap="48px" isFullWidth>
							<Button
								isSubmit
								isDisabled={isNotReadyPublish}
								skin="dark"
								margin="0"
								onClick={handleShare}
							>
								Share capsule
							</Button>
							<Button
								isSubmit
								isDisabled={false}
								skin="dark"
								margin="0"
								onClick={handleSaveAsDraft}
							>
								Save as draft
							</Button>
							<Button
								isSubmit
								isDisabled={false}
								skin="white-outline"
								margin="0"
								onClick={handleDuplicateCapsule}
							>
								Duplicate capsule
							</Button>
						</Block>
					</Col>
				</Row>
			)}

			<Modal
				modalTitle={`Are you ready to share the capsule with ${clientLabel}?`}
				isModalOpen={isShareModalOpen}
				onCancel={handleShareCancel}
				onOk={handleShareOk}
				confirmButtonText="Share capsule"
				cancelButtonText="Cancel"
			>
				<Block isFlexBlock flexDirection="column" gap="27px">
					<ShareModalText>
						Once shared, the capsule will be unavailable for changes unless your
						client requests them
					</ShareModalText>
					<ShareModalText>Make available to</ShareModalText>
					<AvatarClientBlock
						clientName={clientLabel[0]}
						imgUrl={clientAvatarUrl}
					/>
				</Block>
			</Modal>
		</Block>
	)
}
