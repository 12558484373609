import { colors } from '@styles/colors'
import { fontRoboto, regular16 } from '@styles/typography'
import styled from 'styled-components'

export const LabelElement = styled.div`
	${fontRoboto}
	${regular16}

  position: absolute;
	top: 18px;
	left: 13px;
	z-index: 100;
	padding: 0 4px;
	border: 1px solid transparent;

	transition: all 0.2s ease-in-out 0s, font-size 0.2s ease-in-out 0s;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	pointer-events: none;
`

export const Container = styled.div`
	position: relative;

	&.has-value ${LabelElement}, &.is-focused ${LabelElement} {
		font-weight: 400;
		font-size: 1.3rem;
		line-height: 2rem;
		background-color: ${colors.white};
		color: ${colors.blackTr48};

		padding: 0px 4px;
		pointer-events: none;

		top: -11px;
		left: 16px;
	}
`
