import { useRef, useState } from 'react'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { LoadingOutlined } from '@ant-design/icons'

import { useAppDispatch, useAppSelector } from '@store/store'
import { setUserPhoto, userSelector } from '@store/user'
import { authSelector } from '@store/auth'
import { PROD_DOMAIN } from '@typings/constants'
import { UploadImageRequest } from '@typings/types'
import { uploadImage } from '@api/upload-image'
import { updateProfile } from '@api/update-profile'
import { Block, Icon, Button } from '@ui/index'
import {
	UpdateImageWrapper,
	UploadText,
	StyledLabel,
	EditPhotoWrapper,
	ErrorBlock,
	CompWrapper,
} from './upload-avatar-styled'

export function UploadAvatar() {
	const dispatch = useAppDispatch()

	const { userInfo } = useAppSelector(userSelector)
	const { token } = useAppSelector(authSelector)

	const relationId = userInfo.client ? userInfo.client.id : userInfo.stylist.id

	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const hiddenPortfolioFileInput = useRef<HTMLInputElement | null>(null)

	const handleChange = async (e: any) => {
		setErrorMessage('')
		if (!userInfo.role) return
		if (!e.event || !e.fileList[0].originFileObj) {
			return
		}

		setIsLoading(true)

		const requestData: UploadImageRequest = {
			filename: e.fileList[0].originFileObj,
			relation_id: relationId.toString(),
			relation_type: userInfo.role,
		}

		if (!userInfo.id) {
			return
		}

		const newPhoto = await uploadImage({
			requestData,
			token,
		})

		if (!newPhoto) {
			setErrorMessage(
				'Something goes wrong. Maybe your photo is too big. Please try ro upload another photo.'
			)
			setIsLoading(false)
			return
		}

		await updateProfile(
			JSON.stringify({
				[userInfo.role]: {
					params: {
						photo: `image:${newPhoto.id}`,
					},
				},
			}),
			userInfo.id,
			token
		)

		dispatch(setUserPhoto(newPhoto))
		setIsLoading(false)
	}

	return (
		<CompWrapper>
			<ImgCrop rotationSlider>
				<Upload
					onChange={handleChange}
					maxCount={1}
					showUploadList={false}
					disabled={isLoading}
				>
					<UpdateImageWrapper
						imageUrl={
							userInfo.stylist?.params?.photo?.[0].urls?.M
								? PROD_DOMAIN + userInfo.stylist?.params?.photo?.[0].urls?.M
								: ''
						}
					>
						<StyledLabel
							htmlFor="upload-photo"
							isAvatarAdded={!!userInfo.stylist?.params?.photo?.length}
						>
							{isLoading ? (
								<LoadingOutlined />
							) : !!userInfo.stylist?.params?.photo?.length ? null : (
								<>
									<Block>
										<Icon name="uploadAvatarIcon" size={24} />
									</Block>
									<UploadText margin="10px 0 0 0">Add photo</UploadText>
								</>
							)}
						</StyledLabel>
					</UpdateImageWrapper>
					{errorMessage && <ErrorBlock>{errorMessage}</ErrorBlock>}
					<Block isFlexBlock isContentCentered flexDirection="column">
						{!!userInfo.stylist?.params?.photo?.length && (
							<EditPhotoWrapper>
								<Button isDisabled={isLoading} skin="grey-outline" isFitContent>
									Edit photo
								</Button>
							</EditPhotoWrapper>
						)}
					</Block>
				</Upload>
			</ImgCrop>
		</CompWrapper>
	)
}
