import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QuizQuestion, ReduxQuiz } from '@typings/types'

const initialState: ReduxQuiz = {
	isLoading: false,
	currentStep: 0,
	questions: [],
	answers: {},
	quizClientName: '',
}

const slice = createSlice({
	name: 'quiz',
	initialState,
	reducers: {
		setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoading = payload
		},
		setQuizQuestions: (state, { payload }: PayloadAction<QuizQuestion[]>) => {
			state.questions = payload
		},
		setLocalQuizAnswers: (
			state,
			{ payload }: PayloadAction<{ step: number; answer: string[] }>
		) => {
			state.answers = { ...state.answers, [payload.step]: payload.answer }
		},
		setQuizStep: (state, { payload }: PayloadAction<number>) => {
			if (payload < 0) {
				state.currentStep = 0
				return
			}

			state.currentStep = payload
		},
		setInitialQuizState: (state) => {
			state.isLoading = false
			state.currentStep = 0
			state.questions = []
			state.answers = {}
		},
		setQuizClientName: (state, { payload }: PayloadAction<string>) => {
			state.quizClientName = payload
		},
	},
})

export const quizReducer = slice.reducer
export const {
	setIsLoading,
	setQuizStep,
	setQuizQuestions,
	setLocalQuizAnswers,
	setInitialQuizState,
	setQuizClientName,
} = slice.actions
