import ArrowNext from '@components/arrow-next/arrow-next'
import { PROD_DOMAIN } from '@typings/constants'
import { CapsuleInfo, StylistInfo } from '@typings/types'
import { Row } from 'antd'
import {
	Container,
	StylistTitle,
	ClientDataWrapper,
	GrayLine,
	IconWrapper,
	ImageCont,
	CapsuleTitle,
	DivStyled,
	DivCapsuleCard,
	WrapperFlex,
} from './capsules-item-styled'
import Avatar from '@components/avatar/avatar'
import { Block } from '@ui/block'
import { BlankPlaceholder } from '@ui/blank-placeholder/blank-placeholder'
import { colors } from '@styles/colors'

type Props = {
	item: CapsuleInfo
	stylist?: StylistInfo
	onClick: (id: number) => void
}

export function ClientCapsulesItem({ item, stylist, onClick }: Props) {
	const capsulePhotoUrl = item.params?.coverPhoto
		? PROD_DOMAIN + item.params?.coverPhoto?.[0].urls?.M
		: ''

	return (
		<DivCapsuleCard onClick={() => onClick(item.id)}>
			<Container>
				<ImageCont imageUrl={capsulePhotoUrl}>
					{!capsulePhotoUrl && <BlankPlaceholder color={colors.blackTr24} />}
				</ImageCont>
				<ClientDataWrapper>
					<Block isFlexBlock isFullWidth>
						<div>
							<Avatar
								imageUrl={stylist?.params?.photo?.[0].urls?.M || ''}
								workStatus={stylist?.workStatus}
								size={56}
							/>
						</div>
						<WrapperFlex>
							<CapsuleTitle>{item.title}</CapsuleTitle>
							<StylistTitle>
								by {stylist?.first_name} {stylist?.last_name}
							</StylistTitle>
						</WrapperFlex>
					</Block>
					<GrayLine />
					<Row justify="start" align="middle">
						<IconWrapper>{item.summary?.itemsCount}</IconWrapper>
						<DivStyled>clothing items</DivStyled>
						<ArrowNext />
					</Row>
				</ClientDataWrapper>
			</Container>
		</DivCapsuleCard>
	)
}
