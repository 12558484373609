import React from 'react'

import { NameCont } from '@components/styleboard-item/styleboard-item-styled'
import { DealInfo } from '@typings/types'
import { Block, BlankPlaceholder, GrayLine } from '@ui/index'
import Avatar from '@components/avatar/avatar'
import { colors } from '@styles/colors'
import { BookingInfo } from '../booking-info'
import {
	ClientInfoWrapper,
	ImageCont,
	NewClientText,
	InfoCont,
	TextCont,
	AdditionalInfo,
	ClientItemStyled,
} from './client-item-styled'

type Props = {
	dealItem: DealInfo
	onClick: (id: number) => void
	maxWidth?: string
}

export default function ClientItem({ dealItem, onClick, maxWidth }: Props) {
	const clientThumbUrl = dealItem.client?.params?.photo
		? dealItem.client.params.photo[0].urls.S
		: ''
	const clientCoverUrl = dealItem.details?.styleboards[0]?.coverPhoto?.urls
		? dealItem.details.styleboards[0].coverPhoto.urls.M
		: ''

	return (
		<ClientItemStyled
			onClick={() => onClick(dealItem.client?.id)}
			style={{ maxWidth, margin: '0 auto' }}
		>
			<ImageCont imageUrl={clientCoverUrl}>
				{!clientCoverUrl && <BlankPlaceholder color={colors.blackTr24} />}
			</ImageCont>
			<ClientInfoWrapper>
				<InfoCont>
					<div>
						<Avatar
							imageUrl={clientThumbUrl}
							size={56}
							workStatus={dealItem.dealStatus.status}
							count={dealItem.dealStatus?.unreadMessagesCount ?? 0}
						/>
					</div>
					<TextCont>
						<NameCont>
							{dealItem.client?.first_name} {dealItem.client?.last_name}
							{dealItem.dealStatus?.status === 'wait' && (
								<NewClientText>NEW CLIENT</NewClientText>
							)}
						</NameCont>

						<AdditionalInfo>
							{dealItem.client?.tariff?.name
								? `tariff ${dealItem.client?.tariff?.name}`
								: ''}
						</AdditionalInfo>
					</TextCont>
				</InfoCont>
				<Block padding="20px 0">
					<GrayLine />
				</Block>
				<BookingInfo dealStatus={dealItem.dealStatus} />
				<Block padding="20px 0 0">
					<GrayLine />
				</Block>
			</ClientInfoWrapper>
		</ClientItemStyled>
	)
}
