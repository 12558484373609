import { useCallback, useEffect } from 'react'
import { authSelector } from '@store/auth'
import { useAppDispatch, useAppSelector } from '@store/store'
import { NavigationButtonInfo } from '@typings/types'
import { useNavigate } from 'react-router-dom'
import {
	capsulesSelector,
	setCapsulesList,
	setFilteredCapsulesList,
	setIsListLoading,
	setShowListType,
} from '@store/capsules'
import { getCapsulesList } from '@api/capsule/get-capsules-list'
import { setNewCapsuleClothesList } from '@store/clothes'
// import { createCapsule } from '@api/capsule/create-capsule'

export function useCapsulesPage() {
	const { token } = useAppSelector(authSelector)
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { isListLoading, capsulesList, showListType, filteredCapsulesList } =
		useAppSelector(capsulesSelector)

	const loadCapsules = useCallback(async () => {
		dispatch(setShowListType('all'))
		dispatch(setIsListLoading(true))
		let result = await getCapsulesList({ token })

		if (result) {
			result.sort(function (a, b) {
				return a.id > b.id ? -1 : 1
			})

			dispatch(setCapsulesList(result))
			dispatch(
				setFilteredCapsulesList(
					result.filter((capsule) => capsule?.status !== 'draft')
				)
			)
		}

		dispatch(setIsListLoading(false))
	}, [dispatch, token])

	useEffect(() => {
		if (isListLoading) return

		loadCapsules()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleAddNewCapsule = async () => {
		dispatch(setNewCapsuleClothesList([]))
		navigate(`/clothes`, {
			state: {
				status: 'new_capsule',
			},
		})
	}

	const handleCapsuleClick = async (id: number) => {
		navigate(`${id}`, {
			state: {
				capsuleId: id,
			},
		})
	}

	const handleChangeSearch = (data: any) => {
		dispatch(
			setFilteredCapsulesList(
				capsulesList.filter(
					(capsule) =>
						capsule.title &&
						capsule.title
							.toLowerCase()
							.includes(data.target.value.toLowerCase())
				)
			)
		)
	}

	const handleClickAll = useCallback(() => {
		if (showListType === 'all') return

		dispatch(setShowListType('all'))
		dispatch(
			setFilteredCapsulesList(
				capsulesList.filter((capsule) => capsule?.status !== 'draft')
			)
		)
	}, [dispatch, showListType, capsulesList])

	const handleClickDraft = useCallback(() => {
		if (showListType === 'draft') return

		dispatch(setShowListType('draft'))
		dispatch(
			setFilteredCapsulesList(
				capsulesList.filter((capsule) => capsule.status === 'draft')
			)
		)
	}, [dispatch, showListType, capsulesList])

	const buttons: NavigationButtonInfo[] = [
		{
			title: 'All capsules',
			iconName: 'hangerIcon',
			showListType: 'all',
			handleClick: handleClickAll,
		},
		{
			title: 'Draft',
			iconName: 'checkCircleIcon',
			showListType: 'draft',
			handleClick: handleClickDraft,
		},
	]

	return {
		capsulesList: filteredCapsulesList,
		handleAddNewCapsule,
		handleCapsuleClick,
		handleChangeSearch,
		isListLoading,
		buttons,
		showListType,
	}
}
