import { ReactNode } from 'react'

import { DottedFrame, BlockStyled } from './button-link-to-styled'

type Props = {
	icon?: ReactNode
	onClick: (e: any) => void
	title: string
	minHeight?: string
	maxWidth?: string
	height?: string
	bgColor?: string
}

export function ButtonLinkTo({
	onClick,
	title,
	icon,
	minHeight,
	maxWidth,
	height,
	bgColor,
}: Props) {
	return (
		<DottedFrame
			onClick={onClick}
			style={{ height, maxWidth, margin: '0 auto' }}
			bgColor={bgColor}
		>
			<BlockStyled minHeight={minHeight}>
				{icon}
				<div>{title}</div>
			</BlockStyled>
		</DottedFrame>
	)
}
