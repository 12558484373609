import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { ItemInfo } from '@typings/types'
import { ClothesItem } from '../clothes-item'
import { Container } from './gallery-block-styled'

type Props = {
	carouselRef: any
	imageList: ItemInfo[]
}

export function GalleryBlock({ carouselRef, imageList }: Props) {
	const responsive = {
		xxl: {
			// the naming can be any, depends on you.
			breakpoint: { max: 2500, min: 1600 },
			items: 6,
		},
		xl: {
			breakpoint: { max: 1599, min: 1200 },
			items: 5,
		},
	}

	return (
		<Container>
			<Carousel
				ref={(el) => (carouselRef.current = el)}
				responsive={responsive}
				arrows={false}
				containerClass="carousel-container"
				itemClass="carousel-item"
				renderButtonGroupOutside={true}
				centerMode={false}
				partialVisible={false}
			>
				{imageList.map((clothItem, index) => (
					<ClothesItem key={index} clothItem={clothItem} onClick={() => {}} />
				))}
			</Carousel>
		</Container>
	)
}
