import axios from 'axios'
import { axiosInstance } from '../../config'
import { StyleboardInfo } from '@typings/types'

type Props = {
	styleboardId: string | undefined
	token: string
}

export async function getStyleboardInfo({ styleboardId, token }: Props) {
	if (!styleboardId) {
		console.error('No user id!', styleboardId)
		return
	}

	try {
		const response = await axiosInstance.get<StyleboardInfo>(
			`/api/styleboard/${styleboardId}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
