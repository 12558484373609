import { useEffect, useState } from 'react'
import { useGetCurrency } from '@hooks/use-get-currency'
import { useAppDispatch, useAppSelector } from '@store/store'
import { authSelector } from '@store/auth'
import {
	clothesSelector,
	setCapsuleClothesList,
	setNewCapsuleClothesList,
} from '@store/clothes'
import {
	Brand,
	Container,
	DeleteFormCapsule,
	ImageCont,
	Price,
	Title,
} from './clothes-item-styled'
import { useLocation } from 'react-router-dom'
import { removeCapsuleItem } from '@api/capsule/remove-capsule-item'
import { ItemInfo } from '@typings/types'
import { PROD_DOMAIN } from '@typings/constants'
import { Icon } from '@ui/index'

type Props = {
	clothItem: ItemInfo
	onClick: (id: number) => void
}

export function ClothesItem({ clothItem, onClick }: Props) {
	const { state } = useLocation()

	const dispatch = useAppDispatch()

	const { token } = useAppSelector(authSelector)
	const { capsuleClothesList } = useAppSelector(clothesSelector)
	const userPhotoUrl = PROD_DOMAIN + clothItem.coverPhoto?.urls?.M
	const currency = useGetCurrency(clothItem.price_currency_id)

	const [isInCapsule, setIsInCapsule] = useState(
		capsuleClothesList.findIndex((item) => item.id === clothItem.id) !== -1
	)

	useEffect(() => {
		setIsInCapsule(
			capsuleClothesList.findIndex((item) => item.id === clothItem.id) !== -1
		)
	}, [capsuleClothesList, clothItem])

	const handleDeleteItem = async (e: any) => {
		e.stopPropagation()
		const capsuleId = state?.capsule?.id || state.capsuleId
		if (capsuleId) {
			const isItemOperationSuccess = await removeCapsuleItem(
				{
					items: [clothItem.id],
				},
				capsuleId,
				token
			)

			if (!isItemOperationSuccess) return
		}

		const tempList = capsuleClothesList.filter(
			(item) => item.id !== clothItem.id
		)

		setIsInCapsule(false)
		dispatch(setNewCapsuleClothesList(tempList))
		dispatch(setCapsuleClothesList(tempList))
	}

	return (
		<Container
			onClick={() => onClick(clothItem.id)}
			data-created={clothItem.created_at}
		>
			<DeleteFormCapsule
				skin="dark"
				onClick={handleDeleteItem}
				className={`hidden-button`}
				isFitContent
			>
				{isInCapsule && <Icon name="trashIcon" />}{' '}
			</DeleteFormCapsule>
			<ImageCont imageUrl={userPhotoUrl} />
			<Title>{clothItem.title}</Title>
			<Brand>{clothItem.brand}</Brand>
			<Price>{clothItem.price && `${currency} ${clothItem.price}`}</Price>
		</Container>
	)
}
