import { colors } from '@styles/colors'
import {
	DatePatterns,
	DetailsConfiguration,
	MessageComposerConfiguration,
	MessageComposerStyle,
	MessageListConfiguration,
	MessageListStyle,
	MessagesConfiguration,
	UsersStyle,
	WithMessagesStyle,
} from '@cometchat/chat-uikit-react'
import { MessagesHeader } from './messages-header/messages-header'
import scrapIcon from '@ui/icon/assets/scrap-icon.svg'
import sendIcon from '@ui/icon/assets/send-icon.svg'

const MAIN_THEME_GRADIENT_COLOR =
	'linear-gradient(rgb(255, 12, 99) 0%, rgb(255, 86, 86) 100%)'

const usersStyle = new UsersStyle({
	loadingIconTint: colors.mainThemeColor,
	onlineStatusColor: 'lightGreen',
	emptyStateTextColor: '#96DEDA',
	titleTextColor: `${colors.black}`,
	titleTextFont: `26px "Tenor Sans"`,
})

const withMessagesStyle = new WithMessagesStyle({
	border: `1px solid ${colors.blackTr16}`,
	messageTextFont: 'Roboto',
})

const messageListStyle = new MessageListStyle({})

const mListConfiguration = new MessageListConfiguration({
	messageListStyle: messageListStyle,
	DateSeparatorPattern: DatePatterns.DayDate,
	datePattern: DatePatterns.time,
})

const detailsConfiguration = new DetailsConfiguration({
	hideProfile: true,
	detailsStyle: {
		background: 'green',
		titleTextColor: 'black',
		closeButtonIconTint: 'green',
		subtitleTextColor: 'green',
	},
})

const messageComposerStyle = new MessageComposerStyle({
	AIIconTint: MAIN_THEME_GRADIENT_COLOR,
	attachIcontint: MAIN_THEME_GRADIENT_COLOR,
	emojiIconTint: MAIN_THEME_GRADIENT_COLOR,
	voiceRecordingIconTint: MAIN_THEME_GRADIENT_COLOR,
	sendIconTint: MAIN_THEME_GRADIENT_COLOR,
	closePreviewTint: MAIN_THEME_GRADIENT_COLOR,
	background: '#fffcff',
	border: `1px solid ${colors.blackTr24}`,
	borderRadius: '0 0 20px 0',
	inputBackground: colors.blackTr16,
	inputBorderRadius: '0',
	textColor: 'black',
})

const messageComposerConfiguration = new MessageComposerConfiguration({
	messageComposerStyle: messageComposerStyle,
	attachmentIconURL: scrapIcon,
	sendButtonIconURL: sendIcon,
	disableMentions: true,
	hideLayoutMode: true,
	actionSheetStyle: {
		ActionSheetSeparatorTint: colors.mainThemeColor,
	},
	mediaRecorderStyle: {
		submitIconTint: MAIN_THEME_GRADIENT_COLOR,
		startIconTint: MAIN_THEME_GRADIENT_COLOR,
		stopIconTint: MAIN_THEME_GRADIENT_COLOR,
		closeIconTint: MAIN_THEME_GRADIENT_COLOR,
	},
})

const messagesConfig = new MessagesConfiguration({
	hideMessageHeader: false,
	hideMessageComposer: false,
	hideDetails: true,
	messageHeaderView: MessagesHeader,
	messageListConfiguration: mListConfiguration,
	detailsConfiguration: detailsConfiguration,
	messageComposerConfiguration: messageComposerConfiguration,
})

export {
	usersStyle,
	withMessagesStyle,
	messagesConfig,
	mListConfiguration,
	detailsConfiguration,
	messageComposerConfiguration,
}
