import styled, { css } from 'styled-components'
import { fontTenorSans, regular18 } from '@styles/typography'
import { Block } from '../block'
import { colors } from '@styles/colors'

type Props = {
	minHeight?: string
	bgColor?: string
}

export const BlockStyled = styled(Block)<Props>`
	${regular18}
	${fontTenorSans}
  display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	border-radius: 20px;
	border: 2px dashed rgba(0, 0, 0, 0.1);
	padding: 40px 32px;
	justify-content: center;
	align-items: center;

	${(props) =>
		props.minHeight &&
		css`
			min-height: calc(${props.minHeight} - 60px);
		`}

	& > div {
		margin-top: 8px;
		text-align: center;
	}
`

export const DottedFrame = styled.div<Props>`
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 20px;
	background: rgba(0, 0, 0, 0.05);
	padding: 30px;
	height: 100%;

	${(props) =>
		props.bgColor &&
		css`
			background-color: ${props.bgColor};
		`}

	&:hover {
		box-shadow: 0px 6px 32px ${colors.blackTr8};
	}
`
