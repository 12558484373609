import { PreferenceTagState } from '@typings/types'
import { Icon } from '@ui/icon'
import { CheckButtonStyled } from './check-button-styled'

export type Props = {
	title: string
	isActive: boolean
	onSelect: (value: string, status: PreferenceTagState) => void
}

export function CheckButton({ title, onSelect, isActive }: Props) {
	const handleSelect = () => {
		onSelect(title, isActive ? 'ignored' : 'checked')
	}

	return (
		<>
			<CheckButtonStyled
				key={title}
				isActive={isActive}
				skin="grey"
				isFitContent
				leftIcon={
					isActive ? <Icon color={'#FF0C63'} name="heartFilledIcon" /> : null
				}
				onClick={handleSelect}
				size="l"
			>
				{title}
			</CheckButtonStyled>
		</>
	)
}
