import { useCallback } from 'react'
import { getUserInfo } from '@api/get-user-info'
import { useAppDispatch } from '@store/store'
import { setUserInfo } from '@store/user'

export const useUpdateUserInfo = () => {
	const dispatch = useAppDispatch()

	const updateUserInfo = useCallback(
		async (id: number, token: string) => {
			console.log('id', id)
			if (!id) return
			const result = await getUserInfo({ id, token })

			if (result) {
				dispatch(setUserInfo(result))
			}
		},
		[dispatch]
	)

	return { updateUserInfo }
}
