import styled from 'styled-components'

export const Container = styled.div`
	position: fixed;
	top: 0;
	right: 0;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 489px;
	height: 100vh;

	padding: 32px 48px 48px 48px;
	background: #fff;
	z-index: 102;
`

export const FilterHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	h2 {
		margin: 0;
		padding: 0;
		font-size: 26px;
		font-family: Tenor Sans;
		font-style: normal;
		font-weight: 400;
		line-height: 32px;

		color: #000;
	}
`

export const FilterBody = styled.div`
	margin-top: 32px;
`

export const FilterFooter = styled.div`
	button {
		width: 100%;
	}
`

export const FilterClose = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 8px;
	border: 1px solid rgba(0, 0, 0, 0.16);

	padding: 12px;
	cursor: pointer;
`

export const ItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	margin-bottom: 24px;

	h3 {
		margin: 0;
		padding: 0;
		font-size: 20px;
		font-family: Tenor Sans;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;

		color: #000;
	}
`

export const Background = styled.div`
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background: rgba(0, 0, 0, 0.8);
	z-index: 101;
`
