import { useNavigate } from 'react-router-dom'
import { Col, Popover, Row } from 'antd'

import { CapsulesItem } from '@components/capsules-item'
import { colors } from '@styles/index'
import { Icon, Swap } from '@ui/index'
import {
	BlockLabel,
	ButtonStyled,
	PromoContainer,
} from './client-capsules-styled'
import { CapsuleInfo } from '@typings/types'

type Props = {
	stylistId?: number
	capsulesList: CapsuleInfo[]
	elPerRow: number
}

export function ClientCapsulesBlock({
	stylistId,
	capsulesList,
	elPerRow,
}: Props) {
	const navigate = useNavigate()

	return (
		<PromoContainer>
			<Row>
				<Col flex="1 1 auto">
					<BlockLabel>My capsules</BlockLabel>
					<Popover
						placement="rightTop"
						content={
							<div className="styledyle-content">
								A capsule is a collection of clothing and accessories that
								complement each other perfectly.
							</div>
						}
						arrow={false}
						trigger="click"
					>
						<span style={{ cursor: 'pointer' }}>
							<Icon color={colors.black} name="infoCircleFilledIcon" />
						</span>
					</Popover>
				</Col>
				<Col flex="0 1 auto">
					<ButtonStyled
						skin="grey-link"
						rightIcon={
							<Icon
								color={colors.blackTr64}
								name={'arrowCircledIcon'}
								size={20}
							/>
						}
						onClick={() => navigate('/capsules')}
					>
						Show all
					</ButtonStyled>
				</Col>
			</Row>
			<Row gutter={[32, 24]} style={{ marginTop: 24 }}>
				<Swap
					is={!capsulesList[0]}
					isSlot={
						<Col span={24 / elPerRow}>
							<CapsulesItem isEmptyCapsule onClick={() => {}} />
						</Col>
					}
				>
					{!!capsulesList[0] &&
						capsulesList.slice(0, 4).map((capsule) => (
							<Col key={capsule.id} span={24 / elPerRow}>
								<CapsulesItem
									item={capsule}
									onClick={() =>
										navigate(`/capsules/${capsule.id}`, {
											state: {
												capsuleId: capsule.id,
												stylistId,
											},
										})
									}
								/>
							</Col>
						))}
				</Swap>
			</Row>
		</PromoContainer>
	)
}
