import styled from 'styled-components'
import { colors } from '@styles/colors'
import {
	bold14,
	fontRoboto,
	fontTenorSans,
	regular13,
	regular16,
} from '@styles/typography'
import { screenSize } from '@styles/screen-sizes'

export const CarouselWrapper = styled.div`
	width: 100%;
	max-width: 85vw;

	& .carousel-container {
	}

	& .carousel-item {
		padding-right: 40px;
	}
`

export const HoverWrapper = styled.div`
	height: 100%;
	padding: 56px;
	border-radius: 20px;

	@media (${screenSize.antXl}) {
		padding: 36px;
	}

	& .ant-image {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		height: 100%;
		overflow: hidden;
	}
`

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	max-width: 464px;
	height: 100%;
	cursor: pointer;
	border-radius: 6px;

	&:hover {
		& .hidden-button {
			opacity: 1;
		}

		${HoverWrapper} {
			box-shadow: 0px 6px 32px ${colors.blackTr8};
		}
	}
`
export const InfoWrapper = styled.div`
	display: block;
	padding: 16px;
	width: 100%;
	text-align: center;
`

export const Title = styled.div`
	${fontRoboto}
	${bold14}
	padding: 5px;
	min-height: 24px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: inherit;
`

export const Brand = styled.div`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr48};
	padding: 5px;
	min-height: 24px;
`

export const Price = styled.div`
	${fontTenorSans}
	${regular16}
  color: ${colors.blackTr64};
	margin-top: 9px;
	padding: 0 5px;
	min-height: 22px;
	letter-spacing: 0.005em;
`

export const FullScrIcon = styled.div`
	position: absolute;
	top: 10px;
	left: 10px;
	width: 24px;
	height: 24px;
	background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
	border-radius: 8px;
`
