import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FormStep, ReduxLoginFlow } from '../../typings/types'

const initialState: ReduxLoginFlow = {
	loginFormStep: 'email',
}

const slice = createSlice({
	name: 'loginFlow',
	initialState,
	reducers: {
		setLoginFormStep: (state, { payload }: PayloadAction<FormStep>) => {
			state.loginFormStep = payload
		},
	},
})

export const loginFlowReducer = slice.reducer
export const { setLoginFormStep } = slice.actions
