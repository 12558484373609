import { colors } from '@styles/colors'
import { bold12, bold16, fontRoboto, regular13 } from '@styles/typography'
import styled, { css } from 'styled-components'

export const ImageCont = styled.div<{ imageUrl?: string }>`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	padding: 36px 36px 0;
	width: 100%;
	height: 392px;
	border-radius: 20px;
	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
			box-shadow: none;
		`}
	background-color: ${colors.white};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-origin: content-box;
`

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	min-height: 298px;
	width: 100%;
	cursor: pointer;
	border-radius: 20px;

	&:hover {
		& ${ImageCont} {
			box-shadow: 0px 6px 32px ${colors.blackTr8};
		}

		& .hidden-button {
			opacity: 1;
		}
	}
`
export const ClientItemStyled = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 100%;

	&:hover {
		& ${ImageCont} {
			box-shadow: 0px 6px 32px ${colors.blackTr8};
		}

		& .hidden-button {
			opacity: 1;
		}
	}
`

export const ClientInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;

	margin: 0 16px;
	padding: 20px 24px 24px;

	z-index: 10;
	margin-top: -90px;
	position: relative;
	background: #fff;
	border-radius: 20px;
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	flex: 1 1;
`

export const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;
	background: ${colors.blackTr5};
	${fontRoboto}
	${bold16}
  color: ${colors.black};
	text-align: center;
	width: 30px;
	height: 30px;
`
export const InfoCont = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: no-wrap;
	gap: 16px;
	align-items: center;
`
export const TextCont = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export const AdditionalInfo = styled.div`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr64};

	line-height: 24px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const NewClientText = styled.span`
	${fontRoboto}
	${bold12}

	color: #fff;
	border-radius: 16px;
	background: var(--unnamed, linear-gradient(180deg, #ff0c63 0%, #ff5656 100%));
	backdrop-filter: blur(2px);

	padding: 2px 10px;
	margin-left: 10px;
	width: fit-content;
	letter-spacing: 1px;
`
