import { MediaLayout } from '@ui/media-layout/media-layout'
import { LeftSidebarMobile } from './layout/mobile/left-sidebar-mobile'
import { LeftSidebarDesktop } from './layout/desktop/left-sidebar-desktop'
import { SideBarProps } from './types'

export function LeftSidebar(props: SideBarProps) {
	return (
		<MediaLayout
			mobileSlot={<LeftSidebarMobile {...props} />}
			desktopSlot={<LeftSidebarDesktop />}
		/>
	)
}
