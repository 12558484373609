import { useCallback, useEffect, useMemo, useState } from 'react'
import {
	ImageItemType,
	NavigationButtonInfo,
	ShowPageType,
} from '@typings/types'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getItemInfo } from '@api/item/get-item'
import { useAppDispatch, useAppSelector } from '@store/store'
import { authSelector } from '@store/auth'
import { clothesSelector, setIsItemLoading, setItem } from '@store/clothes'
import { PROD_DOMAIN } from '@typings/constants'
import { useGetCurrency } from '@hooks/use-get-currency'
import copy from 'copy-to-clipboard'

export function useClothesPreviewPage() {
	const dispatch = useAppDispatch()
	const { pathname } = useLocation()
	const navigate = useNavigate()

	const { token } = useAppSelector(authSelector)
	const { item, isItemLoading } = useAppSelector(clothesSelector)

	const [imageItems, setImageItems] = useState<ImageItemType[]>([])

	const { id } = useParams()
	const itemId = id ? +id : null
	const currentPage: ShowPageType =
		pathname === `/clothes/${itemId}/edit` ? 'edit' : 'preview'

	const loadItemInfo = useCallback(async () => {
		dispatch(setIsItemLoading(true))
		const data = await getItemInfo({ token, itemId })

		if (data) {
			const images = data.images || []
			const imageItems = images.map((image) => ({
				id: image.id,
				url: PROD_DOMAIN + image.urls.M,
				previewUrl: PROD_DOMAIN + image.urls.L,
				isCover: data.coverPhoto?.id === image.id,
			}))
			dispatch(setItem(data))
			setImageItems(imageItems)
			dispatch(setIsItemLoading(false))
			return
		}
		dispatch(setIsItemLoading(false))
		navigate('/clothes')
	}, [dispatch, token, itemId, navigate])

	useEffect(() => {
		if (!itemId) return
		loadItemInfo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleEditClick = useCallback(() => {
		navigate(`/clothes/${itemId}/edit`, {
			state: {
				itemId: itemId,
			},
		})
	}, [itemId, navigate])

	const currency = useGetCurrency(item.price_currency_id)

	const handleClothLinkSelect = (event: React.MouseEvent<HTMLElement>) => {
		const element = event.currentTarget as HTMLInputElement
		element.select()

		return element.value
	}

	const handleLinkClick = (event: React.MouseEvent<HTMLElement>) => {
		if (!item.source_url) return

		window.open(item.source_url, '_blank')
	}

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'Preview',
				iconName: 'previewIcon',
				showListType: 'preview',
				handleClick: () => {},
			},
			{
				title: 'Edit',
				iconName: 'editIcon',
				showListType: 'edit',
				handleClick: handleEditClick,
			},
		],
		[handleEditClick]
	)

	return {
		currency,
		currentPage,
		handleClothLinkSelect,
		handleLinkClick,
		imageItems,
		isItemLoading,
		item,
		navigationButtons,
	}
}
