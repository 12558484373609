import { useMemo } from 'react'
import { Col, Row } from 'antd'
import moment from 'moment'

import ArrowNext from '@components/arrow-next/arrow-next'
import { PROD_DOMAIN } from '@typings/constants'
import { CapsuleInfo, StylistInfo } from '@typings/types'
import { BlankPlaceholder } from '@ui/index'
import { colors } from '@styles/colors'
import { useAppSelector } from '@store/index'
import { userSelector } from '@store/user'
import EmptyCapsuleBg from './images/empty-capsule-bg.png'
import {
	Container,
	Created,
	DataWrapper,
	GrayLine,
	IconWrapper,
	ImageCont,
	Price,
	Title,
	DivStyled,
	DivCapsuleCard,
	StylistInfoBlock,
	ArrowWrapper,
} from './capsules-item-styled'

type Props = {
	item?: CapsuleInfo
	stylist?: StylistInfo
	onClick: (id: number) => void
	isEmptyCapsule?: boolean
	noDetails?: boolean
	maxWidth?: string
}

export function CapsulesItem({
	item,
	stylist,
	onClick,
	isEmptyCapsule,
	noDetails, // remove Price and creation Date
	maxWidth,
}: Props) {
	const { userInfo } = useAppSelector(userSelector)

	const capsuleCoverPhotoUrl = item?.params?.coverPhoto
		? PROD_DOMAIN + item.params?.coverPhoto?.[0].urls?.M
		: ''

	const stylistName = useMemo(() => {
		return stylist ? `${stylist?.first_name} ${stylist?.last_name}` : undefined
	}, [stylist])
	const thisStylistCapsule = item?.user_id === stylist?.user_id

	if (!item) {
		return (
			<DivCapsuleCard onClick={() => {}} style={{ maxWidth, margin: '0 auto' }}>
				<Container className={isEmptyCapsule ? 'empty' : ''}>
					<ImageCont imageUrl={EmptyCapsuleBg} />

					<DataWrapper>
						<Title style={{ color: colors.blackTr48 }}>Ghost capsule</Title>
						<Row justify="space-between" align="bottom">
							<Price></Price>
							<Created></Created>
						</Row>
						<GrayLine />
						<Row justify="space-between" align="middle">
							<Col>
								<div>&nbsp;</div>
							</Col>
						</Row>
					</DataWrapper>
				</Container>
			</DivCapsuleCard>
		)
	}

	return (
		<Container
			onClick={() => onClick(item.id)}
			style={{ maxWidth, margin: '0 auto' }}
		>
			{!thisStylistCapsule && userInfo.role !== 'stylist' && stylistName && (
				<StylistInfoBlock>by {stylistName}</StylistInfoBlock>
			)}

			<ImageCont imageUrl={capsuleCoverPhotoUrl}>
				{!capsuleCoverPhotoUrl && <BlankPlaceholder color={colors.blackTr24} />}
			</ImageCont>
			<DataWrapper>
				<Title>{item.title}</Title>
				{!noDetails && (
					<Row justify="space-between" align="middle" style={{ marginTop: 6 }}>
						{item.summary?.priceValute && item.summary?.price && (
							<Price>
								{item.summary?.priceValute} {item.summary?.price}
							</Price>
						)}
						<Created>{`Created: ${moment(
							item.created_at,
							'YYYY-MM-DD hh:mm:ss'
						).format('DD, MMM YYYY')}`}</Created>
					</Row>
				)}
				<GrayLine />
				<Row justify="start" align="middle" gutter={8}>
					<Col flex="0 1 52px">
						<IconWrapper>
							{item.items?.length
								? item.items.length
								: item.summary?.itemsCount}
						</IconWrapper>
					</Col>
					<Col flex="1">
						<DivStyled>Clothing items</DivStyled>
					</Col>
					<ArrowWrapper flex="0 1 32px">
						<ArrowNext />
					</ArrowWrapper>
				</Row>
			</DataWrapper>
		</Container>
	)
}
