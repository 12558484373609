import { SdNotification } from '@components/sd-notification'
import { Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ButtonBack2Quiz, RowBordered } from './client-profile-form-styled'
import { PersonalDataForm } from './personal-data-form/personal-data-form'
import { PreferencesDataForm } from './preferences-data-form/preferences-data-form'
import { UploadAvatar } from './upload-avatar/upload-avatar'

export function ClientProfileForm() {
	const navigate = useNavigate()

	return (
		<Row gutter={48} align="top">
			<Col span={6}>
				<UploadAvatar />
				<ButtonBack2Quiz
					skin="white-outline"
					onClick={() => navigate('/quiz')}
					isFitContent
					style={{ marginTop: 24 }}
				>
					Back to quiz
				</ButtonBack2Quiz>
			</Col>
			<Col span={18}>
				<RowBordered gutter={2}>
					<Col flex="1 1 600px">
						<SdNotification text="We autosave every change you make" />
					</Col>
				</RowBordered>
				<Row gutter={48} style={{ marginTop: 42 }}>
					<PersonalDataForm />
					<PreferencesDataForm />
				</Row>
			</Col>
		</Row>
	)
}
