import { settingsSelector } from '@store/settings'
import { useAppSelector } from '@store/store'
import { ColorCircle, DivFlex } from './colors-block-styled'

type Props = { colors?: string[] }

export function ColorsBlock({ colors }: Props) {
	const { settings } = useAppSelector(settingsSelector)

	const storeColors = settings.ItemColors
	return (
		<DivFlex>
			{colors?.map(
				(color) =>
					storeColors?.[color] && (
						<ColorCircle key={color} color={storeColors?.[color]}></ColorCircle>
					)
			)}
		</DivFlex>
	)
}
