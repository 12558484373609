import axios from 'axios'

import { CreateItemByLInkResponse, ItemInfo } from '@typings/types'
import { axiosInstance } from '../../../config'

export async function createItemByLInk({
	token,
	link,
}: {
	token: string
	link: string
}): Promise<ItemInfo | undefined> {
	try {
		const response = await axiosInstance.post<CreateItemByLInkResponse>(
			'/api/item/create-by-link',
			{
				link,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		if (response.data.success) {
			return response.data.item
		}

		return undefined
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
