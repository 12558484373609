import { fontTenorSans, regular26 } from '@styles/typography'
import { Modal } from 'antd'
import styled from 'styled-components'

export const ModalStyled = styled(Modal)`
	& .ant-modal-content {
		padding: 56px;
	}
`

export const ModalText = styled.div`
	${fontTenorSans}
	${regular26}
	text-align: center;
`
