import { Col, Row } from 'antd'

import { StyleboardElement } from '@typings/types'
import { PhotoItem } from './photo-item/photo-item'
import { RowNoLeftPadding } from '../styleboard-preview-page-styled'
import { EmptyBlock } from './photo-list-styled'

type Props = {
	photos: (StyleboardElement | null)[]
}

export function PhotosList({ photos }: Props) {
	return (
		<Row
			gutter={[48, 44]}
			justify={'start'}
			style={{ width: '100%', margin: 0 }}
		>
			{photos.map((photo, index) => {
				if (photo === null)
					return (
						<Col key={index} xxl={{ span: 6 }} xl={{ span: 8 }}>
							<EmptyBlock />
						</Col>
					)

				return (
					<Col key={index} xxl={{ span: 6 }} xl={{ span: 8 }}>
						<PhotoItem photo={photo} />
					</Col>
				)
			})}
		</Row>
	)
}
