import { colors } from '@styles/colors'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;

	position: absolute;
	right: 20px;
	top: 20px;
`

export const IconWrapper = styled.div`
	padding: 10px;
	background: ${colors.whiteTr56};
	border-radius: 8px;

	cursor: pointer;

	&.active,
	&:hover {
		background: ${colors.white};
	}
`

export const DisableButtons = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;

	background-color: transparent;
`
