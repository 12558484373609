import { Col } from 'antd'
import styled from 'styled-components'

export const ColStyled = styled(Col)`
	& .carousel-container {
		width: 100%;
		max-width: 80vw;

		& ul {
			width: 100%;
		}
	}

	& .basket-gallery-item {
		flex: 0 !important;
		margin: 0 5px;
	}
`
