import styled from 'styled-components'

export const FullScrIcon = styled.div`
	position: absolute;
	top: 26px;
	left: 26px;
	width: 48px;
	height: 48px;
	background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
	border-radius: 8px;
	padding: 12px;
`

export const Container = styled.div`
	width: 100%;
	max-width: 85vw;

	& .carousel-container {
	}

	& .carousel-item {
		padding-right: 40px;
	}
`
