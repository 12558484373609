import styled, { css } from 'styled-components'

export const Container = styled.div<{ color?: string; size?: number | string }>`
	display: inline-flex;
	vertical-align: bottom;
	justify-content: center;
	align-items: center;

	${({ size }) => {
		if (typeof size === 'number') {
			return css`
				width: ${size}px;
				height: ${size}px;
			`
		} else if (typeof size === 'string') {
			return css`
				width: ${size};
				height: ${size};
			`
		}
	}}

	${({ color }) =>
		color &&
		css`
			color: ${color};
		`}
`
