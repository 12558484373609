import { useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { UserCookieOptions } from '@typings/types'
import { Block } from '@ui/block'
import { Button } from '@ui/index'
import { RadioButton } from '@ui/radio-button'
import { useState } from 'react'
import { ModalStyled } from './cookie-modal-styled'

type Props = {
	handleOk: (options: UserCookieOptions) => void
	handleCancel: () => void
	isOpen: boolean
}

export function CookieModal({ handleOk, handleCancel, isOpen }: Props) {
	const { userCookieOptions } = useAppSelector(userSelector)
	const [cookieOptions, setCookieOptions] =
		useState<UserCookieOptions>(userCookieOptions)

	const modalFooter = (
		<Block isFlexBlock isContentCentered isFullWidth gap="8px">
			<Button
				isFitContent
				skin="grey-link"
				onClick={handleCancel}
				style={{ minWidth: 200 }}
			>
				Accept only necessary
			</Button>
			<Button
				isFitContent
				skin="dark"
				onClick={() => handleOk(cookieOptions)}
				style={{ minWidth: 200 }}
			>
				Save Preferences
			</Button>
		</Block>
	)

	return (
		<>
			<ModalStyled
				open={isOpen}
				title="Cookie preferences"
				width={'100%'}
				centered
				footer={modalFooter}
				onCancel={handleCancel}
			>
				<div className="body-title-text">
					We use technology such as “cookies” to collect information and store
					your online preferences. By managing your Cookie Preferences you can
					enable or disable a specific set of cookies, based on a predefined
					categorization.
				</div>
				<div className="body-h2-text">How do we categorize cookies?</div>
				<div className="body-cookie-category">
					<Block
						isFlexBlock
						isFullWidth
						justifyContent="space-between"
						gap="8px"
						className="body-cookie-category--label"
					>
						<div>Strictly necessary cookies</div>
						<RadioButton
							onClick={() =>
								setCookieOptions((state) => ({
									...state,
									general: !cookieOptions.general,
								}))
							}
							isActive={cookieOptions.general}
						/>
					</Block>
					<div className="body-cookie-category--description">
						These cookies are required to enable core website functionality and
						are automatically enabled when you use the site. These include
						cookies that help to make the bag and checkout process possible, as
						well as assisting in security issues and conforming to regulations.
					</div>
				</div>
				<div className="body-cookie-category">
					<Block
						isFlexBlock
						isFullWidth
						justifyContent="space-between"
						gap="8px"
						className="body-cookie-category--label"
					>
						<div>Performance cookies</div>
						<RadioButton
							onClick={() =>
								setCookieOptions((state) => ({
									...state,
									performance: !cookieOptions.performance,
								}))
							}
							isActive={cookieOptions.performance}
						/>
					</Block>
					<div className="body-cookie-category--description">
						These cookies collect information on how you use the website, for
						instance which pages you visit most often or if you got any error
						message. The information collected by these cookies is aggregated
						and therefore anonymous. We use them for advertising measurement and
						to improve how the website works.
					</div>
				</div>
				<div className="body-cookie-category">
					<Block
						isFlexBlock
						isFullWidth
						justifyContent="space-between"
						gap="8px"
						className="body-cookie-category--label"
					>
						<div className="body-cookie-category--label">
							Functional cookies
						</div>
						<RadioButton
							onClick={() =>
								setCookieOptions((state) => ({
									...state,
									functional: !cookieOptions.functional,
								}))
							}
							isActive={cookieOptions.functional}
						/>
					</Block>
					<div className="body-cookie-category--description">
						These cookies allow the website to remember choices you make and
						provide enhanced, more personal features. The information these
						cookies collect is anonymized and does not track your browsing
						activity on other websites.
					</div>
				</div>
				<div className="body-title-text">
					You can change your preferences any time by visiting cookie
					preferences at the bottom of any page. Find out more
				</div>
			</ModalStyled>
		</>
	)
}
