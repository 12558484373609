import moment from 'moment'

import ArrowNext from '@components/arrow-next/arrow-next'
import Avatar from '@components/avatar/avatar'
import { PROD_DOMAIN } from '@typings/constants'
import { StyleboardInfo } from '@typings/types'
import { BlankPlaceholder } from '@ui/index'
import { colors } from '@styles/colors'
import {
	Wrapper,
	ImageCont,
	InfoCont,
	TextCont,
	TitleCont,
	DateCont,
	Container,
	ArrowCont,
} from './styleboard-item-styled'

type Props = {
	styleBoard: StyleboardInfo
	onClick: (id: number) => void
	maxWidth?: string
}

export function StyleBoardsItem({ styleBoard, onClick, maxWidth }: Props) {
	const coverPhotoUrl = styleBoard.coverPhoto?.urls?.M
		? PROD_DOMAIN + styleBoard.coverPhoto?.urls?.M
		: ''
	const sharedClientImageUrl = styleBoard.linkedClient?.params?.photo
		? styleBoard.linkedClient?.params.photo[0].urls.M
		: ''

	return (
		<Container
			onClick={() => onClick(styleBoard.id)}
			style={{ maxWidth, margin: '0 auto' }}
		>
			<Wrapper>
				<ImageCont
					imageUrl={coverPhotoUrl}
					style={{ backgroundColor: !coverPhotoUrl ? 'none' : colors.blackTr5 }}
				>
					{!coverPhotoUrl && <BlankPlaceholder color={colors.blackTr24} />}
				</ImageCont>
			</Wrapper>
			<InfoCont>
				<div>
					<Avatar imageUrl={sharedClientImageUrl} size={56} />
				</div>
				<TextCont>
					<TitleCont>{styleBoard.title}</TitleCont>
					<DateCont>{`Created on: ${moment(
						styleBoard.created_at,
						'YYYY-MM-DD hh:mm:ss'
					).format('DD MMM YYYY')}`}</DateCont>
				</TextCont>
				<ArrowCont>
					<ArrowNext />
				</ArrowCont>
			</InfoCont>
		</Container>
	)
}
