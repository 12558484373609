import { Col } from 'antd'
import styled from 'styled-components'

import {
	bold14,
	fontRoboto,
	fontTenorSans,
	regular13,
	regular26,
} from '@styles/typography'
import { colors } from '@styles/colors'

export const CapsuleCollageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 32px 40px 40px;
	margin: 0 auto;
	width: 100%;
	max-width: 393px;
	height: 612px;

	background: ${colors.white};
	border-radius: 20px;
	box-shadow: 0px 6px 32px ${colors.blackTr8};

	&:hover {
		/*box-shadow: 0px 6px 32px ${colors.blackTr8};*/
	}
`
export const CapsuleTitle = styled.div`
	${fontTenorSans}
	${regular26}
	margin-bottom: 24px;
`

export const CapsuleCollageCont = styled.div`
	width: 100%;
	height: 100%;
	margin: 0 auto;
	background: ${colors.white};
	border-radius: 20px;

	.ant-image {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
		height: 100%;
		overflow: hidden;
	}
`

export const ColFlex = styled(Col)`
	display: flex;
	flex-direction: row;
`

export const CreatedDate = styled.span`
	${fontRoboto}
	${bold14}
  color: ${colors.blackTr64};
`

export const ShowcaseBtnText = styled.span`
	${fontRoboto}
	${regular13}
  display: flex;
	height: 100%;
	align-items: center;
	letter-spacing: 0.01em;
`

export const ShowcaseBtn = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	margin: 0 5px;
`

export const ColParamLabel = styled(Col)`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr48};
	align-self: center;
`

export const FullScrIcon = styled.div`
	position: absolute;
	top: 26px;
	left: 26px;
	width: 48px;
	height: 48px;
	background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
	border-radius: 8px;
	padding: 12px;
`

export const StatusBlock = styled.div`
	${fontRoboto}
	${bold14}
	color: blue;
	border-radius: 8px;
	border: 1px solid blue;
	padding: 2px 8px;
	margin: auto;
	margin-right: 12px;
`
