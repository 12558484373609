import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getUserInfo } from '@api/get-user-info'
import { useLoadSettings } from '@hooks/api-to-store/use-load-settings'
import { useInitChat } from '@hooks/use-init-chat'
import { authSelector, setUserToken } from '@store/auth'
import { setChatUser } from '@store/chat'
import { useAppDispatch } from '@store/index'
import { useAppSelector } from '@store/store'
import { setUserInfo } from '@store/user'
import { IS_DEV } from '@typings/constants'
import { getToken } from '@utils/get-token'
import { getUserStatus } from './api'

export function useLoginPage() {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { token } = useAppSelector(authSelector)
	const { initChat } = useInitChat()
	const [isLoading, setLoading] = useState(true)

	useLoadSettings()

	const userStatusHandler = useCallback(async () => {
		setLoading(true)

		const userStatus = await getUserStatus()

		if (!IS_DEV && !userStatus?.['access-token']) {
			setLoading(false)
			return
		}

		const localToken = getToken().token
		const localId = getToken().id

		const token = IS_DEV ? localToken : userStatus?.['access-token']
		const userId = IS_DEV ? localId : userStatus?.id

		if (!token || !userId) {
			setLoading(false)
			return
		}

		dispatch(setUserToken(token))
		const userInfo = await getUserInfo({ id: userId, token })

		userInfo && dispatch(setUserInfo(userInfo))

		if (userInfo && userInfo.status === 'active') {
			const chatUser = await initChat(userInfo, token)
			chatUser && dispatch(setChatUser(chatUser))
		}

		setLoading(false)

		//TODO: Возможно надо переработать
		if (userInfo?.role === 'stylist' && userInfo.status === 'draft') {
			navigate('/stylist-profile')
			return
		}
	}, [dispatch, initChat, navigate])

	useEffect(() => {
		if (token) return
		userStatusHandler()
	}, [])

	return {
		token,
		isLoading,
	}
}
