import { colors } from '@styles/colors'
import { fontRoboto } from '@styles/index'
import { Row } from 'antd'
import styled from 'styled-components'

export const SelectWrapperRow = styled(Row)`
	align-items: center;
	margin-top: 36px;

	.styledyle-input-paddings {
		${fontRoboto}
		font-style: normal;
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 2.2rem !important;
		color: ${colors.blackTr64};
		width: 48%;
		padding: 18px 16px;
		height: 60px;
	}

	.ant-picker .ant-picker-input > input {
		font-size: unset;
		line-height: unset;
		color: unset;
		color: ${colors.black};
	}
	.ant-picker .ant-picker-input > input::placeholder {
		color: unset !important;
	}
`
