import { useContext, useEffect, useMemo, useState } from 'react'

import { CometChat } from '@cometchat/chat-sdk-javascript'
import {
	CometChatMessageComposerAction,
	CometChatMessages,
	CometChatPalette,
	CometChatTheme,
	CometChatThemeContext,
	CometChatUIKit,
	CometChatUIKitConstants,
	CometChatUsersWithMessages,
	ComposerId,
	MessageBubbleAlignment,
	MessageListConfiguration,
	UsersConfiguration,
} from '@cometchat/chat-uikit-react'

import { useUpdateUserInfo } from '@hooks/api/use-update-user-info'
import { authSelector } from '@store/auth'
import { chatSelector, setIsChatLoading, setIsChatOpen } from '@store/chat'
import { useAppDispatch, useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { colors } from '@styles/index'
import { Spinner } from '@ui/spinner'
import { Swap } from '@ui/swap'
import { handleToast } from '@utils/handle-toast'

import {
	mListConfiguration,
	messageComposerConfiguration,
	messagesConfig,
	usersStyle,
	withMessagesStyle,
} from './chat-settings'
import { DrawerStyled } from './chat-styled'

const CHAT_LIMIT = 30

export const Chat = () => {
	const dispatch = useAppDispatch()
	const { isChatOpen, isChatLoading, chatId } = useAppSelector(chatSelector)
	const { userInfo } = useAppSelector(userSelector)
	const { token } = useAppSelector(authSelector)
	const [isChatReady, setIsChatReady] = useState(false)
	const [chatUser, setChatUser] = useState<CometChat.User>()
	const [usersConfiguration, setUsersConfiguration] =
		useState<UsersConfiguration>()
	const { updateUserInfo } = useUpdateUserInfo()

	const { theme } = useContext(CometChatThemeContext)

	const themeContext = useMemo(() => {
		let res = theme
		res = new CometChatTheme({
			palette: new CometChatPalette({
				mode: 'light',
				primary: {
					light: '#FFEEF2',
					dark: '#FFEEF2',
				},
				accent: {
					light: '#000000',
					dark: '#000000',
				},
				secondary: {
					light: '#F2F7FB',
					dark: '#F2F7FB',
				},
			}),
		})
		return res
	}, [theme])

	const getAudioBubble = (
		item: CometChat.MediaMessage,
		_alignment: MessageBubbleAlignment
	) => {
		if (
			!!userInfo.role &&
			item.getSender().getUid() === userInfo[userInfo.role].cometchat_uid
		) {
			const audioTemplateView =
				CometChatUIKit.getDataSource().getAudioMessageContentView(
					item,
					_alignment,
					new CometChatTheme({})
				)
			return (
				<div
					style={{
						background: '#FFEEF2',
						padding: '8px 8px 20px 8px',
						borderRadius: '8px',
					}}
				>
					{audioTemplateView}
				</div>
			)
		}
	}

	const getTemplates = () => {
		try {
			let definedTemplates =
				CometChatUIKit.getDataSource().getAllMessageTemplates(
					new CometChatTheme({})
				)
			definedTemplates.map((template) => {
				if (template.category === 'message' && template.type === 'text') {
					template.contentView = (item: CometChat.TextMessage) => {
						return CometChatUIKit.getDataSource().getTextMessageContentView(
							item,
							MessageBubbleAlignment.left,
							new CometChatTheme({
								palette: new CometChatPalette({
									mode: 'light',
									accent: {
										light: 'black',
									},
								}),
							}),
							{ disableMentions: true }
						)
					}
				}
				if (
					template.category === 'message' &&
					template.type === CometChatUIKitConstants.MessageTypes.audio
				) {
					template.bubbleView = (
						item: CometChat.MediaMessage,
						_alignment: MessageBubbleAlignment
					) => getAudioBubble(item, _alignment)
				}
			})
			return definedTemplates
		} catch (error) {
			console.error('getTemplates error', error)
		}
	}

	messageComposerConfiguration.attachmentOptions = (
		item: CometChat.Group | CometChat.User,
		composerID: ComposerId
	) => {
		try {
			const currentOption: CometChatMessageComposerAction[] | undefined =
				CometChatUIKit.getDataSource().getAttachmentOptions(
					themeContext,
					composerID
				)

			currentOption.forEach((element) => {
				const titleStr = element?.title?.split(' ')[1]

				element.iconTint =
					'linear-gradient(rgb(255, 12, 99) 0%, rgb(255, 86, 86) 100%)'
				if (titleStr) {
					element.title = titleStr[0].toUpperCase() + titleStr.substring(1)
				}
			})
			return currentOption
		} catch (error) {
			console.error('attachmentOptions error', error)
			return []
		}
	}

	const getSubtitleView = (user: any) => {
		return (
			<span style={{ color: colors.blackTr64 as string, font: '400 14px' }}>
				{user.role.split('_')[1]}
			</span>
		)
	}

	const onClose = () => {
		setTimeout(() => {
			updateUserInfo(
				userInfo.role === 'stylist'
					? userInfo.stylist.user_id
					: userInfo.client.user_id,
				token
			)
		}, 2000)
		dispatch(setIsChatLoading(false))
		dispatch(setIsChatOpen(false))
		setIsChatReady(false)
	}

	const loadChat = async (chatId?: string | null) => {
		if (!chatId) {
			handleToast({
				content: 'No opponent chatId is present!',
				type: 'warn',
			})
			return
		}

		try {
			dispatch(setIsChatLoading(true))
			const updatedTemplates = getTemplates()
			if (updatedTemplates) {
				//Stylist part Messages templates update
				messagesConfig.messageListConfiguration = new MessageListConfiguration({
					templates: updatedTemplates,
				})

				//Client part Messages templates update
				mListConfiguration.templates = updatedTemplates
			}

			const secondUser = await CometChat.getUser(chatId)

			if (!secondUser) {
				handleToast({
					content: `User with ${chatId} not found`,
				})
				setChatUser(undefined)
				dispatch(setIsChatLoading(false))
				onClose()
				return
			}

			if (userInfo.role === 'stylist') {
				const chatUsers = userInfo.chatList?.length
					? userInfo.chatList?.map((chat) => chat.cometchatUid).filter(Boolean)
					: []

				setUsersConfiguration(
					new UsersConfiguration({
						usersRequestBuilder: new CometChat.UsersRequestBuilder()
							.setLimit(CHAT_LIMIT)
							.hideBlockedUsers(true)
							.setUIDs(chatUsers),
						hideSeparator: true,
						hideSearch: true,
						showSectionHeader: false,
						usersStyle: usersStyle,
						subtitleView: getSubtitleView,
					})
				)
			}

			secondUser && setChatUser(secondUser)
			dispatch(setIsChatLoading(false))
			setIsChatReady(true)
		} catch (error: any) {
			setChatUser(undefined)
			console.error('CometChat.getUser error', error?.message)
			handleToast({
				content: 'Failed to load chat',
			})
			onClose()
		}
	}

	useEffect(() => {
		!!isChatOpen && loadChat(chatId)
	}, [chatId, isChatOpen])

	return (
		<DrawerStyled
			title={chatUser?.getRole() === 'role_support' ? 'Notifications' : 'Chat'}
			onClose={onClose}
			open={isChatReady}
			size="large"
		>
			<Swap is={isChatLoading} isSlot={<Spinner />}>
				<CometChatThemeContext.Provider value={{ theme: themeContext }}>
					<Swap
						is={
							(userInfo.role === 'client' && !!chatUser) ||
							(!!chatUser && chatUser.getRole() === 'role_support')
						}
						isSlot={
							<CometChatMessages
								user={chatUser}
								hideDetails={true}
								hideMessageComposer={chatUser?.getRole() === 'role_support'}
								hideMessageHeader={chatUser?.getRole() === 'role_support'}
								messageComposerConfiguration={messageComposerConfiguration}
								messageListConfiguration={mListConfiguration}
							/>
						}
					>
						<CometChatUsersWithMessages
							usersConfiguration={usersConfiguration}
							usersWithMessagesStyle={withMessagesStyle}
							messagesConfiguration={messagesConfig}
						/>
					</Swap>
				</CometChatThemeContext.Provider>
			</Swap>
		</DrawerStyled>
	)
}
