import { ReactNode, useMemo } from 'react'
import { Icon } from '@ui/icon'
import { CheckButtonStyled } from './check-button-styled'
import { PreferenceTagState } from '@typings/types'

export type Props = {
	title: string
	state?: PreferenceTagState
	onSelect: (value: string, status: PreferenceTagState) => void
}

export function MultipleCheckButton({ title, onSelect, state }: Props) {
	const handleSelect = (state?: PreferenceTagState) => {
		switch (state) {
			case 'ignored':
				onSelect(title, 'selected')
				return
			case 'selected':
				onSelect(title, 'checked')
				return
			case 'checked':
			default:
				onSelect(title, 'ignored')
				return
		}
	}

	const iconNode: ReactNode = useMemo(() => {
		switch (state) {
			case 'checked':
				return <Icon color={'#FF0C63'} name="heartFilledIcon" />

			case 'selected':
				return <Icon color={'#FF0C63'} name="heartIcon" />

			default:
				return null
		}
	}, [state])

	return (
		<>
			<CheckButtonStyled
				key={title}
				isActive={state === 'checked' || state === 'selected'}
				skin="grey"
				isFitContent
				leftIcon={iconNode}
				onClick={() => handleSelect(state)}
				size="l"
			>
				{title}
			</CheckButtonStyled>
		</>
	)
}
