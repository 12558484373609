import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { bookStylist } from '@api/client/deal/book-stylist'
import { cancelBooking } from '@api/client/deal/cancel-booking'
import { getStylistDealInfo } from '@api/client/deal/get-stylist-deal-info'
import { getBookedStylists } from '@api/client/get-booked-stylists'
import Avatar from '@components/avatar/avatar'
import { useInitChat } from '@hooks/use-init-chat'
import { authSelector } from '@store/auth'
import {
	chatSelector,
	setChatId,
	setChatUser,
	setIsChatOpen,
} from '@store/chat'
import { useAppDispatch, useAppSelector } from '@store/index'
import { userSelector } from '@store/user'
import {
	DealAction,
	DealInfo,
	NavigationButtonInfo,
	StylistInfo,
} from '@typings/types'
import { handleToast } from '@utils/handle-toast'

export function useStylingStudio() {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { token } = useAppSelector(authSelector)
	const { userInfo } = useAppSelector(userSelector)
	const { chatUser } = useAppSelector(chatSelector)
	const { initChat } = useInitChat()

	const { state } = useLocation()

	const [stylists, setStylists] = useState<StylistInfo[]>([])
	const [showListType, setShowListType] = useState('')
	const [activeStylist, setActiveStylist] = useState<StylistInfo>()
	const [isLoading, setIsLoading] = useState(false)
	const [isDealDetailsLoading, setIsDealDetailsLoading] = useState(false)
	const [dealInfo, setDealInfo] = useState<DealInfo>()
	const [isModalOpen, setIsModalOpen] = useState(false)

	const loadStylists = useCallback(async () => {
		setIsLoading(true)

		const stylists = await getBookedStylists({ token })

		if (stylists) {
			const activeStateStylist =
				state && state.stylistId
					? stylists.find((item) => item.id === state.stylistId)
					: undefined

			setStylists(stylists)
			setShowListType(
				activeStateStylist
					? activeStateStylist.user_id.toString()
					: stylists[0]?.user_id.toString()
			)
			setActiveStylist(activeStateStylist ? activeStateStylist : stylists[0])
		}
		setIsLoading(false)
	}, [state, token])

	const loadDealDetails = useCallback(async () => {
		if (!activeStylist?.id) {
			return
		}

		setIsDealDetailsLoading(true)
		const dealData = await getStylistDealInfo({
			token,
			stylistId: activeStylist?.id,
		})

		setDealInfo(dealData)
		setIsDealDetailsLoading(false)
	}, [activeStylist?.id, token])

	const handleActionApply = useCallback(
		async (action: DealAction) => {
			setIsLoading(true)
			// TODO: Тут будут добавляться обработчики других статусов кнопки
			switch (action) {
				case 'book':
					if (!activeStylist?.id) {
						return
					}

					const isStylistBooked = await bookStylist({
						token,
						stylistId: activeStylist?.id,
					})

					if (!isStylistBooked) {
						navigate(`/rate-plans`, {
							state: {
								stylistId: activeStylist?.id,
							},
						})
						return
					}

					setIsLoading(false)
					navigate(`/success-book`)
					return

				case 'styling_studio':
					navigate(`/styling-studio`)
					return

				case 'styleboard_review':
					navigate(`/styleboards/${dealInfo?.details?.styleboards?.[0]?.id}`, {
						state: {
							styleboardId: dealInfo?.details?.styleboards?.[0].id,
							stylistId: activeStylist?.id,
						},
					})
					return

				case 'book_cancel':
					setIsModalOpen(true)
					setIsLoading(false)
					return

				case 'book_another_stylist':
					setIsLoading(false)
					navigate('/stylists')
					return

				case 'capsule_review':
					navigate(`/capsules/${dealInfo?.details?.capsules?.[0].id}`, {
						state: {
							capsuleId: dealInfo?.details?.capsules?.[0].id,
							stylistId: activeStylist?.id,
						},
					})
					return

				case 'chat_open':
					setIsLoading(false)
					if (!chatUser) {
						const result = await initChat(userInfo, token)
						result && dispatch(setChatUser(result))
					}
					dispatch(setIsChatOpen(true))
					dispatch(setChatId(activeStylist?.cometchat_uid))
					return

				default:
					setIsLoading(false)
					handleToast({ content: 'This actions are not ready yet' })
					return
			}
		},
		[
			activeStylist?.id,
			activeStylist?.cometchat_uid,
			token,
			navigate,
			dealInfo?.details?.styleboards,
			dealInfo?.details?.capsules,
			chatUser,
			dispatch,
			initChat,
			userInfo,
		]
	)

	const handleCancelBooking = useCallback(async () => {
		setIsLoading(true)

		if (!activeStylist?.id) {
			setIsLoading(false)
			navigate('/stylists')
			return
		}

		const stylistCanceledResult = await cancelBooking({
			token,
			stylistId: activeStylist?.id,
		})

		if (!stylistCanceledResult?.success) {
			handleToast({
				content:
					stylistCanceledResult?.errors?.[0] ?? 'Error while canceling booking',
			})
			setIsLoading(false)
			return
		}

		loadStylists()
	}, [activeStylist?.id, loadStylists, navigate, token])

	const handleCancel = () => {
		setIsModalOpen(false)
	}

	const handleOk = () => {
		setIsModalOpen(false)
		handleCancelBooking()
	}

	useEffect(() => {
		if (isLoading) return
		loadStylists()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		loadDealDetails()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStylist])

	const handleClickStylist = useCallback(
		(id: number) => {
			if (showListType === id.toString()) return

			setIsLoading(true)

			setShowListType(id.toString())
			setActiveStylist(stylists.find((item) => item.user_id === id))

			setIsLoading(false)
		},
		[showListType, stylists]
	)

	const buttons: NavigationButtonInfo[] = useMemo(() => {
		const stylistsButtons: NavigationButtonInfo[] = stylists.map((stylist) => {
			return {
				title: `${stylist.first_name} ${stylist.last_name}`,
				iconName: 'hangerIcon',
				showListType: stylist.user_id.toString(),
				node: (
					<Avatar
						imageUrl={stylist?.params?.photo?.[0].urls?.M || ''}
						workStatus={stylist?.workStatus}
						size={40}
					/>
				),
				counter:
					userInfo.chatList?.find(
						(chat) => chat.cometchatUid === stylist.cometchat_uid
					)?.unreadMessagesCount ?? 0,
				handleClick: () => handleClickStylist(stylist.user_id),
			}
		})

		return stylistsButtons
	}, [handleClickStylist, stylists, userInfo.chatList])

	return {
		activeStylist,
		buttons,
		isLoading,
		isDealDetailsLoading,
		isModalOpen,
		handleOk,
		handleCancel,
		showListType,
		userRole: userInfo?.role,
		dealInfo,
		handleActionApply,
		navigate,
	}
}
