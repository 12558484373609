import { CookieNotification } from '@components/cookie-notification/cookie-notification'
import { Block } from '../../../../UI/block'
import { SiteLogo } from '../../../../UI/site-logo/site-logo'
import { LayoutProps } from '../../types'
import { ContentBlockWrapper, PageWrapper } from './login-desktop-styled'

export function LoginLayoutDesktop({ children }: LayoutProps) {
	return (
		<PageWrapper>
			<Block margin="74px 0 75px 0">
				<SiteLogo color="#000000" size={73} />
			</Block>
			<ContentBlockWrapper>{children}</ContentBlockWrapper>
			<CookieNotification />
		</PageWrapper>
	)
}
