import { Icon } from '@ui/icon'
import {
	Container,
	FilterHeader,
	FilterBody,
	FilterFooter,
	FilterClose,
	ItemContainer,
	Background,
} from './filters-styled'
import { Button } from '@ui/button'
import { Block } from '@ui/block'
import { Input } from '@ui/input'

type Props = {
	isOpen: boolean
	onSearch: () => void
	onClose: () => void
}

export function Filters({ isOpen, onSearch, onClose }: Props) {
	if (!isOpen) return null

	return (
		<>
			<Container>
				<Block>
					<FilterHeader>
						<h2>Search settings</h2>
						<FilterClose onClick={onClose}>
							<Icon name="closeIcon" />
						</FilterClose>
					</FilterHeader>
					<FilterBody>
						<ItemContainer>
							<h3>Main settings</h3>
							<Input
								inputFieldSlot={<input type="text" />}
								placeholder="Types of clothing items"
							/>
						</ItemContainer>
						<ItemContainer>
							<h3>Extra</h3>
							<Input
								type="checkbox"
								inputFieldSlot={<input type="checkbox" />}
								placeholder="Specialize in men's styling"
							/>
							<Input
								type="checkbox"
								inputFieldSlot={<input type="checkbox" />}
								placeholder="Specialize in maternity styling"
							/>
							<Input
								type="checkbox"
								inputFieldSlot={<input type="checkbox" />}
								placeholder="Specialize in unique body types"
							/>
						</ItemContainer>
					</FilterBody>
				</Block>
				<FilterFooter>
					<Button skin="dark" onClick={onSearch}>
						Find a stylist
					</Button>
				</FilterFooter>
			</Container>
			<Background onClick={onClose} />
		</>
	)
}
