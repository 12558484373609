import { createSlice } from '@reduxjs/toolkit'
import { ReduxChat } from '@typings/types'

const initialState: ReduxChat = {
	isChatLoading: false,
	isChatOpen: false,
	chatId: null,
	chatUser: null,
}

const slice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		setIsChatLoading: (state, { payload }) => {
			state.isChatLoading = payload
		},
		setIsChatOpen: (state, { payload }) => {
			state.isChatOpen = payload
		},
		setChatId: (state, { payload }) => {
			state.chatId = payload
		},
		setChatUser: (state, { payload }) => {
			state.chatUser = payload
		},
	},
})

export const chatReducer = slice.reducer
export const { setIsChatLoading, setIsChatOpen, setChatId, setChatUser } =
	slice.actions
