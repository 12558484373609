import { Block, Button, Icon } from '../../../../UI'
import { FormWrapperUserCreate, TitleWrapper } from '../form-styled'
import { useUserCreateForm } from './use-user-create-form'
import { Col, Row } from 'antd'
import { NewUserButton } from './new-user-button'

export function UserCreateForm() {
	const { userType, onSubmitUser, userTypeSelectHandler } = useUserCreateForm()

	return (
		<FormWrapperUserCreate onSubmit={onSubmitUser}>
			<Block isFlexBlock isContentCentered>
				<TitleWrapper>Hi, let's start!</TitleWrapper>
			</Block>
			<Block margin="56px 0 0px" />
			<Row gutter={56}>
				<Col span={12}>
					<NewUserButton
						onClick={() => userTypeSelectHandler('client')}
						title="I’m a client"
						icon={<Icon name="cabinetIcon" size={156} />}
						minHeight="360px"
						isActive={userType === 'client'}
					/>
				</Col>
				<Col span={12}>
					<NewUserButton
						onClick={() => userTypeSelectHandler('stylist')}
						title="I’m a stylist"
						icon={<Icon name="styleBoardsIcon" size={156} />}
						minHeight="360px"
						isActive={userType === 'stylist'}
					/>
				</Col>
			</Row>
			<Button isSubmit skin="dark" margin="56px 0 0 0">
				Sign up
			</Button>
		</FormWrapperUserCreate>
	)
}
