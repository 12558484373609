import { Col, Row } from 'antd'
import Carousel from 'react-multi-carousel'

import 'react-multi-carousel/lib/styles.css'
import { ColStyled } from './gallery-block-styled'
import { useAppSelector } from '@store/store'
import { clothesSelector } from '@store/clothes'
import { ClothesItem } from '../clothes-item/clothes-item'
import { ItemInfo } from '@typings/types'
import { SdNotification } from '@components/sd-notification'

type Props = {
	carouselRef: any
	imageList: ItemInfo[]
	priceValute?: string
}

export function GalleryBlock({ carouselRef, imageList, priceValute }: Props) {
	const { item } = useAppSelector(clothesSelector)
	const description = item?.params?.description

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 1601 },
			items: 4,
			partialVisibilityGutter: 40,
		},
		desktop: {
			breakpoint: { max: 1600, min: 1201 },
			items: 3,
			partialVisibilityGutter: 40,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	}

	return (
		<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 48 }}>
			<ColStyled span={24} style={{ marginTop: 10 }}>
				<Carousel
					ref={(el) => (carouselRef.current = el)}
					responsive={responsive}
					arrows={false}
					containerClass="carousel-container"
					renderButtonGroupOutside={true}
					centerMode={false}
				>
					{imageList.map((clothItem, index) => (
						<ClothesItem
							key={index}
							clothItem={clothItem}
							onClick={() => {}}
							priceValute={priceValute}
						/>
					))}
				</Carousel>
			</ColStyled>
			<Col span={24} style={{ marginTop: 50 }}>
				{description && <SdNotification text={description} />}
			</Col>
		</Row>
	)
}
