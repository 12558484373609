import { regular26 } from './../../styles/typography'
import { colors } from './../../styles/colors'
import styled from 'styled-components'
import {
	fontRoboto,
	bold16,
	regular13,
	regular18,
	fontTenorSans,
} from '@styles/typography'
import { Button } from '@ui/button'
import { antWidth, screenSize } from '@styles/screen-sizes'

export const StylistInfoBlock = styled.div`
	position: relative;
	padding: 20px 24px 24px;
	background: ${colors.white};
	border-radius: 12px;

	@media (${screenSize.antXs}) {
		margin-top: -15px;
		box-shadow: 'none';
		text-align: center;
	}

	@media (${screenSize.antMd}) {
		margin-top: -15px;
		box-shadow: 'none';
		text-align: center;
	}

	@media (${screenSize.antLg}) {
		margin-top: -20%;
		box-shadow: 0px 6px 32px ${colors.blackTr8};
		text-align: left;
	}
`
export const StylistName = styled.div`
	${fontTenorSans}
	${regular18}
`
export const StylistDescription = styled.div`
	${fontRoboto}
	${regular13}
  	color: ${colors.blackTr64};
	margin: 8px auto;

	@media (${screenSize.antXs}) {
		width: ${antWidth.col20};
	}

	@media (${screenSize.antMd}) {
		width: ${antWidth.col16};	
	}

	@media (${screenSize.antLg}) {
		width: ${antWidth.col24};
	}
`
export const SdNoteContainer = styled.div`
	margin: 0 auto;

	@media (${screenSize.antXs}) {
		width: ${antWidth.col20};
	}

	@media (${screenSize.antMd}) {
		width: ${antWidth.col16};	
	}

	@media (${screenSize.antLg}) {
		width: ${antWidth.col24};
	}
`

export const AvatarCont  = styled.div`
	margin: 0 auto;

	@media (${screenSize.antXs}) {
		width: ${antWidth.col10};
	}

	@media (${screenSize.antMd}) {
		width: ${antWidth.col8};	
	}

	@media (${screenSize.antLg}) {
		width: ${antWidth.col24};
	}
`

export const IconWrapper = styled.div`
	${fontRoboto}
	${bold16}
  	color: ${colors.black};
	background: ${colors.blackTr5};
	padding: 14px;
	border-radius: 64px;
	margin-left: 8px;
	line-height: 2rem;
	min-width: 48px;
	text-align: center;
`

export const DivStyled = styled.div`
	${fontRoboto}
	${regular13}
  	color: ${colors.blackTr48};
	align-self: center;
`

export const ButtonStyled = styled(Button)`
	@media (${screenSize.antXs}) {
		width: unset;
		margin: 0 auto;
	}

	@media (${screenSize.antMd}) {
		width: unset;
		margin: 0 auto;
	}

	@media (${screenSize.antLg}) {
		width: 100%;
	}
`

export const BlockTitle = styled.div`
	${fontTenorSans}
	${regular26}
  margin-top: 32px;
`
