import styled from 'styled-components'

import { colors } from '@styles/colors'
import {
	bold14,
	bold16,
	fontRoboto,
	fontTenorSans,
	regular13,
	regular16,
	regular18,
} from '@styles/typography'
import { Button } from '@ui/button'

export const UserinfoContainer = styled.div`
	display: block;
	width: 100%;
	margin: 0 auto;
	padding: 0 16px;
`
export const UserInfoWrapper = styled.div`
	position: relative;
	margin-top: -25%;
	padding: 20px 24px 24px;
	background: ${colors.white};
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 12px;
`

export const UserName = styled.div`
	${fontTenorSans}
	${regular18}
`
export const BlockTitle = styled.div`
	${fontRoboto}
	${bold16}
  margin-bottom: 8px;
`

export const ButtonMessenger = styled(Button)`
	& > div {
		margin-right: 0;
	}
`
export const ButtonStyled = styled(Button)`
	${fontRoboto}
	${bold14}

	min-width: 100px;
`

export const DeadlineBlock = styled.div`
	display: flex;
	justify-content: end;
	width: 100%;
	margin-top: 6px;
	${fontRoboto}
	${regular13}
	color: ${colors.blackTr64};
`

export const StatusTitle = styled.div`
	color: ${colors.black};
	${regular18}
	${fontTenorSans}
  margin-bottom: 10px;
`

export const TimeWrapper = styled.div`
	${fontRoboto}
	${regular16}

	span {
		color: ${colors.blackTr64};
		${regular13}
	}
`
