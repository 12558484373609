import { LoadingOutlined } from '@ant-design/icons'
import { Block } from '@ui/block'
import { Icon } from '@ui/icon'
import { Container, ButtonWrapper, UploadText } from './add-photo-button-styled'

type Props = {
	isNewPhotoLoading: boolean
	onClick?: (e: any) => void
}

export function AddPhotoButton({ isNewPhotoLoading, onClick }: Props) {
	return (
		<Container onClick={onClick}>
			{isNewPhotoLoading ? (
				<LoadingOutlined />
			) : (
				<>
					<ButtonWrapper htmlFor="upload-file-cv">
						<Block>
							<Icon name="filePlusIcon" size={30} />
						</Block>
						<UploadText margin="10px 0 0 0">Add file</UploadText>
					</ButtonWrapper>
				</>
			)}
		</Container>
	)
}
