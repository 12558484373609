import {
	CapsuleInfo,
	ClientInfo,
	DealInfo,
	ItemInfo,
	StyleboardShortInfo,
} from '@typings/types'
import React from 'react'

type Props<T> = {
	width?: number
	height?: number
	isLoading?: boolean
	numPerRow?: number
	itemsList: T[]
	renderItem: (item: T) => React.ReactNode
	loaderEl?: React.ReactNode
}

export function ItemsList<
	T extends
		| ItemInfo
		| StyleboardShortInfo
		| CapsuleInfo
		| ClientInfo
		| DealInfo
		| { id: Number; name: String }
>({
	width,
	height,
	isLoading,
	numPerRow,
	itemsList,
	renderItem,
	loaderEl,
}: Props<T>) {
	if (isLoading) return <>{loaderEl}</>

	if (!itemsList.length) return null

	return (
		<>
			{itemsList.map((item, index) => (
				<React.Fragment key={index}>{renderItem(item)}</React.Fragment>
			))}
		</>
	)
}
