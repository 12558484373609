import { Block } from '@ui/block'
import { ModalStyled, ModalText } from './modal-styled'
import { Button } from '@ui/button'
import { PropsWithChildren } from 'react'

type Props = {
	customFooter?: React.ReactNode
	isModalOpen: boolean
	onCancel: () => void
	onOk: () => void
	modalTitle?: string
	cancelButtonText?: string
	confirmButtonText?: string
}

export function Modal({
	children,
	customFooter,
	isModalOpen,
	onCancel,
	onOk,
	modalTitle,
	cancelButtonText,
	confirmButtonText,
}: PropsWithChildren<Props>) {
	return (
		<ModalStyled
			centered
			width={438}
			open={isModalOpen}
			onCancel={onCancel}
			footer={null}
		>
			<Block isFlexBlock flexDirection="column" gap="35px">
				<ModalText>{modalTitle}</ModalText>
				{children}
				{customFooter ? (
					customFooter
				) : (
					<Block isFlexBlock isContentCentered isFullWidth gap="6%">
						<Button
							isFitContent
							skin="grey-link"
							onClick={onCancel}
							style={{ minWidth: '47%' }}
						>
							{cancelButtonText ? cancelButtonText : 'Go back'}
						</Button>
						<Button
							isFitContent
							skin="dark"
							onClick={onOk}
							style={{ minWidth: '47%' }}
						>
							{confirmButtonText ? confirmButtonText : 'Confirm'}
						</Button>
					</Block>
				)}
			</Block>
		</ModalStyled>
	)
}
