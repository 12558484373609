import { Icon } from '@ui/icon'
import { Wrapper, BlockStyled, Title } from './new-user-button-styled'
import { ReactNode } from 'react'
import { IconBorder } from '@components/quiz/quiz-styled'

type Props = {
	icon?: ReactNode
	onClick: () => void
	title: string
	minHeight: string
	isActive: boolean
}

export function NewUserButton({
	onClick,
	title,
	icon,
	minHeight,
	isActive,
}: Props) {
	return (
		<Wrapper onClick={onClick}>
			<BlockStyled minHeight={minHeight}>
				{icon}
				<IconBorder style={{ marginTop: 24 }}>
					{isActive && <Icon name="checkCircleColoredIcon" color="#FF0C63" />}
				</IconBorder>
				<Title>{title}</Title>
			</BlockStyled>
		</Wrapper>
	)
}
