import { Col, Row } from 'antd'
import Img404 from '@assets/404.png'
import {
	Container,
	NotFoundImgWrapper,
	NotFoundTextDiv,
	RowStyled,
} from './not-found-page-styled'
import { GapLineCol } from '@components/quiz/quiz-styled'
import { useLoginPage } from '@pages/login/use-login-page'

export function NotFoundPage() {
	const { isLoading } = useLoginPage()

	if (isLoading) return null

	return (
		<Container>
			<RowStyled style={{ marginTop: 90 }}>
				<Col span={3} offset={1} style={{ marginTop: 30 }}>
					<GapLineCol />
				</Col>
				<Col span={6}>
					<NotFoundTextDiv>
						Oops! Something went wrong. Page doesn’t exist.
					</NotFoundTextDiv>
				</Col>
				<Col span={10} offset={1}>
					<NotFoundImgWrapper>
						<img src={Img404} alt="page doesn’t exist" />
					</NotFoundImgWrapper>
				</Col>
			</RowStyled>
		</Container>
	)
}
