import { colors } from '@styles/colors'
import { Button } from '@ui/index'
import styled, { css } from 'styled-components'

export const CheckButtonStyled = styled(Button)`
	padding: 14px;
	color: ${colors.blackTr48};
	text-transform: capitalize;

	${({ isActive }) =>
		isActive &&
		css`
			background: ${colors.blackTr5};
			border: 1px solid ${colors.black};
			color: ${colors.black};
		`}
`
