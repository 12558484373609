import { Col, Row } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { getShareCapsuleInfo } from '@api/capsule/get-share-capsule'
import {
	ChildrenWrapper,
	Content,
	Wrapper,
} from '@components/app-layout/layout/desktop/app-layout-desktop-styled'
import { LogoWrapper } from '@components/app-layout/left-sidebar/layout/desktop/left-sidebar-desktop-styled'
import {
	CapsuleCollageWrapper,
	ImageCont,
} from '@pages/capsules/capsules-preview-page/capsules-preview-page-styled'
import { colors } from '@styles/colors'
import { PROD_DOMAIN } from '@typings/constants'
import { CapsuleInfo } from '@typings/types'
import {
	BlankPlaceholder,
	Block,
	GalleryButtons,
	SiteLogo,
	Spinner,
	Swap,
} from '@ui/index'
import { handleToast } from '@utils/handle-toast'

import {
	CapsuleTitle,
	CategoryItem,
	CategoryList,
	MenuBlockWrapper,
} from './capsule-shared-page-styled'
import { GalleryBlock } from './gallery-block/gallery-block'

export function CapsuleSharedPage() {
	const { id } = useParams()
	const carouselRef = useRef<any>()

	const [isLoading, setIsLoading] = useState(false)
	const [capsuleInfo, setCapsuleInfo] = useState<CapsuleInfo | null>(null)

	const loadCapsuleInfo = useCallback(async () => {
		if (!id) return

		setIsLoading(true)
		const result = await getShareCapsuleInfo({ capsuleId: id })

		if (!result) {
			handleToast({
				content: 'Failed to load capsule info',
			})
			setIsLoading(false)
			return
		}

		setCapsuleInfo(result)
		setIsLoading(false)
	}, [id])

	useEffect(() => {
		if (isLoading || capsuleInfo) return
		loadCapsuleInfo()
	}, [loadCapsuleInfo, id, capsuleInfo, isLoading])

	const coverImageUrl = capsuleInfo?.params?.coverPhoto
		? PROD_DOMAIN + capsuleInfo.params.coverPhoto[0].urls.L
		: ''

	const renderCategories = () => {
		const categories = capsuleInfo?.summary?.category
		if (!categories) return null

		return (
			<CategoryList>
				{Object.entries(categories).map(([key, value], index) => {
					return (
						<CategoryItem key={index}>{`${key}, ${value} pcs`}</CategoryItem>
					)
				})}
			</CategoryList>
		)
	}

	return (
		<Wrapper>
			<MenuBlockWrapper>
				<Link to="/">
					<LogoWrapper>
						<SiteLogo color={colors.white} size={58} />
					</LogoWrapper>
				</Link>
			</MenuBlockWrapper>
			<Content>
				<ChildrenWrapper>
					<Block isFullWidth margin="20px 48px 48px">
						<Swap is={isLoading} isSlot={<Spinner />}>
							{capsuleInfo && (
								<Row gutter={[48, 120]} justify="start">
									<Col span={24}>
										<CapsuleTitle>{capsuleInfo.title}</CapsuleTitle>
									</Col>
									<Col xxl={{ span: 6 }} xl={{ span: 8 }}>
										<CapsuleCollageWrapper>
											<ImageCont imageUrl={coverImageUrl}>
												{!coverImageUrl && (
													<BlankPlaceholder color={colors.blackTr24} />
												)}
											</ImageCont>
										</CapsuleCollageWrapper>
									</Col>
									<Col xxl={{ span: 18 }} xl={{ span: 16 }}>
										<Row gutter={[48, 48]} justify="start">
											<Col span={20}>{renderCategories()}</Col>
											<Col span={4}>
												<GalleryButtons
													onNextArrowClick={() => carouselRef.current?.next()}
													onPrevArrowClick={() =>
														carouselRef.current?.previous()
													}
												/>
											</Col>
											<Col span={24}>
												<GalleryBlock
													carouselRef={carouselRef}
													imageList={capsuleInfo.items}
													priceValute={capsuleInfo?.summary?.priceValute}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							)}
						</Swap>
					</Block>
				</ChildrenWrapper>
			</Content>
		</Wrapper>
	)
}
