import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { acceptCapsule } from '@api/capsule/accept-capsule'
import { getCapsuleInfo } from '@api/capsule/get-capsule'
import { getShareLink } from '@api/capsule/get-share-link'
import { restyleCapsule } from '@api/capsule/restyle-restyle'
import { getStylistsById } from '@api/client/get-stylists-by-id'
import Avatar from '@components/avatar/avatar'
import { authSelector } from '@store/auth'
import {
	capsulesSelector,
	setCapsule,
	setIsCapsuleLoading,
} from '@store/capsules'
import { clothesSelector, setCapsuleClothesList } from '@store/clothes'
import { settingsSelector } from '@store/settings'
import { useAppDispatch, useAppSelector } from '@store/store'
import { PROD_DOMAIN } from '@typings/constants'
import {
	ImageItemType,
	NavigationButtonInfo,
	ShowPageType,
	StylistInfo,
} from '@typings/types'
import { handleToast } from '@utils/handle-toast'

export function useClientCapsulePreviewPage() {
	const dispatch = useAppDispatch()
	const { state, pathname } = useLocation()
	const navigate = useNavigate()

	const { token } = useAppSelector(authSelector)
	const { capsule, isCapsuleLoading } = useAppSelector(capsulesSelector)
	const { settings } = useAppSelector(settingsSelector)
	const { capsuleClothesList } = useAppSelector(clothesSelector)

	const capsuleId = state?.capsuleId || undefined

	const [imageItems, setImageItems] = useState<ImageItemType[]>([])
	const [isShortCategoriesList, setIsShortCategoriesList] = useState(true)
	const [isShowAllCategories, setIsShowAllCategories] = useState(false)
	const [stylist, setStylist] = useState<StylistInfo>()
	const [shareLink, setShareLink] = useState('')

	const currentPage: ShowPageType =
		pathname === `/capsules/${capsuleId}/edit` ? 'edit' : 'preview'

	const categoriesToShow = useMemo(() => {
		const categories = capsule.summary?.category
		if (!categories) return []

		return isShortCategoriesList
			? Object.entries(categories).slice(0, 6)
			: Object.entries(categories)
	}, [capsule.summary?.category, isShortCategoriesList])

	const loadCapsuleInfo = useCallback(async () => {
		dispatch(setIsCapsuleLoading(true))
		const [data, shareLinkResult] = await Promise.all([
			getCapsuleInfo({ token, capsuleId }),
			await getShareLink({ token, capsuleId }),
		])

		if (shareLinkResult?.success) {
			setShareLink(shareLinkResult.link)
		}

		if (data) {
			dispatch(setCapsule(data))

			if (data.user_id) {
				const stylistInfo = await getStylistsById({
					token,
					stylistIds: [data.user_id],
				})
				if (stylistInfo) setStylist(stylistInfo[0])
			}

			const items = data.items || []
			const imageItems = items
				.map((item) => ({
					itemId: item.id,
					id: item.coverPhoto?.id || 0,
					url: PROD_DOMAIN + item.coverPhoto?.urls.M,
					previewUrl: PROD_DOMAIN + item.coverPhoto?.urls.L,
					isCover: false,
					title: item.title,
					brand: item.brand,
					price: item.price,
					currency: data.summary?.priceValute,
				}))
				.filter(Boolean)
			setImageItems(imageItems)
			dispatch(setCapsuleClothesList(data.items))
			data.summary?.category &&
				setIsShowAllCategories(Object.entries(data.summary.category).length > 6)
			dispatch(setIsCapsuleLoading(false))
			return
		}
		dispatch(setIsCapsuleLoading(false))
		navigate('/capsules')
	}, [capsuleId, dispatch, navigate, token])

	useEffect(() => {
		// 	if (!capsuleId) return
		loadCapsuleInfo()
	}, [])

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() =>
			stylist
				? [
						{
							title: `${stylist?.first_name} ${stylist?.last_name}`,
							showListType: 'preview',
							node: (
								<Avatar
									imageUrl={stylist?.params?.photo?.[0].urls?.S || ''}
									size={40}
								/>
							),
							handleClick: () => {
								navigate(`/stylists/${stylist.user_id}`, {
									state: {
										stylistId: stylist.id,
										userId: stylist.user_id,
									},
								})
							},
						},
				  ]
				: [],
		[stylist]
	)

	const handleToggleCategoriesList = () => {
		setIsShortCategoriesList((prev) => !prev)
	}

	const handleAcceptClick = useCallback(async () => {
		dispatch(setIsCapsuleLoading(true))
		const result = await acceptCapsule({ token, capsuleId })

		if (!result) {
			handleToast({ content: 'Error accepting capsule' })
			return
		}

		loadCapsuleInfo()
	}, [capsuleId, dispatch, loadCapsuleInfo, token])

	const handleRestyleClick = useCallback(async () => {
		dispatch(setIsCapsuleLoading(true))
		const result = await restyleCapsule({ token, capsuleId })

		if (!result) {
			handleToast({ content: 'Error restyling capsule' })
			return
		}

		loadCapsuleInfo()
	}, [capsuleId, dispatch, loadCapsuleInfo, token])

	return {
		token,
		shareLink,
		isShowAllCategories,
		isCapsuleLoading,
		capsule,
		capsuleClothesList,
		currentPage,
		imageItems,
		navigationButtons,
		settings,
		categoriesToShow,
		isShortCategoriesList,
		handleToggleCategoriesList,
		handleAcceptClick,
		handleRestyleClick,
	}
}
