import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getMatchStylists } from '@api/client/get-match-stylists'
import { PhotoItem } from '@components/photo-item/photo-item'
import { authSelector } from '@store/auth'
import { QuizSelector } from '@store/quiz'
import { useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { PROD_DOMAIN } from '@typings/constants'
import { StylistInfo } from '@typings/types'
import { Block } from '@ui/block'
import { Button, Spinner } from '@ui/index'
import QuizEndPic from '../../../assets/quiz-end.png'

import {
	ButtonStyled,
	ColContainer,
	HeaderText,
	SmallText,
	StylistAvatar,
	StylistImageFrame,
	StylistInfoStyled,
	StylistName,
	SubHeaderCol,
	SubHeaderText,
} from './quiz-end-styled'
import { screenSize } from '@styles/screen-sizes'

export function QuizEnd() {
	const navigate = useNavigate()
	const { token } = useAppSelector(authSelector)
	const { userInfo } = useAppSelector(userSelector)
	const { quizClientName } = useAppSelector(QuizSelector)

	const [isLoading, setIsLoading] = useState(false)
	const [stylists, setStylists] = useState<StylistInfo[]>([])

	const loadStylists = async () => {
		setIsLoading(true)
		const result = await getMatchStylists({
			token,
		})

		if (result) {
			setStylists(result)
		}

		setIsLoading(false)
	}

	const handleClick = async (stylistId: number, userId: number) => {
		navigate(`/stylists/${userId}`, {
			state: {
				stylistId,
				userId,
			},
		})
	}

	useEffect(() => {
		if (!token || userInfo.role === 'client') loadStylists()
	}, [])

	if (isLoading) {
		return <Spinner color="#FF5656" />
	}

	return (
		<>
			<Row justify="center">
				<Col xs={{span: 22}} md={{span: 24}}>
					<HeaderText>
						{!token &&
							`${
								quizClientName ?? 'Hey'
							}, after careful selection,\n we present to you our best matches`}
						{userInfo.role === 'stylist' &&
							"That's a wrap! Thank you for sharing your insights"}
						{userInfo.role === 'client' &&
							`${
								userInfo.client.first_name ?? 'Client'
							}, after careful selection,\n we present to you our best matches`}
					</HeaderText>
					{userInfo.role === 'stylist' && (
						<SubHeaderCol>
							<SubHeaderText>
								Get ready to assemble your first capsule
							</SubHeaderText>
							<img src={QuizEndPic} alt="quiz-end" />
						</SubHeaderCol>
					)}
				</Col>
			</Row>

			{(!token || userInfo.role === 'client') && (
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 48 }}>
					{stylists.map((stylist) => (
						<ColContainer
							xs={{ span: 24 }}
							sm={{ span: 12 }}
							lg={{ span: 8 }}
							key={stylist.user_id}
							onClick={() => handleClick(stylist.id, stylist.user_id)}
						>
							<StylistImageFrame>
								<PhotoItem
									imageUrl={
										stylist.lastCapsuleCover?.[0].urls.L
											? PROD_DOMAIN + stylist.lastCapsuleCover?.[0].urls.L
											: ''
									}
								/>
							</StylistImageFrame>
							<StylistInfoStyled>
								<Block isFlexBlock>
									<StylistAvatar>
										<PhotoItem
											imageUrl={
												PROD_DOMAIN + stylist.params?.photo?.[0].urls.M || ''
											}
										/>
									</StylistAvatar>
									<Block
										isFlexBlock
										flexDirection="column"
										margin="0 0 0 16px"
										isContentCentered
									>
										<StylistName>{`${stylist.first_name} ${stylist.last_name}`}</StylistName>
									</Block>
								</Block>
								<ButtonStyled
									onClick={() => navigate(`/stylists/${stylist.user_id}`)}
								>
									Meet {stylist.first_name}
								</ButtonStyled>
							</StylistInfoStyled>
						</ColContainer>
					))}
				</Row>
			)}

			{userInfo.role === 'stylist' && (
				<Row justify="center" style={{ marginTop: 48 }}>
					<SmallText>The final touch to highlight your profile</SmallText>
				</Row>
			)}

			<Row
				justify="center"
				style={{ marginTop: screenSize.antXs || screenSize.antMd ? 0 : 88, marginLeft: 5, marginRight: 5 }}
			>
				{userInfo.role === 'stylist' && (
					<>
						<Col span={6}>
							<Button
								skin="dark"
								onClick={() => {
									navigate(`/capsule-guide`)
								}}
							>
								Try now
							</Button>
						</Col>
						<Col offset={1} span={6}>
							<Button
								skin="dark"
								onClick={() => {
									navigate(`/stylist-profile`)
								}}
							>
								Skip
							</Button>
						</Col>
					</>
				)}
				{(!token || userInfo.role === 'client') && (
					<Col style={{ marginTop: 32, marginBottom: screenSize.antXs || screenSize.antMd ? 0 : 32 }}>
						<Button
							skin="dark-link"
							onClick={() => {
								navigate(`/stylists/`)
							}}
						>
							Show more stylists
						</Button>
					</Col>
				)}
			</Row>
		</>
	)
}
