import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DealStatus } from '@typings/types'
import moment from 'moment'

type Props = {
	dealStatus?: DealStatus
}

export function useBookingInfo({ dealStatus }: Props) {
	const interval = useRef<NodeJS.Timer>()
	const [dueDateTimer, setDueDateTimer] = useState('--:--:--')

	const dueDate = useMemo(() => {
		if (!dealStatus?.statusDeadline) return ''

		return moment(dealStatus.statusDeadline, 'YYYY-MM-DD hh:mm:ss').format(
			'DD MMM, hh:mm'
		)
	}, [dealStatus?.statusDeadline])

	const updateTimer = useCallback((bookStatusDeadline: string) => {
		const total = Date.parse(bookStatusDeadline) - Date.now()

		if (total < 0) {
			clearInterval(interval.current)
			setDueDateTimer('00:00:00')
			return
		}

		const seconds = Math.floor((total / 1000) % 60)
		const minutes = Math.floor((total / 1000 / 60) % 60)
		const hours = Math.floor((total / 1000 / 60 / 60) % 24)

		setDueDateTimer(
			(hours > 9 ? hours : '0' + hours) +
				':' +
				(minutes > 9 ? minutes : '0' + minutes) +
				':' +
				(seconds > 9 ? seconds : '0' + seconds)
		)
	}, [])

	useEffect(() => {
		if (!dealStatus?.statusDeadline) return

		interval.current = setInterval(() => {
			updateTimer(dealStatus.statusDeadline)
		}, 1000)
		return () => clearInterval(interval.current)
	}, [dealStatus?.status, dealStatus?.statusDeadline, updateTimer])

	return {
		dueDate,
		dueDateTimer,
		isExpired: dueDateTimer === '00:00:00',
	}
}
