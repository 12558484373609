import axios from 'axios'
import { axiosInstance } from '../config'
import { UploadFileRequest, UploadFileResponse } from '../typings/types'

type Props = {
	requestData: UploadFileRequest
	token: string
}

export async function uploadFile({ requestData, token }: Props) {
	try {
		const { filename, relation_id, relation_type } = requestData

		const request = new FormData()
		request.append('filename', filename)
		request.append('relation_id', relation_id)
		request.append('relation_type', relation_type)

		const response = await axiosInstance.post<UploadFileResponse>(
			'/api/file',
			request,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
				},
			}
		)

		if (response.data) {
			return response.data
		}

		return ''
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
