import { SettingsList } from '@typings/types'
import axios from 'axios'
import { axiosInstance } from '../config'

export async function getLists() {
	try {
		const response = await axiosInstance.get<SettingsList>('/api/lists')

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
