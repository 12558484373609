import styled, { css } from 'styled-components'

export const DivFlex = styled.div`
	display: flex;
	flex-direction: row;

	& div:not(:first-child) {
		margin-left: -10px;
	}
`

export const ColorCircle = styled.div<{ color?: string }>`
	width: 24px;
	height: 24px;
	border: 2px solid #ffffff;
	border-radius: 30px;

	${({ color }) =>
		color &&
		css`
			background: ${color};
		`}
`
