import { useAppSelector } from '@store/index'
import { userSelector } from '@store/user'
import { IconNames } from '../../../../UI/icon/types'

export type MenuItemType = {
	title: string
	route: string
	icon: IconNames
	size: number
}

export function useLeftMenu() {
	const { userInfo } = useAppSelector(userSelector)

	const menuStylistItemsList: Array<MenuItemType> = [
		{
			title: 'Profile',
			route: 'stylist-profile',
			icon: 'cabinetIcon',
			size: 24,
		},
		{
			title: 'Clients',
			route: 'clients',
			icon: 'clientsIcon',
			size: 24,
		},
		{
			title: 'Styleboards',
			route: 'styleboards',
			icon: 'styleBoardsIcon',
			size: 48,
		},
		{
			title: 'Clothes',
			route: 'clothes',
			icon: 'tShirtIcon',
			size: 24,
		},
		{
			title: 'Capsules',
			route: 'capsules',
			icon: 'hangerIcon',
			size: 24,
		},
		/*
		{
			title: 'Events',
			route: 'events',
			icon: 'eventsIcon',
			size: 24,
		},
		*/
		{
			title: 'Finances',
			route: 'finances',
			icon: 'financeIcon',
			size: 24,
		},
	]

	const menuUserItemsList: Array<MenuItemType> = [
		{
			title: 'Me',
			route: 'client-profile',
			icon: 'cabinetIcon',
			size: 24,
		},
		{
			title: 'Styling studio',
			route: 'styling-studio',
			icon: 'clientsIcon',
			size: 24,
		},
		{
			title: 'Stylists',
			route: 'stylists',
			icon: 'styleBoardsIcon',
			size: 48,
		},
		{
			title: 'Capsules',
			route: 'capsules',
			icon: 'tShirtIcon',
			size: 24,
		},
	]

	const menuItemsList =
		userInfo.role === 'stylist' ? menuStylistItemsList : menuUserItemsList

	return {
		menuItemsList,
	}
}
