import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	width: 40px;
	height: 40px;
	right: 20px;
	top: 20px;

	background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
	border-radius: 8px;

	cursor: pointer;
	opacity: 0.4;
	transition: all 0.1s;
`
