import axios from 'axios'
import { BASE_URL } from '@typings/constants'
import { axiosInstance } from '../config'

export type Countries = {
	[key: string]: string[]
}

export async function getCountries() {
	try {
		const response = await axiosInstance.get(
			`${BASE_URL}/static/countries.json`
		)

		if (!response) {
			return undefined
		}

		return response.data as unknown as Countries
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
