import { Col } from 'antd'

import { ImageItemType } from '@typings/types'
import { PhotoItem } from './photo-item/photo-item'

type Props = {
	imageItems: ImageItemType[]
	handleSetCoverClick: (id: number) => void
	handleDeleteClick: (id: number) => void
}

export function PhotosList({
	imageItems,
	handleSetCoverClick,
	handleDeleteClick,
}: Props) {
	return (
		<>
			{imageItems.map((imageItem, index) => (
				<Col key={index} xxl={{ span: 6 }} xl={{ span: 8 }}>
					<PhotoItem
						imageObj={imageItem}
						handleSetCoverClick={() => handleSetCoverClick(imageItem.id)}
						handleDeleteClick={() => handleDeleteClick(imageItem.id)}
					/>
				</Col>
			))}
		</>
	)
}
