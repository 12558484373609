import { colors } from '@styles/colors'
import { Icon } from '@ui/icon'
import { Container } from './make-cover-button-styled'

type Props = {
	isCover?: boolean
	handleSetCoverClick: () => void
}

export function MakeCoverButton({
	isCover = false,
	handleSetCoverClick,
}: Props) {
	return (
		<Container
			className="cover-button"
			data-isactive={!!isCover}
			onClick={isCover ? () => {} : handleSetCoverClick}
		>
			{isCover ? (
				<>
					<Icon size={24} color="#FF0C63" name="pinIcon" />
					Cover photo
				</>
			) : (
				<>
					<Icon size={24} color={colors.blackTr64} name="pinIcon" />
					Make cover photo
				</>
			)}
		</Container>
	)
}
