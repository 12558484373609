import { css } from 'styled-components'
import { colors } from './colors'
import { fontTenorSans } from './typography'

export const bodyStyled = css`
	body {
		margin: 0;
		${fontTenorSans}
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background: ${colors.white};
		color: ${colors.black};
	}
`
