import axios from 'axios'

import { handleToast } from '@utils/handle-toast'
import { axiosInstance } from '../../config'

export async function updateStyleboard(
	request: string,
	styleboardId: number,
	token: string
) {
	try {
		const response = await axiosInstance.patch(
			`/api/styleboard/${styleboardId}`,
			request,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		if (!response.data) {
			handleToast({
				content: response.data.error,
			})
		}

		return !!response.data.success
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
