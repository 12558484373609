import { Col } from 'antd'

import { Spinner, Block } from '@ui/index'
import { PageNavigation } from '@components/page-navigation/page-navigation'
import Avatar from '@components/avatar/avatar'
import { colors } from '@styles/index'
import { useStyleboardPreviewPage } from './use-styleboard-preview-page'
import { PhotosList } from './photos-list/photos-list'
import { ReviewButtons } from './review-buttons/review-buttons'
import {
	Container,
	SharedClientBlock,
	StatusBlock,
} from './styleboard-preview-page-styled'

export function StyleBoardPreviewPage() {
	const {
		currentPage,
		handleAcceptClick,
		handleRestyleClick,
		styleboardElements,
		isLoading,
		navigationButtons,
		userRole,
		styleboard,
		sharedClient,
	} = useStyleboardPreviewPage()

	const sharedClientImageUrl = sharedClient?.params?.photo
		? sharedClient.params.photo[0].urls.M
		: ''

	if (isLoading) return <Spinner color="#FF5656" />

	return (
		<Container>
			<PageNavigation
				showListType={currentPage}
				buttons={navigationButtons}
				leftSideSlot={
					userRole === 'client' ? <Col flex={'0 1 10%'}></Col> : null
				}
				rightSideSlot={
					<>
						{userRole === 'client' && !isLoading && (
							<ReviewButtons
								status={styleboard.dealStatus}
								onAcceptClick={handleAcceptClick}
								onRestyleClick={handleRestyleClick}
							/>
						)}
						{!isLoading && (
							<Block isFlexBlock style={{ color: colors.blackTr64 }}>
								{sharedClient && (
									<SharedClientBlock>
										Linked to {sharedClient.first_name}
										{sharedClientImageUrl && (
											<Avatar imageUrl={sharedClientImageUrl} size={24} />
										)}
									</SharedClientBlock>
								)}
							</Block>
						)}
					</>
				}
			/>
			<PhotosList photos={styleboardElements} />
		</Container>
	)
}
