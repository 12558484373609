import { Col } from 'antd'
import styled from 'styled-components'

export const FullScrIcon = styled.div`
	position: absolute;
	top: 26px;
	left: 26px;
	width: 48px;
	height: 48px;
	background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
	border-radius: 8px;
	padding: 12px;
`

export const ColStyled = styled(Col)`
	& .carousel-container {
		padding: 10px 0;
	}
`
