import { QuizSelector } from '@store/quiz'
import { useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { PROD_DOMAIN } from '@typings/constants'
import { Block, Icon } from '@ui/index'
import { Col, Row } from 'antd'
import {
	AnswerWrapper,
	ColStyled,
	IconBorder,
	ImageWrapper,
	QuestionCont,
	RowFlexColumn,
	TextWrapper,
} from '../../quiz-styled'
import { useQuizAnswers } from '../../use-quiz-answers'

export function GenderQuestion() {
	const { userInfo } = useAppSelector(userSelector)
	const { questions, currentStep, answers } = useAppSelector(QuizSelector)
	const currentQuestion = questions[currentStep - 1]
	const currentAnswers = answers[currentStep] ?? ''
	const { handleSaveAnswer } = useQuizAnswers({
		isMultiAnswer: !!currentQuestion.answers_multiple,
	})

	if (!currentQuestion.answers_json.length) return null

	return (
		<>
			<RowFlexColumn
				justify="center"
				align={{ sm: 'top', lg: 'middle' }}
				gutter={[0, 48]}
			>
				<Col span={24}>
					<QuestionCont>
						{userInfo.role === 'stylist'
							? currentQuestion.stylist_question
							: currentQuestion.question}
					</QuestionCont>
				</Col>
				{currentQuestion.step_comment && (
					<Col span={8}>
						<TextWrapper>{currentQuestion.step_comment}</TextWrapper>
					</Col>
				)}
			</RowFlexColumn>
			{/*<Row justify={{xs: "space-between", md: "center"}} style={{ height: '150px' }}></Row>*/}
			<Row justify={{xs: "space-between", md: "center"}} gutter={{xs: 5, md: 30}} style={{marginBottom: 60, flex: '1 1 auto'}}>
				{currentQuestion.answers_json.map((answer, i) => (
					<>
					{/*!i && <Col
						key={999}					
						xs={{ span: 0 }}
						md={{ span: 4 }}
						lg={{ span: 6 }}
						xxl={{ span: 7 }}></Col>
					*/}
					<ColStyled
						key={i}
						xs={{ span: 11 }}
						md={{ span: 8 }}
						lg={{ span: 6 }}
						xxl={{ span: 5 }}
						onClick={() => handleSaveAnswer(answer.value)}
					>
						{answer.image && (
							<ImageWrapper>
								<img src={PROD_DOMAIN + answer.image} alt="" />
							</ImageWrapper>
						)}
						<Block isContentCentered justifyContent='center' isFullWidth isFlexBlock flexDirection='column' gap='24px'>
							<IconBorder>
								{currentAnswers.includes(answer.value) && (
									<Icon name="checkCircleColoredIcon" color="#FF0C63" />
								)}
							</IconBorder>
							<AnswerWrapper>{answer.text}</AnswerWrapper>
						</Block>
					</ColStyled>
					</>
				))}
			</Row>
		</>
	)
}
