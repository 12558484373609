import { useEffect, useState } from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent'

import { CookieModal } from '@components/cookie-modal'
import { useAppDispatch } from '@store/store'
import { setUserCookieOptions } from '@store/user'
import { UserCookieOptions } from '@typings/types'
import { Block } from '@ui/block'
import { Button } from '@ui/button'
import { GrayLine } from '@ui/gray-line/gray-line'
import {
	ButtonNotification,
	NotificationWrapper,
	TitleWrapper,
} from './cookie-notification-styled'

export function CookieNotification({
	isMobileView = false,
}: {
	isMobileView?: boolean
}) {
	const dispatch = useAppDispatch()

	const [isOpen, setIsOpen] = useState(false)
	const [isShow, setIsShow] = useState(false)

	const handleOk = (options: UserCookieOptions) => {
		dispatch(setUserCookieOptions(options))

		Cookies.set('general', options.general)
		Cookies.set('functional', options.functional)
		Cookies.set('performance', options.performance)

		setIsOpen(false)
		setIsShow(false)
	}

	const handleCancel = () => {
		Cookies.set('general', 'true')
		Cookies.set('functional', 'false')
		Cookies.set('performance', 'false')

		setIsOpen(false)
		setIsShow(false)
	}

	const acceptHandler = () => {
		dispatch(
			setUserCookieOptions({
				general: true,
				functional: true,
				performance: true,
			})
		)
		Cookies.set('general', 'true')
		Cookies.set('functional', 'true')
		Cookies.set('performance', 'true')

		setIsShow(false)
	}

	const declineHandler = () => {
		dispatch(
			setUserCookieOptions({
				general: true,
				functional: false,
				performance: false,
			})
		)

		Cookies.set('general', 'true')
		Cookies.set('functional', 'false')
		Cookies.set('performance', 'false')

		setIsShow(false)
	}

	const manageCookieHandler = () => {
		setIsOpen(true)
	}

	const styles: React.CSSProperties = {
		background: '#fff',
		width: isMobileView ? '100%' : 400,
		right: isMobileView ? 0 : 20,
		left: 'auto',
		borderRadius: isMobileView ? 0 : 8,
		boxShadow:
			'0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
		bottom: isMobileView ? 0 : 80,
	}

	useEffect(() => {
		const cookies = Cookies.get('general')
		if (cookies === undefined) {
			setIsShow(true)
		}
	}, [])

	const notificationContent = (
		<NotificationWrapper>
			<TitleWrapper>We use cookies</TitleWrapper>
			Cookies enhance your experience, tailor your ads and improve our website{' '}
			<Button
				skin="dark-link"
				size="s"
				isFitContent
				style={{ display: 'inline', padding: 0, fontSize: 'unset' }}
				onClick={manageCookieHandler}
			>
				Manage
			</Button>
			<GrayLine style={{ margin: '16px 0' }} />
			<Block isFlexBlock justifyContent="end" gap="46px">
				<ButtonNotification
					skin="dark-link"
					size="s"
					isFitContent
					onClick={declineHandler}
				>
					Accept only necessary
				</ButtonNotification>
				<ButtonNotification
					skin="dark"
					size="s"
					isFitContent
					onClick={acceptHandler}
				>
					Accept All
				</ButtonNotification>
			</Block>
		</NotificationWrapper>
	)

	if (!isShow) {
		return null
	}

	return (
		<>
			<CookieConsent location="" style={styles} ButtonComponent={() => <></>}>
				{notificationContent}
			</CookieConsent>
			<CookieModal
				isOpen={isOpen}
				handleOk={handleOk}
				handleCancel={handleCancel}
			/>
		</>
	)
}
