import { useNavigate } from 'react-router-dom'
import { Col, Popover, Row } from 'antd'

import { CapsulesItem } from '@components/capsules-item'
import { colors } from '@styles/index'
import { Icon, Spinner, Swap } from '@ui/index'
import {
	BlockLabel,
	ButtonStyled,
	PromoContainer,
} from './client-capsules-styled'
import { ReactComponent as SkeletonSvg } from './svg/skeleton.svg'
import { BookStatus, CapsuleInfo } from '@typings/types'
import { BlankContainer } from '@components/client-styleboard-preview/client-styleboard-preview-styled'
import { ButtonLinkTo } from '@ui/button-add'

type Props = {
	isLoading: boolean
	elPerRow: number
	capsulesList: CapsuleInfo[]
	dealStatus?: BookStatus
}

export function ClientCapsulesBlock({
	elPerRow,
	isLoading,
	capsulesList,
	dealStatus,
}: Props) {
	const navigate = useNavigate()

	if (isLoading) {
		return (
			<Col span={10}>
				<SkeletonSvg />
			</Col>
		)
	}

	const xlSpanVal = 24 / elPerRow + 8
	const xlSizeCapsulesItem = {
		span: xlSpanVal,
		offset: (24 - xlSpanVal) / 2,
	}

	return (
		<PromoContainer>
			<Row>
				<Col flex="1 1 auto">
					<BlockLabel>Capsules</BlockLabel>
					<Popover
						placement="rightTop"
						content={
							<div className="styledyle-content">
								A capsule is a collection of clothing and accessories that
								complement each other perfectly.
							</div>
						}
						arrow={false}
						trigger="click"
					>
						<span style={{ cursor: 'pointer' }}>
							<Icon color={colors.black} name="infoCircleFilledIcon" />
						</span>
					</Popover>
				</Col>
			</Row>
			<Row gutter={[32, 24]} style={{ marginTop: 24 }}>
				<Swap
					is={isLoading}
					isSlot={
						<BlankContainer>
							<Spinner color="#FF0C63" />
						</BlankContainer>
					}
				>
					<Swap
						is={!capsulesList.length && dealStatus === 'create_capsule'}
						isSlot={
							<Col
								xxl={{ span: 24 / elPerRow, offset: 0 }}
								xl={{
									span: xlSizeCapsulesItem.span,
									offset: xlSizeCapsulesItem.offset,
								}}
							>
								<ButtonLinkTo
									onClick={() => {
										navigate('/clothes')
									}}
									minHeight="444px"
									title="Add"
									icon={<Icon name="clothAddIcon" size={36} />}
									height="444px"
									bgColor={'transparent'}
								/>
							</Col>
						}
					>
						<Swap
							is={!capsulesList.length}
							isSlot={
								<Col
									xxl={{ span: 24 / elPerRow, offset: 0 }}
									xl={{
										span: xlSizeCapsulesItem.span,
										offset: xlSizeCapsulesItem.offset,
									}}
								>
									<CapsulesItem isEmptyCapsule onClick={() => {}} />
								</Col>
							}
						>
							{!!capsulesList.length &&
								capsulesList.slice(0, 4).map((capsule) => (
									<Col
										key={capsule.id}
										xxl={{ span: 24 / elPerRow, offset: 0 }}
										xl={{
											span: xlSizeCapsulesItem.span,
											offset: xlSizeCapsulesItem.offset,
										}}
									>
										<CapsulesItem
											item={capsule}
											onClick={() =>
												navigate(`/capsules/${capsule.id}`, {
													state: {
														capsuleId: capsule.id,
													},
												})
											}
										/>
									</Col>
								))}
						</Swap>
					</Swap>
				</Swap>
			</Row>
		</PromoContainer>
	)
}
