import { normalize } from 'polished'
import { css } from 'styled-components'
import { colors } from './colors'

export const resetStyled = css`
	${normalize()};
	html {
		/* 1rem = 10px */
		font-size: 62.5%;
		overflow-y: hidden;
	}

	html,
	body,
	#root {
		height: 100%;
		overflow-y: auto;
	}

	#root {
		display: flex;
		flex-direction: column;
	}

	* {
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
	}

	button {
		-webkit-tap-highlight-color: transparent;
	}

	a {
		color: ${colors.black};
	}

	p {
		margin: 0;
		padding: 0;
	}
`
