import axios from 'axios'
import { axiosInstance } from '../../config'
import { UpdateItemResponse } from '@typings/types'

export async function updateItem(
	request: string,
	itemId: number,
	token: string
) {
	try {
		const response = await axiosInstance.patch<UpdateItemResponse>(
			`/api/item/${itemId}`,
			request,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
