import { ReactNode } from 'react'
import { ToastOptions, toast } from 'react-toastify'

export const handleToast = ({
	content,
	customOptions,
	type = 'error',
}: {
	content: ReactNode
	customOptions?: ToastOptions
	type?: 'info' | 'success' | 'warn' | 'error'
}) => {
	const defaultOptions: ToastOptions = {
		autoClose: 10000,
		style: {
			fontFamily: 'Roboto',
			fontStyle: 'normal',
			fontWeight: '700',
			fontSize: '1.4rem',
			padding: '20px',
		},
	}

	switch (type) {
		case 'info':
			toast.info(content, {
				...customOptions,
				...defaultOptions,
			})
			return

		case 'success':
			toast.success(content, {
				...customOptions,
				...defaultOptions,
			})
			return

		case 'warn':
			toast.warn(content, {
				...customOptions,
				...defaultOptions,
			})
			return

		case 'error':
		default:
			toast.error(content, {
				...customOptions,
				...defaultOptions,
			})
			return
	}
}
