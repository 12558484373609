import styled, { css } from 'styled-components'
import { colors } from './../../../styles/colors'

const ICON_GAP = 13

export const Container = styled.div`
	width: 100%;
	height: 55px;
	position: relative;
`

export const Line = styled.div`
	width: 100%;
	position: absolute;
	top: 27px;
	left: 0;
	border-top: 1px solid ${colors.blackTr8};
`
export const IconsCont = styled.div`
	display: flex;
	flex-flow: row;
	flex-wrap: nowrap;
	width: 100%;

	position: relative;
`

export const IconWrapper = styled.div<{ step: number; quizLength: number }>`
	position: absolute;
	top: 0;
	display: flex;
	transition: all 0.5s ease-in-out;

	${(p) =>
		css`
			left: calc(97% - ${ICON_GAP * (p.quizLength - p.step)}px);
		`};
	&.done {
		${(p) =>
			css`
				left: calc(${ICON_GAP * (p.step - 1)}px);
			`};
	}

	&.active {
		left: 48%;
	}
`

export const IconCont = styled.div`
	padding: 14px 0;
	z-index: 100;
`
