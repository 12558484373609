import { loginFlowSelector } from '@store/login-flow'
import { useAppSelector } from '@store/store'
import { CodeForm, EmailForm, UserCreateForm } from '../forms'
import { NewClientForm } from '../forms/new-client'
import { NewStylistForm } from '../forms/new-stylist'

export function EmailLogin() {
	const { loginFormStep } = useAppSelector(loginFlowSelector)

	if (loginFormStep === 'email') {
		return <EmailForm />
	}

	if (loginFormStep === 'user-create') {
		return <UserCreateForm />
	}

	if (loginFormStep === 'code') {
		return <CodeForm />
	}

	if (loginFormStep === 'stylist-form') {
		return <NewStylistForm />
	}

	if (loginFormStep === 'client-form') {
		return <NewClientForm />
	}

	return null
}
