import { colors } from '@styles/colors'
import { fontTenorSans, regular16 } from '@styles/typography'
import { Block } from '@ui/block'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	width: 100%;
	height: 244px;
	padding: 14%;

	background: ${colors.blackTr5};
	border-radius: 20px;

	#upload-photo {
		opacity: 0;
		position: absolute;
		z-index: -1;
	}
`

export const ButtonWrapper = styled.label`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 12px;

	border: 2px dashed ${colors.blackTr5};
	border-radius: 20px;

	cursor: pointer;
	width: 100%;
	height: 194px;
`

export const UploadText = styled(Block)`
	${fontTenorSans}
	${regular16}
	text-align: center;
`
