import { colors } from '@styles/colors'
import styled, { css } from 'styled-components'

export const ButtonBg = styled.div<{
	isActive?: boolean
	isDisabled?: boolean
}>`
	width: 100%;
	height: 100%;
	background: ${colors.white};
	border-radius: 12px;
	border: 2px solid ${colors.blackTr24};
	display: flex;
	justify-content: start;

	${({ isActive }) =>
		isActive &&
		css`
			border-color: transparent;
			background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
			justify-content: end;
		`}

	${({ isDisabled }) =>
		isDisabled &&
		css`
			border-color: ${colors.blackTr24};
			background: ${colors.blackTr24};
			justify-content: end;
		`}
`

export const ButtonSwitch = styled.div<{
	isActive?: boolean
}>`
	position: relative;
	left: 2px;
	top: 2px;
	width: 12px;
	height: 12px;
	border: solid 6px;
	background-color: ${colors.blackTr24};
	border-color: ${colors.blackTr24};
	border-radius: 50%;
	transition: all 0.2s ease;

	${({ isActive }) =>
		isActive &&
		css`
			background-color: ${colors.white};
			border-color: ${colors.white};
			left: unset;
			right: 2px;
		`}
`

export const ButtonWrapper = styled.div`
	width: 36px;
	height: 20px;
	align-self: center;

	cursor: pointer;
`
