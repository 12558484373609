import { Icon } from '@ui/icon'
import { ColorButtonStyled } from './color-button-styled'

export type ColorObj = {
	title: string
	color: string
	isActive: boolean
}

export function ColorButton({
	colorProp,
	handleColorSelect,
}: {
	colorProp: ColorObj
	handleColorSelect: any
}) {
	return (
		<>
			<ColorButtonStyled
				isFitContent
				key={colorProp.title}
				isActive={colorProp.isActive}
				skin="white-outline"
				leftIcon={<Icon color={colorProp.color} name="circleIcon" />}
				onClick={() => handleColorSelect(colorProp.title)}
			>
				{colorProp.title}
			</ColorButtonStyled>
		</>
	)
}
