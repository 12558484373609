import { QuizSelector } from '@store/quiz'
import { useAppSelector } from '@store/store'
import { GenderQuestion } from './quiz-questions/gender-question/gender-question'
import { QuizStyleQuestion } from './quiz-questions/style-question/style-question'
import { QuizStart } from './quiz-start/quiz-start'
import { SilentLogin } from './silent-login/silent-login'

export function Quiz() {
	const { currentStep } = useAppSelector(QuizSelector)

	switch (currentStep) {
		case 0:
			return <QuizStart />
		case 1:
			return <GenderQuestion />
		case 888:
			return <SilentLogin />
		default:
			return <QuizStyleQuestion />
	}
}
