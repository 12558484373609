import axios from 'axios'
import { axiosInstance } from '../../../config'

export async function logOut() {
	try {
		const response = await axiosInstance.get('/api/auth/logout')

		return response.status
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
