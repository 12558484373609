import { css } from 'styled-components'
import { screenSize } from './screen-sizes'

// Title Examples
export const title1 = css`
	font-weight: 700;
	font-size: 4.2rem;
	line-height: 2.4rem;

	@media (${screenSize.minMobile}) {
		font-size: 3rem;
		line-height: 2.4rem;
	}
`

export const regular42 = css`
	font-weight: 400;
	font-size: 4.2rem;
	line-height: 4.6rem;
`

export const regular45 = css`
	font-weight: 400;
	font-size: 4.5rem;
	line-height: 5rem;
`

export const regular40 = css`
	font-weight: 400;
	font-size: 4rem;
	line-height: 4.6rem;
`
export const regular36 = css`
	font-weight: 400;
	font-size: 3.6rem;
	line-height: 4rem;
`

export const regular32 = css`
	font-weight: 400;
	font-size: 3.2rem;
	line-height: 4rem;
`
export const regular26 = css`
	font-weight: 400;
	font-size: 2.6rem;
	line-height: 3rem;
`

export const regular24 = css`
	font-weight: 400;
	font-size: 2.4rem;
	line-height: 3rem;
`

export const regular22 = css`
	font-weight: 400;
	font-size: 2.2rem;
	line-height: 3rem;
`

export const regular20 = css`
	font-weight: 400;
	font-size: 2rem;
	line-height: 2.4rem;
`

export const regular18 = css`
	font-weight: 400;
	font-size: 1.8rem;
	line-height: 2.2rem;
`

export const regular16 = css`
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 2rem;
`

export const regular14 = css`
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 1.8rem;
`

export const regular13 = css`
	font-weight: 400;
	font-size: 1.3rem;
	line-height: 1.6rem;
`

export const regular12 = css`
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.6rem;
`

export const regular10 = css`
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4rem;
`

export const bold18 = css`
	font-weight: 700;
	font-size: 1.8rem;
	line-height: 2.4rem;
`

export const bold16 = css`
	font-weight: 700;
	font-size: 1.6rem;
	line-height: 2.2rem;
`

export const bold14 = css`
	font-weight: 700;
	font-size: 1.4rem;
	line-height: 2rem;
`
export const bold12 = css`
	font-weight: 700;
	font-size: 1.2rem;
	line-height: 1.8rem;
`

export const error = css`
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.7rem;
`

export const fontRoboto = css`
	font-family: 'Roboto';
	font-style: normal;
`

export const fontTenorSans = css`
	font-family: 'Tenor Sans';
	font-style: normal;
`
export const fontPlayfair = css`
	font-family: 'Playfair Display', serif;
	font-style: normal;
`

export const robotoBold14 = css`
	${fontRoboto}
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
`
