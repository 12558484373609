import ContentLoader from 'react-content-loader'
import { ItemsList } from '@components/items-list/items-list'
import { PageNavigation } from '@components/page-navigation/page-navigation'
import { Block } from '@ui/block'
import { Container, RowBordered } from './client-capsules-page-styled'
import { useClientCapsulesPage } from './use-client-capsules-page'
import { ClientCapsulesItem } from '@components/capsules-item/client-capsules-item'
import { colors } from '@styles/colors'
import { Swap } from '@ui/swap'

const CAPSULE_SIZE = {
	width: 393,
	height: 556,
}

export function ClientCapsulesPage() {
	const {
		capsulesList,
		stylists,
		handleCapsuleClick,
		isListLoading,
		buttons,
		showListType,
	} = useClientCapsulesPage()

	const PageNavigationStylistsLoader = () => (
		<RowBordered gutter={0}>
			{[1, 2, 3].map((el) => (
				<ContentLoader
					key={el}
					speed={2}
					width={160}
					height={66}
					viewBox="0 0 160 66"
					backgroundColor={colors.blackTr12}
					foregroundColor="#ecebeb"
				>
					<circle cx="38" cy="33" r="20" />
					<rect x="70" y="26" rx="3" ry="3" width="70" height="14" />
					<rect x="159" y="0" width="1" height="66" />
				</ContentLoader>
			))}
		</RowBordered>
	)

	return (
		<Block isFullWidth margin="0 48px 48px">
			<Swap is={isListLoading} isSlot={<PageNavigationStylistsLoader />}>
				<PageNavigation showListType={showListType} buttons={buttons} />
			</Swap>
			<Container>
				<ItemsList
					width={CAPSULE_SIZE.width}
					height={CAPSULE_SIZE.height}
					isLoading={isListLoading}
					itemsList={capsulesList}
					renderItem={(item) => (
						<ClientCapsulesItem
							key={item.id}
							item={item}
							stylist={stylists.find(
								(stylist) => stylist.user_id === item.user_id
							)}
							onClick={handleCapsuleClick}
						/>
					)}
				/>
			</Container>
		</Block>
	)
}
