import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { getBookedStylists } from '@api/client/get-booked-stylists'
import { getUserInfo } from '@api/get-user-info'
import { getQuizAnswers } from '@api/quiz/get-quiz-answers'
import { updateProfile } from '@api/update-profile'
import { useLoadSettings } from '@hooks/api-to-store/use-load-settings'
import { useInitChat } from '@hooks/use-init-chat'
import { authUserByCode } from '@pages/login/api/auth-user-by-code'
import { generateAuthCode } from '@pages/login/api/generate-auth-code'
import { authSelector, setUserCode, setUserToken } from '@store/auth'
import { setChatUser } from '@store/chat'
import { useAppDispatch } from '@store/index'
import { setLoginFormStep } from '@store/login-flow'
import { setQuizStep } from '@store/quiz'
import { useAppSelector } from '@store/store'
import { setUserInfo, userSelector } from '@store/user'
import { IS_DEV } from '@typings/constants'
import { handleToast } from '@utils/handle-toast'
import { setToken } from '@utils/set-token'

type FormValues = {
	type: string
	message: string
}

export function useCodeForm({ isSilentUser }: { isSilentUser?: boolean }) {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { initChat } = useInitChat()

	const { email, code } = useAppSelector(authSelector)
	const { userInfo, userCookieOptions } = useAppSelector(userSelector)

	const [isValid, setIsValid] = useState(true)
	const [currentCode, setCurrentCode] = useState(code)
	const [errors, setErrors] = useState({
		type: '',
		message: '',
	})
	const [isLoading, setLoading] = useState(false)

	useLoadSettings()

	const { handleSubmit } = useForm<FormValues>()

	const onSubmitCode = async ({
		isNoRedirect,
		stylistId,
	}: {
		isNoRedirect?: boolean
		stylistId?: string
	}) => {
		if (currentCode.length !== 4) {
			setErrors({
				type: 'manual',
				message: 'The code must be 4 digits',
			})
			return
		}

		dispatch(setUserCode(currentCode))

		setLoading(true)

		const authUserData = await authUserByCode({
			email,
			code: currentCode,
			dispatch,
		})

		if (!authUserData?.user_id) {
			handleToast({
				content: authUserData?.errors?.[0] || 'Invalid code, please try again',
			})
			setErrors({
				type: 'manual',
				message: authUserData?.errors?.[0] || 'Invalid code, please try again',
			})
			setLoading(false)
			return
		}

		const token = authUserData ? authUserData['access-token'] : ''
		const userId = authUserData?.user_id ?? 0

		if (!token || !userId) {
			setLoading(false)
			return
		}

		if (IS_DEV) {
			setToken(token, userId)
		}

		dispatch(setUserToken(token))
		setToken(token)

		const userInfo = await getUserInfo({ id: userId, token })

		userInfo && dispatch(setUserInfo(userInfo))

		if (!userInfo || !userInfo.role) {
			handleToast({ content: 'Error while loading user data' })
			setLoading(false)
			return
		}

		if (userInfo?.[userInfo.role]?.cometchat_uid) {
			const chatUser = await initChat(userInfo, token)
			chatUser && dispatch(setChatUser(chatUser))
		}

		const [quizAnswers, updateResult] = await Promise.all([
			getQuizAnswers({ token }),
			updateProfile(
				JSON.stringify({
					[userInfo.role]: {
						params: {
							cookieSettings: {
								general: userCookieOptions.general ? 'true' : 'false',
								performance: userCookieOptions.performance ? 'true' : 'false',
								functional: userCookieOptions.functional ? 'true' : 'false',
							},
						},
					},
				}),
				userInfo.id,
				token
			),
		])

		const isQuizCompleted = quizAnswers && quizAnswers.length > 0

		if (!updateResult?.isSuccess) {
			handleToast({ content: 'Error while updating user profile' })
			setLoading(false)
			return
		}

		if (userInfo?.role === 'stylist') {
			setLoading(false)
			navigate(
				isQuizCompleted
					? userInfo?.status === 'active'
						? '/clients'
						: '/stylist-profile'
					: '/quiz'
			)
			return
		}

		if (userInfo?.role === 'client') {
			const stylists = await getBookedStylists({ token })
			const hasBookedStylists = stylists && stylists.length > 0

			if (hasBookedStylists) {
				setLoading(false)
				navigate('/styling-studio')
				return
			}
		}

		if (isSilentUser) {
			setLoading(false)
			navigate(`/rate-plans`, {
				state: {
					stylistId,
				},
			})
			return
		}

		if (isNoRedirect) {
			setLoading(false)
			dispatch(setQuizStep(999))
			navigate('/quiz-results')
			return
		}

		setLoading(false)
		navigate(
			isQuizCompleted
				? userInfo?.status === 'active'
					? '/stylists'
					: '/client-profile'
				: '/quiz'
		)
	}

	const onSendCodeAgainClick = async () => {
		dispatch(setUserCode(''))
		const userCode = await generateAuthCode({
			email,
		})

		if (!userCode?.success) {
			handleToast({ content: 'Error while generating code' })
			return
		}

		if (userCode?.code) {
			dispatch(setUserCode(userCode.code))
			return
		}

		dispatch(setUserCode(''))
	}

	const onCancelClick = () => {
		dispatch(setLoginFormStep('email'))
	}

	const onCodeChange = (value: string) => {
		setCurrentCode(value)
	}

	return {
		email,
		errors,
		handleSubmit,
		isValid,
		onCancelClick,
		onCodeChange,
		onSendCodeAgainClick,
		onSubmitCode,
		setIsValid,
		isLoading,
		userInfo,
		currentCode,
	}
}
