import axios from 'axios'
import { axiosInstance } from '../../../config'
import { GenerateCodeResponse } from '@typings/types'

type Props = {
	email: string
}

export async function generateAuthCode({ email }: Props) {
	try {
		const response = await axiosInstance.post<GenerateCodeResponse>(
			'/api/auth/generate-auth-code',
			JSON.stringify({
				email,
			})
		)

		if (!response.data) {
			throw new Error('No code data')
		}

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
