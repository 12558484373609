import * as assets from './assets'

export const icons = {
	addedItemIcon: assets.AddedItemIcon,
	alternateEmailIcon: assets.EmailIcon,
	appleFilledIcon: assets.AppleFilledIcon,
	archiveIcon: assets.ArchiveIcon,
	arrowCircledIcon: assets.ArrowCircledIcon,
	arrowDownIcon: assets.ArrowDownIcon,
	arrowIcon: assets.ArrowIcon,
	arrowRightnIcon: assets.ArrowRightIcon,
	backArrowIcon: assets.BackArrowIcon,
	bellIcon: assets.BellIcon,
	bellRingingIcon: assets.BellRingingIcon,
	bellRingingFilledIcon: assets.BellRingingFilledIcon,
	cabinetIcon: assets.CabinetIcon,
	calendarIcon: assets.CalendarIcon,
	capsuleIcon: assets.CapsuleIcon,
	cardIcon: assets.CardIcon,
	chainIcon: assets.ChainIcon,
	chatIcon: assets.ChatIcon,
	checkCircleColoredIcon: assets.CheckCircleColoredIcon,
	checkCircleIcon: assets.CheckCircleIcon,
	checkHeartIcon: assets.CheckHeartIcon,
	circleIcon: assets.CircleIcon,
	clientsIcon: assets.ClientsIcon,
	clientsNewIcon: assets.ClientsNewIcon,
	clockIcon: assets.ClockIcon,
	closeIcon: assets.CloseIcon,
	clothAddIcon: assets.ClothAddIcon,
	commentIcon: assets.CommentIcon,
	copyIcon: assets.CopyIcon,
	crossIcon: assets.CrossIcon,
	dislikColoredIcon: assets.DislikColoredIcon,
	dislikeIcon: assets.DislikeIcon,
	editIcon: assets.EditIcon,
	envelopeIcon: assets.EnvelopeIcon,
	eventsIcon: assets.EventsIcon,
	filePlusIcon: assets.FilePlusIcon,
	filtersIcon: assets.FiltersIcon,
	financeIcon: assets.FinanceIcon,
	fullScreenIcon: assets.FullScreenIcon,
	glassesIcon: assets.GlassesIcon,
	hangerAddIcon: assets.HangerAddIcon,
	hangerFullIcon: assets.HangerFullIcon,
	hangerIcon: assets.HangerIcon,
	heartColoredIcon: assets.HeartColoredIcon,
	heartFilledIcon: assets.HeartFilledIcon,
	heartIcon: assets.HeartIcon,
	infoCircleFilledIcon: assets.InfoCircleFilledIcon,
	infoCircleIcon: assets.InfoCircleIcon,
	infoMessageIcon: assets.InfoMessageIcon,
	linkIcon: assets.LinkIcon,
	linkPlusIcon: assets.LinkPlusIcon,
	mailIcon: assets.MailIcon,
	messageIcon: assets.MessageIcon,
	mobileMenuIcon: assets.MobileMenuIcon,
	openExtIcon: assets.OpenExtIcon,
	paymentsIcon: assets.PaymentsIcon,
	peoplesTwoIcon: assets.PeoplesTwo,
	phoneCallIcon: assets.PhoneIcon,
	phoneIcon: assets.PhoneCallIcon,
	pinIcon: assets.PinIcon,
	plusCircle: assets.PlusCircleIcon,
	plusIcon: assets.PlusIcon,
	previewIcon: assets.PreviewIcon,
	rusFlagIcon: assets.RusFlagIcon,
	scrapIcon: assets.ScrapIcon,
	sendIcon: assets.SendIcon,
	shareArrowIcon: assets.ShareArrowIcon,
	starFilledIcon: assets.StarFilledIcon,
	starIcon: assets.StarIcon,
	starsIcon: assets.StarsIcon,
	styleBoardAddIcon: assets.StyleboardAddIcon,
	styleBoardsIcon: assets.StyleBoardsIcon,
	tShirtIcon: assets.TShirtIcon,
	telegramIcon: assets.TelegramIcon,
	threeDotsIcon: assets.ThreeDotsIcon,
	thumbUpIcon: assets.ThumbUpIcon,
	trashIcon: assets.TrashIcon,
	uploadAvatarIcon: assets.UploadAvatarIcon,
	userAdd: assets.UserAddIcon,
	userCheck: assets.UserCheck,
	userIcon: assets.UserIcon,
	vkIcon: assets.VkIcon,
	whatsappIcon: assets.WhatsappIcon,
}
