import { useState } from 'react'

import { useGetCurrency } from '@hooks/use-get-currency'
import { PROD_DOMAIN } from '@typings/constants'
import { AvailableTariff } from '@typings/types'
import { Block } from '@ui/block'
import { Button } from '@ui/button'
import { GrayLine } from '@ui/gray-line/gray-line'
import { handleToast } from '@utils/handle-toast'

import {
	CheckboxStyled,
	InnerCont,
	OldPrice,
	RateDescription,
	RatePrice,
	RateTitle,
	TermsBlock,
	Wrapper,
} from '../rate-plans-styled'

export default function RateBlock({
	tariff,
	onBuyTariff,
}: {
	tariff: AvailableTariff
	onBuyTariff: () => void
}) {
	const currencySymbol = useGetCurrency(tariff.currency_id)
	const [isTermsChecked, setIsTermsChecked] = useState(false)

	const handleBuyTariff = () => {
		if (!isTermsChecked) {
			handleToast({ content: 'Please accept the terms and conditions' })
			return
		}
		onBuyTariff()
	}

	return (
		<Wrapper>
			<InnerCont>
				<RateTitle dangerouslySetInnerHTML={{ __html: tariff.name }} />
				<GrayLine style={{ margin: '29px 0' }} />
				<RateDescription
					dangerouslySetInnerHTML={{ __html: tariff.description }}
				/>
				<Block
					isFlexBlock
					flexDirection="column"
					isContentCentered
					margin="30px 0"
				>
					{tariff.price_old && (
						<OldPrice>
							{currencySymbol}
							{tariff.price_old}
						</OldPrice>
					)}
					<RatePrice>
						{currencySymbol}
						{tariff.price}
					</RatePrice>
				</Block>
				<CheckboxStyled
					checked={isTermsChecked}
					onChange={() => setIsTermsChecked(!isTermsChecked)}
				>
					By ticking this box, you request for us to start providing the
					Services during the cancellation period.
				</CheckboxStyled>
				<TermsBlock>
					More in{' '}
					<a
						href={PROD_DOMAIN + 'docs/Terms_And_Conditions_StyleDyle_Ltd.pdf'}
						target="_blank"
						rel="noreferrer"
					>
						Terms & Conditions.
					</a>
				</TermsBlock>
				<Button
					skin={'dark'}
					style={{ margin: '16px auto 0' }}
					onClick={handleBuyTariff}
				>
					Start
				</Button>
			</InnerCont>
		</Wrapper>
	)
}
