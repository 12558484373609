import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { removeCapsuleItem } from '@api/capsule/remove-capsule-item'
import { useGetCurrency } from '@hooks/use-get-currency'
import { authSelector } from '@store/auth'
import { clothesSelector, setNewCapsuleClothesList } from '@store/clothes'
import { useAppDispatch, useAppSelector } from '@store/store'
import { PROD_DOMAIN } from '@typings/constants'
import { ItemInfo } from '@typings/types'
import { Icon } from '@ui/index'

import {
	Brand,
	Container,
	DeleteFormCapsule,
	ImageCont,
	Price,
	Title,
} from './clothes-item-styled'

type Props = {
	clothItem: ItemInfo
	onClick: (id: number) => void
}

export function ClothesItem({ clothItem, onClick }: Props) {
	const { state } = useLocation()

	const dispatch = useAppDispatch()

	const { token } = useAppSelector(authSelector)
	const { newCapsuleClothesList } = useAppSelector(clothesSelector)

	const userPhotoUrl = PROD_DOMAIN + clothItem.coverPhoto?.urls?.M

	const currency = useGetCurrency(clothItem.price_currency_id)

	const [isInCapsule, setIsInCapsule] = useState(
		newCapsuleClothesList.findIndex((item) => item.id === clothItem.id) !== -1
	)

	useEffect(() => {
		setIsInCapsule(
			newCapsuleClothesList.findIndex((item) => item.id === clothItem.id) !== -1
		)
	}, [newCapsuleClothesList, clothItem.id])

	const handleDeleteItem = async (e: any) => {
		e.stopPropagation()

		if (state?.capsule?.id) {
			const isItemOperationSuccess = await removeCapsuleItem(
				{
					items: [clothItem.id],
				},
				state?.capsule?.id,
				token
			)

			if (!isItemOperationSuccess) return
		}

		const tempList = newCapsuleClothesList.filter(
			(item) => item.id !== clothItem.id
		)

		setIsInCapsule(false)
		dispatch(setNewCapsuleClothesList(tempList))
	}

	return (
		<Container
			onClick={() => onClick(clothItem.id)}
			data-created={clothItem.created_at}
		>
			<DeleteFormCapsule
				skin="dark"
				onClick={handleDeleteItem}
				className={`hidden-button`}
			>
				{isInCapsule && <Icon name="trashIcon" />}{' '}
			</DeleteFormCapsule>
			<ImageCont imageUrl={userPhotoUrl} />
			<Title>{clothItem.title}</Title>
			<Brand>{clothItem.brand}</Brand>
			<Price>{clothItem.price && `${currency} ${clothItem.price}`}</Price>
		</Container>
	)
}
