import { useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { Col, Row } from 'antd'
import moment from 'moment'

import { useAppSelector } from '@store/store'
import { clothesSelector } from '@store/clothes'
import { GalleryButtons } from '@ui/gallery-buttons'
import {
	ColorCircle,
	ColorWrapper,
} from '@pages/capsules/capsules-edit-page/capsules-edit-page-styled'
import { settingsSelector } from '@store/settings'
import { SdNotification } from '@components/sd-notification'
import { GalleryBlock } from './gallery-block/gallery-block'
import {
	BasketAnimateStyle,
	BasketCont,
	ButtonsBlock,
	ButtonStyled,
	CapsuleName,
	LabelDiv,
	ValueDiv,
} from './basket-styled'

type Props = {
	onCapsuleButtonClick: () => void
}

export function Basket({ onCapsuleButtonClick }: Props) {
	const { state } = useLocation()
	const [basketHeight, setBasketHeight] = useState(0)

	const { newCapsuleClothesList } = useAppSelector(clothesSelector)
	const { settings } = useAppSelector(settingsSelector)
	const carouselRef = useRef<any>()

	useEffect(() => {
		setBasketHeight(newCapsuleClothesList.length !== 0 ? 406 : 0)
	}, [newCapsuleClothesList])

	const capsuleName =
		state?.capsule?.title ?? `Capsule name: New capsule ${moment().format('L')}`
	const renderColors = () => {
		const colors = newCapsuleClothesList.reduce<string[]>((prevItem, item) => {
			if (!item?.params?.color) return [...prevItem]

			return [...prevItem, ...item.params.color]
		}, [])

		if (!colors) return null

		const noDoubleColors = colors.filter(
			(element, index, arr) => element && arr.indexOf(element) === index
		)

		return (
			<ColorWrapper>
				{noDoubleColors.map((color, index) => {
					return (
						<ColorCircle
							key={index}
							color={settings.ItemColors?.[color]}
						></ColorCircle>
					)
				})}
			</ColorWrapper>
		)
	}

	const priceValue = newCapsuleClothesList.reduce((prevItem, item) => {
		const price = item?.price
		return prevItem + price
	}, 0)

	const currency = settings?.Currencies?.find(
		(item) => item.id === newCapsuleClothesList[0]?.price_currency_id
	)?.title

	return (
		<>
			<BasketAnimateStyle />
			<AnimateHeight
				id="example-panel"
				duration={500}
				height={basketHeight}
				animateOpacity={true}
			>
				<BasketCont>
					<Row gutter={20}>
						<Col xxl={{ span: 7 }} xl={{ span: 8 }}>
							<CapsuleName>{capsuleName}</CapsuleName>
						</Col>
						<Col xxl={{ span: 12 }} xl={{ span: 12 }}>
							<SdNotification text="You can edit capsule name on the next step. Save capsule to draft if u want leave this page!" />
						</Col>
						<Col xxl={{ span: 5 }} xl={{ span: 4 }}>
							<GalleryButtons
								onNextArrowClick={() => carouselRef.current?.next()}
								onPrevArrowClick={() => carouselRef.current?.previous()}
							/>
						</Col>
					</Row>
					<Row gutter={56} style={{ marginTop: 10 }}>
						<Col xxl={{ span: 6 }} xl={{ span: 7 }}>
							<LabelDiv>Added items</LabelDiv>
							<ValueDiv>{newCapsuleClothesList.length}</ValueDiv>
							<LabelDiv>Colors</LabelDiv>
							<div>{renderColors()}</div>
							<LabelDiv>Overall budget</LabelDiv>
							<ValueDiv>
								<p>{currency}</p>
								<p>{priceValue}</p>
							</ValueDiv>
							<ButtonsBlock>
								<ButtonStyled skin="dark" onClick={onCapsuleButtonClick}>
									{state?.capsule ? 'Update capsule' : 'Create capsule'}
								</ButtonStyled>
								{!state?.capsule && (
									<ButtonStyled skin="dark-link" onClick={onCapsuleButtonClick}>
										Save to draft
									</ButtonStyled>
								)}
							</ButtonsBlock>
						</Col>
						<Col xxl={{ span: 18 }} xl={{ span: 17 }}>
							<GalleryBlock
								carouselRef={carouselRef}
								imageList={newCapsuleClothesList}
							/>
						</Col>
					</Row>
				</BasketCont>
			</AnimateHeight>
		</>
	)
}
