import { MediaLayout } from '@ui/media-layout/media-layout'
import { HeaderDesktop } from './layout/desktop/header-desktop'
import { HeaderMobile } from './layout/mobile/header-mobile'
import { HeaderProps } from './types'

export function Header(props: HeaderProps) {
	return (
		<MediaLayout
			mobileSlot={<HeaderMobile {...props} />}
			desktopSlot={<HeaderDesktop />}
		/>
	)
}
