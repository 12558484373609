import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReduxSettings, SettingsList } from '@typings/types'

const initialState: ReduxSettings = {
	isLoading: false,
	settings: {},
}

const slice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoading = payload
		},
		setSettings: (state, { payload }: PayloadAction<SettingsList>) => {
			state.settings = payload
		},
		setSettingsInitialState: (state) => {
			state.settings = initialState.settings
		},
	},
})

export const settingsReducer = slice.reducer
export const { setIsLoading, setSettings, setSettingsInitialState } =
	slice.actions
