import { colors, fontRoboto } from '@styles/index'
import styled from 'styled-components'

export const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;

	.styledyle-input-paddings {
		${fontRoboto}
		font-style: normal;
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 2.2rem !important;
		color: ${colors.blackTr64};
		padding: 18px 16px;
		height: 56px;
	}

	.ant-picker .ant-picker-input > input {
		font-size: unset;
		line-height: unset;
		color: unset;
		color: ${colors.black};
	}
	.ant-picker .ant-picker-input > input::placeholder {
		color: unset !important;
	}
`

export const TitleBlock = styled.div`
	font-family: 'Tenor Sans';
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px; /* 123.077% */

	color: ${colors.black};

	margin-right: 20px;
`

export const ContentBlock = styled.div`
	display: flex;
	gap: 36px 0;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 36px;
`
