import { ImageItemType, ItemInfo } from '@typings/types'
import { GalleryButtons } from '@ui/gallery-buttons'
import { Icon } from '@ui/icon'
import { Col, Row, Image } from 'antd'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { FullScrIcon } from './gallery-block-styled'
import {
	Brand,
	CarouselWrapper,
	Container,
	HoverWrapper,
	InfoWrapper,
	Price,
	Title,
} from './gallery-block-styled'
import { Link, useParams } from 'react-router-dom'

type Props = {
	carouselRef: any
	items: ItemInfo[]
	imageList: ImageItemType[]
}

export function GalleryBlock({ carouselRef, items, imageList }: Props) {
	const { id: capsuleId } = useParams()

	const ButtonGroup = () => {
		return (
			<>
				<GalleryButtons
					onNextArrowClick={() => carouselRef.current?.next()}
					onPrevArrowClick={() => carouselRef.current?.previous()}
				/>
			</>
		)
	}

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 1601 },
			items: 3,
			partialVisibilityGutter: 40,
		},
		desktop: {
			breakpoint: { max: 1600, min: 991 },
			items: 3,
			partialVisibilityGutter: 60,
		},
		tablet: {
			breakpoint: { max: 990, min: 465 },
			items: 4,
			partialVisibilityGutter: 0,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 2,
			partialVisibilityGutter: 0,
		},
	}

	return (
		<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 48 }} align='middle'>
			<Col span={18} className="row-block-label" >
				Clothing items
			</Col>
			<Col span={6}>
				<ButtonGroup />
			</Col>
			<Col span={24} style={{ marginTop: 58 }}>
				<CarouselWrapper>
					<Carousel
						ref={(el) => (carouselRef.current = el)}
						responsive={responsive}
						arrows={false}
						containerClass="carousel-container"
						itemClass="carousel-item"
						renderButtonGroupOutside={true}
						centerMode={false}
						partialVisible
					>
						{imageList.map((imageItem, index) => (
							<Link
								to={`/clothes/${imageItem.itemId}`}
								key={index}
								state={{
									item: items.find((item) => item.id === imageItem.itemId),
									capsuleId: capsuleId,
								}}
							>
								<Container data-clothid={imageItem.itemId}>
									<HoverWrapper>
										<Image
											width={134}
											src={imageItem.url}
											preview={{
												src: imageItem.previewUrl,
												mask: (
													<FullScrIcon>
														<Icon color="white" name="fullScreenIcon" />
													</FullScrIcon>
												),
											}}
										/>
									</HoverWrapper>
									<InfoWrapper>
										<Title>{imageItem.title}</Title>
										<Brand>{imageItem.brand}</Brand>
										<Price>
											{imageItem.price &&
												`${imageItem.currency} ${imageItem.price}`}
										</Price>
									</InfoWrapper>
								</Container>
							</Link>
						))}
					</Carousel>
				</CarouselWrapper>
			</Col>
		</Row>
	)
}
