import { colors } from '@styles/colors'
import { fontRoboto, regular13 } from '@styles/typography'
import styled from 'styled-components'

export const CookieBlock = styled.div`
	${fontRoboto}
	${regular13}
	color: ${colors.blackTr64};

	& a {
		color: ${colors.mainThemeColor};

		&:hover {
			text-decoration: underline;
		}
	}
`
