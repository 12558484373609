import { useState } from 'react'
import { authSelector } from '@store/auth'
import { useAppSelector } from '@store/store'
import { Col, Row } from 'antd'
import { HeaderText, PageWrapper, TipText } from './capsule-guide-styled'
import { Button, Spinner } from '@ui/index'
import { useNavigate } from 'react-router-dom'
import CapsuleGuidePic from '../../assets/capsule-guide.png'

export function CapsuleGuide() {
	const navigate = useNavigate()
	const { token } = useAppSelector(authSelector)

	// TODO: Move this logic to store and set types
	const [isLoading, setIsLoading] = useState(false)

	if (isLoading) {
		return <Spinner color="#FF5656" />
	}

	return (
		<>
			<Row justify={'center'} style={{ width: '100%' }}>
				<Col offset={8} span={8}>
					<HeaderText>Watch our capsule creation guide</HeaderText>
					<img src={CapsuleGuidePic} alt="quiz-end" />
				</Col>
				<Col offset={4} span={4}>
					<TipText>
						Style Dyle makes capsule creation straightforward, even for
						first-timers
					</TipText>
				</Col>
			</Row>

			<Row style={{ marginTop: 32, marginBottom: 32, width: '100%' }}>
				<Col offset={5} span={6}>
					<Button
						skin="dark"
						onClick={() => {
							navigate(`/clothes`)
						}}
					>
						Try now
					</Button>
				</Col>
				<Col offset={2} span={6}>
					<Button
						skin="dark"
						onClick={() => {
							navigate(`/stylist-profile`)
						}}
					>
						Skip
					</Button>
				</Col>
			</Row>
		</>
	)
}
