import styled from 'styled-components'
import { Button } from '@ui/button'

export const ArrowPrev = styled(Button)`
	margin-right: 14px;
	padding: 8px;
	display: inline-block;

	> div {
		margin-right: 0;
		transform: rotate(90deg);
	}
`

export const ArrowNext = styled(Button)`
	padding: 8px;
	display: inline-block;

	> div {
		margin-right: 0;
		transform: rotate(270deg);
	}
`
