import { Swap } from '..'
import {
	ButtonWrapper,
	CounterWrapper,
	LeftIconWrapper,
	RightIconWrapper,
} from './button-styled'
import { ButtonProps } from './types'

export function Button({
	children,
	leftIcon,
	rightIcon,
	isSubmit,
	skin = 'dark',
	size = 'xl',
	isFitContent = false,
	isDisabled,
	isActive,
	onClick,
	counter,
	color,
	isLoading,
	...rest
}: ButtonProps) {
	return (
		<ButtonWrapper
			isActive={isActive}
			type={isSubmit ? 'submit' : 'button'}
			skin={skin}
			size={size}
			isFitContent={isFitContent}
			isDisabled={isDisabled}
			onClick={isDisabled ? () => {} : onClick}
			{...rest}
		>
			<Swap is={isLoading} isSlot={'Loading...'}>
				{leftIcon && (
					<LeftIconWrapper hasChildren={!!children}>{leftIcon}</LeftIconWrapper>
				)}
				{children ?? null}
				{!!counter && <CounterWrapper>{counter}</CounterWrapper>}
				{rightIcon && (
					<RightIconWrapper hasChildren={!!children}>
						{rightIcon}
					</RightIconWrapper>
				)}
			</Swap>
		</ButtonWrapper>
	)
}
