import styled, { css } from 'styled-components'

export const PlaceHolder = styled.span`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;

	color: rgba(0, 0, 0, 0.48);

	position: absolute;
	top: -9px;
	left: 10px;
	z-index: 10;

	background-color: #ffffff;
	width: fit-content;
	padding: 0 3px;
`

export const SelectWrapper = styled.div<{ width?: number }>`
	position: relative;

	${({ width }) =>
		width &&
		css`
			width: ${width}px;
		`}

	.ant-select {
		width: 100%;
		height: 100%;

		.ant-select-selection-placeholder {
			display: none;
		}

		.ant-select-selector {
			height: 56px;
			padding: 16px;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 1.6rem;
			line-height: 2.2rem;
			color: #000000;
		}

		.ant-select-selection-search-input {
		}

		.ant-select-single .ant-select-selector .ant-select-selection-item {
			line-height: 2.2rem;
		}
		.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
			line-height: unset;
		}

		.ant-select-arrow {
			color: #000;
		}

		.ant-select-selection-overflow-item {
			&:nth-child(-n + 2) > span {
				&::before {
					content: '';
				}
			}

			& > span {
				background: none;
				border: none;
				margin: 0;
				padding: 0;

				.ant-select-selection-item-content {
					margin-right: 0;
				}

				.ant-select-selection-item-remove {
					display: none;
				}

				&::before {
					content: ',';
					margin-right: 3px;
				}
			}
		}
	}
`
