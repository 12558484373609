import axios from 'axios'
import { axiosInstance } from '../../../config'
import { AuthCheckUserResponse } from '@typings/types'

type Props = {
	email: string
}

export async function checkExistingUser({ email }: Props) {
	try {
		const response = await axiosInstance.post<AuthCheckUserResponse>(
			'/api/user/check',
			JSON.stringify({
				email,
			}),
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			}
		)

		return !!response.data.success
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
