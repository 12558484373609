import { Block, Icon, Input } from '@ui/index'
import { CustomSelect } from '@ui/select/select'
import { Col, Row } from 'antd'
import dayjs from 'dayjs'
import {
	ContentBlock,
	FormWrapper,
	TitleBlock,
} from './personal-data-form-styled'
import { usePersonalDataForm } from './use-personal-data-form'
import { DatePickerSD } from '@ui/date-picker'

export function PersonalDataForm() {
	const {
		cities,
		client,
		countries,
		genderOptions,
		handleCountrySelect,
		handleSetUserInfo,
		handleSetUserParams,
		handleSetUserPhone,
		isCityDisabled,
		isCountriesLoading,
		isSettingsLoading,
		register,
		setValue,
		watch,
		userInfo,
	} = usePersonalDataForm()

	return (
		<Col flex="0 1 33%">
			<FormWrapper>
				<TitleBlock>Personal details</TitleBlock>
				<ContentBlock>
					<Row
						gutter={[8, 36]}
						style={{ width: '100%' }}
						justify="space-between"
					>
						<Input
							label="firstName"
							inputFieldSlot={
								<input
									{...register('firstName')}
									onBlur={(e: any) => {
										if (e.target.value === '') {
											handleSetUserInfo('first_name', client.first_name)
											setValue('firstName', client.first_name)
											return
										}

										handleSetUserInfo('first_name', e.target.value)
									}}
									type="text"
									defaultValue={client.first_name}
								/>
							}
							errorText={'errorText'} //errors.firstName && errors.firstName.message
							width="100%"
							placeholder="Name"
							hasValue={!!watch('firstName')}
						/>
						<Input
							label="lastName"
							inputFieldSlot={
								<input
									{...register('lastName')}
									onBlur={(e: any) => {
										handleSetUserInfo('last_name', e.target.value)
									}}
									type="text"
									defaultValue={client.last_name}
								/>
							}
							errorText={'errorText'} //{errors.lastName && errors.lastName.message}
							width="100%"
							placeholder="Surname"
							hasValue={!!watch('lastName')}
						/>
						<CustomSelect
							isDisabled={isSettingsLoading}
							placeholder="Gender"
							defaultValue={client.sex}
							width={'48%'}
							onChange={(value: string) => handleSetUserInfo('sex', value)}
							options={genderOptions}
						/>
						<Block style={{ width: '48%' }}>
							<DatePickerSD
								placeholder="Date of birth"
								defaultValue={client.params?.birthday?.[0]}
								onChange={(dateString) =>
									dateString &&
									handleSetUserParams('birthday', dateString.toString())
								}
								suffixIcon={<Icon name="calendarIcon" />}
							/>
						</Block>
						<CustomSelect
							isDisabled={isCountriesLoading}
							placeholder="Country"
							defaultValue={client.params?.country}
							searchEnabled
							width={'48%'}
							onChange={handleCountrySelect}
							options={countries}
						/>
						<CustomSelect
							placeholder="City"
							defaultValue={client.params?.city}
							searchEnabled
							width={'48%'}
							onChange={(value: string) => handleSetUserParams('city', value)}
							isDisabled={isCountriesLoading || isCityDisabled}
							options={cities}
						/>
						<Input
							label="Client height"
							inputFieldSlot={
								<input
									{...register('clientHeight')}
									onBlur={(e: any) => {
										handleSetUserParams('clientHeight', e.target.value)
									}}
									type="text"
									defaultValue={client.params?.clientHeight}
								/>
							}
							isError={!client.params?.clientHeight}
							placeholder="Height"
							hasValue={!!watch('clientHeight')}
						/>
						<Input
							label="phone"
							inputFieldSlot={
								<input
									{...register('phone')}
									onBlur={(e: any) => {
										handleSetUserPhone(e.target.value)
									}}
									type="text"
									defaultValue={userInfo.phone}
								/>
							}
							width="100%"
							isError={!userInfo.phone}
							placeholder="Phone number"
							hasValue={!!watch('phone')}
						/>
						<Input
							label="email"
							inputFieldSlot={
								<input
									{...register('email')}
									onBlur={(e: any) => {
										handleSetUserInfo('email', e.target.value)
									}}
									type="text"
									defaultValue={userInfo.email}
									disabled
								/>
							}
							isError={!userInfo.email}
							width="100%"
							placeholder="Email"
							hasValue={true}
						/>
					</Row>
				</ContentBlock>
			</FormWrapper>
		</Col>
	)
}
