import { Button } from '@ui/button'
import { Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
	BlockWrapper,
	GapLineCol,
	TextBlockCol,
	InterviewMsg,
	Red,
	ImageBlock,
} from './wait-approve-screen-styled'

type Props = {
	name: string
}

export function WaitApproveScreen(props: Props) {
	const navigate = useNavigate()

	return (
		<BlockWrapper>
			<Col span={4}>
				<GapLineCol />
			</Col>
			<Col span={6}>
				<TextBlockCol>
					Thank you, {props.name}.<br /> We have received your application
				</TextBlockCol>
				<InterviewMsg>
					We will contact you and conduct a brief interview <br />
					<br />
					Now you can take a quiz to start match with the clients after the
					interview
				</InterviewMsg>

				<Button
					isSubmit
					skin="dark"
					onClick={() => {
						navigate('/quiz')
					}}
				>
					Take a quiz
				</Button>
			</Col>
			<Col span={14}>
				<ImageBlock />
			</Col>
		</BlockWrapper>
	)
}
