import Avatar from '@components/avatar/avatar'
import { ClientBlock } from './avatar-client-block-styled'

export const AvatarClientBlock = ({
	clientName,
	imgUrl,
}: {
	clientName: string
	imgUrl?: string
}) => (
	<ClientBlock title={clientName}>
		<Avatar imageUrl={imgUrl ?? ''} size={24} /> {clientName}
	</ClientBlock>
)
