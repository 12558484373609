import styled from 'styled-components'
import { colors } from '../../../../styles/colors'
import { screenSize } from '../../../../styles/screen-sizes'
import { Block } from '../../../../UI/block'
import LoginBG from '../../../../assets/loginBG.png'

export const PageWrapper = styled(Block)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background: url(${LoginBG}),
		linear-gradient(0deg, rgba(197, 189, 252, 0.12), rgba(197, 189, 252, 0.12)),
		#ffffff;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	height: 100%;
`

export const ContentBlockWrapper = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;

	max-width: 594px;
	padding: 72px 56px;
	margin-bottom: 75px;

	background: ${colors.bgColor};
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 20px;

	& > a {
		color: ${colors.black};
		text-decoration: none;
	}

	color: ${colors.black};
`

export const StyledPolicyP = styled.p`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	width: 326px;
	text-align: center;
	color: ${colors.darkGray};
	margin-block-end: 56px;

	@media (${screenSize.maxMobile}) {
		text-align: center;
		margin: 10px auto 0;
	}
`
