import { icons } from './icons'
import { IconNames } from './types'
import { Container } from './icon-styled'

type IconProps = {
	name: IconNames
	size?: number | string
	color?: string
}

export function Icon({ name, color = 'inherit', size = 24 }: IconProps) {
	const IconComponent = icons[name]

	return (
		<Container color={color} size={size}>
			<IconComponent width={size} height={size} />
		</Container>
	)
}
