import { Col, Row } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getStylistsById } from '@api/client/get-stylists-by-id'
import { getStyleboardInfo } from '@api/styleboard/get-styleboard'
import { useInitChat } from '@hooks/use-init-chat'
import { authSelector } from '@store/auth'
import {
	chatSelector,
	setChatId,
	setChatUser,
	setIsChatOpen,
} from '@store/chat'
import { useAppDispatch, useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { colors } from '@styles/colors'
import { StylistInfo } from '@typings/types'
import { Block, Button, SiteLogo, Spinner, Swap } from '@ui/index'
import { handleToast } from '@utils/handle-toast'

import {
	AvatarEmpty,
	BgCont,
	ClientAvatar,
	InterviewMsg,
	SDLogo,
	StylistAvatar,
	TextBlockCol,
} from './styleboard-accepted-page-styled'

export function StyleBoardAcceptedPage() {
	const dispatch = useAppDispatch()

	const { token } = useAppSelector(authSelector)
	const { userInfo } = useAppSelector(userSelector)
	const { chatUser } = useAppSelector(chatSelector)
	const { initChat } = useInitChat()

	const [stylistInfo, setStylistInfo] = useState<StylistInfo>()
	const [isLoading, setIsLoading] = useState(false)
	const { id: styleboardId } = useParams()

	const loadStylistInfo = useCallback(async () => {
		setIsLoading(true)
		if (!styleboardId) {
			handleToast({ content: 'Styleboard ID is not found' })
			setIsLoading(false)
			return
		}

		const styleboardInfo = await getStyleboardInfo({ token, styleboardId })

		if (!styleboardInfo) {
			handleToast({ content: 'Failed to load styleboard info' })
			setIsLoading(false)
			return
		}

		const data = await getStylistsById({
			token,
			stylistIds: [styleboardInfo.user_id],
		})

		if (!data) {
			handleToast({ content: 'Failed to load stylist info' })
			setIsLoading(false)
			return
		}

		setStylistInfo(data[0])

		setIsLoading(false)
	}, [token, styleboardId])

	const handleChatButtonClick = async () => {
		if (!chatUser) {
			const result = await initChat(userInfo, token)
			result && dispatch(setChatUser(result))
		}

		dispatch(setIsChatOpen(true))
		dispatch(setChatId(stylistInfo?.cometchat_uid))
	}

	useEffect(() => {
		loadStylistInfo()
	}, [loadStylistInfo])

	if (isLoading)
		return (
			<Block isFullWidth style={{ position: 'relative', overflow: 'hidden' }}>
				<Spinner />
			</Block>
		)

	return (
		<Block isFullWidth style={{ position: 'relative', overflow: 'hidden' }}>
			<BgCont></BgCont>
			<Row gutter={48} style={{ margin: '80px 48px 48px' }}>
				<Col xxl={{ span: 4 }} xl={{ span: 2 }}></Col>
				<Col xxl={{ span: 6 }} xl={{ span: 8 }}>
					<TextBlockCol>
						Amazing! <br />
						You are synced up with your stylist
					</TextBlockCol>
					<InterviewMsg>
						Welcome to the next stage
						<br />
						<br /> Let your stylist know what you want for the capsule
					</InterviewMsg>
					<Button
						isSubmit
						skin="dark"
						onClick={handleChatButtonClick}
						style={{ padding: '16px 36px', minWidth: 290 }}
						isFitContent
					>
						Chat
					</Button>
				</Col>
				<Col xxl={{ span: 14 }} xl={{ span: 14 }} style={{ marginTop: 100 }}>
					<Row style={{ height: '100%' }} align="middle">
						<Col
							span={11}
							style={{
								display: 'flex',
								justifyContent: 'end',
								position: 'relative',
							}}
						>
							<Swap
								is={!userInfo.client?.params.photo?.[0].urls.L}
								isSlot={
									<AvatarEmpty>
										<SiteLogo
											color={colors.blackTr16}
											size={100}
											isWithoutText
										/>
									</AvatarEmpty>
								}
							>
								<ClientAvatar
									imageUrl={userInfo.client?.params.photo?.[0].urls.L ?? ''}
								/>
							</Swap>
							<SDLogo>
								<SiteLogo color="white" size={32} />
							</SDLogo>
						</Col>
						<Col
							span={11}
							style={{
								display: 'flex',
								justifyContent: 'start',
								position: 'relative',
							}}
						>
							<Swap
								is={!stylistInfo?.params.photo?.[0].urls.L}
								isSlot={
									<AvatarEmpty>
										<SiteLogo
											color={colors.blackTr16}
											size={100}
											isWithoutText
										/>
									</AvatarEmpty>
								}
							>
								<StylistAvatar
									imageUrl={stylistInfo?.params.photo?.[0].urls.L ?? ''}
								/>
							</Swap>
						</Col>
					</Row>
				</Col>
			</Row>
		</Block>
	)
}
