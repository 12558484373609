// import { LikeButton } from './like-button/like-button'
import { BlankContainer } from '@components/client-styleboard-preview/client-styleboard-preview-styled'
import { PhotoWrapper } from './photo-item-styled'
import { SiteLogo } from '@ui/site-logo/site-logo'
import { colors } from '@styles/colors'

type Props = {
	imageUrl: string
}

// TODO: Implement like button
export function PhotoItem({ imageUrl }: Props) {
	return (
		<PhotoWrapper imageUrl={imageUrl}>
			{!imageUrl && (
				<BlankContainer>
					<SiteLogo color={colors.blackTr16} size={100} isWithoutText />
				</BlankContainer>
			)}
		</PhotoWrapper>
	)
}
