import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { deleteImage } from '@api/delete-image'
import { cloneStyleboard } from '@api/styleboard/clone-styleboard'
import { createStyleBoard } from '@api/styleboard/create-styleboard'
import { getAvailableClients } from '@api/styleboard/get-available-clients'
import { getStyleboardInfo } from '@api/styleboard/get-styleboard'
import { publishStyleboard } from '@api/styleboard/publish-styleboard'
import { updateStyleboard } from '@api/styleboard/update-styleboard'
import { uploadImage } from '@api/upload-image'
import { useUpdateStyleboardInfo } from '@hooks/api/use-update-styleboard-info'
import { authSelector } from '@store/auth'
import { useAppDispatch, useAppSelector } from '@store/index'
import {
	setInitialStyleboard,
	setIsStyleboardLoading,
	setStyleboard,
	styleboardsSelector,
} from '@store/styleboards'
import { PROD_DOMAIN } from '@typings/constants'
import {
	ClientInfo,
	ImageItemType,
	NavigationButtonInfo,
	ShowPageType,
	StyleboardInfo,
	UploadImageRequest,
} from '@typings/types'
import { handleToast } from '@utils/handle-toast'

type FormValues = {
	title: string
	clientName: string
}

const MINIMUM_PHOTOS_COUNT = 8

export function useStyleboardsNewPage() {
	const dispatch = useAppDispatch()
	const { state, pathname } = useLocation()
	const navigate = useNavigate()
	const { id } = useParams()

	const { token } = useAppSelector(authSelector)
	const { isStyleboardLoading: isLoading, styleboard } =
		useAppSelector(styleboardsSelector)

	const [isNewPhotoLoading, setNewPhotoLoading] = useState(false)
	const [imageItems, setImageItems] = useState<ImageItemType[]>([])
	const [isSelectedClientError, setIsSelectedClientError] = useState(false)

	const {
		register,
		watch,
		formState: { errors },
		setError,
		setValue,
		clearErrors,
	} = useForm<FormValues>()

	const [clientListToShow, setClientListToShow] = useState<
		{
			label: string
			value: string
		}[]
	>([])
	const [clientLabel, setClientLabel] = useState<string[]>([])
	const [clientAvatarUrl, setClientAvatarUrl] = useState('')
	const [clientsToShare, setClientsToShare] = useState<ClientInfo[]>()
	const [isClientLoading, setIsClientLoading] = useState(false)

	const [styleboardId, setStyleboardId] = useState(id || '')

	const { handleSetStyleboardInfo } = useUpdateStyleboardInfo()
	const clientId = useMemo(() => state?.clientId || undefined, [state])

	const loadClients = useCallback(
		async (styleboardInfo?: StyleboardInfo) => {
			const clients = await getAvailableClients({ token })

			if (!clients?.length) {
				dispatch(setIsStyleboardLoading(false))
				handleToast({ content: 'No clients found' })
				return
			}

			const filteredClients = clients
				.filter(
					(item) => item.fullName !== styleboardInfo?.linkedClient?.fullName
				)
				.map((item) => {
					const clientName = [item.first_name, item.last_name]
						.filter(Boolean)
						.join(' ')

					return {
						label: clientName ?? item.id.toString(),
						value: item.id.toString(),
					}
				})
			setClientsToShare(clients)
			setClientListToShow(filteredClients)

			return filteredClients
		},
		[dispatch, token]
	)

	const loadStyleboardInfo = useCallback(
		async (newStyleboardId: number) => {
			dispatch(setIsStyleboardLoading(true))
			const data = await getStyleboardInfo({
				token,
				styleboardId: newStyleboardId?.toString(),
			})

			if (data?.linkedClient) {
				const currentClient = data.linkedClient
					? [data.linkedClient?.fullName || data.linkedClient?.id.toString()]
					: []
				setClientLabel(currentClient)
				setClientAvatarUrl(data.linkedClient.params.photo?.[0].urls.S || '')
			} else {
				setClientLabel([])
			}

			await loadClients(data)

			if (data) {
				const coverPhotoId = data.coverPhoto?.id
				const photos = data.photos || []
				const imageItems = photos.map((photo) => ({
					id: photo.id,
					url: PROD_DOMAIN + photo.urls.M,
					previewUrl: PROD_DOMAIN + photo.urls.L,
					isCover: coverPhotoId === photo.id,
					isLiked: data.params.likes?.includes(photo.id.toString()),
					isDisliked: data.params.dislikes?.includes(photo.id.toString()),
				}))
				setImageItems(imageItems)
				setValue('title', data.title)
				dispatch(setStyleboard(data))
				dispatch(setIsStyleboardLoading(false))
				return
			}
			dispatch(setIsStyleboardLoading(false))
			navigate('/styleboards')
		},
		[dispatch, loadClients, navigate, setValue, token]
	)

	const handleLinkClient = useCallback(
		async (
			value: any,
			clients?: {
				label: string
				value: string
			}[]
		) => {
			if (!value.length) return
			clearErrors()
			setIsSelectedClientError(false)

			setIsClientLoading(true)
			const newClient = (clients ?? clientListToShow).find(
				(item) => item.value === value[value.length - 1]
			)
			if (!newClient?.value) {
				handleToast({ content: 'Client not found' })
				setIsClientLoading(false)
				return
			}

			if (styleboardId === 'new') {
				const newFields = JSON.stringify({
					client_id: newClient?.value,
				})

				const resultId = await createStyleBoard(token, newFields)

				if (!resultId) {
					handleToast({ content: 'Something went wrong' })
					return
				}

				setStyleboardId(resultId.toString())
				setClientLabel([newClient?.label])
				setClientAvatarUrl(
					clientsToShare?.find(
						(item) => item.id.toString() === newClient?.value
					)?.params.photo?.[0].urls.S || ''
				)
				const filteredClients =
					clientsToShare?.filter((item) => item.fullName !== newClient.label) ||
					[]
				setClientListToShow(
					filteredClients.map((item) => {
						const clientName = item.fullName

						return {
							label: clientName ?? item.id.toString(),
							value: item.id.toString(),
						}
					})
				)

				setIsClientLoading(false)
				navigate(`/styleboards/${resultId}/edit`)
				loadStyleboardInfo(resultId)
				return
			}

			const result = await handleSetStyleboardInfo(
				+styleboardId,
				'client_id',
				newClient?.value
			)
			if (!!result) {
				setClientLabel([newClient?.label])
				setClientAvatarUrl(
					clientsToShare?.find(
						(item) => item.id.toString() === newClient?.value
					)?.params.photo?.[0].urls.S || ''
				)
				const filteredClients =
					clientsToShare?.filter((item) => item.fullName !== newClient.label) ||
					[]
				setClientListToShow(
					filteredClients.map((item) => {
						const clientName = item.fullName

						return {
							label: clientName ?? item.id.toString(),
							value: item.id.toString(),
						}
					})
				)
			}

			setIsClientLoading(false)
		},
		[
			clearErrors,
			clientListToShow,
			clientsToShare,
			handleSetStyleboardInfo,
			loadStyleboardInfo,
			navigate,
			styleboardId,
			token,
		]
	)

	const loadNewStyleboardInfo = useCallback(async () => {
		dispatch(setIsStyleboardLoading(true))

		setClientLabel([])
		setImageItems([])
		dispatch(setInitialStyleboard())
		const clients = await loadClients()

		if (clients && clientId && !clientLabel.length) {
			await handleLinkClient([`${clientId}`], clients)
		}

		dispatch(setIsStyleboardLoading(false))
	}, [clientId, clientLabel.length, dispatch, handleLinkClient, loadClients])

	useEffect(() => {
		if (!styleboardId || styleboardId === 'new') {
			loadNewStyleboardInfo()
			return
		}
		loadStyleboardInfo(+styleboardId)
	}, [])

	const handleAddNewPhoto = async (e: any) => {
		const files = e.fileList
		if (!e.event || !files) {
			return
		}

		setNewPhotoLoading(true)

		let newStyleboardId: number | undefined
		if (styleboardId === 'new') {
			const newFields = JSON.stringify({
				title: `New styleboard`,
			})
			newStyleboardId = await createStyleBoard(token, newFields)

			if (!newStyleboardId) {
				handleToast({ content: 'Error creating new styleboard' })
				setNewPhotoLoading(false)
				return
			}

			setStyleboardId(newStyleboardId.toString())
			navigate(`/styleboards/${newStyleboardId}/edit`)
		}

		const requestData: UploadImageRequest = {
			filename: files[files.length - 1].originFileObj,
			relation_id: newStyleboardId?.toString() || styleboardId.toString(),
			relation_type: 'styleboard',
		}

		const newImage = await uploadImage({
			requestData,
			token,
		})

		if (!newImage) {
			handleToast({ content: 'Error while uploading image' })
			setNewPhotoLoading(false)
			return
		}

		if (newStyleboardId) {
			loadStyleboardInfo(newStyleboardId)
			setNewPhotoLoading(false)
			return
		}

		const styleboardData = await getStyleboardInfo({ styleboardId, token })
		const imageItems = styleboardData?.photos
			? styleboardData.photos.map((image) => ({
					id: image.id,
					url: PROD_DOMAIN + image.urls.M,
					previewUrl: PROD_DOMAIN + image.urls.L,
					isCover: image.id === styleboardData.coverPhoto?.id,
					isLiked: styleboardData.params.likes?.includes(image.id.toString()),
					isDisliked: styleboardData.params.dislikes?.includes(
						image.id.toString()
					),
			  }))
			: []
		!!imageItems.length && setImageItems(imageItems)

		setNewPhotoLoading(false)
	}

	const handleSetCoverClick = async (imageId: number) => {
		const coverSet = await updateStyleboard(
			JSON.stringify({
				params: {
					coverPhotoId: imageId,
				},
			}),
			+styleboardId,
			token
		)

		if (coverSet) {
			const clearImageItems = imageItems.map((item) => {
				item.isCover = item.id === imageId

				return item
			})

			setImageItems([...clearImageItems])
		}
	}

	const handleDeleteClick = async (imageId: number) => {
		const isDeleted = await deleteImage({ imageId, token })

		if (isDeleted) {
			const clearImageItems = imageItems.filter((item) => item.id !== imageId)

			setImageItems([...clearImageItems])
		}
	}

	const handleChangeTitle = async (e: any) => {
		clearErrors()
		if (styleboardId === 'new') {
			const resultId = await createStyleBoard(token)

			if (!resultId) {
				handleToast({ content: 'Something went wrong' })
				return
			}

			setStyleboardId(resultId.toString())

			await handleSetStyleboardInfo(resultId, 'title', e.target.value)

			navigate(`/styleboards/${resultId}/edit`)

			return
		}

		await handleSetStyleboardInfo(+styleboardId, 'title', e.target.value)
	}

	const handleUnlinkClient = useCallback(async () => {
		setIsClientLoading(true)

		const result = await handleSetStyleboardInfo(
			+styleboardId,
			'client_id',
			null
		)

		if (!result) {
			handleToast({ content: 'Error while unsetting client' })
			setIsClientLoading(false)
			return
		}

		setClientLabel([])
		setClientAvatarUrl('')
		setClientListToShow(
			clientsToShare?.map((item) => {
				const clientName = item.fullName

				return {
					label: clientName ?? item.id.toString(),
					value: item.id.toString(),
				}
			}) || []
		)

		setIsClientLoading(false)
	}, [clientsToShare, handleSetStyleboardInfo, styleboardId])

	const handlePublish = async () => {
		clearErrors()
		if (watch('title') === '') {
			setError('title', { type: 'manual', message: 'Please fill in title' })
			return
		}

		if (!clientLabel) {
			handleToast({ content: 'Please select a client to publish' })
			setIsSelectedClientError(true)
			return
		}

		if (imageItems.length < MINIMUM_PHOTOS_COUNT) {
			handleToast({ content: `Please upload ${MINIMUM_PHOTOS_COUNT} photos` })
			return
		}

		const result = await publishStyleboard({
			token,
			styleboardId: +styleboardId,
		})

		if (result?.success) {
			navigate('/styleboards')
		}
	}

	const handleSaveAsDraft = async () => {
		clearErrors()
		if (watch('title') === '') {
			setError('title', { type: 'manual', message: 'Please fill in title' })
			return
		}

		navigate(`/styleboards/${styleboardId}`)
	}

	const currentPage: ShowPageType =
		pathname === `/styleboards/${styleboardId}/edit` ? 'edit' : 'preview'

	const handlePreviewClick = useCallback(() => {
		if (watch('title') === '') {
			setError('title', { type: 'manual', message: 'Please fill in title' })
			return
		}

		navigate(`/styleboards/${styleboardId}`, {
			state: {
				styleboardId: styleboardId,
			},
		})
	}, [navigate, setError, styleboardId, watch])

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'Preview',
				iconName: 'previewIcon',
				showListType: 'preview',
				handleClick: handlePreviewClick,
			},
			{
				title: 'Edit',
				iconName: 'editIcon',
				showListType: 'edit',
				handleClick: () => {},
			},
		],
		[handlePreviewClick]
	)

	const handleDuplicateStyleboard = async () => {
		dispatch(setIsStyleboardLoading(true))

		const duplicate = await cloneStyleboard({
			styleboardId: +styleboardId,
			token,
		})

		if (!duplicate?.success) {
			handleToast({ content: 'Error while duplicating styleboard' })
			dispatch(setIsStyleboardLoading(false))
			return
		}

		setStyleboardId(duplicate.id.toString())
		navigate(`/styleboards/${duplicate.id}/edit`, {
			state: {
				styleboardId: duplicate.id,
			},
		})
		await loadStyleboardInfo(duplicate.id)
	}

	return {
		isClientLoading,
		clientLabel,
		clientAvatarUrl,
		clientListToShow,
		currentPage,
		errors,
		handleAddNewPhoto,
		handleChangeTitle,
		handleDeleteClick,
		handlePublish,
		handleSaveAsDraft,
		handleLinkClient,
		handleUnlinkClient,
		handleSetCoverClick,
		handleDuplicateStyleboard,
		imageItems,
		isLoading,
		isNewPhotoLoading,
		isSelectedClientError,
		navigationButtons,
		register,
		styleboard,
		watch,
	}
}
