import { useNavigate } from 'react-router-dom'
import { ReactNode } from 'react'
import { Col, Popover, Row } from 'antd'

import { BookStatus, StyleboardInfo, StylistInfo } from '@typings/types'
import { PROD_DOMAIN } from '@typings/constants'
import {
	SectionBlock,
	StyleboardContainer,
	ColStyled,
	EmptyCover,
	GlobalStyle,
} from './client-styleboard-preview-styled'

import {
	Block,
	Icon,
	ButtonLinkTo,
	Swap,
	Button,
	BlankPlaceholder,
} from '@ui/index'
import { colors } from '@styles/index'
import { BlockLabel } from '@components/client-capsules/client-capsules-styled'
import { useAppSelector } from '@store/index'
import { userSelector } from '@store/user'
import EmptySlotBgImg from '@assets/empty-styleboard-bg.png'

type Props = {
	styleBoard: StyleboardInfo | undefined
	stylistInfo: StylistInfo | undefined
	isPreview?: boolean
	dealStatus?: BookStatus
	clientId?: number
}

const emptyBlock = (): ReactNode => {
	return (
		<>
			<Col span={24} flex="0 1 auto">
				<EmptyCover>
					<img src={EmptySlotBgImg} width="100%" alt="Ghost styleboard" />
				</EmptyCover>
			</Col>
			<Col span={24}>
				<Button skin="grey-link" style={{ padding: '14px' }}>
					Ghost styleboard
				</Button>
			</Col>
		</>
	)
}

export function ClientStyleBoardPreview({
	styleBoard,
	stylistInfo,
	isPreview,
	dealStatus,
	clientId,
}: Props) {
	const { userInfo } = useAppSelector(userSelector)
	const navigate = useNavigate()

	return (
		<SectionBlock>
			<GlobalStyle />
			<StyleboardContainer>
				<Row>
					<Col flex="1 1 auto">
						<BlockLabel>Styleboard</BlockLabel>
						<Popover
							placement="rightTop"
							content={
								<div className="styledyle-content">
									A styleboard is a collage of inspirational pictures of
									outfits, ensuring you and your stylist are on the same page.
								</div>
							}
							arrow={false}
							trigger="click"
						>
							<span style={{ cursor: 'pointer' }}>
								<Icon color={colors.black} name="infoCircleFilledIcon" />
							</span>
						</Popover>
					</Col>
				</Row>
				<Row style={{ marginTop: '24px' }} gutter={[0, 24]}>
					<Swap
						is={dealStatus === 'create_styleboard' && !styleBoard}
						isSlot={
							<Col span={24}>
								<ButtonLinkTo
									onClick={() => {
										navigate('/styleboards/new/edit', {
											state: {
												styleboardId: 'new',
												clientId,
											},
										})
									}}
									minHeight="620px"
									title="Add styleboard"
									icon={<Icon name="styleBoardAddIcon" size={72} />}
									height="620px"
									bgColor={'transparent'}
								/>
							</Col>
						}
					>
						<Swap is={!styleBoard} isSlot={emptyBlock()}>
							<Block
								isFullWidth
								isClickable
								onClick={() => {
									navigate(`/styleboards/${styleBoard?.id}`)
								}}
							>
								<ColStyled span={24}>
									<Swap
										is={!styleBoard?.coverPhoto?.urls.M}
										isSlot={<BlankPlaceholder color={colors.blackTr24} />}
									>
										<img
											src={PROD_DOMAIN + styleBoard?.coverPhoto?.urls.M}
											alt=""
											width="100%"
										/>
									</Swap>
								</ColStyled>
								{/*
							<Col span={24}>
								<StyleboardTitle>{styleBoard?.title}</StyleboardTitle>
							</Col>
						*/}
								<Col span={24}>
									<Button skin="grey-link">
										{userInfo.role === 'stylist'
											? 'Goto Styleboard'
											: 'Take a look'}
									</Button>
								</Col>
							</Block>
						</Swap>
					</Swap>
				</Row>
			</StyleboardContainer>
		</SectionBlock>
	)
}
