import { Col, Row } from 'antd'
import { useRef } from 'react'

import Avatar from '@components/avatar/avatar'
import { PageNavigation } from '@components/page-navigation'
import { Block, GrayLine } from '@ui/index'
import { GalleryBlock } from './gallery-block/gallery-block'
import {
	ButtonStyled,
	StylistDescription,
	StylistInfoBlock,
	StylistInfoContainer,
	StylistName,
} from './stylist-profile-preview-page-styled'
import { useStylistProfilePreviewPage } from './use-stylist-profile-preview-page'

export default function StylistProfilePreviewPage() {
	const { userInfo, capsulesList, navigationButtons } =
		useStylistProfilePreviewPage()
	const carouselRef = useRef<any>()

	return (
		<Block isFullWidth padding="0 48px 48px">
			<PageNavigation showListType="preview" buttons={navigationButtons} />
			<Row gutter={48}>
				<Col span={6}>
					<StylistInfoContainer>
						<Avatar
							imageUrl={userInfo?.stylist?.params?.photo?.[0].urls?.M || ''}
							size="100%"
						/>
						<StylistInfoBlock>
							<StylistName>
								{userInfo?.stylist?.first_name} {userInfo?.stylist?.last_name}
							</StylistName>
							<StylistDescription>
								{userInfo?.stylist?.notes}
							</StylistDescription>
							<GrayLine style={{ margin: '20px 0' }} />
							<ButtonStyled>Book {userInfo?.stylist?.first_name}</ButtonStyled>
						</StylistInfoBlock>
					</StylistInfoContainer>
				</Col>
				<Col span={18} style={{ marginTop: 32 }}>
					<GalleryBlock carouselRef={carouselRef} imageList={capsulesList} />
				</Col>
			</Row>
		</Block>
	)
}
