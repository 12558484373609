import { Block, Icon } from '@ui/index'
import { HeaderCountProps } from '../types'
import {
	Badge,
	CircleWrapper,
	TextWrapper,
} from './header-count-desktop-styled'

export function HeaderCountDesktop({
	count,
	title,
	onClick,
}: HeaderCountProps) {
	return (
		<Block isClickable isFlexBlock onClick={onClick}>
			<CircleWrapper>
				<Icon name={count ? 'bellRingingIcon' : 'bellIcon'} color="black" />
				{!!count && <Badge>{count}</Badge>}
			</CircleWrapper>
			{title && (
				<TextWrapper>
					<Block>{count}</Block>
					<Block>{title}</Block>
				</TextWrapper>
			)}
		</Block>
	)
}
