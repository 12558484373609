import axios from 'axios'
import { axiosInstance } from '../../../config'
import { CreateUserResponse } from '@typings/types'

type Props = {
	email: string
	firstName: string
	lastName: string
	phone: string
}

export async function createStylist({
	firstName,
	lastName,
	email,
	phone,
}: Props) {
	try {
		const response = await axiosInstance.post<CreateUserResponse>(
			'/api/user/create',
			JSON.stringify({
				role: 'stylist',
				email,
				phone,
				stylist: {
					first_name: firstName,
					last_name: lastName,
				},
			})
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
