import { Col, Row } from 'antd'
import { useMemo } from 'react'

import ArrowNext from '@components/arrow-next/arrow-next'
import { PROD_DOMAIN } from '@typings/constants'
import { CapsuleInfo, StylistInfo } from '@typings/types'

import {
	Container,
	DataWrapper,
	GrayLine,
	IconWrapper,
	ImageCont,
	Title,
	DivStyled,
	DivCapsuleCard,
	StylistInfoBlock,
	ImageWrapper,
} from './capsules-item-styled'
import { useAppSelector } from '@store/index'
import { userSelector } from '@store/user'
import { BlankPlaceholder } from '@ui/blank-placeholder/blank-placeholder'
import { colors } from '@styles/colors'

type Props = {
	item: CapsuleInfo
	stylist?: StylistInfo
	onClick: (id: number) => void
}

export function CapsulesItem({ item, stylist, onClick }: Props) {
	const { userInfo } = useAppSelector(userSelector)

	const capsulePhotoUrl = item.params?.coverPhoto
		? PROD_DOMAIN + item.params?.coverPhoto?.[0].urls?.M
		: ''
	const stylistName = useMemo(() => {
		return `${stylist?.first_name} ${stylist?.last_name}`
	}, [stylist])

	return (
		<DivCapsuleCard onClick={() => onClick(item.id)}>
			<Container>
				{userInfo.role !== 'stylist' && stylistName && (
					<StylistInfoBlock>by {stylistName}</StylistInfoBlock>
				)}

				<ImageWrapper>
					<ImageCont imageUrl={capsulePhotoUrl}>
						{!capsulePhotoUrl && <BlankPlaceholder color={colors.blackTr24} />}
					</ImageCont>
				</ImageWrapper>
				<DataWrapper>
					<Title>{item.title}</Title>
					<GrayLine />
					<Row justify="start" align="middle" gutter={12}>
						<Col>
							<IconWrapper>{item.summary?.itemsCount || 0}</IconWrapper>
						</Col>
						<Col flex="1 1">
							<DivStyled>clothing items</DivStyled>
						</Col>
						<Col flex="0 1">
							<ArrowNext />
						</Col>
					</Row>
				</DataWrapper>
			</Container>
		</DivCapsuleCard>
	)
}
