import styled, { css } from 'styled-components'
import { colors } from '@styles/colors'
import {
	bold14,
	fontRoboto,
	fontTenorSans,
	regular13,
	regular16,
} from '@styles/typography'
import { Col } from 'antd'

export const StyledCol = styled(Col)`
	& .cv-form-gallery-item {
		width: 170px !important;
		margin-right: 16px;
	}
}`

export const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	margin: 0 auto;
	background: ${colors.blackTr5};
`

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	max-width: 170px;
	width: 100%;
	height: 244px;
	border-radius: 12px;
	background: ${colors.blackTr5};
	padding: 20px;
	cursor: default;

	&:hover {
		& .hidden-button {
			opacity: 1;
		}
	}
`
export const InfoWrapper = styled.div`
	display: block;
	padding: 12px 0;
	width: 100%;
`

export const Title = styled.div`
	${fontRoboto}
	${bold14}
  align-self: start;
	min-height: 24px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: inherit;
	cursor: pointer;
`

export const Time = styled.div`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr56};
	text-align: center;
	min-height: 24px;
`

export const Price = styled.div`
	${fontTenorSans}
	${regular16}
  color: ${colors.blackTr64};
	margin-top: 9px;
	padding: 0 5px;
	align-self: start;
	min-height: 22px;
	letter-spacing: 0.005em;
`

export const FullScrIcon = styled.div`
	position: absolute;
	top: 26px;
	left: 26px;
	width: 48px;
	height: 48px;
	background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
	border-radius: 8px;
	padding: 12px;
`

export const StatusWrapper = styled.div`
	margin: 12px 0 0;
	display: flex;
	gap: 5px;
	justify-content: center;

	& span {
		${fontRoboto}
		${bold14}
		color: #1B998B;
	}
`
export const ActionsWrapper = styled.div`
	position: absolute;
	right: 20px;
	cursor: pointer;
`

export const FileWrapper = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 80px;
	height: 80px;
	border-radius: 50%;
	margin: 0 auto;
	background: rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0px 0px 8px ${colors.blackTr8};

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
			box-shadow: none;
		`}
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`
