import styled from 'styled-components'

import EllipseWoman from '@assets/EllipseWoman.png'
import LoginBG from '@assets/loginBG.png'
import { colors } from '@styles/colors'
import { screenSize } from '@styles/screen-sizes'
import {
	fontPlayfair,
	fontTenorSans,
	regular16,
	regular26,
	regular42,
} from '@styles/typography'

export const BgCont = styled.div`
	background: url(${LoginBG});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	position: absolute;
	width: 80%;
	height: 80%;
	opacity: 0.5;
`

export const GapLineCol = styled.div`
	display: flex;
	width: 48px;
	margin: 136px auto;
	border-top: 2px solid ${colors.black};
`
export const TextBlockCol = styled.div`
	${fontPlayfair}
	${regular42}

	margin: 135px auto 70px;

	@media (${screenSize.maxTablet}) {
		margin: 0 auto 70px;
	}
`

export const InterviewMsg = styled.div`
	${fontTenorSans}
	${regular26}
	align-items: center;
	margin: 0 auto 70px;
`
export const Red = styled.i`
	color: red;
`

export const ImageBlock = styled.div`
	position: relative;
	background: url(${EllipseWoman});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left;
	width: 100%;
	height: 100%;
	max-width: 765px;
	max-height: 660px;
`

export const NoteText = styled.span`
	height: 100%;

	${fontTenorSans}
	${regular16}

	display: flex;
	flex-wrap: wrap;
	align-items: center;
`
