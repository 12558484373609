import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@store/index'
import { authSelector } from '@store/auth'
import { getItemsList } from '@api/item/get-items-list'
import {
	clothesSelector,
	setClothesList,
	setFilteredClothesList,
	setIsListLoading,
	setNewCapsuleClothesList,
	setShowListType,
} from '@store/clothes'
import { ItemInfo, NavigationButtonInfo } from '@typings/types'
import { createCapsule } from '@api/capsule/create-capsule'
import { addCapsuleItem } from '@api/capsule/add-capsule-item'

export function useClothesPage() {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { state } = useLocation()

	const { token } = useAppSelector(authSelector)
	const {
		isListLoading,
		clothesList,
		showListType,
		filteredClothesList,
		newCapsuleClothesList,
	} = useAppSelector(clothesSelector)

	const [isNewCapsuleLoading, setIsNewCapsuleLoading] = useState(false)
	//	const { xxl, xl } = useResponsive()

	const loadItems = useCallback(async () => {
		dispatch(setShowListType(state?.showListType ?? 'active'))
		dispatch(setIsListLoading(true))

		const itemStatus =
			!state?.showListType || state?.showListType === 'edit_capsule'
				? 'active'
				: state.showListType
		const result = await getItemsList({ token })

		if (result) {
			result.sort(function (a, b) {
				return a.id > b.id ? -1 : 1
			})

			dispatch(setClothesList(result))
			dispatch(
				setFilteredClothesList(
					result.filter((item) => item.status === itemStatus)
				)
			)

			if (state?.capsule?.id && state?.itemsIds) {
				const capsuleItems: ItemInfo[] = []

				state?.itemsIds.map((id: number) => {
					const current = clothesList.find((item) => item.id === id)
					if (current) {
						capsuleItems.push(current)
					}
					return null
				})
				dispatch(setNewCapsuleClothesList(capsuleItems))
				dispatch(setIsListLoading(false))
				return
			}
		}

		dispatch(setIsListLoading(false))
	}, [clothesList, dispatch, state, token])

	useEffect(() => {
		if (isListLoading) return

		loadItems()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleClickAll = useCallback(() => {
		if (showListType === 'active') return

		dispatch(setShowListType('active'))
		dispatch(
			setFilteredClothesList(
				clothesList.filter((item) => item.status === 'active')
			)
		)
	}, [clothesList, dispatch, showListType])

	const handleChangeSearch = (data: any) => {
		dispatch(
			setFilteredClothesList(
				clothesList.filter(
					(item) =>
						item.title &&
						item.title.toLowerCase().includes(data.target.value.toLowerCase())
				)
			)
		)
	}

	const handleClickUpdateCapsule = () => {
		navigate(`/capsules/${state?.capsule?.id}/edit`, {
			state: { capsuleId: state?.capsule?.id },
		})
	}

	const handleClickNewCapsule = useCallback(async () => {
		setIsNewCapsuleLoading(true)

		const capsuleId = await createCapsule(token)

		if (!capsuleId) {
			setIsNewCapsuleLoading(false)
			return
		}

		const itemIds = newCapsuleClothesList.map((item) => item.id)

		const itemsLoaded = await addCapsuleItem(
			{
				items: itemIds,
			},
			capsuleId,
			token
		)

		if (itemsLoaded) {
			navigate(`/capsules/${capsuleId}/edit`, {
				state: {
					capsuleId,
				},
			})
		}

		setIsNewCapsuleLoading(false)
	}, [newCapsuleClothesList, navigate, token])

	const handleClickDraft = useCallback(() => {
		if (showListType === 'draft') return

		dispatch(setShowListType('draft'))
		dispatch(
			setFilteredClothesList(
				clothesList.filter((item) => item.status === 'draft')
			)
		)
	}, [clothesList, dispatch, showListType])

	const handleClickArchive = useCallback(() => {
		if (showListType === 'archive') return

		dispatch(setShowListType('archive'))
		dispatch(
			setFilteredClothesList(
				clothesList.filter((item) => item.status === 'archive')
			)
		)
	}, [clothesList, dispatch, showListType])

	const handleAddNewCloth = async () => {
		navigate(`new/edit`, {
			state: {
				itemId: 'new',
			},
		})
	}

	const handleClothClick = async (id: number) => {
		navigate(`${id}`, {
			state: {
				itemId: id,
			},
		})
	}

	const handleClickCapsuleButton =
		state?.status === 'edit_capsule'
			? handleClickUpdateCapsule
			: handleClickNewCapsule

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'All clothing',
				iconName: 'tShirtIcon',
				showListType: 'active',
				handleClick: handleClickAll,
			},
			{
				title: 'Draft',
				iconName: 'checkCircleIcon',
				showListType: 'draft',
				handleClick: handleClickDraft,
			},
			{
				title: 'Archive',
				iconName: 'archiveIcon',
				showListType: 'archive',
				handleClick: handleClickArchive,
			},
		],
		[handleClickAll, handleClickArchive, handleClickDraft]
	)

	return {
		clothesList: filteredClothesList,
		isLoading: isListLoading,
		isNewCapsuleLoading,
		handleAddNewCloth,
		handleChangeSearch,
		handleClothClick,
		navigationButtons,
		showListType,
		handleClickCapsuleButton,
	}
}
