import { HTMLAttributes, ReactNode } from 'react'
import { Icon } from '..'
import { IconNames } from '../icon/types'
import {
	Container,
	InputContainer,
	PlaceholderWrapper,
	IconContainer,
	CheckBoxContainer,
	CheckBoxTextWrapper,
	TextAreaContainer,
	ErrorBlock,
	FlexBlock,
} from './input-styled'

type Props = {
	placeholder?: string
	label?: string
	inputFieldSlot: ReactNode
	iconName?: IconNames
	type?: 'text' | 'checkbox' | 'textArea'
	width?: string
	hasValue?: boolean
	isError?: boolean
	errorText?: string
	rootClassName?: string
} & HTMLAttributes<HTMLInputElement>

export function Input({
	placeholder,
	label,
	inputFieldSlot,
	iconName,
	type = 'text',
	width,
	hasValue,
	isError,
	errorText,
	rootClassName,
}: Props) {
	if (type === 'checkbox') {
		return (
			<CheckBoxContainer>
				{inputFieldSlot}
				<CheckBoxTextWrapper>{placeholder}</CheckBoxTextWrapper>
			</CheckBoxContainer>
		)
	}

	if (type === 'textArea') {
		return (
			<>
				<Container width={width} isError={isError} type={type}>
					<TextAreaContainer>
						<PlaceholderWrapper
							htmlFor={label}
							hasValue={hasValue}
							isError={isError}
						>
							{placeholder}
						</PlaceholderWrapper>
						{inputFieldSlot}
					</TextAreaContainer>
				</Container>
			</>
		)
	}

	return (
		<FlexBlock width={width} className={rootClassName || ''}>
			<Container isError={isError} className="input-text-container">
				{iconName && (
					<IconContainer>
						<Icon name={iconName} />
					</IconContainer>
				)}

				<InputContainer isError={isError}>
					<PlaceholderWrapper
						htmlFor={label}
						hasValue={hasValue}
						isError={isError}
					>
						{placeholder}
					</PlaceholderWrapper>
					{inputFieldSlot}
				</InputContainer>

				{isError && (
					<IconContainer>
						<Icon name={'infoCircleIcon'} color="#FF0C63" />
					</IconContainer>
				)}
			</Container>
			{isError && errorText && <ErrorBlock>{errorText}</ErrorBlock>}
		</FlexBlock>
	)
}
