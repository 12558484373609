import copy from 'copy-to-clipboard'
import { DealStatus } from '@typings/types'
import {
	AcceptButton,
	Container,
	CopyLinkButton,
	InputShareLink,
	RestyleButton,
	LinkTitle,
} from './review-buttons-styled'
import { useMemo, useState } from 'react'
import { Block, Button, Icon, Modal } from '@ui/index'
import { colors } from '@styles/colors'
import { ShareModalText } from '@pages/capsules/capsules-edit-page/capsules-edit-page-styled'

type Props = {
	shareLink?: string
	status?: DealStatus
	onAcceptClick: () => void
	onRestyleClick: () => void
}

export function ReviewButtons({
	shareLink,
	status,
	onAcceptClick,
	onRestyleClick,
}: Props) {
	const [isShareModalOpen, setIsShareModalOpen] = useState(false)
	const [buttonAction, setButtonAction] = useState('')

	const onShareClick = () => {
		if (!shareLink) return
		copy(shareLink)
	}

	const acceptBtnObj = useMemo(
		() =>
			status?.clientActions?.find(
				(action) => action.action === 'capsule_accept'
			),
		[status]
	)

	const restyleBtnObj = useMemo(
		() =>
			status?.clientActions?.find(
				(action) => action.action === 'capsule_restyle'
			),
		[status]
	)

	const shareBtnObj = useMemo(
		() =>
			status?.clientActions?.find(
				(action) => action.action === 'capsule_share'
			),
		[status]
	)

	return (
		<Container>
			{acceptBtnObj && (
				<AcceptButton disabled={!acceptBtnObj.enabled} onClick={onAcceptClick}>
					{acceptBtnObj.button_text}
				</AcceptButton>
			)}
			{restyleBtnObj && (
				<RestyleButton
					disabled={!restyleBtnObj.enabled}
					onClick={() => {
						setIsShareModalOpen(true)
						setButtonAction(restyleBtnObj.action)
					}}
				>
					{restyleBtnObj.button_text}
				</RestyleButton>
			)}
			{shareBtnObj && (
				<Button
					isFitContent
					size="m"
					isDisabled={!shareBtnObj.enabled}
					skin="grey-outline"
					leftIcon={
						<Icon name="shareArrowIcon" color={colors.blackTr64} size={24} />
					}
					onClick={() => {
						setIsShareModalOpen(true)
						setButtonAction(shareBtnObj.action)
					}}
				>
					{shareBtnObj.button_text}
				</Button>
			)}

			<Modal
				modalTitle={
					buttonAction === 'capsule_share'
						? 'Share your Style Dyle experience'
						: 'Are you sure you want to restyle the capsule?'
				}
				isModalOpen={isShareModalOpen}
				onCancel={() => {
					setIsShareModalOpen(false)
				}}
				onOk={() => {
					buttonAction === 'capsule_share' ? onShareClick() : onRestyleClick()
					setIsShareModalOpen(false)
				}}
				confirmButtonText={
					buttonAction === 'capsule_share' ? 'Copy link' : 'Restyle'
				}
				cancelButtonText="Cancel"
			>
				<Block isFlexBlock flexDirection="column" gap="27px">
					<ShareModalText>
						{buttonAction === 'capsule_share'
							? 'Send the link for others to check out your capsule'
							: 'Just so you know, you can request this capsule restyle once'}
					</ShareModalText>

					{shareLink && (
						<Block isFlexBlock justifyContent="start" isFullWidth>
							<LinkTitle>Link</LinkTitle>
							<InputShareLink type="text" readOnly value={shareLink} />
							<CopyLinkButton
								onClick={onShareClick}
								skin="grey-link"
								leftIcon={
									<Icon color={colors.blackTr64} name="copyIcon" size={20} />
								}
							/>
						</Block>
					)}
				</Block>
			</Modal>
		</Container>
	)
}
