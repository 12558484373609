import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { acceptStyleboard } from '@api/styleboard/accept-styleboard'
import { getStyleboardInfo } from '@api/styleboard/get-styleboard'
import { restyleStyleboard } from '@api/styleboard/restyle-styleboard'
import { UpdateReactions } from '@api/styleboard/update-reactions'
import { getConnectedClient } from '@api/stylist/get-connected-client'
import { authSelector } from '@store/auth'
import { useAppDispatch, useAppSelector } from '@store/store'
import { setStyleboard, styleboardsSelector } from '@store/styleboards'
import { userSelector } from '@store/user'
import { PROD_DOMAIN } from '@typings/constants'
import {
	ClientInfo,
	NavigationButtonInfo,
	ShowPageType,
	StyleboardElement,
	StyleboardReactions,
} from '@typings/types'
import { handleToast } from '@utils/handle-toast'

type StyleboardImage = {
	id: string
	url: string
}

export function useStyleboardPreviewPage() {
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { token } = useAppSelector(authSelector)
	const { userInfo } = useAppSelector(userSelector)
	const { styleboard } = useAppSelector(styleboardsSelector)

	const [isLoading, setIsLoading] = useState(false)
	const [images, setImages] = useState<StyleboardImage[]>([])
	const [sharedClient, setSharedClient] = useState<ClientInfo>()

	const [reactions, setReactions] = useState<StyleboardReactions>({
		likes: [],
		dislikes: [],
	})

	const { id: styleboardId } = useParams()

	const loadStyleboardInfo = useCallback(async () => {
		setIsLoading(true)
		const data = await getStyleboardInfo({ token, styleboardId })

		if (data) {
			const photos = data.photos || []
			const images = photos.map((photo) => ({
				id: photo.id.toString(),
				url: PROD_DOMAIN + photo.urls.M,
			}))
			setImages(images)
			setReactions({
				likes: data.params?.likes || [],
				dislikes: data.params?.dislikes || [],
			})

			if (userInfo.role !== 'client' && data.client_id) {
				const clientInfo = await getConnectedClient({
					clientId: data.client_id,
					token,
				})

				setSharedClient(clientInfo)
			}

			dispatch(setStyleboard(data))
		}
		setIsLoading(false)
	}, [dispatch, styleboardId, token, userInfo.role])

	useEffect(() => {
		if (!styleboardId) return
		loadStyleboardInfo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [styleboardId])

	const currentPage: ShowPageType =
		pathname === `/styleboards/${styleboardId}/edit` ? 'edit' : 'preview'

	const handleEditClick = useCallback(() => {
		navigate(`/styleboards/${styleboardId}/edit`, {
			state: {
				styleboardId: styleboardId,
			},
		})
	}, [navigate, styleboardId])

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'Preview',
				iconName: 'previewIcon',
				showListType: 'preview',
				handleClick: () => {},
			},
			{
				title: 'Edit',
				iconName: 'editIcon',
				showListType: 'edit',
				handleClick: handleEditClick,
			},
		],
		[handleEditClick]
	)

	const handleLikeClick = async (id: string) => {
		if (userInfo.role === 'stylist' || !styleboardId) return

		const newReactions = reactions?.likes?.includes(id)
			? {
					likes: reactions?.likes?.filter((_id) => _id !== id),
					dislikes: reactions?.dislikes,
			  }
			: {
					likes: [...reactions?.likes, id],
					dislikes: reactions?.dislikes?.filter((_id) => _id !== id),
			  }
		const result = await UpdateReactions({
			token,
			styleboardId,
			reactions: newReactions,
		})

		if (!result) {
			handleToast({ content: 'Error updating reactions' })
			return
		}

		setReactions(newReactions)
	}

	const handleDislikeClick = async (id: string) => {
		if (userInfo.role === 'stylist' || !styleboardId) return

		const newReactions = reactions?.dislikes?.includes(id)
			? {
					likes: reactions?.likes,
					dislikes: reactions?.dislikes?.filter((_id) => _id !== id),
			  }
			: {
					likes: reactions?.likes?.filter((_id) => _id !== id),
					dislikes: [...reactions?.dislikes, id],
			  }
		const result = await UpdateReactions({
			token,
			styleboardId,
			reactions: newReactions,
		})

		if (!result) {
			handleToast({ content: 'Error updating reactions' })
			return
		}

		setReactions(newReactions)
	}

	const handleAcceptClick = useCallback(async () => {
		if (!styleboardId) return

		const result = await acceptStyleboard({ token, styleboardId })

		if (!result) {
			handleToast({ content: 'Error accepting styleboard' })
			return
		}

		navigate('./accepted')
		//loadStyleboardInfo()
	}, [navigate, styleboardId, token])

	const handleRestyleClick = useCallback(async () => {
		if (!styleboardId) return

		const result = await restyleStyleboard({ token, styleboardId })

		if (!result) {
			handleToast({ content: 'Error restyling styleboard' })
			return
		}

		loadStyleboardInfo()
	}, [loadStyleboardInfo, styleboardId, token])

	const styleboardElements: (StyleboardElement | null)[] = useMemo(() => {
		let elArr: (StyleboardElement | null)[] = []
		elArr = images.map((image) => ({
			id: image.id,
			url: image.url,
			isLiked: reactions?.likes?.includes(image.id),
			isDisliked: reactions?.dislikes?.includes(image.id),
			handleLikeClick: () => handleLikeClick(image.id),
			handleDislikeClick: () => handleDislikeClick(image.id),
		}))

		if (images.length && images.length < 8) {
			for (let i = 0; i < 8 - images.length; i++) {
				elArr.push(null)
			}
		}

		return elArr
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [images, reactions])

	return {
		currentPage,
		handleAcceptClick,
		handleRestyleClick,
		styleboardElements,
		isLoading,
		navigationButtons,
		userRole: userInfo?.role,
		styleboard,
		sharedClient,
	}
}
