import { bold14 } from './../../styles/typography'
import { colors } from '@styles/colors'
import { fontRoboto, fontTenorSans, regular26 } from '@styles/typography'
import styled from 'styled-components'

export const MenuBlockWrapper = styled.aside`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 108px;
	background: #000000;
	box-shadow: 0px 0px 42px rgba(78, 115, 156, 0.1);
	color: ${colors.white};
`
export const CapsuleTitle = styled.div`
	${fontTenorSans}
	${regular26}
  padding: 16px 0;
`

export const CategoryList = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
`

export const CategoryItem = styled.div`
	border-radius: 8px;
	border: 1px solid ${colors.blackTr16};
	padding: 8px;

	${fontRoboto}
	${bold14}
  color: ${colors.blackTr64};
`
