import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { buyTariff } from '@api/tariff/buy-tariff'
import { getAvailableTariffs } from '@api/tariff/get-available'
import { authSelector } from '@store/auth'
import { useAppSelector } from '@store/store'
import { AvailableTariff } from '@typings/types'
import { Spinner } from '@ui/spinner'
import { handleToast } from '@utils/handle-toast'
import RateBlock from './rate-block/rate-block'

import {
	DescriptionText,
	H1Styled,
	PageWrapper,
	RateBlockWrapper,
} from './rate-plans-styled'

export default function RatePlans() {
	const { state } = useLocation()
	const { token } = useAppSelector(authSelector)

	const [isTariffsLoading, setIsTariffsLoading] = useState(false)
	const [tariffs, setTariffs] = useState<AvailableTariff[]>([])

	const loadTariffs = async () => {
		setIsTariffsLoading(true)

		const availableTariffs = await getAvailableTariffs({ token })

		if (!availableTariffs) {
			handleToast({ content: 'Failed to load tariffs' })
			setIsTariffsLoading(false)
			return
		}

		const toTariffsArray = Object.keys(availableTariffs).map(
			(key) => availableTariffs[key]
		)
		setTariffs(toTariffsArray)
		setIsTariffsLoading(false)
	}

	const handleBuyTariff = async (tariffId: number) => {
		localStorage.removeItem('stylistId')
		setIsTariffsLoading(true)

		const response = await buyTariff({ token, tariffId })

		if (!response) {
			handleToast({ content: 'Failed to buy tariff' })
			setIsTariffsLoading(false)
			return
		}

		setIsTariffsLoading(false)
		localStorage.setItem('stylistId', state?.stylistId)
		window.location.href = response.payUrl
	}

	useEffect(() => {
		loadTariffs()
	}, [])

	if (isTariffsLoading) {
		return <Spinner />
	}

	return (
		<PageWrapper isFullWidth margin="0 0 50px 0">
			<H1Styled>
				Start your new &nbsp;<i>style journey</i>
			</H1Styled>
			{tariffs.length > 1 && (
				<DescriptionText>Choose a package that suits you best</DescriptionText>
			)}
			<RateBlockWrapper>
				{tariffs.map((tariff) => (
					<RateBlock
						key={tariff.id}
						tariff={tariff}
						onBuyTariff={() => handleBuyTariff(tariff.id)}
					/>
				))}
			</RateBlockWrapper>
		</PageWrapper>
	)
}
