import styled from 'styled-components'
import { Button } from '@ui/index'
import { screenSize } from '@styles/screen-sizes'

export const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;

	@media (${screenSize.antXs}) {
		margin: 32px 10px;
	}

	@media (${screenSize.antMd}) {
		margin: 32px 40px;
	}
`

export const ButtonStyled = styled(Button)`
	margin: 50px auto 0;
`
