import { Col, Row, Image } from 'antd'
import { useRef } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { ImageItemType } from '@typings/types'
import { Icon, GalleryButtons } from '@ui/index'
import { useAppSelector } from '@store/store'
import { clothesSelector } from '@store/clothes'
import { SdNotification } from '@components/sd-notification'
import {
	FullScrIcon,
	HoverWrapper,
} from '../client-clothes-preview-page-styled'

type Props = { imageList: ImageItemType[] }

export function GalleryBlock({ imageList }: Props) {
	const { item } = useAppSelector(clothesSelector)
	const description = item?.params?.description

	const carouselRef = useRef<any>()

	const ButtonGroup = () => {
		return (
			<Col xxl={{ span: 6 }} xl={{ span: 8 }} xs={{ span: 12}}>
				<GalleryButtons
					onNextArrowClick={() => carouselRef.current?.next()}
					onPrevArrowClick={() => carouselRef.current?.previous()}
				/>
			</Col>
		)
	}

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 1600 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 1599, min: 1400 },
			items: 2,
		},
		tablet: {
			breakpoint: { max: 1399, min: 1200 },
			items: 1,
			partialVisibilityGutter: 155,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	}

	return (
		<Row gutter={16} align={'middle'}>
			<Col xxl={{ span: 18 }} xl={{ span: 16 }} xs={{ span: 12 }} className="row-block-label">
				Item photo
			</Col>
			<ButtonGroup />
			<Col span={24} style={{ marginTop: 58, width: '100%', maxWidth: '85vw' }}>
				<Carousel
					ref={(el) => (carouselRef.current = el)}
					responsive={responsive}
					arrows={false}
					containerClass="carousel-container"
					itemClass="carousel-item"
					renderButtonGroupOutside={true}
					centerMode={false}
					partialVisible
				>
					{imageList.map((imageItem, index) => (
						<HoverWrapper key={index}>
							<Image
								width={246}
								src={imageItem.url}
								preview={{
									src: imageItem.previewUrl,
									mask: (
										<FullScrIcon>
											<Icon color="white" name="fullScreenIcon" />
										</FullScrIcon>
									),
								}}
							/>
						</HoverWrapper>
					))}
				</Carousel>
			</Col>
			<Col span={24} style={{ marginTop: 50 }}>
				{description && <SdNotification text={description} />}
			</Col>
		</Row>
	)
}
