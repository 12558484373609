import axios, { AxiosRequestConfig } from 'axios'

import { axiosInstance } from '../../config'

type Props = {
	token: string
	step: number
	answers: string[]
}

export async function addQuizAnswer({
	token,
	step,
	answers,
}: Props): Promise<boolean | undefined> {
	try {
		const config: AxiosRequestConfig = token
			? {
					headers: {
						Authorization: `Bearer ${token}`,
					},
			  }
			: {}

		const response = await axiosInstance.post(
			`/api/quiz/${step}/add-answer`,
			{
				answers,
			},
			config
		)

		return !!response.data.success
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
