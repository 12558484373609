import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { LoadingOutlined } from '@ant-design/icons'
import { Block } from '@ui/block'
import { Icon } from '@ui/icon'
import { Container, ButtonWrapper, UploadText } from './add-photo-button-styled'

type Props = {
	aspect?: number
	isNewPhotoLoading: boolean
	handleAddNewPhoto: (e: any) => void
}

export function AddPhotoButton({
	aspect,
	isNewPhotoLoading,
	handleAddNewPhoto,
}: Props) {
	return (
		<Container>
			{isNewPhotoLoading ? (
				<LoadingOutlined />
			) : (
				<ImgCrop rotationSlider aspect={aspect}>
					<Upload
						onChange={handleAddNewPhoto}
						showUploadList={false}
						disabled={isNewPhotoLoading}
						className="photo-uploader"
					>
						<ButtonWrapper htmlFor="upload-photo">
							<Block>
								<Icon name="uploadAvatarIcon" size={24} />
							</Block>
							<UploadText margin="10px 0 0 0">Add</UploadText>
						</ButtonWrapper>
					</Upload>
				</ImgCrop>
			)}
		</Container>
	)
}
