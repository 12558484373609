import { useMemo, useState } from 'react'
import { deleteFile } from '../../../api/delete-file'
import { getUserInfo } from '../../../api/get-user-info'
import { updateProfile } from '../../../api/update-profile'
import { uploadFile } from '../../../api/upload-file'
import { AddField, useAddFields } from '../../../hooks/use-add-fields'
import { useAppDispatch, useAppSelector } from '../../../store'
import { authSelector } from '../../../store/auth'
import { setUserInfo, userSelector } from '../../../store/user'
import { DownloadedFile, UploadFileRequest } from '../../../typings/types'
import { useUpdateUserProfileInfo } from '@hooks/api/use-update-user-profile-info'
import { toast } from 'react-toastify'

export function useCvForm() {
	const dispatch = useAppDispatch()

	const { userInfo } = useAppSelector(userSelector)
	const { token } = useAppSelector(authSelector)

	const cvFiles = useMemo(
		() => userInfo.stylist.params.CV || [],
		[userInfo.stylist.params.CV]
	)
	const portfolioFiles = userInfo.stylist.params.portfolio || []

	const relationId = userInfo.client ? userInfo.client.id : userInfo.stylist.id

	const [cvLinksToShow, setCvLinksToShow] = useState<string[]>(
		userInfo.stylist.params.cvLinks || []
	)
	const [newCvLink, setNewCvLink] = useState<string>('')
	const [newCvLinkError, setNewCvLinkError] = useState<string>('')

	const [isCvLoading, setIsCvLoading] = useState(false)
	const [isCvLinkLoading, setIsCvLinkLoading] = useState(false)

	const [isCvLoadingError, setIsCvLoadingError] = useState('')

	const { handleSetUserParams } = useUpdateUserProfileInfo()

	const [isModalOpen, setIsModalOpen] = useState(false)

	const showModal = () => {
		setIsModalOpen(true)
	}

	const handleOk = async () => {
		if (!newCvLink) {
			setNewCvLinkError('Link is required')
			return
		}

		if (cvLinksToShow.includes(newCvLink)) {
			setNewCvLinkError('Link already exists')
			return
		}

		await handleCvLinkAdd(newCvLink)
		setIsModalOpen(false)
	}

	const handleCancel = () => {
		setNewCvLink('')
		setNewCvLinkError('')
		setIsModalOpen(false)
	}

	const handleCvLinkAdd = async (link: string) => {
		setIsCvLinkLoading(true)

		setNewCvLinkError('')
		await handleSetUserParams('cvLinks', [...cvLinksToShow, link])
		setCvLinksToShow([...cvLinksToShow, link])
		setNewCvLink('')

		setIsCvLinkLoading(false)
	}

	const handleCvLinkDelete = async (link: string) => {
		const newLinks = cvLinksToShow.filter((item) => item !== link)
		await handleSetUserParams('cvLinks', newLinks)
		setCvLinksToShow(newLinks)
	}

	const handleCvFileAdd = async (e: any) => {
		setIsCvLoadingError('')

		if (!userInfo.role) return
		if (!e.target.files[0]) {
			return
		}

		setIsCvLoading(true)

		const requestData: UploadFileRequest = {
			filename: e.target.files[0],
			relation_id: relationId.toString(),
			relation_type: userInfo.role,
		}

		if (!userInfo.id) {
			setIsCvLoadingError('No user id')
			setIsCvLoading(false)
			return
		}

		const newFile = await uploadFile({
			requestData,
			token,
		})

		if (!newFile) {
			setIsCvLoadingError('Error to upload you CV')
			setIsCvLoading(false)
			return
		}

		const tempFilesToSend = cvFiles.map((item) => ({
			file: item.file,
		}))

		const result = await updateProfile(
			JSON.stringify({
				[userInfo.role]: {
					params: {
						CV: [...tempFilesToSend, { file: newFile.id }],
					},
				},
			}),
			userInfo.id,
			token
		)

		if (!result?.isSuccess) {
			setIsCvLoadingError('Error to upload you CV')
			setIsCvLoading(false)
			return
		}

		const userData = await getUserInfo({ id: userInfo.id, token })

		if (!userData) {
			setIsCvLoadingError('Something goes wrong')
			setIsCvLoading(false)
			return
		}

		dispatch(setUserInfo(userData))

		setIsCvLoading(false)
	}

	const handleDelete = async (fileId: number) => {
		if (!fileId) {
			return
		}

		const isCvDeleted = await deleteFile({ fileId, token })

		if (!isCvDeleted) {
			return
		}

		const userData = await getUserInfo({ id: userInfo.id, token })

		if (!userData) {
			return
		}

		dispatch(setUserInfo(userData))
	}

	const updateCVItems = (
		cvFiles: DownloadedFile[],
		cvLinksToShow: string[]
	) => {
		let finalArr: (
			| { item: DownloadedFile; type: 'file' }
			| { item: string; type: 'link' }
		)[] = []

		cvFiles.forEach((item) => {
			finalArr.push({
				item,
				type: 'file',
			})
		})

		cvLinksToShow.forEach((item) => {
			finalArr.push({
				item,
				type: 'link',
			})
		})

		return finalArr
	}

	const cvItemsArr = useMemo(
		() => updateCVItems(cvFiles, cvLinksToShow),
		[cvFiles, cvLinksToShow]
	)

	return {
		cvLinksToShow,
		handleCvFileAdd,
		handleCvLinkAdd,
		handleCvLinkDelete,
		handleDelete,
		isCvLoading,
		isCvLoadingError,
		portfolioFiles,
		showModal,
		handleCancel,
		handleOk,
		isModalOpen,
		cvItemsArr,
		setNewCvLink,
		newCvLink,
		newCvLinkError,
		isCvLinkLoading,
	}
}
