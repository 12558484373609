import axios from 'axios'
import { axiosInstance } from '../../config'
import { CreateResponse } from '../../typings/types'

export async function createStyleBoard(
	token: string,
	payload?: string
): Promise<number | undefined> {
	try {
		const response = await axiosInstance.post<CreateResponse>(
			`/api/styleboard`,
			payload,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		if (response.data.success) {
			return response.data.id
		}

		return undefined
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
