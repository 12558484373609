import { colors } from '@styles/colors'
import {
	bold16,
	fontRoboto,
	fontTenorSans,
	regular16,
	regular26,
} from '@styles/typography'
import { Modal } from 'antd'
import styled from 'styled-components'

export const ModalStyled = styled(Modal)`
	${fontTenorSans}
	max-width: 780px;

	& .ant-modal-content {
		padding: 56px 72px;
	}

	& .ant-modal-close {
		color: ${colors.mainThemeColor};
	}

	& .ant-modal-header {
		margin-bottom: 24px;

		& .ant-modal-title {
			${regular26}
		}
	}

	& .ant-modal-body {
		& > div {
			margin-bottom: 24px;
		}

		& .body-title-text {
			${regular16}
		}

		& .body-h2-text {
			${regular26}
		}

		& .body-cookie-category {
			${fontRoboto}

			&--label {
				${bold16}
				margin-bottom: 18px;
			}

			&--description {
				${regular16}
				color: ${colors.blackTr64};
			}
		}
	}
`
