import { useUpdateUserProfileInfo } from '@hooks/api/use-update-user-profile-info'
import { userSelector } from '@store/user'
import { useAppSelector } from '@store/store'
import { useMemo } from 'react'
import { settingsSelector } from '@store/settings'
import { CheckButtonType } from '@typings/types'

const exspencesData = [
	'up to £50',
	'£50 - £100',
	'£100-£250',
	'£250-£500',
	'£500-£1000',
	'£1000+',
]

export function usePreferencesDataForm() {
	const { userInfo } = useAppSelector(userSelector)
	const client = userInfo.client
	const { settings, isLoading: isSettingsLoading } =
		useAppSelector(settingsSelector)

	const { isLoading: isPreferencesLoading, handleSetUserParams } =
		useUpdateUserProfileInfo()

	const sizesShoesOptions = useMemo(() => {
		if (!settings) return

		const sizesShoesData = settings.SIZES_SHOES_DATA

		if (!sizesShoesData) return

		return sizesShoesData.map((item) => ({
			label: item,
			value: item,
		}))
	}, [settings])

	const sizesOptions = useMemo(() => {
		if (!settings) return

		const data = settings.SIZES_DATA

		if (!data) return

		return data.map((item) => ({
			label: item,
			value: item,
		}))
	}, [settings])

	const exspencesOptions = exspencesData.map((item) => ({
		label: item,
		value: item,
	}))

	const bodyShapeOptions: CheckButtonType[] = useMemo(
		() =>
			settings?.bodyType
				? settings?.bodyType?.map((item) => ({
						title: item,
						state: client.params.bodyShape?.includes(item)
							? 'checked'
							: 'ignored',
				  }))
				: [],
		[client.params.bodyShape, settings?.bodyType]
	)

	const categoriesOptions = useMemo(
		() => settings.CATEGORY_DATA,
		[settings.CATEGORY_DATA]
	)

	const handleCategorySelect = (value: string) => {
		handleSetUserParams('categories', value)
	}

	return {
		isSettingsLoading,
		isPreferencesLoading,
		bodyShapeOptions,
		client,
		exspencesOptions,
		handleSetUserParams,
		settings,
		sizesOptions,
		sizesShoesOptions,
		currentCategories: client.params.categories || [],
		categoriesOptions,
		handleCategorySelect,
	}
}
