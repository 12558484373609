import { getAllStylists } from '@api/client/get-all-stylists'
import { getMatchStylists } from '@api/client/get-match-stylists'
import { authSelector } from '@store/auth'
import { useAppDispatch, useAppSelector } from '@store/store'
import { NavigationButtonInfo, StylistInfo } from '@typings/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function useStylistsPage() {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { token } = useAppSelector(authSelector)

	// TODO: Move this logic to store and set types
	const [isLoading, setIsLoading] = useState(false)
	const [showListType, setShowListType] = useState('all')

	const [stylistsList, setStylistsList] = useState<StylistInfo[]>([])
	const [filteredStylists, setFilteredStylists] = useState<StylistInfo[]>([])
	const [isFiltersOpen, setIsFiltersOpen] = useState(false)

	const loadStylists = async () => {
		setIsLoading(true)
		const result =
			showListType === 'all'
				? await getAllStylists({
						token,
				  })
				: await getMatchStylists({
						token,
				  })

		setStylistsList(result || [])
		setFilteredStylists(result || [])

		setIsLoading(false)
	}

	useEffect(() => {
		loadStylists()
	}, [showListType])

	const handleClickAll = useCallback(
		() => {
			setShowListType('all')
		},
		[
			/*stylistList, dispatch, showListType*/
		]
	)

	const handleClickRecommended = useCallback(() => {
		setShowListType('recommended')
	}, [])

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'All stylists',
				iconName: 'clientsIcon',
				showListType: 'all',
				handleClick: handleClickAll,
			},
			{
				title: 'Recommended to you',
				iconName: 'thumbUpIcon',
				showListType: 'recommended',
				handleClick: handleClickRecommended,
			},
		],
		[handleClickAll, handleClickRecommended]
	)

	const handleChangeSearch = (data: any) => {
		setFilteredStylists(
			stylistsList.filter(
				(stylist) =>
					(stylist.last_name &&
						stylist.last_name
							.toLowerCase()
							.includes(data.target.value.toLowerCase())) ||
					(stylist.first_name &&
						stylist.first_name
							.toLowerCase()
							.includes(data.target.value.toLowerCase()))
			)
		)
	}

	return {
		token,
		isLoading,
		recommendedStylists: filteredStylists,
		navigationButtons,
		showListType,
		handleChangeSearch,
		isFiltersOpen,
		setIsFiltersOpen,
	}
}
