import axios, { AxiosRequestConfig } from 'axios'

import { QuizAnswerResult } from '@typings/types'
import { axiosInstance } from '../../config'

type Props = {
	token: string
}

export async function getQuizAnswers({ token }: Props) {
	try {
		const config: AxiosRequestConfig = token
			? {
					headers: {
						Authorization: `Bearer ${token}`,
					},
			  }
			: {}

		const response = await axiosInstance.get<QuizAnswerResult[]>(
			`/api/quiz/answers`,
			config
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
