import axios from 'axios'
import { axiosInstance } from '../../../config'
import { UserStatusResponse } from '@typings/types'

export async function getUserStatus() {
	try {
		const response = await axiosInstance.get<UserStatusResponse>(
			'/api/site/get-user-status'
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
