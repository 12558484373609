import { colors } from '@styles/index'
import { fontTenorSans, regular26 } from '@styles/typography'
import { Drawer } from 'antd'
import styled from 'styled-components'

export const DrawerStyled = styled(Drawer)`
	${fontTenorSans}

	& .ant-drawer-header-title {
		flex-direction: row-reverse;
	}

	& .ant-drawer-title {
		${regular26}
	}

	/*Switch off options block*/
	& .cc-message-options {
		/*display: none !important;*/
	}
`
