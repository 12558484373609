import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'

import { Container, LabelElement } from './date-picker-styled'

type Props = {
	placeholder: string
	defaultValue: string | null | undefined
	onChange:
		| ((date: dayjs.Dayjs, dateString: string | string[]) => void)
		| undefined
	suffixIcon: React.ReactNode
}

export function DatePickerSD({
	placeholder,
	defaultValue,
	onChange,
	suffixIcon,
}: Props) {
	const [isFocused, setIsFocused] = useState<boolean>(false)
	const [hasValue, setHasValue] = useState<boolean>(!!defaultValue)

	const onChangeHandler = (
		date: dayjs.Dayjs,
		dateString: string | string[]
	) => {
		date ? setHasValue(true) : setHasValue(false)
		onChange && onChange(date, dateString)
	}

	return (
		<Container
			className={`${hasValue ? 'has-value' : ''} ${
				isFocused ? 'is-focused' : ''
			}`}
		>
			<LabelElement>{placeholder}</LabelElement>
			<DatePicker
				placeholder=""
				showWeek
				defaultValue={defaultValue ? dayjs(defaultValue) : null}
				className="styledyle-input-paddings"
				onChange={onChangeHandler}
				suffixIcon={suffixIcon}
				onOpenChange={(value) => {
					setIsFocused(value)
				}}
			/>
		</Container>
	)
}
