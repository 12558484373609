import { Navigate } from 'react-router-dom'
import VerificationInput from 'react-verification-input'

import { Block, Button, Skeleton } from '@ui/index'
import {
	ButtonsBlock,
	FormWrapper,
	StyledP,
	TitleWrapper,
} from '../form-styled'
import {
	ErrorCodeMessage,
	InputsWrapper,
	StyledPhoneP,
	Wrapper,
} from './code-form-styled'
import { RepeatCode } from './repeat-code/repeat-code'
import { useCodeForm } from './use-code-form'

export function CodeForm({
	handleCancelClick,
	isNoRedirect = false,
	isSilentUser = false,
	isWithTitle = true,
	stylistId,
}: {
	handleCancelClick?: () => void
	isNoRedirect?: boolean
	isSilentUser?: boolean
	isWithTitle?: boolean
	stylistId?: string
}) {
	const {
		email,
		errors,
		handleSubmit,
		isValid,
		onCancelClick,
		onCodeChange,
		onSendCodeAgainClick,
		onSubmitCode,
		isLoading,
		userInfo,
		currentCode,
	} = useCodeForm({ isSilentUser })

	if (isLoading) {
		return (
			<Skeleton width={326} height={352}>
				<rect x="0" y="0" rx="6" ry="6" width="326" height="32" />
				<rect x="0" y="56" rx="6" ry="6" width="326" height="66" />
				<rect x="0" y="146" rx="6" ry="6" width="326" height="94" />
				<rect x="0" y="296" rx="6" ry="6" width="326" height="56" />
			</Skeleton>
		)
	}

	if (userInfo.id && userInfo?.role === 'stylist') {
		return <Navigate to="/stylist-profile" replace={true} />
	}

	/*
	if (userInfo.id && userInfo?.role === 'client') {
		return <Navigate to="/" replace={true} />
	}
	*/
	return (
		<FormWrapper
			onSubmit={handleSubmit(() => onSubmitCode({ isNoRedirect, stylistId }))}
		>
			<Block isFlexBlock isContentCentered>
				{isWithTitle && (
					<TitleWrapper>
						Enter the code <br /> from the email
					</TitleWrapper>
				)}
			</Block>
			<StyledP>
				{isWithTitle ? 'We have sent to:' : 'Enter the code we have sent to:'}
			</StyledP>
			<StyledPhoneP title={email}>{email}</StyledPhoneP>
			<Wrapper>
				<InputsWrapper>
					<VerificationInput
						classNames={{
							container: 'container',
							character: 'character',
							characterInactive: 'character--inactive',
							characterSelected: 'character--selected',
						}}
						validChars="0-9"
						inputProps={{ type: 'tel' }}
						length={4}
						placeholder="-"
						onChange={onCodeChange}
						value={currentCode}
					/>
				</InputsWrapper>
				{errors.message && (
					<ErrorCodeMessage>{errors.message}</ErrorCodeMessage>
				)}
				<RepeatCode onRepeatClick={onSendCodeAgainClick} />
			</Wrapper>

			<ButtonsBlock>
				<Button
					skin="grey-link"
					onClick={handleCancelClick || onCancelClick}
					width={159}
				>
					Cancel
				</Button>
				<Button isSubmit isDisabled={!isValid} skin="dark" width={159}>
					Next
				</Button>
			</ButtonsBlock>
		</FormWrapper>
	)
}
