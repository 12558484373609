import {
	bold16,
	colors,
	fontTenorSans,
	fontRoboto,
	regular13,
	regular18,
	screenSize,
} from '@styles/index'
import { Row } from 'antd'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background: ${colors.white};
	border-radius: 20px;
	cursor: pointer;

	&:hover {
		& > div {
			box-shadow: 0px 6px 32px ${colors.blackTr8};
		}
	}
`

export const CardImage = styled.div<{ bgImage: string }>`
	height: 389px;
	border-radius: 20px;
	overflow: hidden;

	${({ bgImage }) =>
		bgImage &&
		css`
			background-image: url(${bgImage});
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		`}

	@media (${screenSize.antXs}) {
		margin: 25px;
	}
	
	@media (${screenSize.antXl}) {
		margin: 36px;
	}
`

export const BlankContainer = styled.div`
	background-color: ${colors.blackTr5};
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const LinkText = styled.div`
	${fontRoboto}
	${regular13}
	color: ${colors.mainThemeColor};
	text-decoration: underline;
`

export const StylistInfoBlock = styled.div`
	margin: -66px 16px 0;
	padding: 22px 24px;
	border-radius: 12px;
	background: ${colors.white};
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	flex: 1 1 auto;
`

export const IconWrapper = styled.div`
	${fontRoboto}
	${bold16}
  color: ${colors.black};
	background: ${colors.blackTr5};
	padding: 14px;
	border-radius: 64px;
	margin-left: 8px;
	line-height: 2rem;
	min-width: 48px;
	text-align: center;
`

export const DivStyled = styled.div`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr48};
	align-self: center;
`

export const RowStyled = styled(Row)`
	margin: 0 24px;
`

export const ColumnBlock = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: min-content;
	margin-left: 16px;
	overflow: hidden;
`

export const StylistName = styled.div`
	${fontTenorSans}
	${regular18}
`

export const StylistAbout = styled.div`
	${fontRoboto}
	${regular13}
	color: ${colors.blackTr64};
	margin-top: 8px;
	overflow: hidden;
  	display: -webkit-box;
  	-webkit-box-orient: vertical;

	@media (${screenSize.antXs}) {
  		-webkit-line-clamp: 5;
	}
	
	@media (${screenSize.antMd}) {
  		-webkit-line-clamp: 5;
	}

	@media (${screenSize.antXl}) {
  		-webkit-line-clamp: 3;
	}
`

export const StylistAlignment = styled.div`
	${fontRoboto}
	${bold16}
	color: #FF0C63;
	align-self: start;
	margin-top: 8px;
`
