import { Row } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, 468px);
	grid-gap: 48px;
`

export const RowBordered = styled(Row)`
	border: 2px solid rgba(0, 0, 0, 0.05);
	border-radius: 12px;

	width: 100%;
	margin: 0 0 40px;
`
