import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ClientInfo, ReduxClients, ShowClientsListType } from '@typings/types'

const initialState: ReduxClients = {
	isListLoading: false,
	isItemLoading: false,
	showListType: 'active',
	clientsList: [],
	filteredClientsList: [],
}

const slice = createSlice({
	name: 'clients',
	initialState,
	reducers: {
		setIsListLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isListLoading = payload
		},
		setIsItemLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isItemLoading = payload
		},
		setShowListType: (
			state,
			{ payload }: PayloadAction<ShowClientsListType>
		) => {
			state.showListType = payload
		},
		setClientsList: (state, { payload }: PayloadAction<ClientInfo[]>) => {
			state.clientsList = payload
		},
		setFilteredClientsList: (
			state,
			{ payload }: PayloadAction<ClientInfo[]>
		) => {
			state.filteredClientsList = payload
		},
	},
})

export const clientsReducer = slice.reducer
export const {
	setIsListLoading,
	setIsItemLoading,
	setShowListType,
	setClientsList,
	setFilteredClientsList,
} = slice.actions
