import { InfoBlock, TextLabel } from './info-text-block-styled'

export const InfoTextBlock = ({
	label,
	text,
}: {
	label: string
	text: string
}) => (
	<InfoBlock>
		<TextLabel>{label}</TextLabel>
		{text}
	</InfoBlock>
)
