import styled from 'styled-components'
import { Col, Row } from 'antd'

export const RowStyled = styled(Row)`
	flex-flow: column;
	height: 100%;
`

export const QuizCol = styled(Col)`
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: space-around;
	flex: 1 1 50%;

	&.results-frame {
		flex: unset;
	}
`
