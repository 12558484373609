import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { ImageItemType } from '@typings/types'
import { Container } from './gallery-block-styled'
import { ClothesItem } from '../clothes-item'

type Props = {
	carouselRef?: any
	imageList: ImageItemType[]
	handleSetCoverClick: (imageId: number) => void
	handleDeleteClick: (imageId: number) => void
}

export function GalleryBlock({
	carouselRef,
	imageList,
	handleSetCoverClick,
	handleDeleteClick,
}: Props) {
	const responsive = {
		xxl: {
			// the naming can be any, depends on you.
			breakpoint: { max: 2500, min: 1600 },
			items: 3,
		},
		xl: {
			breakpoint: { max: 1599, min: 1400 },
			items: 3,
		},
		smallXl: {
			breakpoint: { max: 1399, min: 1200 },
			items: 2,
		},
	}

	return (
		<Container>
			<Carousel
				responsive={responsive}
				arrows={false}
				ref={(el) => (carouselRef.current = el)}
				containerClass="carousel-container"
				itemClass="carousel-item"
				renderButtonGroupOutside={true}
				centerMode={false}
				partialVisible={false}
			>
				{imageList.map((clothItem, index) => (
					<ClothesItem
						key={index}
						imageItem={clothItem}
						handleSetCoverClick={handleSetCoverClick}
						handleDeleteClick={handleDeleteClick}
					/>
				))}
			</Carousel>
		</Container>
	)
}
