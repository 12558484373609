import { colors } from './../../styles/colors'
import { bold14, fontRoboto } from '@styles/index'
import { Button } from '@ui/index'
import styled from 'styled-components'
import { Row } from 'antd'

export const RowBordered = styled(Row)`
	border: 1px solid ${colors.blackTr5};
	border-radius: 12px;
	padding: 12px;
`

export const ButtonStyled = styled(Button)`
	${fontRoboto}
	${bold14}
  color: ${colors.blackTr64};

	padding: 10px 16px;
	float: right;
`
export const ButtonBack2Quiz = styled(Button)`
	margin: 0 auto;
`
