import axios from 'axios'
import { axiosInstance } from '../config'

export async function signToInterview(profileId: number, token: string) {
	try {
		const response = await axiosInstance.get(
			`/api/stylist/${profileId}/signup-interview`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return !!response.data.success
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
