import styled from 'styled-components'

import { screenSize } from '@styles/screen-sizes'
import { fontRoboto } from '@styles/typography'
import { colors } from '@styles/colors'

export const FooterWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: start;
	padding: 20px 48px;
	width: 100%;
	background-color: ${colors.blackTr8};
	color: ${colors.blackTr64};
	gap: 16px;


	@media (${screenSize.antXs}) {
		padding: 20px 20px;
		align-items: start;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
	}

	@media (${screenSize.antMd}) {
		padding: 20px 48px;
	}
`

export const MenuWrapper = styled.div`
	display: flex;
	text-align: start;
	gap: 16px;
	flex-direction: row;
	flex-wrap: wrap;
	flex: 1 1 auto;

	@media (${screenSize.antMd}) {
		flex-direction: row;
		text-align: start;
	}

	@media (${screenSize.antLg}) {
		gap: 16px 48px;
	}
`

export const IconsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: start;
	gap: 30px;
`

export const MenuItem = styled.div`
	${fontRoboto}

	cursor: pointer;
	width: 30%;

	a {
		color: ${colors.blackTr64};
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0.13px;

		text-decoration: none;
	}

	@media (${screenSize.antXs}) {
		width: calc(50% - 8px);
	}

	@media (${screenSize.antLg}) {
		width: 20%;
	}

	@media (${screenSize.antLg}) {
		width: unset;
	}
`
