import { Row } from 'antd'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { ItemInfo } from '@typings/types'
import { ClothesItem } from '../clothes-item'
import { ColStyled } from './gallery-block-styled'

type Props = {
	carouselRef: any
	imageList: ItemInfo[]
}

export function GalleryBlock({ carouselRef, imageList }: Props) {
	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 1600 },
			items: 9,
		},
		desktop: {
			breakpoint: { max: 1599, min: 1400 },
			items: 7,
		},
		desktopSmall: {
			breakpoint: { max: 1399, min: 1200 },
			items: 5,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	}

	return (
		<Row gutter={10}>
			<ColStyled span={24} style={{ marginTop: 10 }}>
				<Carousel
					ref={(el) => (carouselRef.current = el)}
					responsive={responsive}
					arrows={false}
					containerClass="carousel-container"
					itemClass="basket-gallery-item"
					renderButtonGroupOutside={true}
					centerMode={false}
					partialVisbile={false}
				>
					{imageList.map((clothItem, index) => (
						<ClothesItem key={index} clothItem={clothItem} onClick={() => {}} />
					))}
				</Carousel>
			</ColStyled>
		</Row>
	)
}
