import { Col, Row } from 'antd'
import Carousel from 'react-multi-carousel'
import { useNavigate } from 'react-router-dom'
import 'react-multi-carousel/lib/styles.css'

import { CapsuleInfo } from '@typings/types'
import { GalleryButtons, Swap, Skeleton } from '@ui/index'
import { Container, LoaderCont } from './gallery-block-styled'
import { CapsulesItem } from '../capsules-item'

type Props = {
	carouselRef: any
	imageList: CapsuleInfo[]
}

export function GalleryBlock({ carouselRef, imageList }: Props) {
	const navigate = useNavigate()

	const ButtonGroup = () => {
		return (
			<Col span={6}>
				<GalleryButtons
					onNextArrowClick={() => carouselRef.current?.next()}
					onPrevArrowClick={() => carouselRef.current?.previous()}
				/>
			</Col>
		)
	}

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	}

	const Loader = () => {
		return (
			<LoaderCont>
				{[1, 2, 3].map((item) => (
					<Skeleton key={item} width={'100%'} height={452}>
						<rect x="0" y="0" rx="6" ry="6" width="100%" height={452} />
					</Skeleton>
				))}
			</LoaderCont>
		)
	}

	return (
		<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 48 }}>
			<Col span={18} className="row-block-label">
				My work
			</Col>
			<ButtonGroup />
			<Col span={24} style={{ marginTop: 58 }}>
				<Swap is={!imageList.length} isSlot={<Loader />}>
					<Carousel
						ref={(el) => (carouselRef.current = el)}
						responsive={responsive}
						arrows={false}
						containerClass="carousel-container"
						renderButtonGroupOutside={true}
						centerMode={false}
					>
						{imageList.map((item) => (
							<Container key={item.id}>
								<CapsulesItem
									item={item}
									onClick={() => {
										navigate(`/capsules/${item.id}`, {
											state: {
												capsuleId: item.id,
											},
										})
									}}
								/>
							</Container>
						))}
					</Carousel>
				</Swap>
			</Col>
		</Row>
	)
}
