import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuItemType } from '../use-left-menu'

export function useLeftMenuItem(item: MenuItemType) {
	const [isActiveMenuItem, setIsActiveMenuItem] = useState(false)
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const mainDomain = pathname.split('/')[1]

	const menuItemClickHandler = (route: string) => {
		navigate(route)
	}

	useEffect(() => {
		setIsActiveMenuItem(item.route === mainDomain)
	}, [item.route, mainDomain])

	return {
		menuItemClickHandler,
		isActiveMenuItem,
	}
}
