import { screenSize } from '@styles/screen-sizes'
import { fontTenorSans, regular26 } from '@styles/typography'
import { Block } from '@ui/block'
import styled from 'styled-components'

export const StyledBlock = styled(Block)`
	${fontTenorSans}
	${regular26}

	& > div > div {
		cursor: pointer;
		margin-right: 25px;
		transform: rotate(180deg);
	}

	@media (${screenSize.maxTablet}) {
		display: none;
	}
`
export const SpanStyled = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
`
