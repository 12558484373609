import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../store'
import {
	authSelector,
	setUserCode,
	setUserEmail,
	setUserFirstName,
} from '@store/auth'
import { setLoginFormStep } from '@store/login-flow'
import { createUser } from '@pages/login/api'

type FormValues = {
	name: string
	email: string
}

export function useNewClientForm() {
	const dispatch = useAppDispatch()
	const [isValidated, setIsValidated] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const { email } = useAppSelector(authSelector)

	const {
		register,
		handleSubmit,
		formState: { errors, defaultValues },
		setError,
		watch,
	} = useForm<FormValues>({
		defaultValues: {
			email: email,
		},
	})

	const createUserHandler = useCallback(
		async ({ email, name }: { email: string; name: string }) => {
			setIsLoading(true)

			const createUserData = await createUser({ email, name })

			if (createUserData?.success) {
				createUserData && dispatch(setUserCode(createUserData.code))
				setIsLoading(false)
				dispatch(setLoginFormStep('code'))
				return
			}

			setIsLoading(false)
			const emailErrorMessage = createUserData?.errors?.email?.[0]
			const nameErrorMessage = createUserData?.errors?.name?.[0]
			!!emailErrorMessage &&
				setError('email', { type: 'validate', message: emailErrorMessage })
			!!nameErrorMessage &&
				setError('name', { type: 'validate', message: nameErrorMessage })
		},
		[dispatch, setError]
	)

	const onSubmitNewUser = async (data: FormValues) => {
		if (!data.name) {
			setError('name', { type: 'validate', message: 'Please enter name' })
			return
		}

		if (!data.email) {
			setError('email', { type: 'validate', message: 'Please enter email' })
			return
		}

		dispatch(setUserEmail(data.email))
		dispatch(setUserFirstName(data.name))

		await createUserHandler({
			email: data.email,
			name: data.name,
		})
	}

	const onCancelClick = () => {
		dispatch(setLoginFormStep('email'))
	}

	const emailRegexp =
		// eslint-disable-next-line no-useless-escape
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	const emailRegister = register('email', {
		onChange: (e) => {
			setIsValidated(emailRegexp.test(e.target.value))
		},
	})

	return {
		defaultValues,
		emailRegister,
		errors,
		handleSubmit,
		isLoading,
		isValidated,
		onCancelClick,
		onSubmitNewUser,
		register,
		watch,
	}
}
