import { colors } from '@styles/colors'
import { NavigationButtonInfo } from '@typings/types'
import { Icon } from '@ui/icon'
import { Col, Row } from 'antd'
import {
	ButtonAll,
	ButtonDraft,
	ButtonFilter,
	ColBordered,
	ColRightAlign,
	IconWrapper,
	SearchStyled,
} from './page-navigation-styled'

type Props = {
	showListType?: string
	isShowAllBtn?: boolean
	buttons?: NavigationButtonInfo[]
	handleChangeSearch?: (data: any) => void
	onFilterClick?: () => void
	leftSideSlot?: React.ReactNode
	rightSideSlot?: React.ReactNode
}

export function PageNavigation({
	showListType,
	isShowAllBtn = true,
	buttons,
	handleChangeSearch,
	onFilterClick,
	leftSideSlot,
	rightSideSlot,
}: Props) {
	const haveRightPart = rightSideSlot || handleChangeSearch

	return (
		<Row gutter={0} wrap={false} style={{ width: '100%' }}>
			<ColBordered span={24}>
				<Row gutter={[16, 20]} style={{ height: '100%' }}>
					{leftSideSlot}
					{!leftSideSlot && buttons && (
						<Col
							xs={{span: 24}}
							lg={{span: 12}}
							style={{display: 'flex'}}
						>
							{buttons.map((button, index) => {
								if (index === 0 && isShowAllBtn) {
									return (
										<ButtonAll
											key={index}
											isActive={showListType === button.showListType}
											onClick={button.handleClick}
											skin="grey-outline"
											isFitContent
											counter={button.counter}
											leftIcon={
												button.iconName && (
													<Icon
														color={
															showListType === button.showListType
																? '#FF0C63'
																: colors.blackTr64
														}
														name={button.iconName}
													/>
												)
											}
											rightIcon={
												button.showNotificationIcon && (
													<IconWrapper>
														<Icon
															color="white"
															name="bellRingingFilledIcon"
															size={9}
														/>
													</IconWrapper>
												)
											}
										>
											{button.title}
										</ButtonAll>
									)
								}

								return (
									<ButtonDraft
										key={index}
										color={button.color}
										isFitContent
										style={{ padding: '30px 18px' }}
										isActive={showListType === button.showListType}
										onClick={button.handleClick}
										skin="grey-outline"
										counter={button.counter}
										leftIcon={
											button.node
											? button.node
											: button.iconName && (
												<Icon
													color={
														showListType === button.showListType
															? '#FF0C63'
															: colors.blackTr64
													}
													name={button.iconName}
												/>
											)
										}
										rightIcon={
											button.showNotificationIcon && (
												<IconWrapper>
													<Icon
														color="white"
														name="bellRingingFilledIcon"
														size={9}
													/>
												</IconWrapper>
											)
										}
									>
										{button.title}
									</ButtonDraft>
								)
							})}
						</Col>
					)}

					{haveRightPart && (
						<ColRightAlign xs={{span: 24}} lg={{flex: "1 1 50%", offset: !buttons ? 12 : 0}}>
							{rightSideSlot}
							{handleChangeSearch && (
								<>
									<SearchStyled
										placeholder="Search"
										allowClear
										onChange={handleChangeSearch}
										style={{ flex: '1 1 auto' }}
									/>
									<ButtonFilter
										isFitContent
										onClick={onFilterClick}
										leftIcon={
											<Icon
												color="linear-gradient(180deg, #FF0C63 0%, #FF5656 100%);"
												name="filtersIcon"
											/>
										}
										skin="grey-outline"
									></ButtonFilter>
								</>
							)}
						</ColRightAlign>
					)}
				</Row>
			</ColBordered>
		</Row>
	)
}
