import { EmailLogin } from './email-login'
import { LoginLayout } from './login-layout'

export function LoginPage() {
	return (
		<LoginLayout>
			<EmailLogin />
		</LoginLayout>
	)
}
