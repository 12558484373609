import { Col, Row, Modal } from 'antd'
import { useRef } from 'react'

import { colors } from '@styles/colors'
import { PROD_DOMAIN } from '@typings/constants'
import {
	Block,
	Button,
	GalleryButtons,
	Input,
	Spinner,
	Swap,
	Icon,
} from '@ui/index'
import {
	UploadedFile,
	UploadFileWrapper,
	ErrorBlock,
	ModalTitle,
} from './cv-form-styled'
import { useCvForm } from './use-cv-form'
import { AddPhotoButton } from './add-photo-button/add-photo-button'
import { AddLinkButton } from './add-link-button/add-link-button'
import { GalleryBlock } from './gallery-block/gallery-block'
import { SdNotification } from '@components/sd-notification'

export function CvForm() {
	const {
		handleCvFileAdd,
		handleCvLinkDelete,
		handleDelete,
		isCvLoading,
		isCvLoadingError,
		portfolioFiles,
		showModal,
		handleCancel,
		handleOk,
		isModalOpen,
		cvItemsArr,
		setNewCvLink,
		newCvLink,
		newCvLinkError,
		isCvLinkLoading,
	} = useCvForm()

	const carouselRef = useRef<any>()

	const ButtonGroup = () => {
		return (
			<Col span={6}>
				<GalleryButtons
					onNextArrowClick={() => carouselRef.current?.next()}
					onPrevArrowClick={() => carouselRef.current?.previous()}
				/>
			</Col>
		)
	}

	return (
		<>
			<Col xxl={{ span: 6 }} xl={{ span: 4 }} className="row-block-label">
				My files
			</Col>
			<Col xxl={{ span: 18 }} xl={{ span: 20 }}>
				<Row>
					<Col span={18}>
						<SdNotification text="CV and portfolio are only needed for the interview with the StyleDyle team, your future clients will not see them." />
					</Col>
					{!!cvItemsArr.length && <ButtonGroup />}
				</Row>
				<Row gutter={[16, 26]} style={{ marginTop: 26 }}>
					<Col flex={'0 1 170px'}>
						{/*xxl={{ span: 3 }} xl={{ span: 4 }} */}
						<UploadFileWrapper>
							<AddPhotoButton isNewPhotoLoading={isCvLoading} />
							<input
								type="file"
								id="upload-file-cv"
								accept=".doc,.docx,.xls,.xlsx,.txt,.pdf,.jpg,.jpeg,.png,.rtf,.zip,.rar,.7z"
								onChange={handleCvFileAdd}
							/>
						</UploadFileWrapper>
					</Col>
					<Col flex={'0 1 170px'}>
						<AddLinkButton onClick={showModal} />
						<Modal
							centered
							open={isModalOpen}
							footer={null}
							onCancel={handleCancel}
						>
							<Swap is={isCvLinkLoading} isSlot={<Spinner />}>
								<ModalTitle>Add your link</ModalTitle>
								<Block margin="20px 0">
									<Input
										label="cvLink"
										inputFieldSlot={
											<input
												value={newCvLink}
												type="text"
												onChange={(e: any) => setNewCvLink(e.target.value)}
											/>
										}
										width="648px"
										placeholder="Add link here"
										hasValue={!!newCvLink}
										isError={!!newCvLinkError}
										errorText={newCvLinkError}
									/>
								</Block>
								<Row justify={'center'}>
									<Col span={8}>
										<Button skin="grey-link" onClick={handleCancel}>
											Cancel
										</Button>
									</Col>
									<Col offset={2} span={8}>
										<Button skin="dark" onClick={handleOk}>
											Save
										</Button>
									</Col>
								</Row>
							</Swap>
						</Modal>
					</Col>
					<Col flex={'1 1 auto'} style={{ overflow: 'hidden' }}>
						<GalleryBlock
							carouselRef={carouselRef}
							itemsList={cvItemsArr}
							onLinkDelete={handleCvLinkDelete}
							onFileDelete={handleDelete}
						/>
					</Col>
				</Row>
				{!!isCvLoadingError.length && (
					<ErrorBlock>{isCvLoadingError}</ErrorBlock>
				)}

				{!!portfolioFiles.length && (
					<Block>
						{portfolioFiles.map((portfolio, index) => (
							<UploadedFile key={portfolio.file}>
								<Block margin="0 10px 0 0">
									<Icon
										name="scrapIcon"
										color={colors.mainThemeColor}
										size={20}
									/>
								</Block>
								<a
									href={PROD_DOMAIN + portfolio.urls.origin}
									target="_blank"
									rel="noreferrer"
								>
									<Block>{`Portfolio ${index + 1}`}</Block>
								</a>
								<Block
									margin="0 0 0 5px"
									isClickable
									onClick={() => handleDelete(portfolio.file)}
								>
									<Icon name="crossIcon" color="black" size={16} />
								</Block>
							</UploadedFile>
						))}
					</Block>
				)}
			</Col>
		</>
	)
}
