import axios from 'axios'
import { axiosInstance } from '../config'
import { UserInfo } from '@typings/types'

export async function updateProfile(
	request: string,
	userId: number,
	token: string
) {
	try {
		const response = await axiosInstance.put<{
			success: boolean
			user: UserInfo
		}>(`/api/user/${userId}`, request, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		if (!response.data.success) {
			return {
				isSuccess: response.data.success,
				user: null,
			}
		}

		return {
			isSuccess: response.data.success,
			user: response.data.user,
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
