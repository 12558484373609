import { ClientInfo } from '@typings/types'
import axios from 'axios'
import { axiosInstance } from '../../config'

export async function getAvailableClients({ token }: { token: string }) {
	try {
		const response = await axiosInstance.get<ClientInfo[]>(
			`/api/capsule/client-list`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
