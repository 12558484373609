import { Outlet } from 'react-router-dom'
import { useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { NotFoundPage } from '@pages/not-found-page/not-found-page'

export function RoleClient() {
	const { userInfo } = useAppSelector(userSelector)

	if (userInfo.role === 'client') return <Outlet />

	return <NotFoundPage />
}
