import {
	bold14,
	fontRoboto,
	fontTenorSans,
	regular26,
} from '@styles/typography'
import { Row } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	margin: 0 48px 48px;

	width: 100%;
`

export const StatusBlock = styled.div`
	${fontRoboto}
	${bold14}
	color: blue;
	border-radius: 8px;
	border: 1px solid blue;
	padding: 2px 8px;
	margin: auto;
	margin-right: 12px;
`

export const SharedClientBlock = styled.div`
	${fontRoboto}
	${bold14}
	border-radius: 8px;
	border: 1px solid black;
	padding: 2px 8px;
	margin: auto;
	margin-right: 12px;
	display: flex;
	flex-flow: row;
	align-items: center;
	gap: 5px;
`

export const StyleboardLabel = styled.div`
	${fontTenorSans}
	${regular26}
`

export const RowNoLeftPadding = styled(Row)`
	& > div:first-child {
		padding-left: 0 !important;
	}
`
