import { Col } from 'antd'
import styled, { css } from 'styled-components'

import { bold14, fontRoboto, regular13 } from '@styles/typography'
import { colors } from '@styles/colors'

export const CapsuleCollageWrapper = styled.div`
	padding: 36px 20px;
	margin: auto;

	max-width: 393px;
	height: 612px;
	overflow: hidden;

	background: #ffffff;
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 20px;

	& img {
		object-fit: contain;
	}
`

export const ImageCont = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
			box-shadow: none;
		`}
	background-color: ${colors.white};
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
`

export const ColFlex = styled(Col)`
	display: flex;
	flex-direction: row;
`
export const CreatedDate = styled.span`
	${fontRoboto}
	${bold14}
  color: ${colors.blackTr64};
`

export const ShowcaseBtnText = styled.span`
	${fontRoboto}
	${regular13}
  display: flex;
	height: 100%;
	align-items: center;
	letter-spacing: 0.01em;
	margin-right: 12px;
`

export const ShowcaseBtn = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	margin: 0 5px;
`

export const ColParamLabel = styled(Col)`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr48};
	align-self: center;
`

export const FullScrIcon = styled.div`
	position: absolute;
	top: 26px;
	left: 26px;
	width: 48px;
	height: 48px;
	background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
	border-radius: 8px;
	padding: 12px;
`
