import { colors } from '@styles/colors'
import {
	bold12,
	bold14,
	bold16,
	fontRoboto,
	fontTenorSans,
	regular26,
} from '@styles/typography'
import { Button } from '@ui/button'
import { Col } from 'antd'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'

export const StyleboardContainer = styled.div`
	position: relative;

	& > img {
		border-radius: 20px;
		margin: 24px 0;
	}
`

export const StylistInfoBlock = styled.span`
	${fontRoboto}
	${bold12}
	background: ${colors.black};
	color: ${colors.white};
	position: absolute;
	top: 16px;
	left: 16px;
	padding: 4px 16px;
	border-radius: 8px;
`

export const SectionBlock = styled.div`
	height: 100%;
	padding: 32px 40px;
	border-radius: 20px;
	background: ${colors.blackTr5};
`

export const StyleboardTitle = styled.div`
	${fontTenorSans}
	${regular26}

	&>span {
		margin-right: 10px;
	}
`

export const BlankContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const ColStyled = styled(Col)`
	border-radius: 20px;
	overflow: hidden;
	aspect-ratio: 270 / 276;

	display: flex;
	align-items: center;

	&:hover {
		box-shadow: 0px 6px 32px ${colors.blackTr8};
	}
`

export const ButtonAdd = styled(Button)`
	${fontRoboto}
	${bold16}
	color: #FF0C63;
	padding: 0;
`

export const EmptyCover = styled.div`
	border-radius: 20px;
	overflow: hidden;
	width: 100%;

	opacity: 0.3;
`

export const GlobalStyle = createGlobalStyle`
  body {
    & .ant-popover {
			padding-top: 12px !important;
			width: 250px;

			& .ant-popover-inner {
				padding: 12px 16px;
				border-radius: 0 12px 12px 12px;
			}

			& .styledyle-content {
				${fontRoboto}
				${bold14}
			}
		}
  }
`
