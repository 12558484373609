import axios from 'axios'
import { axiosInstance } from '../../config'
import { CreateResponse } from '../../typings/types'

type Props = {
	capsuleId: number
	token: string
}

export async function cloneCapsule({ capsuleId, token }: Props) {
	try {
		const response = await axiosInstance.get<CreateResponse>(
			`/api/capsule/${capsuleId}/clone`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
