import { PageNavigation } from '@components/page-navigation/page-navigation'
import { colors } from '@styles/colors'
import { PROD_DOMAIN } from '@typings/constants'
import { BlankPlaceholder } from '@ui/blank-placeholder/blank-placeholder'
import { Block, GrayLine, Icon, Swap } from '@ui/index'
import { Col, Row } from 'antd'
import {
	AntCSSlStyle,
	ColCoverBlock,
	ColFlex,
	ColParamLabel,
	CopyLinkButton,
	FullScrIcon,
	ImageCont,
	ImageStyled,
	InputClothLink,
	ParamValue,
	PriceValue,
	SpaceBlock,
} from './client-clothes-preview-page-styled'
import { ColorsBlock } from './colors-block/colors-block'
import { GalleryBlock } from './gallery-block/gallery-block'
import { useClothesPreviewPage } from './use-client-clothes-preview-page'

export function ClientClothesPreviewPage() {
	const {
		token,
		item,
		currency,
		imageItems,
		navigationButtons,
		handleClothLinkSelect,
		handleLinkClick,
	} = useClothesPreviewPage()
	const clothPhotoUrlM = item.coverPhoto?.urls
		? PROD_DOMAIN + item.coverPhoto?.urls.M
		: ''
	const clothPhotoUrlL = item.coverPhoto?.urls
		? PROD_DOMAIN + item.coverPhoto?.urls.L
		: ''

	if (!item) return null

	return (
		<Block isFullWidth margin={token ? '0 48px 48px' : '48px'}>
			<AntCSSlStyle />
			{token && (
				<PageNavigation showListType="preview" buttons={navigationButtons} />
			)}
			<Row gutter={[32, 48]} >
				<ColCoverBlock xxl={{ span: 6 }} lg={{ span: 8 }} md={{ span: 9, offset: 0 }} xs={{ span: 22, offset: 1 }}>
					<Swap
						is={!clothPhotoUrlM}
						isSlot={
							<ImageCont
								imageUrl={''}
								style={{
									backgroundColor: !clothPhotoUrlM ? 'none' : colors.blackTr5,
								}}
							>
								{!clothPhotoUrlM && (
									<BlankPlaceholder color={colors.blackTr24} />
								)}
							</ImageCont>
						}
					>
						<ImageStyled
							src={clothPhotoUrlM}
							alt=""
							preview={{
								src: clothPhotoUrlL,
								mask: (
									<FullScrIcon>
										<Icon color="white" name="fullScreenIcon" />
									</FullScrIcon>
								),
							}}
						/>
					</Swap>
				</ColCoverBlock>
				<Col xxl={{ span: 6 }} xl={{ span: 7, offset: 0 }} md={{ span: 12, offset: 1 }}  xs={{ span: 22, offset: 1 }}>
					<div className="row-block-label">Description</div>
					<SpaceBlock />
					<Row>
						<ColParamLabel span={8}>Category</ColParamLabel>
						<Col span={16}>
							{item.params.category && (
								<ParamValue>{item.params.category}</ParamValue>
							)}
						</Col>
						<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
							<GrayLine />
						</Col>
						<ColParamLabel span={8}>Brand / Designer</ColParamLabel>
						<Col span={16}>
							{item.brand && <ParamValue>{item.brand}</ParamValue>}
						</Col>
						<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
							<GrayLine />
						</Col>
						<ColParamLabel span={8}>Size</ColParamLabel>
						<Col span={16}>
							{item.params.size && <ParamValue>{item.params.size}</ParamValue>}
						</Col>
						<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
							<GrayLine />
						</Col>
						<ColParamLabel span={8}>Colors</ColParamLabel>
						<Col span={16}>
							<ColorsBlock colors={item.params.color} />
						</Col>
						<Col span={24} style={{ marginTop: 58 }}></Col>
						<ColParamLabel span={8}>Price</ColParamLabel>
						<Col span={16}>
							<PriceValue>
								{currency} {item.price}
							</PriceValue>
						</Col>
						<Col span={24} style={{ marginTop: 58 }}></Col>
						<ColParamLabel span={8}>Link</ColParamLabel>
						<ColFlex span={16}>
							<InputClothLink
								onClick={handleClothLinkSelect}
								type="text"
								readOnly
								value={item.source_url || ''}
							/>
							<CopyLinkButton
								onClick={handleLinkClick}
								skin="grey-link"
								leftIcon={<Icon color={colors.blackTr64} name="openExtIcon" />}
							/>
						</ColFlex>
					</Row>
				</Col>
				<Col xxl={{ span: 12 }} xl={{ span: 10, offset: 0 }} xs={{ span: 22, offset: 1 }}>
					<GalleryBlock imageList={imageItems} />
				</Col>
			</Row>
		</Block>
	)
}
