import { CreateUserResponse } from '@typings/types'
import axios from 'axios'
import { axiosInstance } from '../../../config'

type Props = {
	email: string
	name: string
	isSubscribed?: boolean
}

export async function createUser({ email, name, isSubscribed }: Props) {
	try {
		const response = await axiosInstance.post<CreateUserResponse>(
			'/api/user/create',
			JSON.stringify({
				role: 'client',
				email,
				client: {
					first_name: name,
					params: {
						subscribed: !!isSubscribed,
					},
				},
			})
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
