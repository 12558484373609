import { AvailableTariff } from '@typings/types'
import axios from 'axios'
import { axiosInstance } from '../../config'

export async function getAvailableTariffs({ token }: { token: string }) {
	try {
		const response = await axiosInstance.get(`/api/tariff/get-avaliable`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
