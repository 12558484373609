import { Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom'

import { Block } from '@ui/block'
import { Button } from '@ui/button'
import { Container } from '../stylist-registration-page/stylist-registration-page-styled'
import {
	BgCont,
	ImageBlock,
	InterviewMsg,
	NoteText,
	TextBlockCol,
} from './client-book-success-page-styled'

export function ClientBookSuccessPage() {
	const navigate = useNavigate()

	return (
		<Container>
			<BgCont></BgCont>
			<Block>
				<TextBlockCol>
					Awesome! <br />
					Your booking request has been sent
				</TextBlockCol>
				<InterviewMsg>
					The stylist typically confirms
					<br /> within 24 hrs, often sooner
				</InterviewMsg>
				<Row gutter={[12, 24]}>
					<Col flex="none">
						<Button
							isSubmit
							skin="dark"
							onClick={() => {
								navigate('/client-profile')
							}}
							style={{ padding: '16px 36px' }}
							isFitContent
						>
							Go to your profile
						</Button>
					</Col>
					<Col flex="1 1 200px">
						<NoteText>
							use this time to complete <br />
							your profile set up
						</NoteText>
					</Col>
				</Row>
			</Block>
			<ImageBlock />
		</Container>
	)
}
