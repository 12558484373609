export const colors = {
	mainThemeColor: '#FF0C63',
	mainLightThemeColor: 'rgba(255, 86, 86, 0.08)',
	grayText: '#747474',
	white: '#ffffff',
	black: '#000000',
	primary: '#000000',
	secondary: '#B5B5B5',
	bgColor: '#ffffff',
	buttonDisabled: '#898687',
	buttonLight:
		'linear-gradient(180deg, rgba(255, 12, 99, 0.08) 0%, rgba(255, 86, 86, 0.08) 100%)',
	buttonDark: 'linear-gradient(180deg, #FF0C63 0%, #FF5656 100%)',
	inputBackground: '#594359',
	mainBackground: '#E5E5E5',
	darkGray: '#858585',
	error: '#ff6565',

	/* Styleguide Colors */
	/* Transparent colors */
	whiteTr80: '#FFFFFFcc',
	whiteTr56: '#FFFFFF8f',
	whiteTr24: '#FFFFFF3d',
	whiteTr8: '#FFFFFF14',
	blackTr80: '#000000cc',
	blackTr64: '#000000a3',
	blackTr56: '#0000008f',
	blackTr48: '#0000007a',
	blackTr24: '#0000003d',
	blackTr16: '#00000029',
	blackTr12: '#0000001f',
	blackTr8: '#00000014',
	blackTr5: '#0000000d',
}
