import { useAppDispatch, useAppSelector } from '@store/store'
import { QuizSelector, setLocalQuizAnswers } from '@store/quiz'

type Props = {
	isMultiAnswer?: boolean
}

export const useQuizAnswers = ({ isMultiAnswer = false }: Props) => {
	const dispatch = useAppDispatch()
	const { currentStep, answers } = useAppSelector(QuizSelector)
	const currentAnswers = answers[currentStep] ?? ''

	const handleSaveAnswer = async (answer: string) => {
		if (isMultiAnswer) {
			if (currentAnswers.includes(answer)) {
				const filteredAnswers = currentAnswers.filter((item) => item !== answer)
				dispatch(
					setLocalQuizAnswers({
						answer: filteredAnswers,
						step: currentStep,
					})
				)
				return
			}

			dispatch(
				setLocalQuizAnswers({
					answer: [...currentAnswers, answer],
					step: currentStep,
				})
			)

			return
		}

		dispatch(
			setLocalQuizAnswers({
				answer: [answer],
				step: currentStep,
			})
		)
	}

	return { handleSaveAnswer }
}
