import styled, { css } from 'styled-components'
import { fontRoboto, fontTenorSans, regular20 } from '@styles/typography'
import { colors } from '@styles/colors'

export const Wrapper = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	max-width: 393px;
	width: 100%;
	padding: 36px 36px 0;
	background: #ffffff;
	border-radius: 20px;
	cursor: pointer;
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 464px;
	height: 100%;

	&:hover {
		${Wrapper} {
			box-shadow: 0px 6px 32px ${colors.blackTr8};
		}
	}
`

export const ImageCont = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 392px;
	border-radius: 20px;
	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
			box-shadow: none;
		`}
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export const InfoCont = styled.div`
	padding: 20px 5%;
	margin: -50px auto 0;
	width: 100%;
	position: relative;
	z-index: 100;
	background: #ffffff;
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 12px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 10px;
	align-items: center;
	cursor: pointer;
`

export const IconCont = styled.div<{ imageUrl?: string; iconUrl?: string }>`
	position: relative;
	width: 56px;
	height: 56px;
	flex: 0 0 auto;
	border-radius: 50%;
	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
			box-shadow: none;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		`}

	&::after {
		${({ iconUrl }) =>
			iconUrl &&
			css`
				content: '';
				position: absolute;
				padding: 2px;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background: rgba(0, 0, 0, 0.08);
				bottom: -5px;
				right: -5px;

				background-color: #fff;
				background-image: url(${iconUrl});
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				background-origin: content-box;
			`}
	}
`

export const TextCont = styled.div`
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	gap: 8px;
	max-width: 207px;
`

export const NameCont = styled.div`
	${fontTenorSans}
	${regular20}
	display: flex;
	align-items: center;
`

export const TitleCont = styled.div`
	${fontRoboto}
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px; /* 137.5% */

	display: block;
	color: #000000;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const DateCont = styled.div`
	${fontRoboto}
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.01em;
	color: rgba(0, 0, 0, 0.64);
`
export const ArrowCont = styled.div`
	flex: 0 0 auto;
`
