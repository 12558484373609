import { DealAction, DealInfo } from '@typings/types'
import { GrayLine } from '@ui/gray-line/gray-line'
import Avatar from '@components/avatar/avatar'
import { SdNotification } from '@components/sd-notification'
import moment from 'moment'
import { Swap } from '@ui/swap'
import { Col, Row } from 'antd'
import { Block } from '@ui/index'
import { chatSelector } from '@store/chat'
import { useAppSelector } from '@store/store'
import {
	ButtonStyled,
	DeadlineBlock,
	StatusTitle,
	TimeWrapper,
	UserinfoContainer,
	UserInfoWrapper,
	UserName,
} from './stylist-info-styled'
import { CounterWrapper } from '@ui/button/button-styled'
import { userSelector } from '@store/user'
import { useMemo } from 'react'

type Props = {
	dealInfo?: DealInfo
	onActionApply: (action: DealAction) => void
}

export function StylistInfoBlock({ dealInfo, onActionApply }: Props) {
	const { isChatLoading } = useAppSelector(chatSelector)
	const { userInfo } = useAppSelector(userSelector)
	const userName = dealInfo?.stylist
		? `${dealInfo?.stylist.first_name} ${dealInfo?.stylist.last_name}`
		: 'StyleDyle Stylist'

	const sdNotificationText =
		dealInfo?.dealStatus.statusText ||
		'Welcome to the styling studio! To kick off your styling journey, please book a stylist.'

	const unreadMessagesCount = useMemo(
		() =>
			userInfo.chatList?.find(
				(chat) => chat.cometchatUid === dealInfo?.stylist.cometchat_uid
			)?.unreadMessagesCount ?? 0,
		[userInfo.chatList, dealInfo?.stylist.cometchat_uid]
	)

	return (
		<>
			<UserinfoContainer>
				<Avatar
					imageUrl={dealInfo?.stylist?.params?.photo?.[0].urls?.M || ''}
					size="100%"
				/>
				<UserInfoWrapper>
					<UserName>{userName}</UserName>
					<GrayLine style={{ margin: '20px 0' }} />
					<SdNotification text={sdNotificationText} />
					{dealInfo?.dealStatus.statusDeadline && (
						<DeadlineBlock>
							Due date{' '}
							{moment(dealInfo?.dealStatus.statusDeadline).format(
								'DD MMM, hh:mm'
							)}
						</DeadlineBlock>
					)}
					<GrayLine style={{ margin: '20px 0' }} />
					<Row gutter={[20, 20]} justify={'start'}>
						<Swap
							is={!dealInfo?.dealStatus?.clientActions}
							isSlot={
								<Col xxl={{ span: 16, offset: 4 }} xl={{ span: 24 }}>
									<ButtonStyled
										skin={'dark'}
										onClick={() => onActionApply('book_another_stylist')}
										size="m"
									>
										Book a stylist
									</ButtonStyled>
								</Col>
							}
						>
							{dealInfo?.dealStatus?.clientActions &&
								dealInfo.dealStatus.clientActions.map((action, index) => (
									<Col
										xxl={{ span: 16, offset: 4 }}
										xl={{ span: 24 }}
										key={index}
									>
										<ButtonStyled
											isDisabled={
												!action.enabled ||
												(action.action === 'chat_open' && isChatLoading)
											}
											skin={'dark'}
											onClick={() => onActionApply(action.action)}
											size="m"
											isLoading={action.action === 'chat_open' && isChatLoading}
											rightIcon={
												action.action === 'chat_open' &&
												!!unreadMessagesCount && (
													<CounterWrapper>{unreadMessagesCount}</CounterWrapper>
												)
											}
										>
											{action.button_text}
										</ButtonStyled>
									</Col>
								))}
						</Swap>
					</Row>
					{dealInfo?.dealStatus?.status === 'done' &&
						dealInfo?.dealStatus.statusDeadline && (
							<>
								<GrayLine style={{ margin: '20px 0' }} />
								<Row gutter={[20, 20]} justify={'center'}>
									<Block
										isFlexBlock
										flexDirection="column"
										isFullWidth
										isContentCentered
										gap="6px"
									>
										<StatusTitle>Chat available</StatusTitle>
										<TimeWrapper>
											<span>until</span>{' '}
											{moment(
												dealInfo?.dealStatus.statusDeadline,
												'YYYY-MM-DD hh:mm:ss'
											).format('DD MMM, hh:mm')}
										</TimeWrapper>
									</Block>
								</Row>
							</>
						)}
				</UserInfoWrapper>
			</UserinfoContainer>
		</>
	)
}
