import { StyleboardElement } from '@typings/types'
import { LikeButtons } from './like-buttons/like-buttons'
import { PhotoWrapper } from './photo-item-styled'

type Props = {
	photo: StyleboardElement
}

export function PhotoItem({ photo }: Props) {
	return (
		<PhotoWrapper imageUrl={photo.url}>
			<LikeButtons photo={photo} />
		</PhotoWrapper>
	)
}
