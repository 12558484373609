import { useRef, useState } from 'react'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { LoadingOutlined } from '@ant-design/icons'

import { useAppDispatch, useAppSelector } from '@store/store'
import { authSelector } from '@store/auth'
import { capsulesSelector, setCapsule } from '@store/capsules'
import { PROD_DOMAIN } from '@typings/constants'
import { UploadImageRequest } from '@typings/types'
import { uploadImage } from '@api/upload-image'
import { updateCapsule } from '@api/capsule/update-capsule'
import { Block, Icon, Button } from '@ui/index'
import {
	UpdateImageWrapper,
	UploadText,
	StyledLabel,
	EditPhotoWrapper,
	Wrapper,
} from './upload-cover-styled'

export function UploadCover() {
	const { capsule } = useAppSelector(capsulesSelector)
	const { token } = useAppSelector(authSelector)
	const dispatch = useAppDispatch()

	const [isLoading, setIsLoading] = useState(false)
	const [imageUrl, setImageUrl] = useState(
		capsule.params?.coverPhoto?.[0].urls?.M
			? PROD_DOMAIN + capsule.params?.coverPhoto?.[0].urls?.M
			: ''
	)
	const [isCoverAdded, setIsCoverAdded] = useState(!!imageUrl)
	const hiddenPortfolioFileInput = useRef<HTMLInputElement | null>(null)

	const handleChange = async (e: any) => {
		if (!e.event || !e.fileList[0].originFileObj) {
			return
		}
		setIsLoading(true)

		const requestData: UploadImageRequest = {
			filename: e.fileList[0].originFileObj,
			relation_id: capsule.id.toString(),
			relation_type: 'capsule',
		}

		if (!capsule.id) {
			return
		}

		const newPhoto = await uploadImage({
			requestData,
			token,
		})

		if (newPhoto) {
			await updateCapsule(
				JSON.stringify({
					params: {
						coverPhoto: `image:${newPhoto.id}`,
					},
				}),
				capsule.id,
				token
			)

			const newCapsuleCover = PROD_DOMAIN + newPhoto.urls.M
			dispatch(
				setCapsule({
					...capsule,
					params: { ...capsule.params, coverPhoto: [newPhoto] },
				})
			)
			setImageUrl(newCapsuleCover)
			setIsCoverAdded(true)
			setIsLoading(false)
			return
		}
		setIsCoverAdded(false)
		setIsLoading(false)
	}

	return (
		<Wrapper>
			<ImgCrop rotationSlider aspect={1 / 1}>
				<Upload
					onChange={handleChange}
					maxCount={1}
					showUploadList={false}
					disabled={isLoading}
					className="cover-uploader"
				>
					<UpdateImageWrapper imageUrl={imageUrl}>
						<StyledLabel htmlFor="upload-photo" isCoverAdded={isCoverAdded}>
							{isLoading ? (
								<LoadingOutlined />
							) : isCoverAdded ? null : (
								<>
									<Block>
										<Icon name="uploadAvatarIcon" size={24} />
									</Block>
									<UploadText margin="10px 0 0 0">Add photo</UploadText>
								</>
							)}
						</StyledLabel>
					</UpdateImageWrapper>
					{isCoverAdded && (
						<Block
							isFlexBlock
							isContentCentered
							isFullWidth
							flexDirection="column"
						>
							<EditPhotoWrapper>
								<Button
									isDisabled={isLoading}
									skin="grey-outline"
									width={120}
									margin="24px 0 0 0"
									onClick={() =>
										hiddenPortfolioFileInput?.current &&
										hiddenPortfolioFileInput?.current.click()
									}
								>
									Edit photo
								</Button>
							</EditPhotoWrapper>
						</Block>
					)}
				</Upload>
			</ImgCrop>
		</Wrapper>
	)
}
