import { Select, SelectProps as AntSelectProps } from 'antd'
import { FocusEventHandler, ReactNode } from 'react'
import { SelectWrapper, PlaceHolder } from './select-styled'

export type OptionsSelect = {
	label: string | ReactNode
	value?: string
}

type TagRender = AntSelectProps['tagRender']

export type SelectProps = {
	mode?: 'multiple' | 'tags' | undefined
	placeholder?: string
	defaultValue?: any
	width?: number | string
	onChange: (value: string) => void
	onBlur?: FocusEventHandler<HTMLElement>
	options?: {
		label: string
		value?: string
		options?: OptionsSelect[]
	}[]
	isDisabled?: boolean
	searchEnabled?: boolean
	filterOption?: boolean
	multiItemsType?: 'text' | 'block' | 'client'
	allowClear?: boolean
	isError?: boolean
	tagRender?: TagRender
}

export function CustomSelect({
	defaultValue,
	mode = undefined,
	onChange,
	onBlur,
	options,
	placeholder,
	width,
	isDisabled,
	searchEnabled,
	filterOption,
	multiItemsType = 'text',
	allowClear,
	isError,
	tagRender,
}: SelectProps) {
	return (
		<SelectWrapper width={width} multiItemsType={multiItemsType}>
			<PlaceHolder>{placeholder}</PlaceHolder>
			<Select
				status={isError ? 'error' : ''}
				value={defaultValue}
				mode={mode}
				allowClear={allowClear}
				defaultValue={defaultValue}
				placeholder={placeholder}
				onChange={onChange}
				options={options}
				disabled={isDisabled}
				onBlur={onBlur}
				showSearch={searchEnabled}
				optionFilterProp={searchEnabled ? 'children' : ''}
				tagRender={tagRender}
				filterOption={
					searchEnabled
						? (input, option) =>
								(option?.label.toLowerCase() ?? '').includes(
									input.toLowerCase()
								)
						: () => {
								return false
						  }
				}
				filterSort={
					searchEnabled
						? (optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
						: () => {
								return 0
						  }
				}
			/>
		</SelectWrapper>
	)
}
