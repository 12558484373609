import { colors } from '../../../../styles/colors'
import {
	fontTenorSans,
	fontRoboto,
	bold16,
} from '../../../../styles/typography'
import styled from 'styled-components'

export const ButtonsBlock = styled.div`
	${fontRoboto}
	${bold16}
	margin-top: 66px;
	display: flex;
	color: ${colors.black};

	> * {
		width: 50%;
	}
`

export const CancelBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;

	.container {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 0px;
		gap: 8px;
		height: 56px;
	}

	.character {
		${fontRoboto}
		font-weight: 400;
		font-size: 16px;
		line-height: 56px;
		background: #ffffff;
		border: 1px solid rgba(0, 0, 0, 0.12);
		border-radius: 8px;
	}

	.character--inactive {
		background-color: rgba(255, 255, 255, 0.1);
		box-shadow: none;
	}

	.character--selected {
		border: 1px solid white;
	}
`
export const InputsWrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;

	gap: 8px;
`

export const ErrorCodeMessage = styled.div`
	display: flex;
	align-self: center;
	margin-top: 10px;
	color: red;
`

export const StyledPhoneP = styled.div`
	${fontTenorSans}
	font-weight: 400;
	font-size: 26px;
	line-height: 32px;
	text-align: center;
	margin: 12px 0 24px;
	overflow-wrap: anywhere;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`
