import { Tooltip } from 'antd'
import { Block, Icon } from '@ui/index'

type Props = {
	text: string
	onClick: () => void
}

export function SdInformation({ text, onClick }: Props) {
	return (
		<Tooltip placement="right" title={text}>
			<Block isFlexBlock isContentCentered isClickable onClick={onClick}>
				<Icon name="infoCircleFilledIcon" color="white" size={20} />
			</Block>
		</Tooltip>
	)
}
