import styled, { css } from 'styled-components'

export const PhotoWrapper = styled.div<{ imageUrl?: string }>`
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	background: rgba(0, 0, 0, 0.05);
	border-radius: 20px;

	width: 100%;
	height: 392px;

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
		`}
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
`
