import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../store'
import {
	authSelector,
	setUserCode,
	setUserEmail,
	setUserFirstName,
	setUserLastName,
	setUserPhone,
} from '@store/auth'
import { setLoginFormStep } from '@store/login-flow'
import {
	isPossiblePhoneNumber,
	isValidPhoneNumber,
} from 'react-phone-number-input'
import { createStylist } from '../../api/create-stylist'

type FormValues = {
	firstName: string
	lastName: string
	email: string
	phone: string
	isSubscribe: boolean
}

export function useNewStylistForm() {
	const dispatch = useAppDispatch()
	const [isValidated, setIsValidated] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const { email } = useAppSelector(authSelector)

	const {
		register,
		handleSubmit,
		formState: { errors, defaultValues },
		setError,
		watch,
	} = useForm<FormValues>({
		defaultValues: {
			email: email,
		},
	})

	const createStylistHandler = useCallback(
		async ({ email, firstName, lastName, phone }: FormValues) => {
			setIsLoading(true)

			const createStylistData = await createStylist({
				email,
				firstName,
				lastName,
				phone,
			})

			if (createStylistData?.success) {
				dispatch(setUserCode(createStylistData.code))
				setIsLoading(false)
				dispatch(setLoginFormStep('code'))
				return
			}

			setIsLoading(false)
			if (createStylistData?.errors) {
				const emailErrorMessage = createStylistData?.errors?.email?.[0]
				const phoneErrorMessage = createStylistData?.errors?.phone?.[0]
				!!emailErrorMessage &&
					setError('email', { type: 'validate', message: emailErrorMessage })
				!!phoneErrorMessage &&
					setError('phone', { type: 'validate', message: phoneErrorMessage })
			}
		},
		[dispatch, setError]
	)

	const onSubmitNewUser = async (data: FormValues) => {
		if (!data.firstName) {
			setError('firstName', {})
			return
		}

		if (!data.email) {
			setError('email', {})
			return
		}

		if (!isValidPhoneNumber(data.phone)) {
			setError('phone', { type: 'validate', message: 'Incorrect phone number' })
			return
		}

		dispatch(setUserEmail(data.email))
		dispatch(setUserFirstName(data.firstName))
		dispatch(setUserLastName(data.lastName))
		dispatch(setUserPhone(data.phone))

		await createStylistHandler({
			email: data.email,
			firstName: data.firstName,
			lastName: data.lastName,
			phone: data.phone,
			isSubscribe: data.isSubscribe,
		})
	}

	const onCancelClick = () => {
		dispatch(setLoginFormStep('email'))
	}

	const emailRegexp =
		// eslint-disable-next-line no-useless-escape
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	const emailRegister = register('email', {
		onChange: (e) => {
			setIsValidated(emailRegexp.test(e.target.value))
		},
	})

	const phoneRegister = register('phone', {
		onChange: (e) => {
			const regex = /^[0-9]+$/
			const lastSymbol = e.target.value.slice(-1)
			if (!regex.test(lastSymbol)) {
				return
			}
			setIsValidated(isPossiblePhoneNumber(e.target.value))
		},
	})

	return {
		defaultValues,
		emailRegister,
		errors,
		handleSubmit,
		isLoading,
		isValidated,
		onCancelClick,
		onSubmitNewUser,
		phoneRegister,
		register,
		watch,
	}
}
