import { ReactComponent as LogoSvg } from '@assets/svg/site-logo.svg'
import { ReactComponent as LogoSvgNoText } from '@assets/svg/site-logo-no-text.svg'

type LogoProps = {
	color: string
	size?: number
	isWithoutText?: boolean
}

export function SiteLogo({ size = 48, color, isWithoutText }: LogoProps) {
	return isWithoutText ? (
		<LogoSvgNoText color={color} width={size} height={size} />
	) : (
		<LogoSvg color={color} width={size} height={size} />
	)
}
