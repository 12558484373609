import axios from 'axios'
import { axiosInstance } from '../../config'

export async function shareCapsule(
	token: string,
	capsuleId: number,
	clientId: number
): Promise<boolean | undefined> {
	try {
		const response = await axiosInstance.post(
			`/api/capsule/${capsuleId}/link-client/${clientId}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return !!response.data.success
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
