import { Col, Row } from 'antd'
import styled from 'styled-components'

import { Block } from '@ui/block'
import { screenSize } from '@styles/index'

export const BlockStyled = styled(Block)`
	padding: 0 48px 48px;

	@media (${screenSize.antXs}) {
		padding: 0 16px 16px;
	}
`

export const RowStyled = styled(Row)`
	flex-flow: column;
	height: 100%;
`

export const QuizCol = styled(Col)`
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	flex: 1 1 50%;

	@media (${screenSize.antXs}) {
		justify-content: space-around;
	}

	@media (${screenSize.antMd}) {
		margin-top: 60px;
		justify-content: space-between;
	}

	@media (${screenSize.antLg}) {
		margin-top: 0;
		justify-content: space-between;
	}

	@media (${screenSize.antXl}) {
		justify-content: space-around;
	}

	&.results-frame {
		flex: unset;
	}
`
