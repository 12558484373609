import { Route, Routes } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

import { AppLayout } from '@components/app-layout/app-layout'
import { AuthRequired } from '@components/auth-required/auth-required'
import { RoleClient } from '@components/role-client/role-client'
import { RoleStylist } from '@components/role-stylist/role-stylist'

import { CapsuleGuidePage } from '@pages/capsule-guide-page/capsule-guide-page'
import { CapsuleSharedPage } from '@pages/capsule-shared-page/capsule-shared-page'
import { CapsulesEditPage } from '@pages/capsules/capsules-edit-page/capsules-edit-page'
import { CapsulesPage } from '@pages/capsules/capsules-page'
import { CapsulesPreviewPage } from '@pages/capsules/capsules-preview-page/capsules-preview-page'
import { ClientBookSuccessPage } from '@pages/client-book-success-page/client-book-success-page'
import { ClientCapsulesPage } from '@pages/client-capsules/client-capsules-page'
import { ClientCapsulesPreviewPage } from '@pages/client-capsules/client-capsules-preview-page/client-capsules-preview-page'
import { ClientClothesPreviewPage } from '@pages/client-clothes-preview-page/client-clothes-preview-page'
import { ClientPaymentErrorPage } from '@pages/client-payment-error-page/client-payment-error-page'
import { ClientPaymentSuccessPage } from '@pages/client-payment-success-page/client-payment-success-page'
import { ClientProfilePage } from '@pages/client-profile/client-profile'
import ClientsPage from '@pages/clients/clients-page'
import { ClothesEditPage } from '@pages/clothes/clothes-edit-page/clothes-edit-page'
import { ClothesPage } from '@pages/clothes/clothes-page'
import { ClothesPreviewPage } from '@pages/clothes/clothes-preview-page/clothes-preview-page'
import { LoginPage } from '@pages/login'
import { NotFoundPage } from '@pages/not-found-page/not-found-page'
import { QuizPage } from '@pages/quiz-page/quiz-page'
import { QuizResultPage } from '@pages/quiz-result-page/quiz-result-page'
import RatePlans from '@pages/rate-plans/rate-plans'
import { StyleBoardAcceptedPage } from '@pages/styleboards/styleboard-accepted-page/styleboard-accepted-page'
import { StyleBoardPreviewPage } from '@pages/styleboards/styleboard-preview-page/styleboard-preview-page'
import { StyleBoardsNewPage } from '@pages/styleboards/styleboards-new-page'
import { StyleBoardsPage } from '@pages/styleboards/styleboards-page'
import StylingStudio from '@pages/styling-studio/styling-studio'
import StylistClientDetails from '@pages/stylist-client-details/stylist-client-details'
import { StylistDetailsPage } from '@pages/stylist-details/stylist-details-page'
import StylistProfilePreviewPage from '@pages/stylist-profile-preview-page/stylist-profile-preview-page'
import { StylistRegistrationPage } from '@pages/stylist-registration-page/stylist-registration-page'
import { StylistWaitApprovePage } from '@pages/stylist-wait-approve-page/stylist-wait-approve-page'
import StylistsPage from '@pages/stylists-page/stylists-page'
import { useAppSelector } from '@store/index'
import { userSelector } from '@store/user'

function App() {
	const { userInfo } = useAppSelector(userSelector)

	return (
		<Routes>
			<Route path="/capsule-shared">
				<Route path=":id" element={<CapsuleSharedPage />} />
			</Route>
			<Route element={<AuthRequired />}>
				<Route path="/login" element={<LoginPage />} />
				<Route path="/" element={<AppLayout />}>
					{userInfo.role === 'client' && (
						<Route element={<RoleClient />}>
							<Route index element={<StylingStudio />} />
							<Route path="client-profile" element={<ClientProfilePage />} />
							<Route path="rate-plans" element={<RatePlans />} />
							<Route path="styling-studio" element={<StylingStudio />} />
							<Route path="success-book" element={<ClientBookSuccessPage />} />
							<Route
								path="payment-success"
								element={<ClientPaymentSuccessPage />}
							/>
							<Route
								path="payment-cancel"
								element={<div>Payment Cancel</div>}
							/>
							<Route
								path="payment-error"
								element={<ClientPaymentErrorPage />}
							/>
						</Route>
					)}

					{userInfo.role === 'stylist' && (
						<Route element={<RoleStylist />}>
							<Route index element={<StylistWaitApprovePage />} />
							<Route path="stylist-profile">
								<Route index element={<StylistRegistrationPage />} />
								<Route path="preview" element={<StylistProfilePreviewPage />} />
							</Route>
							<Route path="clients">
								<Route index element={<ClientsPage />} />
								<Route path=":id" element={<StylistClientDetails />} />
							</Route>
							<Route path="stylists/:id" element={<StylistDetailsPage />} />
							<Route path="clothes">
								<Route index element={<ClothesPage />} />
								<Route path={':id'}>
									<Route index element={<ClothesPreviewPage />} />
									<Route path={'edit'} element={<ClothesEditPage />} />
								</Route>
							</Route>
							<Route path="capsules">
								<Route index element={<CapsulesPage />} />
								<Route path={':id'}>
									<Route index element={<CapsulesPreviewPage />} />
									<Route path={'edit'} element={<CapsulesEditPage />} />
								</Route>
							</Route>
							<Route path="capsule-guide" element={<CapsuleGuidePage />} />
						</Route>
					)}

					<Route path="styleboards">
						<Route index element={<StyleBoardsPage />} />
						<Route path={':id'}>
							<Route index element={<StyleBoardPreviewPage />} />
							<Route path={'accepted'} element={<StyleBoardAcceptedPage />} />
							<Route path={'edit'} element={<StyleBoardsNewPage />} />
						</Route>
					</Route>
				</Route>
			</Route>

			<Route path="/" element={<AppLayout />}>
				<Route path="quiz" element={<QuizPage />} />
				<Route path="quiz-results" element={<QuizResultPage />} />
				{userInfo.role !== 'stylist' && (
					<>
						<Route path="stylists">
							<Route index element={<StylistsPage />} />
							<Route path=":id" element={<StylistDetailsPage />} />
						</Route>
						<Route path="capsules">
							<Route index element={<ClientCapsulesPage />} />
							<Route path=":id" element={<ClientCapsulesPreviewPage />} />
						</Route>
						<Route path="clothes/:id" element={<ClientClothesPreviewPage />} />
					</>
				)}
			</Route>

			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	)
}

export default App
