import { authSelector, useAppSelector } from '@store/index'
import { MediaLayout } from '@ui/media-layout/media-layout'
import { AppLayoutDesktop } from './layout/desktop/app-layout-desktop'
import { AppLayoutMobile } from './layout/mobile/app-layout-mobile'

export function AppLayout() {
	const { token } = useAppSelector(authSelector)

	/*
	if (
		!token &&
		(!window.location.pathname || window.location.pathname === '/')
	) {
		window.location.assign('https://styledyle.com')
		return <></>
	}
	*/

	return (
		<MediaLayout
			mobileSlot={<AppLayoutMobile />}
			desktopSlot={<AppLayoutDesktop />}
		/>
	)
}
