import { Col, Row } from 'antd'
import { Block, GrayLine, Icon, Spinner, Swap } from '@ui/index'
import {
	AntCSSlStyle,
	ColParamLabel,
	ParamValue,
	PriceValue,
	InputClothLink,
	CopyLinkButton,
	ColFlex,
	ImageCont,
	CoverImageWrapper,
} from './clothes-preview-page-styled'
import { useClothesPreviewPage } from './use-clothes-preview-page'
import { ColorsBlock } from './colors-block/colors-block'
import { PROD_DOMAIN } from '@typings/constants'
import { GalleryBlock } from './gallery-block/gallery-block'
import { colors } from '@styles/colors'
import { PageNavigation } from '@components/page-navigation/page-navigation'
import { BlankPlaceholder } from '@ui/blank-placeholder/blank-placeholder'

export function ClothesPreviewPage() {
	const {
		currency,
		currentPage,
		handleClothLinkSelect,
		handleLinkClick,
		imageItems,
		isItemLoading,
		item,
		navigationButtons,
	} = useClothesPreviewPage()
	const clothPhotoUrlM = item.coverPhoto?.urls
		? PROD_DOMAIN + item.coverPhoto?.urls.M
		: ''

	return (
		<Block isFullWidth margin="0 48px 48px">
			<AntCSSlStyle />
			<PageNavigation showListType={currentPage} buttons={navigationButtons} />
			<Row gutter={48}>
				<Swap is={isItemLoading} isSlot={<Spinner color="#ff0c63" />}>
					<>
						<Col xxl={{ span: 6 }} xl={{ span: 7 }}>
							<CoverImageWrapper>
								<ImageCont imageUrl={clothPhotoUrlM}>
									{!clothPhotoUrlM && (
										<BlankPlaceholder color={colors.blackTr24} />
									)}
								</ImageCont>
							</CoverImageWrapper>
						</Col>
						<Col xxl={{ span: 6 }} xl={{ span: 7 }}>
							<div className="row-block-label">Description</div>
							<Row style={{ marginTop: 50 }}>
								<ColParamLabel span={8}>Category</ColParamLabel>
								<Col span={16}>
									{item.params.category && (
										<ParamValue>{item.params.category}</ParamValue>
									)}
								</Col>
								<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
									<GrayLine />
								</Col>
								<ColParamLabel span={8}>Brand / Designer</ColParamLabel>
								<Col span={16}>
									{item.brand && <ParamValue>{item.brand}</ParamValue>}
								</Col>
								<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
									<GrayLine />
								</Col>
								<ColParamLabel span={8}>Size</ColParamLabel>
								<Col span={16}>
									{item.params.size && (
										<ParamValue>{item.params.size}</ParamValue>
									)}
								</Col>
								<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
									<GrayLine />
								</Col>
								<ColParamLabel span={8}>Colors</ColParamLabel>
								<Col span={16}>
									<ColorsBlock colors={item.params.color} />
								</Col>
								<Col span={24} style={{ marginTop: 58 }}></Col>
								<ColParamLabel span={8}>Price</ColParamLabel>
								<Col span={16}>
									<PriceValue>
										{currency} {item.price}
									</PriceValue>
								</Col>
								<Col span={24} style={{ marginTop: 58 }}></Col>
								<ColParamLabel span={8}>Link</ColParamLabel>
								<ColFlex span={16}>
									<InputClothLink
										onClick={handleClothLinkSelect}
										type="text"
										readOnly
										value={item.source_url || ''}
									/>
									<CopyLinkButton
										onClick={handleLinkClick}
										skin="grey-link"
										leftIcon={
											<Icon color={colors.blackTr64} name="openExtIcon" />
										}
									/>
								</ColFlex>
							</Row>
						</Col>
						<Col xxl={{ span: 12 }} xl={{ span: 10 }}>
							<GalleryBlock imageList={imageItems} />
						</Col>
					</>
				</Swap>
			</Row>
		</Block>
	)
}
