import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReduxAuth } from '../../typings/types'

const initialState: ReduxAuth = {
	email: '',
	password: '',
	phone: '',
	token: '',
	id: 0,
	code: '',
	isRegisteredUser: false,
	firstName: '',
	lastName: '',
}

const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUserEmail: (state, { payload }: PayloadAction<string>) => {
			state.email = payload
		},
		setUserPassword: (state, { payload }: PayloadAction<string>) => {
			state.password = payload
		},
		setUserPhone: (state, { payload }: PayloadAction<string>) => {
			state.phone = payload
		},
		setUserId: (state, { payload }: PayloadAction<number>) => {
			state.id = payload
		},
		setUserToken: (state, { payload }: PayloadAction<string>) => {
			state.token = payload
		},
		setUserCode: (state, { payload }: PayloadAction<string>) => {
			state.code = payload
		},
		setIsRegisteredUser: (state, { payload }: PayloadAction<boolean>) => {
			state.isRegisteredUser = payload
		},
		setUserFirstName: (state, { payload }: PayloadAction<string>) => {
			state.firstName = payload
		},
		setUserLastName: (state, { payload }: PayloadAction<string>) => {
			state.lastName = payload
		},
		setAuthInitialState: (state) => {
			state.email = initialState.email
			state.firstName = initialState.firstName
			state.token = initialState.token
			state.code = initialState.code
			state.id = initialState.id
			state.phone = initialState.phone
			state.isRegisteredUser = initialState.isRegisteredUser
		},
	},
})

export const authReducer = slice.reducer
export const {
	setUserEmail,
	setUserPassword,
	setUserPhone,
	setUserToken,
	setUserCode,
	setAuthInitialState,
	setIsRegisteredUser,
	setUserFirstName,
	setUserLastName,
} = slice.actions
