import styled from 'styled-components'
import { Button } from '../../../../UI/button'
import { colors } from '@styles/colors'

export const ColorButtonStyled = styled(Button)`
	padding: 14px;
	margin-right: 16px;
	margin-bottom: 16px;

	& .active {
		background: ${colors.blackTr5};
		border: 1px solid #000000;
	}
`
