import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ItemInfo, ReduxClothes, ShowClothesListType } from '@typings/types'

const initialState: ReduxClothes = {
	isListLoading: false,
	isItemLoading: false,
	showListType: 'active',
	clothesList: [],
	filteredClothesList: [],
	capsuleClothesList: [],
	newCapsuleClothesList: [],
	item: {
		id: 0,
		user_id: 0,
		title: '',
		brand: '',
		price: 0,
		price_currency_id: 0,
		source_url: '',
		status: 'draft',
		created_at: '',
		updated_at: '',
		params: {},
		images: [],
		isArchived: false,
	},
}

const slice = createSlice({
	name: 'clothes',
	initialState,
	reducers: {
		setIsListLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isListLoading = payload
		},
		setIsItemLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isItemLoading = payload
		},
		setShowListType: (
			state,
			{ payload }: PayloadAction<ShowClothesListType>
		) => {
			state.showListType = payload
		},
		setClothesList: (state, { payload }: PayloadAction<ItemInfo[]>) => {
			state.clothesList = payload
		},
		setFilteredClothesList: (state, { payload }: PayloadAction<ItemInfo[]>) => {
			state.filteredClothesList = payload
		},
		setCapsuleClothesList: (state, { payload }: PayloadAction<ItemInfo[]>) => {
			state.capsuleClothesList = payload
		},
		setNewCapsuleClothesList: (
			state,
			{ payload }: PayloadAction<ItemInfo[]>
		) => {
			state.newCapsuleClothesList = payload
		},
		setItem: (state, { payload }: PayloadAction<ItemInfo>) => {
			state.item = payload
		},
		setIsItemArchived: (state, { payload }: PayloadAction<boolean>) => {
			state.item.isArchived = payload
		},
		setNewItem: (state) => {
			state.item = initialState.item
		},
	},
})

export const clothesReducer = slice.reducer
export const {
	setIsListLoading,
	setIsItemLoading,
	setShowListType,
	setClothesList,
	setFilteredClothesList,
	setCapsuleClothesList,
	setNewCapsuleClothesList,
	setItem,
	setIsItemArchived,
	setNewItem,
} = slice.actions
