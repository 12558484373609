import { Col, Image, Row } from 'antd'
import styled from 'styled-components'

import { colors, fontRoboto, fontTenorSans, screenSize } from '@styles/index'
import {
	bold14,
	bold16,
	fontPlayfair,
	regular20,
	regular22,
	regular24,
	regular26,
	regular32,
} from '@styles/typography'

export const QuestionCont = styled.div`
	${fontPlayfair}

	align-items: center;
	text-align: center;
	letter-spacing: 0.84px;

	color: ${colors.black};

	@media (${screenSize.antXs}) {
		margin: 40px auto 0;
		${regular24}
	}

	@media (${screenSize.antMd}) {
		margin: 58px auto 0;
		${regular26}
		width: 90%;
	}

	@media (${screenSize.antLg}) {
		margin: 58px auto 0;
		${regular32}
		width: 90%;
	}
`
export const RowFlexColumn = styled(Row)`
	display: flex;
	width: 100%;
	min-height: 100px;

	@media (${screenSize.antXs}) {
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	@media (${screenSize.antMd}) {
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	@media (${screenSize.antLg}) {
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	& .ant-image {
		margin: 0 auto;
		display: block;
	}
`

export const RowStyled = styled(Row)`
	display: flex;
	width: 100%;
	flex-direction: row;

	@media (${screenSize.antXs}) {
		overflow-x: auto;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}

	@media (${screenSize.antMd}) {
		margin-bottom: 12px;
	}
`
export const RowAnswersStyled = styled(RowStyled)`
	@media (${screenSize.antXs}) {
		margin-top: 20px;
	}

	@media (${screenSize.antMd}) {
		margin-top: 40px;
	}
`

export const ColStratched = styled(Col)`
	height: 100%;
`

export const ColStyled = styled(Col)`
	align-self: center;
	text-align: center;

	border-radius: 12px;
	cursor: pointer;

	&:hover {
		box-shadow: 0px 6px 32px ${colors.blackTr8};
	}

	@media (${screenSize.antXs}) {
		padding: 5px;
	}

	@media (${screenSize.antMd}) {
		padding: 24px 5px;
	}
`
export const IconBorder = styled.div`
	min-width: 32px;
	height: 32px;
	border: 1px solid ${colors.blackTr16};
	border-radius: 42px;
	padding: 3px;
`

export const ImageStyled = styled(Image)`
	display: flex;
	border-radius: 20px;
	overflow: hidden;

	+ .ant-image-mask {
		border-radius: 20px;
	}

	@media (${screenSize.antXs}) {
		margin: 0 auto;
	}

	@media (${screenSize.antMd}) {
		margin: 0 auto;
	}
`
export const ImageWrapper = styled.div`
	display: block;
	margin: 24px auto;
	border-radius: 20px;
	overflow: hidden;
`

export const AnswerWrapper = styled.div`
	${fontRoboto}

	color: ${colors.blackTr64};
	letter-spacing: 0.06em;

	@media (${screenSize.antXs}) {
		${bold14}
	}

	@media (${screenSize.antLg}) {
		${bold16}
	}
`

export const StepComment = styled.div`
	${fontTenorSans}
	${regular26}
	color: ${colors.blackTr64};
	width: 50%;
	text-align: center;
	margin: 0 auto;
`

export const GapLineCol = styled.div`
	position: absolute;
	width: 48px;
	left: 100px;
	top: 72px;
	border-top: 2px solid ${colors.black};
`

export const TextWrapper = styled.div`
	${fontTenorSans}
	${regular26}

	margin: 0 auto;
	align-items: center;
	text-align: center;

	color: ${colors.blackTr64};

	@media (${screenSize.antXs}) {
		${regular20}
	}

	@media (${screenSize.antMd}) {
		${regular22}
	}

	@media (${screenSize.antXl}) {
		${regular26}
	}
`
