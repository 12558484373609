import { colors } from '@styles/colors'
import { Icon } from '@ui/icon'
import { Container } from './delete-button-styled'

type Props = {
	handleDeleteClick: () => void
}

export function DeleteButton({ handleDeleteClick }: Props) {
	return (
		<Container className="delete-button" onClick={handleDeleteClick}>
			<Icon size={24} color={colors.white} name="trashIcon" />
		</Container>
	)
}
