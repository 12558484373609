import { colors } from './../../../styles/colors'
import {
	bold12,
	fontPlayfair,
	regular26,
	regular42,
} from './../../../styles/typography'
import styled from 'styled-components'
import { Row } from 'antd'
import LoginBG from '../../../assets/loginBG.png'
import EllipseWoman from './assets/EllipseWoman.png'
import { fontTenorSans, fontRoboto } from '../../../styles/typography'

export const BlockWrapper = styled(Row)`
	background: url(${LoginBG});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	height: 100%;
`

export const GapLineCol = styled.div`
	display: flex;
	width: 48px;
	margin: 136px auto;
	border-top: 2px solid ${colors.black};
`
export const TextBlockCol = styled.div`
	${fontPlayfair}
	${regular42}

	margin: 135px auto 70px;
`

export const InterviewMsg = styled.div`
	${fontTenorSans}
	${regular26}
	align-items: center;
	margin: 0 auto 70px;
`
export const Red = styled.i`
	color: red;
`

export const ImageBlock = styled.div`
	position: relative;
	background: url(${EllipseWoman});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left;
	width: 100%;
	height: 100%;
	max-width: 765px;
	max-height: 660px;
	margin: 62px 0 0 65px;
`

export const TagsBlock = styled.div`
	display: flex;
	gap: 8px;
	${fontRoboto}
	${bold12}
	color: #c27526;
`

export const Tag = styled.div`
	padding: 3px 6px;
	border: 1px solid #c27526;
	border-radius: 24px;
	cursor: default;
`

export const StylistRatingBlock = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	gap: 16px;
	right: 50px;
	bottom: 50px;
	padding: 24px;
	background: #fff;
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 20px;
`

export const IconWrapper = styled.div`
	width: 48px;
	height: 48px;
	background: rgba(0, 0, 0, 0.05);
	border-radius: 8px;
`

export const RatingInfoFlex = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`

export const RatingValue = styled.div`
	${fontTenorSans}
	font-weight: 400;
	font-size: 26px;
	line-height: 32px;
	min-width: 100px;
`

export const RatingLabel = styled.div`
	${fontRoboto}
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.01em;
	color: rgba(0, 0, 0, 0.48);
`
