import { useEffect, useState } from 'react'
import { Props } from './types'

const REPEAT_TIMER_SECONDS = 30

export function useRepeatCode({ onRepeatClick }: Props) {
	const [smsRepeatTimer, setSmsRepeatTimer] =
		useState<number>(REPEAT_TIMER_SECONDS)
	const [isCodeTimerFinished, setIsCodeTimerFinished] = useState(false)

	useEffect(() => {
		let timeout = setTimeout(() => setSmsRepeatTimer(smsRepeatTimer - 1), 1000)

		if (!smsRepeatTimer) {
			clearTimeout(timeout)
			setIsCodeTimerFinished(true)
		}

		return () => clearTimeout(timeout)
	}, [smsRepeatTimer, setSmsRepeatTimer])

	const formatTimer = (time: number) => {
		const seconds = time % 60
		const minutes = Math.floor(time / 60)
		const minutesStr = minutes.toString().length === 1 ? '0' + minutes : minutes
		const secondsStr = seconds.toString().length === 1 ? '0' + seconds : seconds
		return minutesStr + ':' + secondsStr
	}

	const handleRepeatCode = () => {
		onRepeatClick()
		setIsCodeTimerFinished(false)
		setSmsRepeatTimer(REPEAT_TIMER_SECONDS)
	}

	return {
		isCodeTimerFinished,
		timerText: formatTimer(smsRepeatTimer),
		handleRepeatCode,
	}
}
