import { useCallback, useEffect, useMemo, useState } from 'react'
import { authSelector } from '@store/auth'
import { useAppDispatch, useAppSelector } from '@store/store'
import { CapsuleInfo, NavigationButtonInfo, StylistInfo } from '@typings/types'
import { useNavigate } from 'react-router-dom'
import { getAllCapsules } from '@api/client/get-all-capsules'
import {
	clientCapsulesSelector,
	setCapsulesList,
	setFilteredCapsulesList,
	setIsListLoading,
} from '@store/client-capsules'
import { getStylistsById } from '@api/client/get-stylists-by-id'
import Avatar from '@components/avatar/avatar'

export function useClientCapsulesPage() {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { token } = useAppSelector(authSelector)
	const { capsulesList, isListLoading, filteredCapsulesList } = useAppSelector(
		clientCapsulesSelector
	)
	const [showListType, setShowListType] = useState('all')
	const [stylists, setStylists] = useState<StylistInfo[]>([])

	const loadClientCapsules = async () => {
		dispatch(setIsListLoading(true))
		const capsules = await getAllCapsules({ token })

		if (capsules) {
			dispatch(setCapsulesList(capsules))
			dispatch(setFilteredCapsulesList(capsules))
		}

		const stylistIds = capsules?.map((capsule: CapsuleInfo) => capsule.user_id)

		const stylists = stylistIds
			? await getStylistsById({ token, stylistIds })
			: []
		stylists && setStylists(stylists)
		dispatch(setIsListLoading(false))
	}

	useEffect(() => {
		loadClientCapsules()
	}, [])

	const handleCapsuleClick = async (id: number) => {
		navigate(`${id}`, {
			state: {
				capsuleId: id,
			},
		})
	}

	const handleClickAll = useCallback(() => {
		if (showListType === 'all') return

		setShowListType('all')
		dispatch(setFilteredCapsulesList(capsulesList))
	}, [dispatch, showListType, capsulesList])

	const handleClickStylist = useCallback(
		(id: number) => {
			if (showListType === id.toString()) return

			setShowListType(id.toString())
			dispatch(
				setFilteredCapsulesList(
					capsulesList.filter((capsule) => capsule.user_id === id)
				)
			)
		},
		[showListType, dispatch, capsulesList]
	)

	const buttons: NavigationButtonInfo[] = useMemo(() => {
		const stylistsButtons: NavigationButtonInfo[] = stylists.map((stylist) => {
			return {
				title: `${stylist.first_name} ${stylist.last_name}`,
				iconName: 'hangerIcon',
				showListType: stylist.user_id.toString(),
				node: (
					<Avatar
						imageUrl={stylist?.params?.photo?.[0].urls?.M || ''}
						workStatus={stylist?.workStatus}
						size={40}
					/>
				),
				handleClick: () => handleClickStylist(stylist.user_id),
			}
		})

		return [
			{
				title: 'All capsules',
				iconName: 'hangerIcon',
				showListType: 'all',
				handleClick: handleClickAll,
			},
			...stylistsButtons,
		]
	}, [handleClickAll, handleClickStylist, stylists])

	return {
		capsulesList: filteredCapsulesList,
		stylists,
		handleCapsuleClick,
		isListLoading,
		buttons,
		showListType,
	}
}
