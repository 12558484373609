import { CometChat } from '@cometchat/chat-sdk-javascript'
import {
	CometChatUIKit,
	UIKitSettingsBuilder,
} from '@cometchat/chat-uikit-react'

import { UserInfo } from '@typings/types'
import { handleToast } from '@utils/handle-toast'

import { useUpdateUserInfo } from './api/use-update-user-info'

const COMETCHAT_CONSTANTS = {
	APP_ID: '25433143567fa2d1', //Replace with your App ID
	REGION: 'eu', //Replace with your App Region
	AUTH_KEY: 'ea96fac2b101c4e81947cf9de2662e9578a32270', //Replace with your Auth Key
}

export const useInitChat = () => {
	const { updateUserInfo } = useUpdateUserInfo()

	const initChat = async (
		userInfo: UserInfo,
		token: string
	): Promise<CometChat.User | Object | undefined> => {
		const UIKitSettings = new UIKitSettingsBuilder()
			.setAppId(COMETCHAT_CONSTANTS.APP_ID)
			.setRegion(COMETCHAT_CONSTANTS.REGION)
			.setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
			.subscribePresenceForFriends()
			.build()

		//Initialize CometChat UIKit
		try {
			const chatInfo = await CometChatUIKit.init(UIKitSettings)
			CometChatUIKit.Localize.setLocale('en')

			const uid = userInfo?.role && userInfo[userInfo.role].cometchat_uid

			console.info('initChat chatInfo: ', chatInfo, uid)

			if (uid) {
				console.info('have uid!!! getLoggedUser  !!!')
				let loginUser: CometChat.User | null =
					await CometChatUIKit.getLoggedinUser()

				if (loginUser && loginUser.getUid() !== uid) {
					console.info(
						'Another user was logged, need logout first!!! uid: ',
						loginUser?.getUid()
					)
					await CometChatUIKit.logout()
					loginUser = null
				}

				if (!loginUser) loginUser = await CometChatUIKit.login(uid)

				const messageListenerID = loginUser.getUid() + '_message'
				CometChat.addMessageListener(
					messageListenerID,
					new CometChat.MessageListener({
						onMediaMessageReceived: (message: CometChat.MediaMessage) => {
							//TODO: возможно понадобится для кастомизации
							//console.info('onMediaMessageReceived: ', message)
						},
						onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
							setTimeout(() => {
								updateUserInfo(
									userInfo.role === 'stylist'
										? userInfo.stylist.user_id
										: userInfo.client.user_id,
									token
								)
							}, 2000)
							handleToast({
								content: (
									<div>
										<h3>{textMessage.getSender().getName()}</h3>
										<p>{textMessage.getText()}</p>
									</div>
								),
								type: 'info',
							})
						},
					})
				)

				if (loginUser) {
					console.info('Login Successful via uid:', loginUser)
					return loginUser
				}
			}

			console.info('Login failed with error:', 'No uid', chatInfo)
		} catch (error) {
			console.error('Initialization failed with error:', error)
		}
	}

	return { initChat }
}
