import { Link } from 'react-router-dom'
import { useLeftMenu } from './use-left-menu'
import { MenuWrapper } from './left-menu-styled'
import { LeftMenuItem } from './left-menu-item/left-menu-item'

export function LeftMenu({ handleClose }: { handleClose?: () => void }) {
	const { menuItemsList } = useLeftMenu()

	return (
		<MenuWrapper>
			{menuItemsList.map((menuItem, index) => (
				<Link key={index} to={menuItem.route} onClick={handleClose}>
					<LeftMenuItem item={menuItem} />
				</Link>
			))}
		</MenuWrapper>
	)
}
