import { colors } from '@styles/colors'
import styled from 'styled-components'

export const TitleBlock = styled.div`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;

	color: ${colors.black};

	margin-bottom: 24px;
`

export const FieldsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
`
