import { TitleCont } from '@components/styleboard-item/styleboard-item-styled'
import {
	InfoCont,
	TextCont,
	AdditionalInfo,
	TimeWrapper,
	LinkCont,
	TitleFlex,
} from './booking-info-styled'
import { useBookingInfo } from './use-booking-info'
import { DealStatus } from '@typings/types'
import { Block } from '@ui/block'
import StatusData from './status-data.json'

type Props = {
	dealStatus?: DealStatus
}

export function BookingInfo({ dealStatus }: Props) {
	const { dueDate, dueDateTimer, isExpired } = useBookingInfo({
		dealStatus,
	})
	if (!dealStatus?.status) return <></>

	return (
		<InfoCont>
			<TextCont>
				<TitleFlex>
					<TitleCont>{StatusData[dealStatus.status].title}</TitleCont>
					{StatusData[dealStatus.status].linkText && (
						<LinkCont>{StatusData[dealStatus.status].linkText}</LinkCont>
					)}
				</TitleFlex>
				<Block>
					{dealStatus.statusDeadline && (
						<>
							<AdditionalInfo>
								<span>until</span> {dueDate}
							</AdditionalInfo>
							{!['done', 'archive'].includes(dealStatus.status) && (
								<TimeWrapper isExpired={isExpired}>
									<span>in</span> {dueDateTimer}
								</TimeWrapper>
							)}
						</>
					)}
				</Block>
			</TextCont>
		</InfoCont>
	)
}
