import { settingsReducer } from './settings/settings'
import { loginFlowReducer } from './login-flow'
import { authReducer } from './auth'
import { userReducer } from './user/user'
import { styleboardsReducer } from './styleboards/styleboards'
import { clothesReducer } from './clothes/clothes'
import { clientsReducer } from './clients/clients'
import { capsulesReducer } from './capsules/capsules'
import { ClientCapsulesReducer } from './client-capsules'
import { quizReducer } from './quiz'
import { dealReducer } from './deal'
import { chatReducer } from './chat'

export const rootReducer = {
	auth: authReducer,
	capsules: capsulesReducer,
	chat: chatReducer,
	clientCapsules: ClientCapsulesReducer,
	clients: clientsReducer,
	clothes: clothesReducer,
	deal: dealReducer,
	loginFlow: loginFlowReducer,
	quiz: quizReducer,
	settings: settingsReducer,
	styleboards: styleboardsReducer,
	user: userReducer,
}
