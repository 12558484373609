import styled, { css } from 'styled-components'

import {
	bold12,
	bold16,
	fontRoboto,
	fontTenorSans,
	regular13,
	regular18,
} from '@styles/typography'
import { colors } from '@styles/colors'
import { Button } from '@ui/button'

export const CoverImageCont = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	border-radius: 20px;
	padding: 56px;
`

export const Container = styled.div`
	max-width: 464px;
	min-height: 415px;
	width: 100%;
	height: 100%;
	cursor: pointer;

	.archive-btn {

	}

	&:hover ${CoverImageCont} {
		box-shadow: 0px 6px 32px ${colors.blackTr8};

		& .hidden-button {
			opacity: 1;
		}

		& 
	}
`

export const IconWrapper = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
	padding: 4px;
	cursor: pointer;
	z-index: 10;
	opacity: 0;
	background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
	border-radius: 8px;
`

export const ImageWrapper = styled.div`
	padding: 56px;
`

export const ImageCont = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 201px;
	border-radius: 12px;
	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
			box-shadow: none;
		`}
	background-color: ${colors.blackTr5};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export const Title = styled.div`
	${fontRoboto}
	${bold16}
  text-align: center;
	padding: 18px 16px 0;
	min-height: 24px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: inherit;
`

export const Brand = styled.div`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr48};
	text-align: center;
	padding: 6px 16px 0;
	min-height: 24px;
`

export const Price = styled.div`
	${fontTenorSans}
	${regular18}
  color: ${colors.blackTr64};
	padding: 13px 16px;
	text-align: center;
	min-height: 24px;
`

export const AddToCapsuleButton = styled(Button)`
	${fontRoboto}
	${bold12}
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 13px 20px;
	gap: 4px;

	position: absolute;
	margin: 0 auto;
	bottom: 34px;

	background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
	border-radius: 8px;

	opacity: 0;

	&.item-added {
		background: #1b998b;
		opacity: 1;
		padding: 10px 20px;
	}
`
