import axios from 'axios'

import { ItemParamsList } from '@typings/types'
import { handleToast } from '@utils/handle-toast'
import { axiosInstance } from '../../config'

type Props = {
	token: string
}

export async function getItemParams({ token }: Props) {
	try {
		const response = await axiosInstance.get<ItemParamsList>(
			'/api/item/params',
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		if (response.status === 403 || response.status === 500) {
			handleToast({
				content:
					'You have problems with loading item params. Please, try again later.',
			})
			return undefined
		}

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
