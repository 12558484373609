import axios from 'axios'
import { axiosInstance } from '../../config'
import { ItemInfo } from '../../typings/types'

type Props = {
	itemId: number | null
	token: string
}

export async function getItemInfo({ itemId, token }: Props) {
	if (!itemId) {
		console.error('No item id!', itemId)
		return
	}

	try {
		const response = await axiosInstance.get<ItemInfo>(`/api/item/${itemId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
