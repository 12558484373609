import axios from 'axios'
import { axiosInstance } from '../../config'
import { PreferenceTag, SuccessResponse } from '@typings/types'

type Props = {
	id: number
	token: string
	request: PreferenceTag[]
}

export async function setStylistPreferences({ id, token, request }: Props) {
	try {
		const response = await axiosInstance.post<SuccessResponse>(
			`/api/stylist/${id}/preferences-change`,
			{
				tags: request,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return !!response.data.success
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
