import { ImageItemType } from '@typings/types'
import { DeleteButton } from './delete-button/delete-button'
import { MakeCoverButton } from './make-cover-button/make-cover-button'
import { IconWrapper, PhotoWrapper } from './photo-item-styled'
import { Icon } from '@ui/icon'
import { colors } from '@styles/colors'

type Props = {
	imageObj: ImageItemType
	handleSetCoverClick?: () => void
	handleDeleteClick?: () => void
}

export function PhotoItem({
	imageObj,
	handleSetCoverClick,
	handleDeleteClick,
}: Props) {
	return (
		<PhotoWrapper
			imageUrl={imageObj.url}
			className={imageObj.isCover ? 'cover' : ''}
		>
			{imageObj.isLiked && (
				<IconWrapper className="like-icon">
					<Icon name="heartIcon" size={28} color={colors.mainThemeColor} />
				</IconWrapper>
			)}
			{imageObj.isDisliked && (
				<IconWrapper className="dislike-icon">
					<Icon name="dislikeIcon" size={28} color={colors.mainThemeColor} />
				</IconWrapper>
			)}
			{handleDeleteClick && (
				<DeleteButton handleDeleteClick={handleDeleteClick} />
			)}
			{handleSetCoverClick && (
				<MakeCoverButton
					handleSetCoverClick={handleSetCoverClick}
					isCover={imageObj.isCover}
				/>
			)}
		</PhotoWrapper>
	)
}
