import { useEffect, useState } from 'react'
import { CheckButton } from './check-button/check-button'
import { TitleBlock, FieldsContainer } from './checkboxes-component-styled'
import { CheckButtonType, PreferenceTagState } from '@typings/types'
import { MultipleCheckButton } from './multiple-check-button/multiple-check-button'

type Props = {
	label?: String
	onFieldsSelect: (option: SelectOptions) => void
	fields: CheckButtonType[]
	isMultipleChoice?: boolean
	isMultipleClick?: boolean
}

export type SelectOptions = CheckButtonType[]

export function CheckBoxesComponent({
	label,
	fields,
	onFieldsSelect,
	isMultipleChoice = false,
	isMultipleClick = false,
}: Props) {
	useEffect(() => {
		setOptions(fields)
	}, [fields])

	const [options, setOptions] = useState<SelectOptions>(fields)

	const handleSelect = (value: string, status: PreferenceTagState) => {
		if (isMultipleChoice) {
			const newOptions: SelectOptions = options.map((option) => {
				if (option.title === value) {
					return {
						...option,
						state: status,
					}
				}
				return option
			})
			setOptions(newOptions)
			onFieldsSelect(newOptions)
			return
		}

		const newOptions: SelectOptions = options.map((option) => {
			if (option.title === value) {
				return {
					...option,
					state: status,
				}
			}

			return {
				...option,
				state: 'ignored',
			}
		})
		setOptions(newOptions)
		onFieldsSelect(newOptions)
	}

	return (
		<>
			{label && <TitleBlock>{label}</TitleBlock>}
			<FieldsContainer>
				{options.map((option, index) => {
					if (!option.title) return null

					return isMultipleClick ? (
						<MultipleCheckButton
							key={index}
							title={option.title}
							state={option.state}
							onSelect={handleSelect}
						/>
					) : (
						<CheckButton
							key={index}
							title={option.title}
							onSelect={handleSelect}
							isActive={option.state === 'checked'}
						/>
					)
				})}
			</FieldsContainer>
		</>
	)
}
