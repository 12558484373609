import { MediaLayout } from '../../UI/media-layout/media-layout'
import { LoginLayoutDesktop } from './layout/desktop/login-desktop'
import { LoginLayoutMobile } from './layout/mobile/login-mobile'
import { LayoutProps } from './types'

export function LoginLayout(props: LayoutProps) {
	return (
		<MediaLayout
			mobileSlot={<LoginLayoutMobile {...props} />}
			desktopSlot={<LoginLayoutDesktop {...props} />}
		/>
	)
}
