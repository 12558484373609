import axios from 'axios'
import { axiosInstance } from '../../../config'
import { AuthUserResponse } from '@typings/types'

type Props = {
	email: string
	code: string
	dispatch: any
}

export async function authUserByCode({ email, code }: Props) {
	try {
		const response = await axiosInstance.post<AuthUserResponse>(
			'/api/auth/auth-by-code',
			JSON.stringify({
				email,
				code,
			})
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
