import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	ReduxStyleboards,
	ShowStyleboardsListType,
	StyleboardInfo,
	StyleboardShortInfo,
} from '@typings/types'

const initialState: ReduxStyleboards = {
	isListLoading: false,
	isStyleboardLoading: false,
	showListType: 'all',
	styleboardsList: [],
	filteredStyleboardsList: [],
	styleboard: {
		id: 0,
		user_id: 0,
		title: '',
		client_id: 0,
		status: 'draft',
		sortnum: 0,
		created_at: '',
		updated_at: '',
		params: {},
		coverPhoto: undefined,
		photos: [],
	},
}

const slice = createSlice({
	name: 'styleboards',
	initialState,
	reducers: {
		setIsListLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isListLoading = payload
		},
		setIsStyleboardLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isStyleboardLoading = payload
		},
		setShowListType: (
			state,
			{ payload }: PayloadAction<ShowStyleboardsListType>
		) => {
			state.showListType = payload
		},
		setStyleboardsList: (
			state,
			{ payload }: PayloadAction<StyleboardShortInfo[]>
		) => {
			state.styleboardsList = payload
		},
		setFilteredStyleboardsList: (
			state,
			{ payload }: PayloadAction<StyleboardShortInfo[]>
		) => {
			state.filteredStyleboardsList = payload
		},
		setStyleboard: (state, { payload }: PayloadAction<StyleboardInfo>) => {
			state.styleboard = payload
		},
		setInitialStyleboard: (state) => {
			state.styleboard = initialState.styleboard
		},
	},
})

export const styleboardsReducer = slice.reducer
export const {
	setIsListLoading,
	setIsStyleboardLoading,
	setShowListType,
	setStyleboardsList,
	setFilteredStyleboardsList,
	setStyleboard,
	setInitialStyleboard,
} = slice.actions
