import axios from 'axios'
import { axiosInstance } from '../../config'
import { ItemInfo } from '../../typings/types'

type Props = {
	token: string
}

export async function getItemsList({ token }: Props) {
	try {
		const items = await axiosInstance.get<ItemInfo[]>(`/api/item`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		const archiveItems = await axiosInstance.get<ItemInfo[]>(
			`/api/item/list-archive`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return [...items.data, ...archiveItems.data].filter(Boolean)
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
