import styled from 'styled-components'
import { colors } from '../../../../../styles'
import { regular13 } from '../../../../../styles/typography'
import { Icon as EttaIcon } from '../../../../../UI/icon'

export const Icon = styled(EttaIcon)``

export const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;

	color: rgba(255, 255, 255, 0.56);
`

export const MenuTitle = styled.div`
	display: block;
	${regular13}
	color: rgba(255, 255, 255, 0.56);
	margin: 6px auto;
	text-align: center;
`

export const MenuItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	flex-direction: column;
	width: 100%;

	&:hover,
	&.active {
		${MenuTitle} {
			color: ${colors.white};
			transition: color 0.3s ease-out;
		}

		${IconWrapper} {
			color: ${colors.black};
			transition: color, background-color 0.3s ease-out;

			background-color: ${colors.white};
			border-radius: 8px;
		}
	}
`
