import styled, { css } from 'styled-components'
import { Block } from '@ui/index'
import { fontTenorSans, regular18 } from '@styles/typography'
import { colors } from '@styles/colors'

export const CompWrapper = styled.div`
	margin: 0 auto;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 26px;
`

export const UpdateImageWrapper = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	width: 240px;
	aspect-ratio: 1;
	margin: 0 auto;

	background: ${colors.blackTr5};
	border-radius: 50%;

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
		`}
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	#upload-photo {
		opacity: 0;
		position: absolute;
		z-index: -1;
	}
`

export const StyledLabel = styled.label<{ isAvatarAdded?: boolean }>`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 25px;

	width: 100%;
	aspect-ratio: 1;
	max-width: 240px;

	border: 2px dashed ${colors.blackTr8};
	border-radius: 50%;

	${({ isAvatarAdded }) =>
		isAvatarAdded &&
		css`
			border: none;
		`}
`

export const UploadText = styled(Block)`
	${fontTenorSans}
	${regular18}
`

export const EditPhotoWrapper = styled.div`
	margin-top: 24px;
	#upload-file {
		opacity: 0;
		position: absolute;
		z-index: -1;
	}
`

export const ErrorBlock = styled.div`
	${fontTenorSans}
	color: red;
	text-align: center;
`
