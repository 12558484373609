import styled from 'styled-components'

export const InfoBlock = styled.div`
	position: relative;
	width: 100%;
	height: 50px;

	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	background-color: rgb(255, 255, 255);
	padding: 15px 6px 15px 16px;

	text-transform: capitalize;
`

export const TextLabel = styled.div`
	position: absolute;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	line-height: 2.2rem;
	background-color: rgb(255, 255, 255);

	z-index: 100;
	padding: 0px 4px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 1.3rem;
	color: rgba(0, 0, 0, 0.48);
	top: -13px;
	left: 13px;
`
