import { useAppDispatch, useAppSelector } from '@store/index'
import { QuizSelector, setQuizStep } from '@store/quiz'
import { userSelector } from '@store/user'

import {
	ButtonStyled,
	HeaderText,
	MainText,
	TimeText,
} from './quiz-start-styled'
import { Block } from '@ui/index'

export function QuizStart() {
	const dispatch = useAppDispatch()
	const { currentStep, questions } = useAppSelector(QuizSelector)
	const { userInfo } = useAppSelector(userSelector)

	if (!questions?.length) return null

	if (userInfo.role === 'stylist') {
		return (
			<>
				<div className="row"></div>
				<HeaderText>Let's put together your stylist profile</HeaderText>
				<MainText>
					Take our style quiz to show what you're good at. It'll help us match
					you with clients
				</MainText>
				<ButtonStyled
					skin="dark"
					width={304}
					onClick={() => dispatch(setQuizStep(currentStep + 1))}
				>
					Build your profile
				</ButtonStyled>
				<TimeText>In under 4 minutes</TimeText>
			</>
		)
	}

	return (
		<>
			{!!questions.length && (
				<HeaderText>
					Say hello to your perfect stylist in a few easy questions
				</HeaderText>
			)}
			<MainText>
				We'll decode your style preferences and match you with a stylist who is
				pro in just what you're after
			</MainText>
			<Block>
				<ButtonStyled
					skin="dark"
					width={304}
					onClick={() => dispatch(setQuizStep(currentStep + 1))}
				>
					Match with a stylist
				</ButtonStyled>
				<TimeText>It won't take more than four minutes</TimeText>
			</Block>
		</>
	)
}
