import { ReactNode } from 'react'
import { ContentLoaderWrapper } from './skeleton-styled'

export type SkeletonProps = {
	width: number | string
	height: number | string
	children: ReactNode | ReactNode[]
}

export function Skeleton({ width, height, children }: SkeletonProps) {
	return (
		<ContentLoaderWrapper
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
		>
			{children}
		</ContentLoaderWrapper>
	)
}
