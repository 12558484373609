import styled, { css } from 'styled-components'
import {
	bold16,
	fontRoboto,
	fontTenorSans,
	regular16,
} from '@styles/typography'
import { Block } from '@ui/block'
import { colors } from '@styles/colors'

type Props = {
	minHeight: string
}

export const BlockStyled = styled(Block)<Props>`
	${regular16}
	${fontTenorSans}
  display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 454px;
	border-radius: 8px;
	border: 1px solid ${colors.blackTr5};
	padding: 25px;
	justify-content: center;
	align-items: center;

	${(props) =>
		props.minHeight &&
		css`
			min-height: ${props.minHeight};
		`}
`

export const Wrapper = styled.div`
	cursor: pointer;
	border-radius: 8px;
	background: ${colors.white};
	height: 100%;

	&:hover {
		box-shadow: 0px 6px 32px ${colors.blackTr8};
	}
`
export const Title = styled.div`
	${bold16}
	${fontRoboto}
	margin-top: 24px;
	text-transform: uppercase;
	color: ${colors.blackTr64};
	text-align: center;
`
