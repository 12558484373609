import axios from 'axios'
import { axiosInstance } from '../config'
import { DeleteResponse } from '../typings/types'

type Props = {
	imageId: number
	token: string
}

export async function deleteImage({ imageId, token }: Props) {
	try {
		const response = await axiosInstance.delete<DeleteResponse>(
			`/api/image/${imageId}`,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return !!response.data.success
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
