import styled from 'styled-components'
import { colors } from '@styles/colors'
import {
	bold14,
	fontRoboto,
	fontTenorSans,
	regular13,
	regular16,
} from '@styles/typography'

export const HoverWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 56px;
	border-radius: 20px;
`
export const LoaderCont = styled.div`
	display: flex;
	flex-deriction: row;
	gap: 48px;
`

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	max-width: 464px;
	width: 100%;
	cursor: pointer;
	border-radius: 6px;

	&:hover {
		& .hidden-button {
			opacity: 1;
		}

		${HoverWrapper} {
			box-shadow: 0px 6px 32px ${colors.blackTr8};
		}
	}
`
export const InfoWrapper = styled.div`
	display: block;
	padding: 16px;
	width: 100%;
`

export const Title = styled.div`
	${fontRoboto}
	${bold14}
  align-self: start;
	padding: 5px;
	min-height: 24px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: inherit;
`

export const Brand = styled.div`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr48};
	align-self: start;
	padding: 5px;
	min-height: 24px;
`

export const Price = styled.div`
	${fontTenorSans}
	${regular16}
  color: ${colors.blackTr64};
	margin-top: 9px;
	padding: 0 5px;
	align-self: start;
	min-height: 22px;
	letter-spacing: 0.005em;
`
