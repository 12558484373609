import { CapsuleInfo, CapsuleParams } from '@typings/types'
import { updateCapsule } from '@api/capsule/update-capsule'
import { useAppSelector } from '../../store'
import { authSelector } from '../../store/auth'

type Props = {
	id: number
}
type InfoKeysToSave = keyof CapsuleInfo
type ParamsKeysToSave = keyof CapsuleParams
type ValueToSave = string | string[] | number | number[] | boolean

export function useUpdateCapsuleInfo({ id }: Props) {
	const { token } = useAppSelector(authSelector)

	const handleUpdateCapsule = async (newFields: string) => {
		return await updateCapsule(newFields, id, token)
	}

	const handleSetCapsuleInfo = async (
		key: InfoKeysToSave,
		value: ValueToSave
	) => {
		return await handleUpdateCapsule(
			JSON.stringify({
				[key]: value,
			})
		)
	}

	const handleSetCapsuleParams = async (
		key: ParamsKeysToSave,
		value: ValueToSave
	) => {
		return await handleUpdateCapsule(
			JSON.stringify({
				params: {
					[key]: value,
				},
			})
		)
	}

	return {
		handleSetCapsuleInfo,
		handleSetCapsuleParams,
	}
}
