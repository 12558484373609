import { Icon } from '@ui/icon'
import { ArrowEl } from './arrow-next-styled'

export default function ArrowNext() {
	return (
		<ArrowEl>
			<Icon color="#000000a3" name="arrowDownIcon" />
		</ArrowEl>
	)
}
