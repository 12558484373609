import { colors } from './../../../styles/colors'
import { fontRoboto, regular13 } from './../../../styles/typography'
import { antWidth, screenSize } from '@styles/screen-sizes'
import styled from 'styled-components'

export const Container = styled.div`
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (${screenSize.antXs}) {
		width: ${antWidth.col22};
		flex-wrap: wrap;
		gap: 30px 10px;
	}

	@media (${screenSize.antMd}) {
		width: ${antWidth.col16};
		flex-wrap: nowrap;
		gap: 0px 22px;
	}

	@media (${screenSize.antLg}) {
		width: ${antWidth.col16};
	}

	@media (${screenSize.antXxl}) {
		width: ${antWidth.col12};
	}
`

export const ButtonContainer = styled.div`
	min-width: 142px;
`

export const CommentBlock = styled.div`
	${fontRoboto}
	${regular13}
	letter-spacing: 0.13em;
    text-align: center;
	color: ${colors.blackTr64};

	@media (${screenSize.antXs}) {
		order: 3;
		flex-grow: 1;
	}

	@media (${screenSize.antMd}) {
		order: unset;
		flex-grow: unset;
	}
`
