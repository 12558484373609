import { Block } from '@ui/block'
import { ReactComponent as LogoSvgNoText } from '@assets/svg/site-logo-no-text.svg'
import { LogoCircle, TipTextBlock } from './sd-notification-styled'

type Props = {
	text: string
	linkSlot?: React.ReactNode
}

export function SdNotification({ text, linkSlot }: Props) {
	return (
		<Block isFlexBlock>
			<LogoCircle>
				<LogoSvgNoText color="white" width={24} height={24} />
			</LogoCircle>
			<TipTextBlock>
				<span dangerouslySetInnerHTML={{ __html: text }} />
				&nbsp;
				{linkSlot}
			</TipTextBlock>
		</Block>
	)
}
