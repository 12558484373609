import { useCallback, useMemo, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@store/store'
import { ClientInfo, DealInfo, NavigationButtonInfo } from '@typings/types'
import { authSelector } from '../../store/auth'
import { getAllClientsList } from '../../api/stylist/get-all-clients-list'
import {
	clientsSelector,
	setIsListLoading,
	setShowListType,
	setClientsList,
	setFilteredClientsList,
} from '@store/clients'
import { useNavigate } from 'react-router-dom'
import { getActiveDeals } from '@api/stylist/deal/get-active-deals'
import { getArchiveDeals } from '@api/stylist/deal/get-archive-deals'
import { userSelector } from '@store/user'

export function useClientsPage() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { token } = useAppSelector(authSelector)

	const [isLoading, setIsLoading] = useState(false)
	const [activeDeals, setActiveDeals] = useState<DealInfo[]>([])
	const [archiveDeals, setArchiveDeals] = useState<DealInfo[]>([])
	const [dealsToShow, setDealsToShow] = useState<DealInfo[]>([])
	const [clientsList, setClientsList] = useState<ClientInfo[]>([])

	const { showListType } = useAppSelector(clientsSelector)
	const { userInfo } = useAppSelector(userSelector)

	const loadClients = useCallback(
		async (disableLoader: boolean = false) => {
			!disableLoader && setIsLoading(true)
			const [activeResult, archiveResult] = await Promise.all([
				await getActiveDeals({ token }),
				await getArchiveDeals({ token }),
			])

			if (activeResult) {
				setActiveDeals(activeResult)
				setClientsList(activeResult.map((deal) => deal.client))
				showListType === 'active' && setDealsToShow(activeResult)
			}

			if (archiveResult) {
				setArchiveDeals(archiveResult)
				setClientsList(archiveResult.map((deal) => deal.client))
				showListType === 'archive' && setDealsToShow(archiveResult)
			}

			setIsLoading(false)
		},
		[token]
	)

	useEffect(() => {
		if (isLoading) return

		loadClients()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (isLoading) return

		loadClients(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo])

	const handleChangeSearch = (data: any) => {
		dispatch(
			setFilteredClientsList(
				clientsList.filter(
					(client) =>
						client.first_name &&
						client.first_name
							.toLowerCase()
							.includes(data.target.value.toLowerCase())
				)
			)
		)
	}

	const handleClickActive = useCallback(() => {
		if (showListType === 'active') return

		setDealsToShow(activeDeals)
		dispatch(setShowListType('active'))
	}, [activeDeals, dispatch, showListType])

	const handleClickArchive = useCallback(() => {
		if (showListType === 'archive') return

		setDealsToShow(archiveDeals)
		dispatch(setShowListType('archive'))
	}, [archiveDeals, dispatch, showListType])

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'Active clients',
				iconName: 'clientsNewIcon',
				showListType: 'active',
				handleClick: handleClickActive,
				showNotificationIcon: !!activeDeals.find(
					(deal) => !!deal.dealStatus.unreadMessagesCount
				),
			},
			{
				title: 'Archive clients',
				iconName: 'archiveIcon',
				showListType: 'archive',
				handleClick: handleClickArchive,
				showNotificationIcon: !!archiveDeals.find(
					(deal) => !!deal.dealStatus.unreadMessagesCount
				),
			},
		],
		[activeDeals, archiveDeals, handleClickActive, handleClickArchive]
	)

	const handleClickClient = useCallback(
		(id: number) => {
			navigate(`${id}`, {
				state: {
					clientId: id,
					clientType: showListType,
				},
			})
		},
		[navigate, showListType]
	)

	return {
		dealsToShow,
		isListLoading: isLoading,
		handleChangeSearch,
		handleClickClient,
		navigationButtons,
		showListType,
	}
}
