import { colors } from '@styles/colors'
import { screenSize } from '@styles/screen-sizes'
import {
	bold16,
	fontRoboto,
	fontTenorSans,
	regular13,
	regular20,
	regular26,
	regular32,
} from '@styles/typography'
import { Button } from '@ui/button'
import { Col } from 'antd'
import styled from 'styled-components'

export const HeaderText = styled.div`
	font-family: 'Playfair Display', 'Tenor Sans', sans-serif;

	text-align: center;
	margin: 70px auto 32px;
	white-space: pre-wrap;

	@media (${screenSize.antXs}) {
		${regular26}
	}

	@media (${screenSize.antMd}) {
		${regular26}
	}

	@media (${screenSize.antXl}) {
		${regular32}
	}
`

export const SubHeaderCol = styled(Col)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

export const SubHeaderText = styled.div`
	font-family: 'Playfair Display', 'Tenor Sans', sans-serif;
	font-size: 42px;
	font-style: normal;
	font-weight: 400;
	line-height: 50px;
	letter-spacing: 0.84px;

	margin: 48px 0;
`

export const SmallText = styled.div`
	${fontTenorSans}
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
`

export const StylistImageFrame = styled.div`
	margin: 36px;
	height: 392px;
	border-radius: 20px;
`
export const StylistInfoStyled = styled.div`
	position: relative;
	z-index: 100;
	background: ${colors.white};
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 12px;
	display: flex;

	background: ${colors.white};
	padding: 24px;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;

	@media (${screenSize.antXl}) {
		flex-direction: row;
		justify-content: space-between;
		margin: -68px 16px 0;
	}
`
export const StylistAvatar = styled.div`
	min-width: 56px;
	height: 56px;
	border-radius: 50%;
	background: gray;
	overflow: hidden;
`

export const StylistName = styled.div`
	${fontTenorSans}
	${regular20}
`

export const StylistAlignment = styled.div`
	${fontRoboto}
	${bold16}
  color: #FF0C63;
	align-self: start;
`

export const ColFlex = styled(Col)`
	display: flex;
	align-items: center;
`

export const CountCircle = styled.div`
	${fontRoboto}
	${bold16}
	width: 48px;
	height: 48px;
	line-height: 48px;
	border-radius: 50%;
	background: ${colors.blackTr5};
	text-align: center;
`

export const CountText = styled.div`
	margin: 0 16px;
	${fontRoboto}
	${regular13}
	color: ${colors.blackTr48};
`

export const StylistLink = styled.a`
	${fontRoboto}
	${bold16}
	color: ${colors.blackTr64};
	display: flex;
	align-items: center;

	&:hover {
		color: ${colors.blackTr64};
	}
`

export const ColContainer = styled(Col)`
	cursor: pointer;

	&:hover {
		& > div {
			box-shadow: 0px 6px 32px ${colors.blackTr8};
		}
	}
`

export const ButtonStyled = styled(Button)`
	max-width: 150px;
	width: 100%;
`
