import { Container, DeleteFormCapsule, ImageCont } from './clothes-item-styled'
import { ImageItemType } from '@typings/types'
import { Icon } from '@ui/index'
import { MakeCoverButton } from '@components/gallery-block/photos-list/photo-item/make-cover-button/make-cover-button'

type Props = {
	imageItem: ImageItemType
	handleSetCoverClick: (imageId: number) => void
	handleDeleteClick: (imageId: number) => void
}

export function ClothesItem({
	imageItem,
	handleSetCoverClick,
	handleDeleteClick,
}: Props) {
	return (
		<Container>
			<DeleteFormCapsule
				skin="dark"
				onClick={() => handleDeleteClick(imageItem.id)}
				className={`hidden-button`}
				isFitContent
			>
				<Icon name="trashIcon" />
			</DeleteFormCapsule>
			{handleSetCoverClick && (
				<MakeCoverButton
					handleSetCoverClick={() => handleSetCoverClick(imageItem.id)}
					isCover={imageItem.isCover}
				/>
			)}
			<ImageCont imageUrl={imageItem.url} />
		</Container>
	)
}
