import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DealInfo, ReduxDeal } from '../../typings/types'

const initialState: ReduxDeal = {
	isDealInfoLoading: false,
	dealsInfo: [],
	styleboards: [],
	capsules: [],
}

const slice = createSlice({
	name: 'deal',
	initialState,
	reducers: {
		setDealsInfo: (state, { payload }: PayloadAction<DealInfo[]>) => {
			state.dealsInfo = payload
		},
	},
})

export const dealReducer = slice.reducer
export const { setDealsInfo } = slice.actions
