import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { acceptClient } from '@api/stylist/accept-client'
import { getActiveDeals } from '@api/stylist/deal/get-active-deals'
import { getArchiveDeals } from '@api/stylist/deal/get-archive-deals'
import { getClientDealInfo } from '@api/stylist/deal/get-client-deal-info'
import { rejectClient } from '@api/stylist/reject-client'
import Avatar from '@components/avatar/avatar'
import statusData from '@components/booking-info/status-data.json'
import { useInitChat } from '@hooks/use-init-chat'
import { authSelector } from '@store/auth'
import {
	chatSelector,
	setChatId,
	setChatUser,
	setIsChatOpen,
} from '@store/chat'
import { useAppDispatch, useAppSelector } from '@store/index'
import { userSelector } from '@store/user'
import {
	ClientInfo,
	DealAction,
	DealInfo,
	NavigationButtonInfo,
} from '@typings/types'
import { handleToast } from '@utils/handle-toast'

export function useStylistClientDetails() {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const { id } = useParams()
	const { token } = useAppSelector(authSelector)
	const { userInfo } = useAppSelector(userSelector)
	const { isChatLoading, chatUser } = useAppSelector(chatSelector)
	const { initChat } = useInitChat()

	const [dealDetails, setDealDetails] = useState<DealInfo>()
	const [clientsList, setClientsList] = useState<ClientInfo[]>([])
	const [clientDetails, setClientDetails] = useState<ClientInfo>()
	const [showListType, setShowListType] = useState<string>()
	const [isLoading, setIsLoading] = useState(false)

	const [showClientDetailsListType, setShowClientDetailsListType] = useState<
		'artifacts' | 'preferences'
	>('preferences')
	const [isModalOpen, setIsModalOpen] = useState(false)

	const loadClients = useCallback(
		async (id: string) => {
			setIsLoading(true)
			const clientDealDetails = await getClientDealInfo({
				token,
				clientId: Number(id),
			})

			let clientType =
				clientDealDetails?.dealStatus.status === 'done' ? 'archive' : 'active'
			const resultClientList =
				clientType === 'archive'
					? await getArchiveDeals({ token })
					: await getActiveDeals({ token })

			if (clientDealDetails && resultClientList) {
				setDealDetails(clientDealDetails)
				setClientsList(
					resultClientList.map((deal) => {
						deal.client.unreadMessagesCount =
							deal.dealStatus.unreadMessagesCount
						return deal.client
					})
				)
				setClientDetails(clientDealDetails?.client)
				setShowListType(id)
			}
			setIsLoading(false)
		},
		[token]
	)

	const handleOk = async () => {
		if (!clientDetails?.id) return

		setIsLoading(true)
		const result = await rejectClient({
			token,
			clientId: clientDetails.id,
		})

		if (!result) {
			handleToast({ content: 'Something went wrong with rejecting client' })
		}

		handleToast({
			content: 'Client was rejected successfully',
			type: 'success',
		})
		setIsModalOpen(false)
		navigate('/clients')
	}

	const handleCancel = () => {
		setIsModalOpen(false)
	}

	const handleClickClient = useCallback(
		(clientId: number) => {
			navigate(`/clients/${clientId}`)
		},
		[navigate]
	)

	const handleClickAllClients = () => {
		navigate('/clients')
	}

	const buttons: NavigationButtonInfo[] = useMemo(() => {
		let clientsButtons: NavigationButtonInfo[] = clientsList.map((client) => {
			return {
				title: client.first_name,
				iconName: 'hangerIcon',
				showListType: client.id.toString(),
				node: (
					<Avatar
						imageUrl={client?.params?.photo?.[0].urls?.S || ''}
						size={40}
					/>
				),
				counter:
					userInfo.chatList?.find(
						(chat) => chat.cometchatUid === client.cometchat_uid
					)?.unreadMessagesCount ?? 0,
				handleClick: () => handleClickClient(client.id),
			}
		})

		clientsButtons = clientsButtons.slice(0, 5)
		clientsButtons.push({
			title: 'Go to all clients',
			showListType: '',
			handleClick: handleClickAllClients,
			color: '#FF0C63',
		})

		return clientsButtons
	}, [clientsList, handleClickAllClients, handleClickClient])

	useEffect(() => {
		setShowClientDetailsListType(
			dealDetails?.dealStatus?.status === 'wait' ? 'preferences' : 'artifacts'
		)
	}, [dealDetails?.dealStatus?.status])

	useEffect(() => {
		if (!id) return

		loadClients(id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	const clientNavButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'Styling studio',
				showListType: 'artifacts',
				iconName: 'glassesIcon',
				handleClick: () => {
					setShowClientDetailsListType('artifacts')
				},
			},
			{
				title: 'Profile',
				showListType: 'preferences',
				iconName: 'checkHeartIcon',
				handleClick: () => {
					setShowClientDetailsListType('preferences')
				},
			},
		],
		[]
	)

	const handleActionApply = useCallback(
		async (action: DealAction) => {
			if (!clientDetails?.id) return
			setIsLoading(true)

			// TODO: Need to implement all statuses
			switch (action) {
				case 'start_work':
					const result = await acceptClient({
						token,
						clientId: clientDetails.id,
					})

					if (!result) {
						handleToast({
							content: 'Something went wrong with accepting client',
						})
						setIsLoading(false)
						return
					}

					handleToast({ content: 'Client was accepted', type: 'success' })
					id && loadClients(id)
					setIsLoading(false)
					return

				case 'book_decline':
					setIsLoading(false)
					setIsModalOpen(true)
					return

				case 'create_capsule':
					setIsLoading(false)
					navigate('/capsules')
					return

				case 'restyle_capsule':
					setIsLoading(false)
					const capsuleId = dealDetails?.details?.capsules[0]?.id
					navigate(capsuleId ? `/capsules/${capsuleId}/edit` : '/capsules', {
						state: {
							capsuleId,
						},
					})
					return

				case 'create_styleboard':
				case 'add_styleboard':
					setIsLoading(false)
					navigate('/styleboards/new/edit', {
						state: {
							styleboardId: 'new',
							clientId: clientDetails.id,
						},
					})
					return

				case 'restyle_styleboard':
					setIsLoading(false)
					const styleboardId = dealDetails?.details?.styleboards[0]?.id
					navigate(
						styleboardId ? `/styleboards/${styleboardId}/edit` : '/capsules',
						{
							state: {
								styleboardId,
							},
						}
					)
					return

				case 'chat_open':
					setIsLoading(false)
					if (!chatUser) {
						const result = await initChat(userInfo, token)
						result && dispatch(setChatUser(result))
					}
					dispatch(setIsChatOpen(true))
					dispatch(setChatId(clientDetails.cometchat_uid))
					return

				default:
					setIsLoading(false)
					handleToast({ content: `${action} action are not ready yet` })
					return
			}
		},
		[
			chatUser,
			clientDetails?.cometchat_uid,
			clientDetails?.id,
			dealDetails?.details?.capsules,
			dealDetails?.details?.styleboards,
			dispatch,
			id,
			initChat,
			loadClients,
			navigate,
			token,
			userInfo,
		]
	)

	const dueDate = useMemo(() => {
		if (!dealDetails?.dealStatus?.statusDeadline) return ''

		return moment(
			dealDetails?.dealStatus.statusDeadline,
			'YYYY-MM-DD hh:mm:ss'
		).format('DD MMM, hh:mm')
	}, [dealDetails?.dealStatus?.statusDeadline])

	const unreadMessagesCount = useMemo(
		() =>
			userInfo.chatList?.find(
				(chat) => chat.cometchatUid === clientDetails?.cometchat_uid
			)?.unreadMessagesCount ?? 0,
		[userInfo.chatList, clientDetails?.cometchat_uid]
	)

	return {
		statusData,
		dueDate,
		isLoading,
		clientDetails,
		dealDetails,
		showListType,
		buttons,
		handleActionApply,
		stylistInfo: userInfo?.stylist,
		clientNavButtons,
		showClientDetailsListType,
		isModalOpen,
		handleOk,
		handleCancel,
		isChatLoading,
		unreadMessagesCount,
	}
}
