import { fontTenorSans, regular26 } from './../../../styles/typography'
import styled from 'styled-components'
import { colors } from '../../../styles'

export const UploadedFile = styled.div`
	display: flex;
	justify-content: flex-start;
	color: ${colors.blackTr64};

	margin-top: 16px;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
`

export const UploadFileWrapper = styled.div`
	#upload-file-cv {
		opacity: 0;
		position: absolute;
		z-index: -1;
	}
`

export const ErrorBlock = styled.div`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;

	margin: 10px 0;

	color: ${colors.error};
`

export const ModalTitle = styled.div`
	${fontTenorSans}
	${regular26}
	color: ${colors.black};

	text-align: center;
	margin: 36px auto;
`
