import styled from 'styled-components'

import { Button } from '@ui/button'
import { colors } from '@styles/colors'
import {
	fontRoboto,
	bold16,
	regular13,
	regular26,
	fontTenorSans,
	regular18,
} from '@styles/typography'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 24% 33% 40%;
	grid-gap: 48px;
`

export const StylistInfoContainer = styled.div`
	display: block;
	width: 100%;
	margin: 0 auto;
	padding: 0 16px;
`
export const SectionBlock = styled.div`
	padding: 32px 40px;
	border-radius: 20px;
	background: ${colors.blackTr5};
`

export const SectionTitle = styled.div`
	${fontTenorSans}
	${regular18}

	&>span {
		margin-right: 10px;
	}
`

export const StylistInfoBlock = styled.div`
	position: relative;
	margin-top: -25%;
	padding: 20px 24px 24px;
	background: ${colors.white};
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 12px;
`

export const StylistName = styled.div`
	${fontTenorSans}
	${regular26}
	color: ${colors.black};
`

export const StylistDescription = styled.div`
	${fontRoboto}
	${regular13}
	margin-top: 8px;
	letter-spacing: 0.13px;
	color: ${colors.blackTr64};
`

export const IconWrapper = styled.div`
	${fontRoboto}
	${bold16}
  color: ${colors.black};
	background: ${colors.blackTr5};
	padding: 12px;
	border-radius: 64px;
	line-height: 2rem;
	min-width: 44px;
	text-align: center;

	&:not(:first-child) {
		margin-left: 8px;
	}
`

export const DivStyled = styled.div`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr48};
	align-self: center;
`

export const ButtonStyled = styled(Button)`
	margin-top: 20px;
`

export const HeaderText = styled.div`
	${fontTenorSans}
	${regular26}
	color: ${colors.black};
`

export const ButtonAdd = styled(Button)`
	${fontRoboto}
	${bold16}
	color: #FF0C63;
	padding: 0;
`

export const ButtonMessenger = styled(Button)`
	& > div {
		margin-right: 0;
	}
`

export const BlockTitle = styled.div`
	${fontRoboto}
	${bold16}
  margin-bottom: 8px;
`
