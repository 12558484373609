import {
	bold12,
	fontRoboto,
	fontTenorSans,
	regular13,
	regular18,
} from '@styles/typography'
import styled, { css } from 'styled-components'
import { colors } from '@styles/colors'

export const ImageCont = styled.div<{ imageUrl?: string }>`
	margin-top: 25px;
	margin-bottom: 25px;
	width: 106px;
	height: 153px;
	border-radius: 12px;
	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
			box-shadow: none;
		`}
	background-color: ${colors.blackTr5};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	max-width: 140px;
	min-height: 293px;
	width: 100%;
	border-radius: 20px;
	padding: 6px 16px;
	margin: 0 auto;
	/*
	&:hover {
		box-shadow: 0px 6px 32px ${colors.blackTr8};
	}
	*/
`

export const Title = styled.div`
	${fontRoboto}
	${bold12}
  align-self: start;

	padding: 0;
	min-height: 24px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: inherit;
`

export const Brand = styled.div`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr48};
	align-self: start;
	min-height: 24px;
`

export const Price = styled.div`
	${fontTenorSans}
	${regular18}
  color: ${colors.blackTr64};
	padding: 9px 0;
	align-self: start;
	min-height: 24px;
`
