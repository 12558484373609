import {
	FormWrapper,
	ButtonsBlock,
	TitleWrapper,
	StyledP,
} from '../form-styled'
import { Button, Input, Skeleton, Block } from '../../../../UI'
import { useNewClientForm } from './use-new-client-form'
import { Row, Col, Checkbox } from 'antd'
import { CookieBlock, SubscribeCheckBox } from './new-client-form-styled'
import { PROD_DOMAIN } from '@typings/constants'

export function NewClientForm() {
	const {
		defaultValues,
		emailRegister,
		errors,
		handleSubmit,
		isLoading,
		onCancelClick,
		onSubmitNewUser,
		register,
		watch,
	} = useNewClientForm()

	if (isLoading) {
		return (
			<Skeleton width={326} height={354}>
				<rect x="0" y="0" rx="6" ry="6" width="326" height="32" />
				<rect x="0" y="56" rx="6" ry="6" width="326" height="88" />
				<rect x="0" y="200" rx="6" ry="6" width="326" height="222" />
				<rect x="0" y="478" rx="6" ry="6" width="326" height="56" />
			</Skeleton>
		)
	}

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmitNewUser)}>
			<Block margin="0 auto">
				<TitleWrapper>Register as a Client</TitleWrapper>
			</Block>
			<StyledP>
				Fill in the required fields and click "Next". <br />
				We'll email you a confirmation code to proceed with your application
			</StyledP>
			<Block margin="56px auto 0">
				<Row gutter={[8, 32]}>
					<Col span={24}>
						<Input
							hasValue={!!watch('name')}
							label="name"
							placeholder="Name *"
							inputFieldSlot={
								<input {...register('name')} type="text" disabled={isLoading} />
							}
							isError={!!errors.name}
							errorText={errors.name?.message}
						/>
					</Col>

					<Col span={24}>
						<Input
							hasValue={!!defaultValues?.email || !!watch('email')}
							label="email"
							placeholder="E-mail address *"
							inputFieldSlot={
								<input type="email" {...emailRegister} disabled={isLoading} />
							}
							isError={!!errors.email}
							errorText={errors.email?.message}
						/>
					</Col>

					<Col>
						<CookieBlock>
							By registering, you agree to our{' '}
							<a
								href={
									PROD_DOMAIN + 'docs/Terms_And_Conditions_StyleDyle_Ltd.pdf'
								}
								target="_blank"
								rel="noreferrer"
							>
								Terms & Conditions
							</a>
							,{' '}
							<a
								href={
									PROD_DOMAIN +
									'docs/Privacy_and_Cookie_Policy_StyleDyle_Ltd.pdf'
								}
								target="_blank"
								rel="noreferrer"
							>
								Privacy and Cookie Policy
							</a>
							, and to join our loyalty programme:
						</CookieBlock>
						<SubscribeCheckBox>
							<Checkbox>
								Subscribe to our newsletter and stay updated on our best offers
								(we'll only use your email, name and style preferences)
							</Checkbox>
						</SubscribeCheckBox>
					</Col>
				</Row>
			</Block>
			<ButtonsBlock>
				<Button onClick={onCancelClick} skin="grey-link" width={159}>
					Cancel
				</Button>
				<Button isSubmit skin="dark" width={159}>
					Next
				</Button>
			</ButtonsBlock>
		</FormWrapper>
	)
}
