import styled, { css } from 'styled-components'
import { FlexDirection } from './types'

type Props = {
	isFullWidth?: boolean
	margin?: string
	padding?: string
	gap?: string

	isFlexBlock?: boolean
	isContentCentered?: boolean
	flexDirection?: FlexDirection
	justifyContent?:
		| 'start'
		| 'flex-start'
		| 'center'
		| 'end'
		| 'flex-end'
		| 'left'
		| 'right'
		| 'space-between'
		| 'space-around'
	isClickable?: boolean
}

export const Block = styled.div<Props>`
	${(props) => (props.isFullWidth ? 'width: 100%;' : '')}

	${(props) =>
		props.padding &&
		css`
			padding: ${props.padding};
		`}

  ${(props) =>
		props.margin &&
		css`
			margin: ${props.margin};
		`}

	${(props) =>
		props.isFlexBlock &&
		css`
			display: flex;
			align-items: center;
		`}

		${(props) =>
		props.justifyContent &&
		css`
			justify-content: ${props.justifyContent};
		`}
		
	${(props) =>
		props.gap &&
		css`
			gap: ${props.gap};
		`}		

	${(props) =>
		props.flexDirection &&
		css`
			flex-direction: ${props.flexDirection};
		`}

	${(props) =>
		props.isContentCentered &&
		css`
			justify-content: center;
		`}

	${(props) =>
		props.isClickable &&
		css`
			cursor: pointer;
		`}
`
