import styled, { css } from 'styled-components'
import { ButtonProps } from './types'
import { colors } from '../../styles/colors'
import { screenSize } from '../../styles/screen-sizes'
import { fontRoboto } from '@styles/typography'

export const CounterWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 21px;
	height: 21px;
	border-radius: 50%;

	${fontRoboto}
	font-weight: 700;
	font-size: 13px;
	line-height: 13px;
	margin-left: 1rem;

	backdrop-filter: blur(2px);
`

export const ButtonWrapper = styled.button<ButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: 8px;
	border: none;
	flex-wrap: nowrap;

	cursor: pointer;

	font-family: 'Roboto';
	font-style: normal;

	${({ isFitContent }) =>
		css`
			width: ${isFitContent ? 'fit-content' : '100%'};
		`}

	transition: all 0.2s ease-in-out;

	${({ margin }) =>
		margin &&
		css`
			margin: ${margin};
		`}

	${({ width }) =>
		width &&
		css`
			max-width: ${width}px;
		`}

    ${({ size }) => {
		switch (size) {
			case 's':
				return css`
					font-size: 12px;
					font-weight: 700;
					line-height: 18px;

					padding: 6px 8px;
					max-height: 30px;
				`

			case 'm':
				return css`
					font-size: 14px;
					font-weight: 600;
					line-height: 20px;

					padding: 8px 16px;
					max-height: 40px;
				`

			case 'l':
				return css`
					font-size: 15px;
					font-weight: 700;
					line-height: 20px;

					padding: 12px;
					max-height: 48px;
				`

			case 'xl':
			default:
				return css`
					font-size: 16px;
					font-weight: 600;
					line-height: 18px;

					padding: 18px;
					max-height: 56px;
				`
		}
	}}

	${({ skin, isActive }) => {
		switch (skin) {
			case 'dark':
				return css`
					color: ${colors.white};
					background: ${colors.buttonDark};

					& ${CounterWrapper} {
						color: ${colors.mainThemeColor};
						background: ${colors.white};
					}
				`

			case 'dark-outline':
				return css`
					color: ${colors.mainThemeColor};
					background: ${colors.white};
					border: 1px solid ${colors.mainThemeColor};
				`

			case 'dark-link':
				return css`
					color: ${colors.mainThemeColor};
					background: transparent;
					border: none;
				`

			case 'light':
				return css`
					color: ${colors.mainThemeColor};
					background: ${colors.buttonLight};
				`

			case 'light-link':
				return css`
					color: ${colors.mainThemeColor};
					background: transparent;
					border: none;
				`

			case 'grey':
				return css`
					color: ${colors.black};
					background: ${colors.blackTr5};
				`

			case 'grey-link':
				return css`
					color: ${colors.blackTr64};
					background: transparent;
					border: none;
				`

			case 'grey-outline':
				return css`
					color: ${colors.blackTr64};
					background: ${colors.white};
					border: 1px solid ${colors.blackTr16};

					& ${CounterWrapper} {
						color: ${colors.white};
						background: ${colors.buttonDark};
					}

					${isActive &&
					css`
						background: ${colors.blackTr16};
					`}

					&:hover {
						background: ${colors.blackTr16};
					}
				`
			case 'white-outline':
				return css`
					color: ${colors.blackTr64};
					background: ${colors.white};
					border: 1px solid ${colors.blackTr16};

					${isActive &&
					css`
						background: ${colors.blackTr5};
						border: 1px solid ${colors.black};
					`}

					&:hover {
						background: ${colors.blackTr16};
					}
				`

			default:
				return css`
					color: ${colors.white};
					background: ${colors.buttonDark};
				`
		}
	}}

	${({ isDisabled }) =>
		isDisabled &&
		css`
			color: ${colors.white};
			background: ${colors.buttonDisabled};

			&:hover {
				color: ${colors.white};
				background: ${colors.buttonDisabled};
			}
		`}

	@media (${screenSize.maxMobile}) {
		&button:nth-child(2n) {
			margin-left: 10px;
		}
	}
`

export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const LeftIconWrapper = styled(IconWrapper)<{ hasChildren: boolean }>`
	${({ hasChildren }) =>
		hasChildren &&
		css`
			margin-right: 1rem;
		`}
`
export const RightIconWrapper = styled(IconWrapper)<{ hasChildren: boolean }>`
	${({ hasChildren }) =>
		hasChildren &&
		css`
			margin-left: 1rem;
		`}
`
