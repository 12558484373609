import styled from 'styled-components'

export const RepeatCodeWrapper = styled.div`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	margin-top: 18px;
	opacity: 0.64;

	& span {
		color: red;
		cursor: pointer;
	}
`
