import { Col } from 'antd'

import { FullScrIcon } from '@pages/capsules/capsules-preview-page/capsules-preview-page-styled'
import { QuizSelector } from '@store/quiz'
import { useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { PROD_DOMAIN } from '@typings/constants'
import { Block, Icon } from '@ui/index'
import {
	AnswerWrapper,
	ColStyled,
	IconBorder,
	ImageStyled,
	ImageWrapper,
	QuestionCont,
	RowAnswersStyled,
	RowFlexColumn,
	RowStyled,
	TextWrapper,
} from '../../quiz-styled'
import { useQuizAnswers } from '../../use-quiz-answers'

export function QuizStyleQuestion() {
	const { userInfo } = useAppSelector(userSelector)

	const { questions, currentStep, answers } = useAppSelector(QuizSelector)
	const currentQuestion = questions[currentStep - 1]
	const currentAnswers = answers[currentStep] ?? ''
	const { handleSaveAnswer } = useQuizAnswers({
		isMultiAnswer: !!currentQuestion?.answers_multiple,
	})
	const questionImage = currentQuestion?.cover_image
		? PROD_DOMAIN + currentQuestion?.cover_image
		: ''
	const answrLen = currentQuestion?.answers_json.length;

	if (!currentQuestion?.answers_json.length) return null

	return (
		<RowStyled style={{ height: '100%' }} >
			<Col
				span={24}
				style={{
					justifyContent: 'space-between',
					display: 'flex',
					flexFlow: 'column',
				}}
			>
				<RowFlexColumn justify="center" align="middle" gutter={[0, 48]}>
					<Col xs={{span: 22, offset: 1}} md={{span: 14, offset: 5}} lg={{span: 14, offset: 5}} xl={{span: 12, offset: 6}}>
						<QuestionCont>
							{userInfo.role === 'stylist'
								? currentQuestion?.stylist_question
								: currentQuestion?.question}
						</QuestionCont>
					</Col>
					{currentQuestion.step_comment && (
						<Col 
						xs={{span: 22, offset: 1}} md={{span: 14, offset: 5}} lg={{span: 14, offset: 5}} xl={{span: 12, offset: 6}}
						>
							<TextWrapper>{currentQuestion.step_comment}</TextWrapper>
						</Col>
					)}
					{questionImage && (
					<Col xs={{span: 22, offset: 0}} md={{span: 20, offset: 2}} lg={{span: 12, offset: 6}}>
						<ImageStyled
							loading="eager"
							src={questionImage}
							width='100%'
							preview={{
								mask: (
									<FullScrIcon>
										<Icon color="white" name="fullScreenIcon" />
									</FullScrIcon>
								),
							}}
						/>
					</Col>
					)}
				</RowFlexColumn>

				<RowAnswersStyled justify="center" align="middle" gutter={[24,24]}>
					{currentQuestion.answers_json.map((answer, index) => {
						
						return (
						<ColStyled
							xs={{span: answer.image || answrLen > 3 ? 16 : 8}}
							md={{span: answer.image ? 12 : answrLen > 3 ? 4 : 5}}
							lg={{span: answer.image ? 6 : 4}}
							xl={{span: answer.image ? 5 : 4}}
							xxl={{span: answer.image ? 4 : answrLen > 3 ? 3 : 4}}
							key={index}
							onClick={() => handleSaveAnswer(answer.value)}
						>
							{answer.image && (
								<ImageWrapper>
									<img src={PROD_DOMAIN + answer.image} alt="" />
								</ImageWrapper>
							)}
							<Block 
								isContentCentered
								justifyContent='center' 
								isFullWidth 
								isFlexBlock 
								flexDirection={answer.image || answrLen > 3 ? 'column':'row'} 
								gap={answer.image || answrLen > 3 ? '24px' : '8px'}
								padding={ answrLen > 3 ? '32px 0' : 'unset'}
							>
								<IconBorder>
									{currentAnswers.includes(answer.value) && (
										<Icon name="checkCircleColoredIcon" color="#FF0C63" />
									)}
								</IconBorder>
								<AnswerWrapper>{answer.text}</AnswerWrapper>
							</Block>
						</ColStyled>
					)})}
				</RowAnswersStyled>
			</Col>
		</RowStyled>
	)
}
