import { MediaLayout } from '@ui/media-layout/media-layout'
import { HeaderCountDesktop } from './desktop/header-count-desktop'
import { HeaderCountMobile } from './mobile/header-count-mobile'
import { HeaderCountProps } from './types'

export function HeaderCount(props: HeaderCountProps) {
	return (
		<MediaLayout
			mobileSlot={<HeaderCountMobile {...props} />}
			desktopSlot={<HeaderCountDesktop {...props} />}
		/>
	)
}
