import { ReactComponent as LogoSvgNoText } from '@assets/svg/site-logo-no-text.svg'
import { colors } from '@styles/colors'

type LogoProps = {
	color: string
	logoSize?: string
}

export function BlankPlaceholder({ color, logoSize }: LogoProps) {
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexFlow: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				background: colors.blackTr5,
				borderRadius: 20,
			}}
		>
			<div style={{ width: logoSize || '25%', height: logoSize || '25%' }}>
				<LogoSvgNoText color={color} />
			</div>
		</div>
	)
}
