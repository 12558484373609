import { Block } from '@ui/block'
import { Icon } from '@ui/icon'
import { Container, ButtonWrapper, UploadText } from './add-link-button-styled'

type Props = {
	onClick: (e: any) => void
}

export function AddLinkButton({ onClick }: Props) {
	return (
		<Container onClick={onClick}>
			<ButtonWrapper>
				<Block>
					<Icon name="linkPlusIcon" size={30} />
				</Block>
				<UploadText margin="10px 0 0 0">Add link</UploadText>
			</ButtonWrapper>
		</Container>
	)
}
