import { useState } from 'react'
import { useAppDispatch } from '@store/store'
import { setLoginFormStep } from '@store/login-flow'

type UserType = 'client' | 'stylist'

export function useUserCreateForm() {
	const dispatch = useAppDispatch()
	const [userType, setUserType] = useState<UserType>('client')

	const onSubmitUser = async () => {
		if (userType === 'client') {
			dispatch(setLoginFormStep('client-form'))
			return
		}

		dispatch(setLoginFormStep('stylist-form'))
	}

	const userTypeSelectHandler = (userType: UserType) => {
		setUserType(userType)
	}

	return {
		userType,
		onSubmitUser,
		userTypeSelectHandler,
	}
}
