import { LeftMenu } from '../../left-menu/left-menu'
import { SideBarProps } from '../../types'
import { Wrapper } from './left-sidebar-mobile-styled'

export function LeftSidebarMobile({ isOpen, handleClose }: SideBarProps) {
	if (!isOpen) return null

	return (
		<Wrapper>
			<LeftMenu handleClose={handleClose} />
		</Wrapper>
	)
}
