import axios from 'axios'

import { StyleboardReactions } from '@typings/types'
import { handleToast } from '@utils/handle-toast'
import { axiosInstance } from '../../config'

type Props = {
	styleboardId: string
	token: string
	reactions: StyleboardReactions
}

export async function UpdateReactions({
	token,
	styleboardId,
	reactions,
}: Props) {
	try {
		const response = await axiosInstance.patch(
			`/api/styleboard/${styleboardId}/reactions`,
			JSON.stringify({
				params: {
					likes: reactions.likes,
					dislikes: reactions.dislikes,
				},
			}),
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return !!response.data.success
	} catch (error) {
		if (axios.isAxiosError(error)) {
			handleToast({
				content: error.message,
			})
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
