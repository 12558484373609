import axios from 'axios'
import { axiosInstance } from '../../../config'
import { SuccessResponse } from '@typings/types'

type Props = {
	token: string
	stylistId: number
}

export async function bookStylist({ token, stylistId }: Props) {
	try {
		const response = await axiosInstance.post<SuccessResponse>(
			`/api/stylist/${stylistId}/book`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return !!response.data.success
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
