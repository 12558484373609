import { regular20 } from '@styles/index'
import {
	bold12,
	bold16,
	fontRoboto,
	fontTenorSans,
	regular13,
	regular16,
	regular18,
} from '@styles/typography'
import { colors } from '@styles/colors'
import styled, { css } from 'styled-components'

export const DivCapsuleCard = styled.div`
	max-width: 468px;
	width: 100%;
	margin: 0 auto;
`
export const ImageWrapper = styled.div`
	margin: 0 36px;
	border-radius: 20px;
`

export const ImageCont = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 321px;
	width: 100%;
	height: 321px;
	border-radius: 20px;
	margin: 0 auto;
	background: ${colors.white};

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
			box-shadow: none;
		`}
	background-color: ${colors.blackTr5};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export const Container = styled.div`
	position: relative;
	border-radius: 20px;
	cursor: pointer;
	height: 100%;

	:hover {
		& ${ImageWrapper} {
			box-shadow: 0px 6px 32px ${colors.blackTr8};
		}
	}
`

export const DataWrapper = styled.div`
	background: ${colors.white};
	border-radius: 12px;
	margin: 0 16px;
	margin-top: -50px;
	padding: 20px 24px;
`

export const ClientDataWrapper = styled.div`
	border-radius: 12px;
	padding: 23px 16px;
	background: ${colors.white};
	margin: -20px 0 0;
	box-shadow: 0px 6px 32px ${colors.blackTr8};
`

export const WrapperFlex = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	margin-left: 16px;
	overflow: hidden;
`

export const Title = styled.div`
	${fontTenorSans}
	${regular20}
	color: ${colors.black};
	margin: 20px 0 6px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

export const InfoBlock = styled.div`
	${fontRoboto}
	${regular20}
	color: ${colors.black};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

export const Price = styled.div`
	${fontTenorSans}
	${regular18}
  color: ${colors.black};
	margin-left: 8px;
`

export const Created = styled.div`
	${fontRoboto}
	${regular13}
  font-style: italic;
	vertical-align: bottom;
	color: ${colors.blackTr64};
	margin-right: 8px;
`

export const GrayLine = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	background: ${colors.blackTr5};
	margin: 26px 8px;
`

export const IconWrapper = styled.div`
	${fontRoboto}
	${bold16}
  color: ${colors.black};
	background: ${colors.blackTr5};
	padding: 12px;
	border-radius: 64px;
	line-height: 2rem;
	min-width: 44px;
	text-align: center;

	&:not(:first-child) {
		margin-left: 8px;
	}
`

export const Comment = styled.div`
	${fontRoboto}
	${regular16}
  color: ${colors.black};
	align-self: center;
	flex-grow: 1;
	margin-left: 20px;
`

export const DivStyled = styled.div`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr48};
	align-self: center;
`

export const CapsuleTitle = styled.div`
	${fontRoboto}
	${regular18}
	overflow: hidden;
	text-overflow: ellipsis;
`

export const StylistTitle = styled.div`
	${fontRoboto}
	${regular13}
	color: rgba(0, 0, 0, 0.64);
`
export const StylistInfoBlock = styled.span`
	${fontRoboto}
	${bold12}	
	background: ${colors.black};
	color: ${colors.white};
	position: absolute;
	top: 16px;
	left: 16px;
	padding: 4px 16px;
	border-radius: 8px;
`
