import { colors } from '@styles/index'
import styled, { css } from 'styled-components'

export const IconWrapper = styled.div`
	padding: 10px;
	background: ${colors.white};
	border-radius: 8px;
	position: absolute;
	right: 20px;
	top: 20px;
`

export const PhotoWrapper = styled.div<{ imageUrl?: string }>`
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	background: ${colors.blackTr5};
	border-radius: 20px;

	width: 100%;
	max-width: 270px;
	height: 276px;
	margin: 0 auto;

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
		`}

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	& > .cover-button,
	> .delete-button {
		opacity: 0;
	}

	&.cover > .cover-button {
		opacity: 1 !important;
		color: #ff0c63;
	}

	&:hover {
		box-shadow: 0px 6px 32px ${colors.blackTr8};

		& > .like-icon,
		.dislike-icon {
			opacity: 0;
		}

		& > .cover-button,
		> .delete-button {
			opacity: 0.6;
		}

		& > .cover-button:hover,
		> .delete-button:hover {
			opacity: 1;
		}
	}
`
