import axios from 'axios'
import { axiosInstance } from '../../config'
import { PublishStyleboardResponse } from '../../typings/types'

type Props = {
	token: string
	styleboardId: number
}

export async function publishStyleboard({ token, styleboardId }: Props) {
	try {
		const response = await axiosInstance.post<PublishStyleboardResponse>(
			`/api/styleboard/${styleboardId}/publish`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		if (response.data.success) {
			return response.data
		}

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
