import { Container } from './stylist-registration-form-styled'
import { PersonalDataForm } from './personal-data/personal-data'

export function StylistRegistrationForm() {
	return (
		<Container>
			<PersonalDataForm />
		</Container>
	)
}
