import { RepeatCodeWrapper } from './repeat-code-styled'
import { Props } from './types'
import { useRepeatCode } from './use-repeat-code'

export function RepeatCode({ onRepeatClick }: Props) {
	const { isCodeTimerFinished, timerText, handleRepeatCode } = useRepeatCode({
		onRepeatClick,
	})

	return (
		<RepeatCodeWrapper>
			{isCodeTimerFinished ? (
				<>
					I didn't receive the code,{' '}
					<span onClick={handleRepeatCode}>send again</span>.
				</>
			) : (
				<>{`I didn't receive the code, send again ${timerText}.`}</>
			)}
		</RepeatCodeWrapper>
	)
}
