import { bold16 } from './../../styles/typography'
import styled from 'styled-components'
import { colors, fontRoboto, regular16 } from '../../styles'

export const Container = styled.div`
	display: flex;
	flex-flow: column;
	gap: 48px;
`

export const TitleBlock = styled.div`
	${fontRoboto}
	${bold16}

	color: ${colors.black};
`
