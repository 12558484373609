import { Checkbox, CheckboxProps, Col, Row } from 'antd'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { createUser } from '@pages/login/api'
import { checkExistingUser } from '@pages/login/api/check-existing-user'
import { generateAuthCode } from '@pages/login/api/generate-auth-code'
import { CodeForm } from '@pages/login/forms'
import {
	CookieBlock,
	SubscribeCheckBox,
} from '@pages/login/forms/new-client/new-client-form-styled'
import {
	setUserCode,
	setUserEmail,
	setUserFirstName,
	useAppDispatch,
	useAppSelector,
} from '@store/index'
import { setLoginFormStep } from '@store/login-flow'
import { QuizSelector, setQuizClientName, setQuizStep } from '@store/quiz'
import { PROD_DOMAIN } from '@typings/constants'
import { Input, Modal, Spinner } from '@ui/index'
import { handleToast } from '@utils/handle-toast'
import { useState } from 'react'
import { HeaderText, MainText } from '../quiz-start/quiz-start-styled'
import { ButtonStyled, FormWrapper } from './silent-login-styled'

type FormValues = {
	name: string
	email: string
}

export const SilentLogin = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { questions } = useAppSelector(QuizSelector)
	const [isValidated, setIsValidated] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isSubscribed, setIsSubscribed] = useState(false)
	const [isNeedAuth, setIsNeedAuth] = useState(false)

	const {
		setError,
		register,
		formState: { errors },
		watch,
		clearErrors,
		handleSubmit,
	} = useForm<FormValues>()

	const onCheckboxChange: CheckboxProps['onChange'] = (e) => {
		setIsSubscribed(e.target.checked)
	}

	const emailRegexp =
		// eslint-disable-next-line no-useless-escape
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	const emailFormRegister = register('email', {
		onChange: (e) => {
			setIsValidated(emailRegexp.test(e.target.value))
		},
	})

	const onSubmit = async (data: FormValues) => {
		clearErrors()

		if (!data.email) {
			handleToast({ content: 'Please enter your email' })
			setError('email', {
				type: 'manual',
				message: 'Please enter your email',
			})
			return
		}

		if (!isValidated) {
			handleToast({ content: 'Please enter a valid email' })
			setError('email', {
				type: 'manual',
				message: 'Please enter a valid email',
			})
			return
		}

		if (!data.name) {
			handleToast({ content: 'Please enter your name' })
			setError('name', {
				type: 'manual',
				message: 'Please enter your name',
			})
			return
		}

		dispatch(setUserEmail(data.email))

		setIsLoading(true)
		const isUserExist = await checkExistingUser({
			email: data.email,
		})

		if (!isUserExist) {
			setIsLoading(false)
			const createUserData = await createUser({
				email: data.email,
				name: data.name,
				isSubscribed,
			})

			if (!createUserData?.success) {
				const errors = [
					createUserData?.errors?.email?.[0],
					createUserData?.errors?.username?.[0],
				].filter(Boolean)
				for (let i = 0; i < errors.length; i++) {
					handleToast({ content: errors?.[i] })
				}

				if (!!createUserData?.errors?.email?.[0]) {
					setError('email', {
						type: 'manual',
						message: createUserData?.errors?.email?.[0],
					})
				}
				setIsLoading(false)
				return
			}

			createUserData && dispatch(setUserCode(createUserData.code))
			dispatch(setUserEmail(data.email))
			dispatch(setUserFirstName(data.name))

			dispatch(setLoginFormStep('code'))

			dispatch(setQuizClientName(data.name))
			dispatch(setQuizStep(999))
			navigate('/quiz-results')

			setIsLoading(false)
			return
		}

		dispatch(setUserCode(''))
		const userCode = await generateAuthCode({
			email: data.email,
		})

		if (userCode?.success) {
			setIsNeedAuth(true)
		} else {
			handleToast({ content: 'Something went wrong. Please try again' })
			setIsLoading(false)
			return
		}

		if (userCode?.code) {
			dispatch(setUserCode(userCode.code))
		}

		setIsLoading(false)
	}

	if (isLoading) {
		return <Spinner />
	}

	return (
		<>
			{!!questions.length && (
				<HeaderText>
					You are one step away from matching with a stylist
				</HeaderText>
			)}
			<MainText>
				Please provide your name and email to receive your stylist match results
			</MainText>
			<Row>
				<Col lg={{ span: 16, offset: 4 }} xl={{ span: 12, offset: 6 }} xxl={{ span: 8, offset: 8 }}>
					<FormWrapper onSubmit={handleSubmit(onSubmit)}>
						<Row gutter={[32, 32]} justify={'center'}>
							<Col xs={{span: 22}} md={{span: 16}} lg={{span: 8}}>
								<Input
									label="name"
									hasValue={!!watch('name')}
									placeholder="Name"
									inputFieldSlot={<input type="text" {...register('name')} />}
									isError={!!errors.name}
									errorText={errors.name?.message}
								/>
							</Col>
							<Col xs={{span: 22}} md={{span: 16}}>
								<Input
									label="email"
									hasValue={!!watch('email')}
									placeholder="E-mail address"
									inputFieldSlot={<input type="text" {...emailFormRegister} />}
									isError={!!errors.email}
									errorText={errors.email?.message}
								/>
							</Col>
							<Col xs={{span: 22}} md={{span: 16}} style={{ marginTop: 32 }}>
								<CookieBlock>
									By registering, you agree to our{' '}
									<a
										href={
											PROD_DOMAIN +
											'docs/Terms_And_Conditions_StyleDyle_Ltd.pdf'
										}
										target="_blank"
										rel="noreferrer"
									>
										Terms & Conditions
									</a>
									,{' '}
									<a
										href={
											PROD_DOMAIN +
											'docs/Privacy_and_Cookie_Policy_StyleDyle_Ltd.pdf'
										}
										target="_blank"
										rel="noreferrer"
									>
										Privacy and Cookie Policy
									</a>
									, and to join our loyalty programme:
								</CookieBlock>
								<SubscribeCheckBox>
									<Checkbox checked={isSubscribed} onChange={onCheckboxChange}>
										Subscribe to our newsletter and stay updated on our best
										offers (we'll only use your email, name and style
										preferences)
									</Checkbox>
								</SubscribeCheckBox>
							</Col>
						</Row>
						<ButtonStyled skin="dark" isSubmit>
							Go to my stylist match
						</ButtonStyled>
					</FormWrapper>
				</Col>
			</Row>
			<Modal
				isModalOpen={isNeedAuth}
				onCancel={() => setIsNeedAuth(false)}
				onOk={() => {}}
				customFooter={<></>}
				children={
					<CodeForm
						handleCancelClick={() => setIsNeedAuth(false)}
						isNoRedirect={true}
					/>
				}
			/>
		</>
	)
}
