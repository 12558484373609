import React from 'react'
import {
	CometChatMessageHeader,
	AvatarStyle,
} from '@cometchat/chat-uikit-react'
import { colors } from '@styles/index'

export function MessagesHeader(user: any) {
	console.info('MessagesHeader chatUser: ', user)
	const avatarStyle = new AvatarStyle({
		backgroundColor: colors.white,
		border: 'none',
		borderRadius: '50%',
		nameTextColor: colors.black,
	})

	return user ? (
		<div>
			<CometChatMessageHeader
				user={user}
				avatarStyle={avatarStyle}
				hideBackButton
			/>
		</div>
	) : null
}
