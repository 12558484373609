import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { Icon } from '@ui/icon'
import {
	FooterWrapper,
	MenuWrapper,
	IconsWrapper,
	MenuItem,
} from './footer-styled'
import { BASE_URL } from '@typings/constants'

type FooterProps = {
	disabled?: boolean
}

type FooterMenuItems = {
	title: string
	link: string
}

export function Footer({ disabled }: FooterProps) {
	const { userInfo } = useAppSelector(userSelector)

	const temporaryMenuItems: FooterMenuItems[] = useMemo(
		() =>
			userInfo.role === 'stylist'
				? [
						{
							title: 'About Style Dyle',
							link: 'https://styledyle.com',
						},
						{
							title: 'Work with us',
							link: 'https://styledyle.com/stylist',
						},
						{
							title: 'Privacy & Cookie policy',
							link:
								BASE_URL + '/docs/Privacy_and_Cookie_Policy_StyleDyle_Ltd.pdf',
						},
						{
							title: 'Terms and conditions',
							link: BASE_URL + '/docs/Terms_And_Conditions_StyleDyle_Ltd.pdf',
						},
						{
							title: 'FAQs',
							link: 'https://styledyle.com/stylist#rec743040682',
						},
						{
							title: 'Contact Us',
							link: 'mailto:stylist.support@styledyle.com',
						},
				  ]
				: [
						{
							title: 'About Style Dyle',
							link: 'https://styledyle.com',
						},
						{
							title: 'Work with us',
							link: 'https://styledyle.com/stylist',
						},
						{
							title: 'Privacy & Cookie policy',
							link:
								BASE_URL + '/docs/Privacy_and_Cookie_Policy_StyleDyle_Ltd.pdf',
						},
						{
							title: 'Terms and conditions',
							link: BASE_URL + '/docs/Terms_And_Conditions_StyleDyle_Ltd.pdf',
						},
						{
							title: 'FAQs',
							link: 'https://styledyle.com/#rec606384876',
						},
						{
							title: 'Contact Us',
							link: 'mailto:client.care@styledyle.com',
						},
				  ],
		[userInfo.role]
	)

	if (disabled) {
		return null
	}

	return (
		<FooterWrapper>
			<MenuWrapper>
				{temporaryMenuItems.map((item, index) => (
					<MenuItem key={index}>
						<Link to={item.link} target="blank">
							{item.title}
						</Link>
					</MenuItem>
				))}
			</MenuWrapper>
			<IconsWrapper>
				<Link to="mailto:client.care@styledyle.com">
					<MenuItem>
						<Icon name="envelopeIcon" />
					</MenuItem>
				</Link>
			</IconsWrapper>
		</FooterWrapper>
	)
}
