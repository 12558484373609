import { useEffect } from 'react'
import { getLists } from '@api/get-lists'
import { setIsLoading, setSettings, settingsSelector } from '@store/settings'
import { useAppDispatch, useAppSelector } from '@store/store'

export async function useLoadSettings() {
	const dispatch = useAppDispatch()
	const { settings } = useAppSelector(settingsSelector)

	useEffect(() => {
		async function fetchData() {
			if (Object.keys(settings).length) return

			dispatch(setIsLoading(true))
			const response = await getLists()

			if (!response) {
				dispatch(setIsLoading(false))
				fetchData()
				return
			}

			dispatch(setSettings(response))
			dispatch(setIsLoading(false))
		}

		fetchData()
	}, [])
}
