import axios from 'axios'

import { handleToast } from '@utils/handle-toast'
import { axiosInstance } from '../../config'

export async function resetCapsule(
	token: string,
	capsuleId: number
): Promise<boolean | undefined> {
	try {
		const response = await axiosInstance.post<{
			success: boolean
			message?: string
		}>(
			`/api/capsule/${capsuleId}/reset`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return !!response.data.success
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
			handleToast({ content: error?.response?.data.message })
		} else {
			console.log('unexpected error: ', error)
		}
	}
}
