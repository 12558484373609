import { createGlobalStyle } from 'styled-components'

import { bodyStyled } from './body-styled'
import { colors } from './colors'
import { resetStyled } from './reset-styled'
import { fontTenorSans, fontRoboto, regular26, bold16 } from './typography'

export const GlobalStyle = createGlobalStyle<{ bodyMargin?: string }>`
  ${resetStyled}
  ${bodyStyled};

  .row-block-label {
		${fontTenorSans}
		${regular26}
	}

  .col-block-label-h3 {
    ${fontRoboto}
    ${bold16}
	}

  :where(.css-dev-only-do-not-override-byeoj0).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
		background-color: transparent;
	}

  & .ant-modal-root .ant-modal-mask {
		background-color: ${colors.blackTr80};
	}

  .ant-modal-root > .ant-modal-wrap,
	.ant-modal-root > .ant-modal-mask {
		z-index: 10001;
	}
`
