import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { canPublishCapsule } from '@api/capsule/can-publish-capsule'
import { cloneCapsule } from '@api/capsule/clone-capsule'
import { getAvailableClients } from '@api/capsule/get-available-clients'
import { getCapsuleInfo } from '@api/capsule/get-capsule'
import { publishCapsule } from '@api/capsule/publish-capsule'
import { resetCapsule } from '@api/capsule/reset-capsule'
import { shareCapsule } from '@api/capsule/share-capsule'
import { getClientDealInfo } from '@api/stylist/deal/get-client-deal-info'
import { useUpdateCapsuleInfo } from '@hooks/api/use-update-capsule-info'
import { authSelector } from '@store/auth'
import {
	capsulesSelector,
	setCapsule,
	setCapsuleClients,
	setIsCapsuleLoading,
} from '@store/capsules'
import { clothesSelector, setCapsuleClothesList } from '@store/clothes'
import { useAppDispatch, useAppSelector } from '@store/index'
import { settingsSelector } from '@store/settings'
import { DealInfo, NavigationButtonInfo, ShowPageType } from '@typings/types'
import { handleToast } from '@utils/handle-toast'

type FormValues = {
	title: string
	description: string
	client: string
}

export function useCapsuleEditPage() {
	const dispatch = useAppDispatch()
	const { state, pathname } = useLocation()
	const navigate = useNavigate()
	const { token } = useAppSelector(authSelector)
	const { isCapsuleLoading, capsule, clientsList } =
		useAppSelector(capsulesSelector)
	const { settings } = useAppSelector(settingsSelector)
	const { capsuleClothesList } = useAppSelector(clothesSelector)

	const [isClientLoading, setIsClientLoading] = useState(false)
	const [clientLabel, setClientLabel] = useState<string[]>([])
	const [clientAvatarUrl, setClientAvatarUrl] = useState('')
	const [isHomepageRadioActive, setHomepageRadioActive] =
		useState<boolean>(false)
	const [isCoverCapsule, setIsCoverCapsule] = useState<boolean>(false)
	const [isShareModalOpen, setIsShareModalOpen] = useState(false)
	const [clientsToSave, setClientsToSave] = useState()
	const [dealInfo, setDealInfo] = useState<DealInfo>()
	const [capsuleGender, setCapsuleGender] = useState(capsule.gender_target)

	const {
		register,
		watch,
		formState: { errors },
		setError,
		setValue,
		getValues,
	} = useForm<FormValues>()

	const capsuleId = state?.capsuleId || undefined
	const { handleSetCapsuleInfo, handleSetCapsuleParams } = useUpdateCapsuleInfo(
		{ id: capsuleId }
	)

	const [clientListToShow, setClientListToShow] = useState<
		{
			label: string
			value: string
			avatarUrl: string
		}[]
	>([])

	const capsuleGenderOptions = useMemo(() => {
		if (!settings) return

		const genderTargets = settings.CapsuleGenderTargets

		if (!genderTargets) return

		return Object.keys(genderTargets).map((item) => ({
			label: item,
			value: genderTargets[item],
		}))
	}, [settings])

	const handleGenderOptionsChange = (value: any) => {
		const result = handleSetCapsuleInfo('gender_target', value)
		if (!result) {
			handleToast({ content: 'Error while updating capsule gender options' })
			return
		}

		setCapsuleGender(value)
	}

	const loadCapsuleInfo = useCallback(async () => {
		dispatch(setIsCapsuleLoading(true))
		const [data, capsuleClients] = await Promise.all([
			await getCapsuleInfo({ token, capsuleId }),
			await getAvailableClients({ token }),
		])

		if (capsuleClients) {
			const filteredClients = capsuleClients.filter(
				(item) => item.fullName !== capsule?.linkedClient?.fullName
			)
			dispatch(setCapsuleClients(capsuleClients))
			setClientListToShow(
				filteredClients.map((item) => {
					const clientName = item.fullName

					return {
						label: clientName ?? item.id.toString(),
						value: item.id.toString(),
						avatarUrl: item.params.photo?.[0].urls.S || '',
					}
				})
			)
		}

		if (data) {
			const clientId = data?.linkedClient?.id
			const dealInfo = clientId
				? await getClientDealInfo({ token, clientId })
				: undefined

			if (dealInfo) setDealInfo(dealInfo)
			setValue('title', data.title)
			data.params?.client && setValue('client', data.params.client[0])
			dispatch(setCapsule(data))
			dispatch(setCapsuleClothesList(data.items))
			dispatch(setIsCapsuleLoading(false))
			const currentClient = data.linkedClient
				? [data.linkedClient?.fullName || data.linkedClient?.id.toString()]
				: []
			setClientLabel(currentClient)
			setClientAvatarUrl(data?.linkedClient?.params.photo?.[0].urls.S || '')
			setHomepageRadioActive(!!data.params?.showOnHomepage?.[0])
			setIsCoverCapsule(!!data.is_cover)
			setCapsuleGender(data.gender_target)
			return
		}

		dispatch(setIsCapsuleLoading(false))
		navigate('/capsules')
	}, [
		capsule?.linkedClient?.fullName,
		capsuleId,
		dispatch,
		navigate,
		setValue,
		token,
	])

	useEffect(() => {
		loadCapsuleInfo()
	}, [capsuleId, loadCapsuleInfo])

	const handleAddNewItem = () => {
		navigate('/clothes', {
			state: {
				status: 'edit_capsule',
				capsule: capsule,
				itemsIds: capsuleClothesList.map((item) => item.id),
			},
		})
	}

	const handleShare = async () => {
		if (watch('title') === '' || watch('title') === 'New capsule') {
			setError('title', { type: 'manual', message: 'Please fill in title' })
			return
		}

		if (capsuleClothesList.length < 4) {
			handleToast({
				content: 'You need to add at least 4 items to the capsule',
			})
			return
		}

		if (!capsule.params?.coverPhoto?.[0]) {
			handleToast({ content: 'You need to add a cover photo to the capsule' })
			return
		}

		if (!clientLabel.length && !isHomepageRadioActive) {
			handleToast({
				content:
					'You need to link a client or set the capsule to be shown on the homepage',
			})
			return
		}

		dispatch(setIsCapsuleLoading(true))

		const canPublish = await canPublishCapsule({ capsuleId, token })

		if (!canPublish) {
			dispatch(setIsCapsuleLoading(false))
			handleToast({ content: 'You can not share this capsule' })
			return
		}

		const result = await publishCapsule(token, capsuleId)

		if (!result) {
			dispatch(setIsCapsuleLoading(false))
			return
		}

		dispatch(setIsCapsuleLoading(false))
		navigate('/capsules')
	}

	const handleSaveAsDraft = async () => {
		navigate('/capsules')
	}

	const currentPage: ShowPageType =
		pathname === `/capsules/${capsuleId}/edit` ? 'edit' : 'preview'

	const handlePreviewClick = useCallback(() => {
		if (watch('title') === '') {
			setError('title', { type: 'manual', message: 'Please fill in title' })
			return
		}

		navigate(`/capsules/${capsuleId}`, {
			state: {
				capsuleId: capsuleId,
			},
		})
	}, [navigate, setError, capsuleId, watch])

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'Preview',
				iconName: 'previewIcon',
				showListType: 'preview',
				handleClick: handlePreviewClick,
			},
			{
				title: 'Edit',
				iconName: 'editIcon',
				showListType: 'edit',
				handleClick: () => {},
			},
		],
		[handlePreviewClick]
	)

	const handleLinkClients = (value: any) => {
		if (!value.length) return

		setClientsToSave(value)
		const newClientId = value[value.length - 1]
		const newClient = clientListToShow.find(
			(client) => client.value === newClientId
		)
		setClientLabel(newClient?.label ? [newClient.label] : [])
		setClientAvatarUrl(newClient?.avatarUrl || '')
		setIsShareModalOpen(true)
	}

	const handleUnlinkClient = async () => {
		try {
			dispatch(setIsCapsuleLoading(true))

			const clientIdToUnlink = capsule.linkedClient?.id
			if (!clientIdToUnlink) {
				handleToast({ content: 'Client not found' })
				dispatch(setIsCapsuleLoading(false))
				return
			}

			const result = await resetCapsule(token, capsuleId)

			if (!result) {
				dispatch(setIsCapsuleLoading(false))
				return
			}

			setClientLabel([])
			setClientAvatarUrl('')
			setClientListToShow(clientListToShow)
			setDealInfo(undefined)
			await loadCapsuleInfo()
			dispatch(setIsCapsuleLoading(false))
		} catch (error: any) {
			throw new Error(error.message)
		}
	}

	const saveClients = async (value: any) => {
		try {
			setIsClientLoading(true)
			const newClient = clientListToShow.find(
				(item) => item.value === value[value.length - 1]
			)

			if (!newClient?.value) {
				handleToast({ content: 'Client not found' })
				setIsClientLoading(false)
				return
			}

			const result = await shareCapsule(token, capsuleId, +newClient.value)

			if (!result) {
				handleToast({
					content: 'The operation is not applicable to this client',
				})
				setClientLabel(
					capsule.linkedClient?.fullName ? [capsule.linkedClient.fullName] : []
				)
				setIsClientLoading(false)
				return
			}

			setClientLabel([newClient?.label])
			setClientAvatarUrl(newClient?.avatarUrl || '')
			const filteredClients =
				clientsList?.filter((item) => item.fullName !== newClient.label) || []
			setClientListToShow(
				filteredClients.map((item) => {
					const clientName = item.fullName

					return {
						label: clientName ?? item.id.toString(),
						value: item.id.toString(),
						avatarUrl: item.params.photo?.[0].urls.S || '',
					}
				})
			)
			setIsClientLoading(false)
		} catch (error: any) {
			throw new Error(error.message)
		}
	}

	const homepageRadioHandleClick = async (e: any) => {
		setHomepageRadioActive(!isHomepageRadioActive)
		handleSetCapsuleParams('showOnHomepage', !isHomepageRadioActive)
	}

	const coverCapsuleHandleClick = async (e: any) => {
		setIsCoverCapsule(!isCoverCapsule)
		handleSetCapsuleInfo('is_cover', !isCoverCapsule)
	}

	const handleShareOk = () => {
		saveClients(clientsToSave)
		setIsShareModalOpen(false)
	}

	const handleShareCancel = () => {
		setClientLabel(
			capsule.linkedClient?.fullName ? [capsule.linkedClient.fullName] : []
		)

		setIsShareModalOpen(false)
	}

	const handleDuplicateCapsule = async () => {
		dispatch(setIsCapsuleLoading(true))

		const duplicate = await cloneCapsule({ capsuleId, token })

		if (!duplicate?.success) {
			handleToast({ content: 'Error while duplicating capsule' })
			dispatch(setIsCapsuleLoading(false))
			return
		}

		setDealInfo(undefined)
		navigate(`/capsules/${duplicate.id}/edit`, {
			state: {
				capsuleId: duplicate.id,
			},
		})
	}

	const isNotReadyPublish = useMemo(() => {
		const isClientSelected = !!clientLabel?.length && !isHomepageRadioActive
		const isHomepageSelected = isHomepageRadioActive && !clientLabel?.length
		const clientOption = isClientSelected || isHomepageSelected

		return (
			!getValues('title')?.length ||
			!capsule.params?.coverPhoto?.[0] ||
			!clientOption ||
			capsuleClothesList?.length < 4
		)
	}, [
		capsule.params?.coverPhoto,
		capsuleClothesList?.length,
		clientLabel?.length,
		getValues,
		isHomepageRadioActive,
	])

	return {
		clientAvatarUrl,
		capsule,
		capsuleClothesList,
		clientLabel,
		currentPage,
		dealInfo,
		errors,
		handleAddNewItem,
		handleShare,
		handleSaveAsDraft,
		handleLinkClients,
		handleUnlinkClient,
		handleSetCapsuleInfo,
		handleDuplicateCapsule,
		isCapsuleLoading,
		isClientLoading,
		navigationButtons,
		register,
		settings,
		capsuleGenderOptions,
		handleGenderOptionsChange,
		watch,
		clientListToShow,
		homepageRadioHandleClick,
		coverCapsuleHandleClick,
		isHomepageRadioActive,
		isCoverCapsule,
		isShareModalOpen,
		handleShareOk,
		handleShareCancel,
		isNotReadyPublish,
		capsuleGender,
	}
}
