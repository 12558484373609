import {
	bold16,
	colors,
	fontRoboto,
	fontTenorSans,
	regular18,
} from '@styles/index'
import { Button } from '@ui/index'
import styled from 'styled-components'

export const PromoContainer = styled.div`
	display: block;
	flex-wrap: nowrap;
	width: 100%;
	height: 100%;

	padding: 32px;

	border-radius: 20px;
	background-color: ${colors.blackTr5};
`

export const BlockLabel = styled.span`
	${fontTenorSans}
	${regular18}

  margin-right: 10px;
`
export const ButtonStyled = styled(Button)`
	${fontRoboto}
	${bold16}
	color: ${colors.blackTr64};

	padding: 0;
`
