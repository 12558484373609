import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	setFilteredStyleboardsList,
	setIsListLoading,
	setShowListType,
	setStyleboardsList,
	styleboardsSelector,
} from '@store/styleboards'
import { useAppDispatch, useAppSelector } from '@store/store'
import { authSelector } from '@store/auth'
import { getStyleboardsList } from '../../api/styleboard/get-styleboards-list'
import { NavigationButtonInfo } from '@typings/types'

export function useStyleboardsPage() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { token } = useAppSelector(authSelector)
	const {
		isListLoading: isLoading,
		showListType,
		styleboardsList,
		filteredStyleboardsList,
	} = useAppSelector(styleboardsSelector)

	const loadStyleboards = useCallback(async () => {
		dispatch(setShowListType('all'))
		dispatch(setIsListLoading(true))
		const result = await getStyleboardsList({ token })

		if (result) {
			dispatch(setStyleboardsList(result))

			if (showListType === 'all') {
				dispatch(
					setFilteredStyleboardsList(
						result
							.filter(
								(item) => item.status !== 'draft' && item.client_id !== null
							)
							.sort((a, b) => b.id - a.id)
					)
				)
			} else {
				dispatch(
					setFilteredStyleboardsList(
						result
							.filter((item) => item.status === 'draft')
							.sort((a, b) => b.id - a.id)
					)
				)
			}
		}

		dispatch(setIsListLoading(false))
	}, [dispatch, showListType, token])

	useEffect(() => {
		if (isLoading) return

		loadStyleboards()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleStyleboardClick = (id: number) => {
		navigate(`${id}`, {
			state: {
				styleboardId: id,
			},
		})
	}

	const handleAddNewStyleboard = async () => {
		navigate(`new/edit`, {
			state: {
				styleboardId: 'new',
			},
		})
	}

	const handleClickAll = useCallback(() => {
		if (showListType === 'all') return

		dispatch(setShowListType('all'))
		dispatch(
			setFilteredStyleboardsList(
				styleboardsList
					.filter((item) => item.status !== 'draft' && item.client_id !== null)
					.sort((a, b) => b.id - a.id)
			)
		)
	}, [dispatch, showListType, styleboardsList])

	const handleClickDraft = useCallback(() => {
		if (showListType === 'draft') return

		dispatch(setShowListType('draft'))
		dispatch(
			setFilteredStyleboardsList(
				styleboardsList
					.filter((item) => item.status === 'draft')
					.sort((a, b) => b.id - a.id)
			)
		)
	}, [dispatch, showListType, styleboardsList])

	const handleChangeSearch = (data: any) => {
		dispatch(
			setFilteredStyleboardsList(
				styleboardsList.filter(
					(item) =>
						item.title &&
						item.title.toLowerCase().includes(data.target.value.toLowerCase())
				)
			)
		)
	}

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'Styleboards',
				iconName: 'tShirtIcon',
				showListType: 'all',
				handleClick: handleClickAll,
			},
			{
				title: 'Draft',
				iconName: 'checkCircleIcon',
				showListType: 'draft',
				handleClick: handleClickDraft,
			},
		],
		[handleClickAll, handleClickDraft]
	)

	return {
		handleAddNewStyleboard,
		handleChangeSearch,
		handleStyleboardClick,
		isLoading,
		showListType,
		styleboardsList: filteredStyleboardsList,
		navigationButtons,
	}
}
